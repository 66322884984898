<template>
  <s-info-modifiable
    titre="Chef.fe de service"
    :valeur="service.chefDeService.nomComplet"
    titre-action="Désigner"
    @validateUpdate="envoyerUneCommandeDeChangementDeChefDeService"
    @cancelUpdate="annulerModification"
  >
    <template v-slot:champ>
      <employe-autocomplete
        v-model="chefDuService"
        label="Chef.fe de service"
        required
        @keydown.escape="$emit('cancelUpdate')"
        @keydown.enter="$emit('validateUpdate')"
      />
    </template>
  </s-info-modifiable>
</template>

<script>
import { mapActions } from 'vuex'
import SInfoModifiable from '../../../components/global/s-info-modifiable.vue'
import EmployeAutocomplete from '../../../components/employe/employe-autocomplete.vue'

export default {
  name: 'service-designer-chef-de-service',
  components: { EmployeAutocomplete, SInfoModifiable },
  props: {
    service: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chefDuService: undefined,
    }
  },
  created() {
    this.chefDuService = this.service.chefDeService
  },
  watch: {
    service: {
      handler() {
        this.chefDuService = this.service.chefDeService
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      designerChefDeService: 'service/designerChefDeService',
    }),
    envoyerUneCommandeDeChangementDeChefDeService() {
      if (this.chefDuService.employeId !== this.service.chefDeService.employeId) {
        this.designerChefDeService({
          serviceId: this.service.serviceId,
          nouveauChefDeServiceId: this.chefDuService.employeId,
        })
      }
    },
    annulerModification() {
      this.chefDuService = this.service.chefDeService
    },
  },
}
</script>

<style scoped></style>

<template>
  <div class="planning-body-jour-container">
    <planning-body-jour
      v-if="jourNuit === 'jour'"
      :creneaux="creneaux"
      :joursDeRepos="joursDeRepos"
      :semaine="semaine"
      :jour="jour"
      :membres="membres"
      :displayJoursDeRepos="displayJoursDeRepos"
      :slot-height="slotHeight"
    />
    <planning-body-nuit
      v-if="jourNuit === 'nuit'"
      :creneaux="creneaux"
      :joursDeRepos="joursDeRepos"
      :semaine="semaine"
      :jour="jour"
      :membres="membres"
      :displayJoursDeRepos="displayJoursDeRepos"
      :slot-height="slotHeight"
    />
  </div>
</template>

<script>
import planningBodyNuit from '@/components/planning/body/planning-body-nuit.vue'
import PlanningBodyJour from '@/components/planning/body/planning-body-jour.vue'

export default {
  name: 'planning-body-jour-container',
  components: {
    PlanningBodyJour,
    planningBodyNuit,
  },
  props: {
    slotHeight: {
      type: Number,
      requred: true,
    },
    creneaux: {
      type: Array,
      required: true,
    },
    joursDeRepos: {
      type: Array,
      required: true,
    },
    semaine: {
      type: Object,
      required: true,
    },
    jour: {
      type: Object,
      required: true,
    },
    jourNuit: {
      type: String,
      required: true,
    },
    membres: {
      type: Array,
      required: true,
    },
    displayJoursDeRepos: {
      type: Boolean,
      required: false,
      default: false,
    },
    apercu: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.planning-body-jour-container {
  flex: 1 1 60%;
}
</style>

<template>
  <v-menu
    v-model="showMenu"
    :close-on-content-click="false"
    :nudge-width="200"
    offset-x
    @click="(e) => e.stopPropagation()"
    @mousedown="(e) => e.stopPropagation()"
  >
    <template #activator="{ on }">
      <div class="ma-0 pa-0" v-on="on">
        <slot />
      </div>
    </template>
    <v-card v-if="showMenu" width="330px">
      <s-toolbar text="Modifier le créneau" update @close="close()" />
      <v-card-text class="pt-0">
        <modele-creneau-form v-model="modeleCreneauToUpdate" @updateFormValid="updateFormValid" />
      </v-card-text>
      <v-card-actions>
        <v-btn icon @click="dispatchDelete()">
          <v-icon small color="error">fa-trash</v-icon>
        </v-btn>
        <modele-creneau-create-dialog :copy="modeleCreneauToUpdate">
          <v-btn icon>
            <v-icon small color="success">fa-copy</v-icon>
          </v-btn>
        </modele-creneau-create-dialog>
        <v-spacer />
        <v-btn color="grey darken-1" text @click="close()"> Annuler </v-btn>
        <v-btn color="info" :disabled="!formValid" @click="validate()"> Mettre à jour </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import ModeleCreneauForm from './modele-creneau-form'
import ModeleCreneauCreateDialog from './modele-creneau-create-dialog'
import { mapActions } from 'vuex'

export default {
  name: 'modele-creneau-update-menu',
  components: {
    ModeleCreneauForm,
    ModeleCreneauCreateDialog,
  },
  props: {
    modeleCreneau: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showMenu: false,
      formValid: false,
      modeleCreneauToUpdate: undefined,
    }
  },
  watch: {
    modeleCreneau() {
      this.initialize()
    },
  },
  created() {
    this.initialize()
  },
  methods: {
    ...mapActions({
      updateModeleCreneau: 'modelePlanning/updateModeleCreneau',
      deleteModeleCreneau: 'modelePlanning/deleteModeleCreneau',
    }),
    initialize() {
      this.modeleCreneauToUpdate = JSON.parse(JSON.stringify(this.modeleCreneau))
    },
    updateFormValid(_validity) {
      this.formValid = _validity
    },
    validate() {
      if (this.formValid) {
        this.updateModeleCreneau(this.modeleCreneauToUpdate)
        this.close()
      }
    },
    dispatchDelete() {
      this.deleteModeleCreneau({
        modeleCreneauDeTravailId: this.modeleCreneau.modeleCreneauDeTravailId,
        ordreSemaine: this.modeleCreneau.ordreSemaine,
      })
      this.close()
    },
    close() {
      this.initialize()
      this.showMenu = false
    },
  },
}
</script>

<style scoped></style>

export default {
  namespaced: true,
  state: {
    missingData: [
      { action: 'conges/fetchCongesType', payload: undefined },
      { action: 'calendrier/fetchJoursFeries', payload: undefined },
      { action: 'calendrier/fetchBorneHoraireList', payload: undefined },
      { action: 'calendrier/fetchJourList', payload: undefined },
      { action: 'demande/fetchDemandes', payload: undefined },
    ],
    selectedEtablissement: undefined,
    planning: {
      jour_nuit: 'jour',
      afficherAbsences: false,
    },
  },
  mutations: {
    SET_SELECTED_ETABLISSEMENT(state, _etablissement) {
      if (
        !state.selectedEtablissement ||
        state.selectedEtablissement.etablissementId !== _etablissement.etablissementId
      ) {
        state.selectedEtablissement = _etablissement
        state.missingData.push(
          { action: 'employe/fetchEmployesByEtablissement', payload: _etablissement },
          { action: 'service/fetchServicesByEtablissement', payload: _etablissement },
          { action: 'membre/fetchMembresByEtablissement', payload: _etablissement }
        )
        localStorage.setItem('selectedEtablissement', JSON.stringify(_etablissement))
      }
    },
    REMOVE_LOADING_DATA(state, _toRemove) {
      state.missingData.splice(state.missingData.indexOf(_toRemove), 1)
    },
    SET_PLANNING_JOUR_NUIT(state, _value) {
      if (_value === 'jour' || _value === 'nuit') {
        state.planning.jour_nuit = _value
      }
    },
    SWTCH_PLANNING_AFFICHER_ABSENCES(state) {
      state.planning.afficherAbsences = !state.planning.afficherAbsences
    },
  },
  actions: {
    fetchMissingData({ commit, state }) {
      state.missingData.forEach((d) => {
        if (d.payload) {
          this.dispatch(d.action, d.payload).then(() => commit('REMOVE_LOADING_DATA', d))
        } else {
          this.dispatch(d.action).then(() => commit('REMOVE_LOADING_DATA', d))
        }
      })
    },
    retrieveSelectedEtablissementFromStorage({ dispatch }) {
      const selectedEtablissementString = localStorage.getItem('selectedEtablissement')
      console.log('store.application.retrieveSelectedEtablissementFromStorage')
      if (selectedEtablissementString) {
        dispatch('selectEtablissement', JSON.parse(selectedEtablissementString))
      }
    },
    selectEtablissement({ commit, getters, dispatch }, _etablissement) {
      if (
        !getters.getSelectedEtablissement ||
        getters.getSelectedEtablissement.etablissementId !== _etablissement.etablissementId
      ) {
        commit('SET_SELECTED_ETABLISSEMENT', _etablissement)
        dispatch('fetchMissingData')
      }
    },
    setPlanningJourNuit({ commit }, _toSet) {
      commit('SET_PLANNING_JOUR_NUIT', _toSet)
    },
    switchPlanningAfficherAbsences({ commit }) {
      commit('SWTCH_PLANNING_AFFICHER_ABSENCES')
    },
  },
  getters: {
    loaded(state) {
      return state.missingData.length === 0
    },
    getSelectedEtablissement(state) {
      return state.selectedEtablissement
    },
    getGranularite(state) {
      if (state.selectedEtablissement && state.selectedEtablissement.granulariteMinutes) {
        return state.selectedEtablissement.granulariteMinutes
      } else {
        return 15
      }
    },
    getPlanningParameters(state) {
      return state.planning
    },
    //FROM: https://gomakethings.com/dynamically-changing-the-text-color-based-on-background-color-contrast-with-vanilla-js/
    getTextColor: () => (_backgroundColor) => {
      // Convertion en RGB
      let r = parseInt(_backgroundColor.substr(1, 2), 16)
      let g = parseInt(_backgroundColor.substr(3, 2), 16)
      let b = parseInt(_backgroundColor.substr(5, 2), 16)

      // YIQ ratio
      let yiq = (r * 299 + g * 587 + b * 114) / 1000

      return yiq >= 128 ? 'black' : 'white'
    },
  },
}

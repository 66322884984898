<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col cols="12" class="py-0">
        <div class="planning-astreintes-header planning-astreintes-ligne">
          <div class="planning-astreintes-header planning-astreintes-colonne-semaine">Sem.</div>
          <div
            class="planning-astreintes-header planning-astreintes-colonne-jour"
            v-for="jour in jours"
            :key="jour.jourId"
          >
            {{ jour.shortLabel }}
          </div>
          <div v-if="!disabled" class="planning-astreintes-header planning-astreintes-colonne-assignement" />
          <div v-if="!disabled" class="planning-astreintes-header planning-astreintes-colonne-validation" />
        </div>
      </v-col>
      <v-col cols="12" v-if="loaded && planning" class="pt-0">
        <astreintes-planning-semaine
          class="planning-astreintes-ligne"
          v-for="astreintesHebdomadaire in planning.astreintesHedbdomadaires"
          :key="astreintesHebdomadaire.semaine.numero"
          :astreintesHebdomadaire="astreintesHebdomadaire"
          :annee="annee"
          :mois="mois"
          :etablissementId="etablissement.etablissementId"
          :disabled="disabled"
        />
      </v-col>
      <v-col cols="12" v-else class="pt-0">
        <div v-for="index in 5" :key="index" class="planning-astreintes-ligne">
          <div class="planning-astreintes-colonne-semaine">-</div>
          <div class="planning-astreintes-colonne-jour" v-for="jour in jours" :key="jour.jourId" :id="jour.jourId" />
          <v-icon v-if="!disabled" class="planning-astreintes-colonne-assignement" disabled>mdi-calendar-week</v-icon>
          <div v-if="!disabled" class="planning-astreintes-colonne-validation">
            <v-btn icon small disabled>
              <v-icon>mdi-check-circle-outline</v-icon>
            </v-btn>
          </div>
        </div>
      </v-col>
      <v-col v-if="afficherLaRedirection" cols="12" class="py-0">
        <p class="text-center">
          Les appels d'urgence sont actuellement redirigés vers le:
          <b style="font-weight: bold">{{ numeroDastreinte }}</b>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import _ from 'lodash'
import AstreintesPlanningSemaine from './astreintes-planning-semaine.vue'

export default {
  name: 'astreintes-planning',
  components: {
    AstreintesPlanningSemaine,
  },
  props: {
    etablissement: {
      type: Object,
      required: true,
    },
    annee: {
      type: Number,
      required: true,
    },
    mois: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      numeroDastreinte: '-',
    }
  },
  computed: {
    ...mapGetters({
      jours: 'calendrier/getJours',
      planning: 'astreinte/getPlanningDastreintes',
      employes: 'employe/getEmployes',
      isInRole: 'security/isInRole',
    }),
    etablissementId() {
      return this.etablissement.etablissementId
    },
    afficherLaRedirection() {
      return this.isInRole('ADMINISTRATEUR_1') && this.etablissementId === 1
    },
    numeroDastreinteToD() {
      return '067252'
    },
    loaded() {
      return this.planning !== undefined && this.employes.length > 0
    },
  },
  created() {
    this.fetchPlanning()
    if (this.afficherLaRedirection) {
      this.obtenirLeNumeroDastreinteActuellement(this.etablissementId).then(
        (numero) => (this.numeroDastreinte = numero)
      )
    }
  },
  watch: {
    etablissement() {
      this.discardPlanningAstreintesMensuel()
      this.fetchPlanning()
    },
    annee() {
      this.discardPlanningAstreintesMensuel()
      this.fetchPlanning()
    },
    mois() {
      this.discardPlanningAstreintesMensuel()
      this.fetchPlanning()
    },
  },
  methods: {
    ...mapActions({
      discardPlanningAstreintesMensuel: 'astreinte/discardPlanningAstreintesMensuel',
      fetchPlanningAstreintesMensuel: 'astreinte/fetchPlanningAstreintesMensuel',
      obtenirLeNumeroDastreinteActuellement: 'etablissement/obtenirLeNumeroDastreinteActuellement',
    }),
    fetchPlanning: _.debounce(function () {
      this.fetchPlanningAstreintesMensuel({
        etablissementId: this.etablissement.etablissementId,
        annee: this.annee,
        mois: this.mois + 1,
      })
    }, 500),
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.planning-astreintes-header {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  justify-content: end;
  border: 0 !important;
}

.planning-astreintes-ligne {
  display: flex;
  flex-direction: row;
  min-height: 30px;
  margin-bottom: 5px;
}

.planning-astreintes-colonne-semaine {
  display: flex;
  margin-right: 25px;
  min-width: 50px;
  justify-content: end;
  align-items: center;
}

.planning-astreintes-colonne-jour {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
  justify-content: center;
  border: solid 1px #eeeeee;
  margin-left: 5px;
}

.planning-astreintes-colonne-jour#1 {
  margin-left: 0 !important;
}

.planning-astreintes-colonne-assignement {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 50px;
}

.planning-astreintes-colonne-validation {
  display: flex;
  flex-direction: row;
  width: 50px;
  align-items: center;
  justify-content: center;
}
</style>

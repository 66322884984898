import axios from 'axios'

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    findAllAclClass() {
      return axios.get(`${process.env.VUE_APP_URL_BACK_SERENITY}/acl`).then((response) => {
        return response.data
      })
    },
    // eslint-disable-next-line no-unused-vars
    refreshAcls({ commit }, _refreshAclsCommand) {
      return axios.post(`${process.env.VUE_APP_URL_BACK_SERENITY}/acl/refresh`, _refreshAclsCommand)
    },
    // eslint-disable-next-line no-unused-vars
    envoyerUnMailDeTest({ commit }, _envoyerUnMailDeTestCommand) {
      return axios.post(
        `${process.env.VUE_APP_URL_BACK_SERENITY}/administration/envoyerMailTest`,
        _envoyerUnMailDeTestCommand
      )
    },
    updateDemandesStatut() {
      return axios.put(`${process.env.VUE_APP_URL_BACK_SERENITY}/demande`)
    },
  },
  getters: {},
}

<template>
  <div
    class="modele-planning-creneau"
    v-bind:style="{
      top: topPosition + 'px',
      height: height + 2 * resizerHeight + 'px',
      'min-height': height + 2 * resizerHeight + 'px',
      background: background,
      pointerEvents: listenPointerEvent,
    }"
    v-bind:class="{ focused: focused }"
    @mousedown="(e) => e.stopPropagation()"
    @click="(e) => e.stopPropagation()"
    @contextmenu="(e) => e.stopPropagation()"
  >
    <div
      ref="modele-planning-creneau-resizer-top"
      class="modele-planning-creneau-resizer-top"
      draggable="true"
      @dragstart="(e) => dragStart(e, 'top')"
      @dragend="dragEnd()"
      v-bind:style="{
        pointerEvents: listenPointerEvent,
        height: resizerHeight + 'px',
      }"
    />
    <modele-creneau-update-menu ref="details" :modeleCreneau="creneau">
      <div ref="creneau" v-bind:style="{ height: height + 'px' }" />
    </modele-creneau-update-menu>
    <div
      ref="modele-planning-creneau-resizer-bottom"
      class="modele-planning-creneau-resizer-bottom"
      draggable="true"
      @dragstart="(e) => dragStart(e, 'bottom')"
      @dragend="dragEnd()"
      v-bind:style="{
        pointerEvents: listenPointerEvent,
        height: resizerHeight + 'px',
      }"
    />
  </div>
</template>

<script>
import EventBus from '@/EventBus'
import { mapGetters } from 'vuex'
import ModeleCreneauUpdateMenu from '@/components/modelePlanning/creneau/modele-creneau-update-menu.vue'

const RESIZER_HEIGHT = 6
export default {
  name: 'modele-planning-creneau',
  components: {
    ModeleCreneauUpdateMenu,
  },
  props: {
    slotHeight: {
      type: Number,
      required: true,
    },
    borneDebut: {
      type: Object,
      required: true,
    },
    creneau: {
      type: Object,
      required: true,
    },
    couleur: {
      type: String,
      required: true,
    },
    listenForMouseEvent: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      dragging: '',
      creneauEdited: undefined,
    }
  },
  computed: {
    ...mapGetters({
      getEmployeById: 'employe/getEmployeById',
    }),
    resizerHeight() {
      return RESIZER_HEIGHT
    },
    topPosition() {
      if (this.creneauEdited === undefined) {
        return this.getDistance(this.borneDebut, this.creneau.heureDebut)
      } else {
        return this.getDistance(this.borneDebut, this.creneauEdited.heureDebut)
      }
    },
    height() {
      //On retire 12px pour les deux éléments draggable de 6px en tête et pied d'élément créneau
      if (this.creneauEdited === undefined) {
        return this.getDistance(this.creneau.heureDebut, this.creneau.heureFin) - 2 * this.resizerHeight
      } else {
        return this.getDistance(this.creneauEdited.heureDebut, this.creneauEdited.heureFin) - 2 * this.resizerHeight
      }
    },
    background() {
      return this.couleur
    },
    focused() {
      return this.dragging !== '' || this.creneau.modeleCreneauDeTravailId === undefined
    },
    listenPointerEvent() {
      if (this.listenForMouseEvent && this.creneau.modeleCreneauDeTravailId !== undefined && this.dragging === '') {
        return 'all'
      } else {
        return 'none'
      }
    },
  },
  watch: {
    creneau() {
      this.creneauEdited = undefined
    },
  },
  mounted() {
    this.$refs['modele-planning-creneau-resizer-top'].addEventListener('dragstart', this.dragStartTop, false)
    this.$refs['modele-planning-creneau-resizer-bottom'].addEventListener('dragstart', this.dragStartBottom, false)
  },
  methods: {
    getDistance(_borneReference, _borne) {
      return ((_borne.valeur - _borneReference.valeur) / 15) * this.slotHeight
    },
    dragStart(event) {
      event.dataTransfer.setData('text/html', null)
      EventBus.$on('DRAGGING', (draggingBorneY) => this.receiveDragging(draggingBorneY))
    },
    dragStartTop(event) {
      this.dragStart(event)
      this.dragging = 'top'
    },
    dragStartBottom(event) {
      this.dragStart(event)
      this.dragging = 'bottom'
    },
    receiveDragging(draggingBorneY) {
      if (this.creneauEdited === undefined) {
        this.creneauEdited = JSON.parse(JSON.stringify(this.creneau))
      }

      switch (this.dragging) {
        case 'top':
          if (draggingBorneY.valeur < this.creneauEdited.heureFin.valeur) {
            this.creneauEdited.heureDebut = draggingBorneY
          }
          break
        case 'bottom':
          if (draggingBorneY.valeur > this.creneauEdited.heureDebut.valeur) {
            this.creneauEdited.heureFin = draggingBorneY
          }
          break
        default:
          break
      }
    },
    dragEnd() {
      this.dragging = ''
      EventBus.$off('DRAGGING')
      if (this.creneauEdited !== undefined && this.creneauEdited.modeleCreneauDeTravailId > 0) {
        this.$refs.details.updateModeleCreneau(this.creneauEdited)
      }
      this.$emit('dragend')
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.modele-planning-creneau {
  position: absolute;
  cursor: pointer;
  overflow: auto;
  width: 100%;
}

.hidden-ghost {
  display: none;
}

.modele-planning-creneau-resizer-top,
.modele-planning-creneau-resizer-bottom {
  pointer-events: all;
  cursor: s-resize;
  background-color: black;
  opacity: 0.4;
}

.focused {
  z-index: 5004 !important;
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.5);
}
</style>

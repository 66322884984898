<template>
  <div class="modele-planning-body-nuit" v-bind:class="{ 'modele-planning-body-nuit-lundi': jour.jourId === 1 }">
    <!-- Slots et container du matin (avant le planning de jour) -->
    <div class="modele-planning-body-nuit-container">
      <planning-body-slot
        v-for="borneHoraire in bornesHoraireMatin"
        :key="borneHoraire.borneHoraireId"
        :borneHoraire="borneHoraire"
        :jour="jour"
        :jour_nuit="isJourNuit(borneHoraire)"
        :slot-height="slotHeight"
      />
      <modele-planning-creneau-container
        v-if="bornesHoraireMatin"
        :creneaux="creneaux"
        :borneDebut="bornesHoraireMatin[0]"
        :borneFin="bornesHoraireMatin[bornesHoraireMatin.length - 1]"
        :semaine="semaine"
        :jour="jour"
        :membres="membres"
        :selectedMembre="selectedMembre"
        :slot-height="slotHeight"
      />
    </div>
    <div class="modele-planning-body-nuit-spacer" />
    <!-- Slots et container du soir (après le planning de jour) -->
    <div class="modele-planning-body-nuit-container">
      <planning-body-slot
        v-for="borneHoraire in bornesHoraireSoir"
        :key="borneHoraire.borneHoraireId"
        :borneHoraire="borneHoraire"
        :jour="jour"
        :jour_nuit="isJourNuit(borneHoraire)"
        :slot-height="slotHeight"
      />
      <modele-planning-creneau-container
        v-if="bornesHoraireSoir"
        :creneaux="creneaux"
        :borneDebut="bornesHoraireSoir[0]"
        :borneFin="bornesHoraireSoir[bornesHoraireSoir.length - 1]"
        :semaine="semaine"
        :jour="jour"
        :membres="membres"
        :selectedMembre="selectedMembre"
        :slot-height="slotHeight"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PlanningBodySlot from '@/components/planning/body/planning-body-slot.vue'
import ModelePlanningCreneauContainer from '../creneau/modele-planning-creneau-container.vue'

export default {
  name: 'modele-planning-body-nuit',
  components: {
    ModelePlanningCreneauContainer,
    PlanningBodySlot,
  },
  props: {
    slotHeight: {
      type: Number,
      required: true,
    },
    semaine: {
      type: Object,
      required: true,
    },
    jour: {
      type: Object,
      required: true,
    },
    membres: {
      type: Array,
      required: true,
    },
    selectedMembre: undefined,
  },
  computed: {
    ...mapGetters({
      modelePlanning: 'modelePlanning/getModelePlanning',
      getBornesHoraireMatin: 'calendrier/getBornesHoraireMatin',
      getBornesHoraireSoir: 'calendrier/getBornesHoraireSoir',
      isJourNuit: 'calendrier/isJourNuit',
    }),
    creneaux() {
      return this.semaine.creneaux.filter((creneau) => creneau.jourId === this.jour.jourId)
    },
    bornesHoraireMatin: function () {
      return this.getBornesHoraireMatin()
    },
    bornesHoraireSoir: function () {
      return this.getBornesHoraireSoir()
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.modele-planning-body-nuit {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex: 1;
  position: relative;

  border-left: 5px solid #c0c0c0;
}

.modele-planning-body-nuit-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex: 1;
  position: relative;
}

.modele-planning-body-nuit-spacer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: repeating-linear-gradient(45deg, #ffee93, #ffee93 8px, #edaa00 8px, #edaa00 16px);

  border-bottom: solid 1px #edaa00;
  opacity: 0.3;
  height: 30px;
}
</style>

import axios from 'axios'

export default {
  namespaced: true,
  state: {
    usagers: [],
  },
  mutations: {
    ADD_USAGER(state, _usager) {
      state.usagers.push(_usager)
    },
    SET_USAGERS(state, _usagers) {
      state.usagers = _usagers
    },
    REMOVE_USAGER(state, _usagerId) {
      for (let i = 0; i < state.usagers.length; i++) {
        if (state.usagers[i].usagerId === _usagerId) {
          state.usagers.splice(i, 1)
          break
        }
      }
    },
  },
  getters: {
    getUsagers: (state) => {
      return state.usagers
    },
    getUsagerById: (state) => (_usagerId) => {
      return state.usagers.find((usager) => usager.usagerId === _usagerId)
    },
  },
  actions: {
    fetchUsagers({ commit }) {
      return axios.get(`${process.env.VUE_APP_URL_BACK_SERENITY}/usager`).then((response) => {
        commit('SET_USAGERS', response.data)
      })
    },
  },
}

<template>
  <v-dialog v-model="showDialog" persistent max-width="600px">
    <template v-slot:activator="{ on }">
      <v-btn x-small color="success" dark v-on="on" fab elevation="0" class="ml-2"><v-icon>fa-plus</v-icon></v-btn>
    </template>
    <v-card v-if="showDialog">
      <s-toolbar text="Ajouter un membre" create @close="close()" />
      <v-card-text>
        <membre-form v-model="createMembreCommand" create @updateFormValid="updateFormValid" />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" text @click="close()">Annuler</v-btn>
        <v-btn color="success" :disabled="!formValid" @click="validate()">Créer</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import membreForm from './membre-form'
import { mapActions } from 'vuex'

export default {
  name: 'membre-create-dialog',
  components: {
    membreForm,
  },
  props: {
    service: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDialog: false,
      createMembreCommand: undefined,
      formValid: false,
    }
  },
  watch: {
    showDialog() {
      if (this.showDialog) {
        this.initialize()
      }
    },
  },
  methods: {
    ...mapActions({
      createMembre: 'membre/createMembre',
    }),
    updateFormValid(_validity) {
      this.formValid = _validity
    },
    initialize() {
      this.createMembreCommand = {
        employeId: undefined,
        serviceId: this.service.serviceId,
        couleur: '#EEEEEE',
        heuresHebdo: undefined,
      }
    },
    validate() {
      if (this.formValid) {
        this.createMembreCommand.employeId = this.createMembreCommand.employe.employeId
        this.createMembre(this.createMembreCommand)
        this.close()
      }
    },
    close() {
      this.showDialog = false
    },
  },
}
</script>

<style scoped></style>

<template>
  <v-form ref="form" v-model="formValid">
    <v-row dense>
      <v-col cols="6">
        <s-text-info v-if="!create" label="Service" v-model="selectedService.nom" />
        <service-autocomplete v-else v-model="selectedService" :list-services="selectedService" required disabled />
      </v-col>
      <v-col cols="6">
        <s-text-info v-if="!create" label="Employé" v-model="membre.employe.nomComplet" />
        <membre-employe-autocomplete v-else v-model="membre.employe" :service-id="selectedService.serviceId" required />
      </v-col>
      <v-col cols="2">
        <membreColorPicker v-model="membre.couleur" />
      </v-col>
      <v-col cols="10">
        <v-text-field
          v-model.number="membre.heuresHebdo"
          :rules="[(v) => !isNaN(v) && v >= 0]"
          hide-no-data
          type="number"
          label="Heure(s) par semaine*"
          required
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import serviceAutocomplete from '../service/service-autocomplete'
import membreEmployeAutocomplete from './membre-employe-autocomplete'
import membreColorPicker from './membre-color-picker'
import { mapGetters } from 'vuex'

export default {
  name: 'membre-form',
  components: {
    serviceAutocomplete,
    membreEmployeAutocomplete,
    membreColorPicker,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    create: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      formValid: false,
      membre: undefined,
      numericFieldRule: [(v) => !isNaN(v) || 'Entrer une valeur numérique'],
    }
  },
  computed: {
    ...mapGetters({
      getEmployeById: 'employe/getEmployeById',
      getServiceById: 'service/getServiceById',
    }),
    selectedService: {
      get: function () {
        if (this.membre) {
          return this.getServiceById(this.membre.serviceId)
        } else {
          return undefined
        }
      },
      set: function (_selectedService) {
        this.membre.serviceId = _selectedService.serviceId
      },
    },
  },
  created() {
    this.membre = this.value
  },
  watch: {
    membre: {
      handler() {
        this.$emit('input', this.membre)
      },
      deep: true,
    },
    value() {
      this.membre = this.value
    },
    formValid() {
      this.$emit('updateFormValid', this.formValid)
    },
  },
}
</script>

<style scoped></style>

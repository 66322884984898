import axios from 'axios'

export default {
  namespaced: true,
  state: {
    user: null,
    utilisateur: null,
  },
  mutations: {
    SET_USER_DATA(state, userData) {
      state.user = userData
      localStorage.setItem('user', JSON.stringify(userData))
      axios.defaults.headers.common['Authorization'] = `${userData.tokenType} ${userData.accessToken}`
    },
    SET_UTILISATEUR(state, _utilisateur) {
      state.utilisateur = _utilisateur
    },
    CLEAR_USER_DATA() {
      axios.defaults.headers.common['Authorization'] = ``
      localStorage.clear()
      location.reload()
    },
  },
  actions: {
    login({ dispatch, commit }, credentials) {
      return axios
        .post(`${process.env.VUE_APP_URL_BACK_SERENITY}/authentification/connexion`, credentials)
        .then(({ data }) => {
          commit('SET_USER_DATA', data)
          dispatch('fetchUtilisateurCourant', data.username)
        })
    },
    retrieveTokenFromStorage({ dispatch, commit }) {
      const userString = localStorage.getItem('user')
      if (userString) {
        const userData = JSON.parse(userString)
        commit('SET_USER_DATA', userData)
        dispatch('fetchUtilisateurCourant', userData.username)
      }
    },
    fetchUtilisateurCourant({ commit }, _email) {
      return axios
        .get(`${process.env.VUE_APP_URL_BACK_SERENITY}/utilisateur/email?_search=${_email}`)
        .catch((error) => {
          console.log(error)
        })
        .then((response) => {
          commit('SET_UTILISATEUR', response.data)
          this.dispatch('etablissement/fetchEtablissements')
          this.dispatch('etablissement/fetchAssociations')
        })
    },
    logout({ commit }) {
      console.log('vuex/security/logout')
      commit('CLEAR_USER_DATA')
    },
  },
  getters: {
    authenticated(state) {
      return !!state.user
    },
    isAdmin(state) {
      return state.user.roles.includes('ROLE_Administrateur')
    },
    isInRole: (state) => (_role) => {
      return (
        state.user &&
        (state.user.username === _role ||
          state.user.roles.includes('ROLE_' + _role) ||
          state.user.roles.includes('ROLE_Administrateur'))
      )
    },
    getAuthenticatedUser(state) {
      return state.user
    },
    getUtilisateur(state) {
      return state.utilisateur
    },
  },
}

<template>
  <div class="planning-body">
    <perfect-scrollbar class="planning-body-scrollable">
      <div class="planning-panel">
        <planning-body-interval :slot-height="slotHeight" :jour_nuit="jour_nuit" position="left" />
        <div v-for="semaine in semaines" :key="semaine.numero" class="planning-body-semaine">
          <planning-body-jour-container
            v-for="jour in jours"
            :key="jour.jourId"
            :jour-nuit="jour_nuit"
            :creneaux="getCreneauxBySemaineAndJour(semaine, jour)"
            :joursDeRepos="getJoursDeReposBySemaineAndJour(semaine, jour)"
            :semaine="semaine"
            :jour="jour"
            :membres="membres"
            :displayJoursDeRepos="displayJoursDeRepos"
            :slot-height="slotHeight"
          >
          </planning-body-jour-container>
        </div>
        <planning-body-interval
          v-if="!affichageImpression"
          :slot-height="slotHeight"
          :jour_nuit="jour_nuit"
          position="right"
        />
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import planningBodyInterval from '@/components/planning/body/planning-body-interval'
import { mapGetters } from 'vuex'
import PlanningBodyJourContainer from '@/components/planning/body/planning-body-jour-container.vue'

export default {
  name: 'planning-body',
  components: {
    PlanningBodyJourContainer,
    planningBodyInterval,
  },
  props: {
    affichageImpression: {
      type: Boolean,
      default: false,
    },
    creneaux: {
      type: Array,
      required: true,
    },
    joursDeRepos: {
      type: Array,
      required: true,
    },
    slotHeight: {
      type: Number,
      requred: true,
    },
    semaines: {
      type: Array,
      required: true,
    },
    membres: {
      type: Array,
      required: true,
    },
    jour_nuit: {
      type: String,
      required: true,
    },
    service: {
      type: Object,
      required: false,
      default: undefined,
    },
    displayJoursDeRepos: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      getDateOfISOWeekDay: 'calendrier/getDateOfISOWeekDay',
      getISOWeekOfDate: 'calendrier/getISOWeekOfDate',
      jours: 'calendrier/getJours',
    }),
  },
  methods: {
    getCreneauxBySemaineAndJour(_semaine, _jour) {
      return this.creneaux.filter(
        (c) => c.date === this.getDateOfISOWeekDay(_semaine.annee, _semaine.semaine, _jour.jourId)
      )
    },
    getJoursDeReposBySemaineAndJour(_semaine, _jour) {
      return this.joursDeRepos.filter((jr) => jr.semaine.numero === _semaine.numero && jr.jour.jourId === _jour.jourId)
    },
  },
}
</script>

<style scoped>
.planning {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;

  overflow: hidden;
}

.default {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100%;
  overflow: hidden;
}

.planning-body {
  display: flex;
  flex-direction: column;
  flex: 1 1 60%;
  overflow: hidden;
  position: relative;
}

.planning-body-scrollable {
  display: flex;
  flex-direction: column;
}

.planning-panel {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
}

.planning-body-semaine {
  display: flex;
  flex-direction: row;
  flex: 1 1 60%;
  overflow-x: visible;
  position: relative;
}
</style>

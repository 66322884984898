<template>
  <v-card width="600" class="mx-auto mt-5">
    <v-form @submit.prevent="register">
      <v-card-title>
        <h1 class="display-1">Inscription</h1>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="2">
            <v-combobox v-model="this.genre" :items="['Mr', 'Mme']" label="M*" hide-no-data required></v-combobox>
          </v-col>
          <v-col cols="5">
            <v-text-field v-model="nom" label="Nom*" />
          </v-col>
          <v-col cols="5">
            <v-text-field v-model="prenom" label="Prénom*" />
          </v-col>
        </v-row>
        <v-text-field v-model="email" label="email*" prepend-icon="mdi-account-circle" />
        <v-text-field
          v-model="password"
          label="mot de passe*"
          :type="showPassword ? 'text' : 'password'"
          prepend-icon="mdi-lock"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
        />
      </v-card-text>
      <ul>
        <li v-for="(error, index) in errors" :key="index">
          {{ error }}
        </li>
      </ul>
      <v-divider></v-divider>
      <v-card-actions>
        <router-link :to="{ name: 'login' }"> Déjà un compte ? Connectez vous </router-link>
        <!-- <v-btn color="success">Register</v-btn> -->
        <v-spacer></v-spacer>
        <v-btn color="info" type="submit">S'inscrire</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      nom: '',
      prenom: '',
      email: '',
      password: '',
      genre: undefined,
      errors: null,
      showPassword: false,
    }
  },
  methods: {
    register() {
      this.$store
        .dispatch('security/register', {
          genre: this.genre,
          nom: this.nom,
          prenom: this.prenom,
          email: this.email,
          password: this.password,
        })
        .then(() => {
          this.$router.push({ name: 'employe' })
        })
        .catch((err) => {
          this.errors = err.response.data.errors
        })
    },
  },
}
</script>

<style scoped></style>

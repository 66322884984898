<template>
  <v-list-item @click="generatePlanningPdf">
    <v-list-item-icon>
      <v-icon color="info">fa-print</v-icon>
    </v-list-item-icon>
    <v-list-item-title>Imprimer le planning</v-list-item-title>
    <vue-html2pdf
      v-show="false"
      :show-layout="false"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1000"
      :filename="'Planning_semaine' + semaineString"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="100%"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <planning
          @MOUNTED="
            isPlanningReady = true
            generatePlanningPdf()
          "
          v-if="loadPlanning"
          :affichage-impression="true"
          :creneaux="creneaux"
          :jours-de-repos="joursDeRepos"
          :semaines="semaines"
          :membres="membres"
          :jour_nuit="jour_nuit"
          :service="service"
          :display-jours-de-repos="displayJoursDeRepos"
        />
      </section>
    </vue-html2pdf>
  </v-list-item>
</template>

<script>
import planning from '../planning/planning.vue'
import VueHtml2pdf from 'vue-html2pdf'
export default {
  components: { planning, VueHtml2pdf },
  props: {
    creneaux: {
      type: Array,
      required: true,
    },
    joursDeRepos: {
      type: Array,
      required: true,
    },
    semaines: {
      type: Array,
      required: true,
    },
    membres: {
      type: Array,
      required: true,
    },
    jour_nuit: {
      type: String,
      required: true,
    },
    service: {
      type: Object,
      required: false,
      default: undefined,
    },
    displayJoursDeRepos: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    semaineString() {
      let s = ''
      this.semaines.forEach((value) => (s += '-' + value.semaine))
      return s
    },
  },
  data() {
    return {
      dialog: false,
      loadPlanning: false,
      isPlanningReady: false,
    }
  },
  methods: {
    generatePlanningPdf() {
      if (this.isPlanningReady) {
        this.$refs.html2Pdf.generatePdf()
      } else {
        this.loadPlanning = true
      }
    },
  },
}
</script>

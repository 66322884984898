<template>
  <v-dialog v-model="showDialog" persistent max-width="800px">
    <v-card v-if="showDialog">
      <s-toolbar :text="title" :update="updatable" @close="close()" />
      <v-card-text>
        <conges-form
          v-model="updateCongesCommand"
          :etablissement="etablissement"
          @updateFormValid="updateFormValid"
          :disabled="!updatable"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn v-if="updatable" icon @click="deleteConges">
          <v-icon small color="red">fa-trash</v-icon>
        </v-btn>
        <v-spacer />
        <v-btn color="grey darken-1" text @click="close()" v-text="'Annuler'" />
        <v-btn
          v-if="fromDemande"
          color="primary"
          :to="{ name: 'demandeCongesDetail', params: { demandeId: congesToUpdate.demandeCongesId } }"
          v-text="'Voir la demande'"
        />
        <v-btn v-else color="info" :disabled="!formValid" @click="validate()" v-text="'Mettre à jour'" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CongesForm from './conges-form'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'conges-update-dialog',
  components: {
    CongesForm,
  },
  props: {
    value: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    congesToUpdate: {
      type: Object,
      required: false,
      default: undefined,
    },
    employe: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDialog: false,
      formValid: false,
      updateCongesCommand: undefined,
    }
  },
  computed: {
    ...mapGetters({
      isInRole: 'security/isInRole',
      etablissement: 'application/getSelectedEtablissement',
    }),
    fromDemande() {
      return this.congesToUpdate.demandeCongesId > 0
    },
    updatable() {
      return !this.fromDemande && this.isInRole('ADMINISTRATEUR_' + this.employe.etablissement.etablissementId)
    },
    title() {
      return this.updatable ? 'Modifier un congé' : "Détail d'un congés"
    },
  },
  created() {
    this.showDialog = this.value
    this.initialize()
  },
  watch: {
    showDialog() {
      this.$emit('input', this.showDialog)
    },
    value() {
      this.showDialog = this.value
    },
  },
  methods: {
    ...mapActions({
      dispatchUpdateConges: 'conges/updateConges',
      dispatchDeleteConges: 'conges/deleteConges',
    }),
    initialize() {
      this.updateCongesCommand = JSON.parse(JSON.stringify(this.congesToUpdate))
      this.updateCongesCommand.employeId = this.employe.employeId
    },
    updateFormValid(_validity) {
      this.formValid = _validity
    },
    deleteConges() {
      this.dispatchDeleteConges(this.updateCongesCommand.congesId)
      this.close()
    },
    validate() {
      if (this.formValid) {
        this.dispatchUpdateConges(this.updateCongesCommand)
        this.close()
      }
    },
    close() {
      this.initialize()
      this.showDialog = false
    },
  },
}
</script>

<style scoped></style>

<template>
  <div class="s-text">
    <template v-if="editing">
      <slot name="champ"></slot>
      <v-btn class="ml-2" color="grey darken-1" text @click="cancelUpdate">Annuler</v-btn>
      <v-btn class="ml-2" color="info darken-1" dark @click="validateUpdate">{{ titreAction }}</v-btn>
    </template>
    <template v-else class="flex flex-row flex-grow-0" @mouseover="showEditButton" @mouseleave="hideEditButton">
      <div class="s-text-read">
        <div class="text-overline grey--text text--darken-1">{{ titre }}</div>
        <slot name="contentToDisplay" v-if="this.$slots.contentToDisplay"></slot>
        <div v-else :class="textClass">
          {{ contentToDisplay }}
        </div>
      </div>
      <v-btn class="s-text-action ml-2" outlined color="info" @click="startUpdate">{{ titreAction }}</v-btn>
    </template>
  </div>
</template>

<script>
export default {
  name: 's-info-modifiable',
  props: {
    titre: {
      type: String,
      required: true,
    },
    valeur: {
      type: String,
      required: false,
    },
    titreAction: {
      type: String,
      required: false,
      default: 'Modifier',
    },
  },
  data() {
    return {
      editing: false,
    }
  },
  computed: {
    contentToDisplay() {
      if (!this.valeur || this.valeur === '') {
        return 'Non renseigné'
      } else {
        return this.valeur
      }
    },
    textClass() {
      if (!this.valeur || this.valeur === '') {
        return 'text-body-2 error--text'
      } else {
        return 'text-body-2 black--text'
      }
    },
  },
  methods: {
    showEditButton() {
      if (this.editable) {
        this.editButtonVisible = true
      }
    },
    hideEditButton() {
      this.editButtonVisible = false
    },
    startUpdate() {
      this.editing = true
    },
    validateUpdate() {
      this.$emit('validateUpdate', this.content)
      this.editing = false
    },
    cancelUpdate() {
      this.$emit('cancelUpdate', this.content)
      this.editing = false
    },
  },
}
</script>

<style scoped>
.s-text {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 70px;
}

.s-text-read {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.s-text-action {
  display: flex;
}
</style>

<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card-text>
          <p>
            Liste des employés pour lesquels un contrat contenant des jours d'astreintes existe à la date voulue. Si
            cette liste est incorrecte, rendez vous sur la page des employés concernés pour modifier leur contrat.
          </p>
          <template
            v-if="
              genererModeleAstreinteCommand === undefined ||
              genererModeleAstreinteCommand.nbJoursParEmployesParAn === undefined
            "
          >
            <v-skeleton-loader type="list-item-avatar-two-line@3" />
          </template>
          <template v-else-if="Object.keys(genererModeleAstreinteCommand.nbJoursParEmployesParAn).length > 0">
            <employe-list-item
              v-for="employeId in Object.keys(genererModeleAstreinteCommand.nbJoursParEmployesParAn).map((id) =>
                Number(id)
              )"
              :key="employeId"
              :employe-id="employeId"
            >
              <template v-slot:actions>
                <span>
                  {{ 'Nb jours dus par an: ' + genererModeleAstreinteCommand.nbJoursParEmployesParAn[employeId] }}
                </span>
              </template>
            </employe-list-item>
          </template>
          <template v-else>
            Aucun employé ne doit d'astreinte au {{ getDateFormattee(dateMiseEnApplication) }}.
          </template>
        </v-card-text>
        <v-card-actions>
          <v-btn color="grey darken-1" text @click="$emit('precedent')">Précédent</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" :disabled="!genererModeleAstreinteFormValid" @click="generer()">Générer</v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import EmployeListItem from '../employe/employe-list-item.vue'

export default {
  name: 'modele-astreintes-create-step2-generer',
  components: { EmployeListItem },
  props: {
    value: {
      type: Object,
      required: false,
      default: undefined,
    },
    etablissement: {
      type: Object,
      required: true,
    },
    dateMiseEnApplication: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      modeleAstreinte: undefined,
      genererModeleAstreinteCommand: undefined,
    }
  },
  computed: {
    ...mapGetters({
      getDateFormattee: 'calendrier/formatDateToDisplay',
      getEmployeByIdStore: 'employe/getEmployeById',
    }),
    loaded() {
      return true
    },
    genererModeleAstreinteFormValid() {
      if (this.genererModeleAstreinteCommand && this.genererModeleAstreinteCommand.nbJoursParEmployesParAn) {
        return Object.keys(this.genererModeleAstreinteCommand.nbJoursParEmployesParAn).length > 0
      } else {
        return false
      }
    },
    nbJoursParEmployesParAn() {
      if (this.genererModeleAstreinteCommand) {
        return this.genererModeleAstreinteCommand.nbJoursParEmployesParAn
      } else {
        return undefined
      }
    },
  },
  created() {
    this.modeleAstreinte = this.value
  },
  watch: {
    modeleAstreinte() {
      this.$emit('input', this.modeleAstreinte)
    },
    value() {
      this.modeleAstreinte = this.value
    },
    dateMiseEnApplication() {
      if (this.dateMiseEnApplication) {
        this.initialiserCommand()
      } else {
        this.genererModeleAstreinteCommand = undefined
      }
    },
    nbJoursParEmployesParAn() {
      this.$emit('nbJoursParEmployesParAnUpdated', this.nbJoursParEmployesParAn)
    },
  },
  methods: {
    ...mapActions({
      dispatchGenererModeleAstreintes: 'astreinte/genererModeleAstreinte',
      fetchAstreintesDue: 'astreinte/fetchAstreintesDues',
    }),
    getEmployeById(_employeId) {
      return this.getEmployeByIdStore(_employeId)
    },
    initialiserCommand() {
      this.genererModeleAstreinteCommand = {
        etablissementId: this.etablissement.etablissementId,
        nbJoursParEmployesParAn: undefined,
      }

      this.fetchAstreintesDue({
        etablissementId: this.etablissement.etablissementId,
        date: this.dateMiseEnApplication,
      }).then((response) => {
        this.genererModeleAstreinteCommand.nbJoursParEmployesParAn = response.data
      })
    },
    generer() {
      this.$emit('suivant')
      this.dispatchGenererModeleAstreintes(this.genererModeleAstreinteCommand).then((data) => {
        this.modeleAstreinte = data
      })
    },
  },
}
</script>

<style scoped></style>

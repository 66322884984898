<template>
  <v-app class="s-application">
    <v-app-bar class="title" color="primary" app clipped-left dark>
      <administration-menu class="mr-4" />
      <acl-refresh-dialog v-if="isInRole('Administrateur')" />
      <envoyer-mail-test-dialog v-if="isInRole('Administrateur')" />
      <v-btn
        v-else-if="displayMenu"
        text
        rounded
        :to="{ name: 'planningUtilisateur' }"
        v-text="'Mon Planning'"
        class="mr-4"
      />
      <demande-btn v-if="user" />
      <v-divider v-if="displayMenu" vertical class="mx-4" />
      <etablissement-picker v-if="displayMenu" />
      <v-btn v-for="link in filteredLinks" class="mr-4" :key="`${link.label}-header-link`" text rounded :to="link.url">
        {{ link.label }}
      </v-btn>
      <v-spacer />
      <v-btn v-if="user" class="ml-3" href="https://wiki.kilya.social" target="_blank" large icon color="white">
        <v-icon large>fa-question-circle</v-icon>
      </v-btn>
      <v-btn v-if="user" class="ml-3" :to="{ name: 'utilisateur-informations' }" fab small color="primary">
        <utilisateur-avatar :utilisateur="user" />
      </v-btn>
    </v-app-bar>
    <v-main class="ma-0" style="max-height: 100%">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { authenticated, initialDataLoaded } from './store/modules/helpers.js'
import { mapGetters } from 'vuex'
import utilisateurAvatar from './components/utilisateur/utilisateur-avatar'
import demandeBtn from './components/demande/demande-btn'
import administrationMenu from './views/administration/administration-menu'
import EtablissementPicker from './views/etablissement/etablissement-picker'
import aclRefreshDialog from './views/administration/acl-refresh-dialog.vue'
import envoyerMailTestDialog from './views/administration/envoyer-mail-test-dialog.vue'

export default {
  components: {
    demandeBtn,
    EtablissementPicker,
    utilisateurAvatar,
    administrationMenu,
    aclRefreshDialog,
    envoyerMailTestDialog,
  },
  data() {
    return {}
  },
  computed: {
    ...authenticated,
    ...initialDataLoaded,
    ...mapGetters({
      etablissement: 'application/getSelectedEtablissement',
      isInRole: 'security/isInRole',
      user: 'security/getUtilisateur',
    }),
    etablissementId() {
      return this.etablissement ? this.etablissement.etablissementId : ''
    },
    siegeSocialId() {
      return this.etablissement ? this.etablissement.siegeSocialId : ''
    },
    links() {
      return [
        {
          label: 'Établissement',
          url: `/etablissement`,
          loggedIn: true,
          permission: [
            'DIRECTEUR_' + this.etablissementId,
            'DIRECTEUR_' + this.siegeSocialId,
            'SALARIE_' + this.etablissementId,
          ],
        },
        {
          label: 'Tableau des congés',
          url: '/tableau-des-conges',
          loggedIn: true,
          permission: ['UTILISATEUR'],
        },
        {
          label: 'Services',
          url: '/services',
          loggedIn: true,
          permission: ['UTILISATEUR'],
        },
        {
          label: 'Employés',
          url: '/employes',
          loggedIn: true,
          permission: ['ADMINISTRATEUR_' + this.etablissementId, 'ADMINISTRATEUR_' + this.siegeSocialId],
        },
        {
          label: 'Usagers',
          url: '/usagers',
          loggedIn: true,
          permission: ['ADMINISTRATEUR'],
        },
      ]
    },
    displayMenu() {
      return this.authenticated && this.isInRole('UTILISATEUR')
    },
    filteredLinks() {
      return this.links.filter(
        (x) => x.loggedIn === this.authenticated && this.etablissement && x.permission.find((p) => this.isInRole(p))
      )
    },
  },
  beforeCreate() {
    if (this.authenticated) {
      this.$store.dispatch('application/fetchMissingData')
    }
  },
}
</script>

<style>
.v-application {
  font-family: Arial, sans-serif;
}

.s-application,
.v-application--wrap,
.v-content,
.v-content__wrap {
  max-height: 100%;
}

.active {
  color: #1976d2;
  background-color: #e3eefa;
  outline-color: #1976d2;
}

.bg-info-lighten-4 {
  background-color: var(--v-info-lighten3) !important;
  border-color: var(--v-info-lighten3) !important;
}
.bg-surface-lighten-2 {
  background-color: #e2f3f1 !important;
}
.bg-primary-lighten-2 {
  background-color: var(--v-primary-lighten4) !important;
}

.bg-secondary-lighten-4 {
  background-color: rgb(from var(--v-secondary-lighten4) r g b / 0.75) !important;
}
.bg-secondary-lighten-5 {
  background-color: rgb(from var(--v-secondary-lighten5) r g b / 0.75) !important;
}
</style>

import axios from 'axios'

export default {
  namespaced: true,
  state: {
    resumeDesAstreintes: {
      employes: [],
    },
    planningAstreintesMensuel: undefined,
  },
  mutations: {
    RESET_RESUME(state) {
      state.resumeDesAstreintes = {
        employes: [],
      }
    },
    SET_RESUME(state, _resumeDesAstreintes) {
      state.resumeDesAstreintes = _resumeDesAstreintes
    },
    RESET_PLANNING(state) {
      state.planningAstreintesMensuel = undefined
    },
    SET_PLANNING_ASTREINTES_MENSUEL(state, _planningAstreintesMensuel) {
      state.planningAstreintesMensuel = _planningAstreintesMensuel
    },
  },
  getters: {
    getResumeDesAstreintes(state) {
      return state.resumeDesAstreintes
    },
    getEmployesDastreinte(state) {
      return state.resumeDesAstreintes.employes
    },
    getCouleurByEmployeId: (state) => (_employeId) => {
      for (let i = 0; i < state.resumeDesAstreintes.employes.length; i++) {
        if (state.resumeDesAstreintes.employes[i].employeId === _employeId) {
          return state.resumeDesAstreintes.employes[i].couleur
        }
      }
      return '#FFFFFF'
    },
    getPlanningDastreintes(state) {
      return state.planningAstreintesMensuel
    },
  },
  actions: {
    // eslint-disable-next-line no-empty-pattern
    fetchResumeAnnuelDesAstreintes({ commit }, _payload) {
      return axios
        .get(
          `${process.env.VUE_APP_URL_BACK_SERENITY}/astreinte/etablissement/${_payload.etablissementId}/resumeAnnuel?_annee=${_payload.annee}`
        )
        .then((response) => commit('SET_RESUME', response.data))
    },
    discardResumeAnnuelDesAstreintes({ commit }) {
      commit('RESET_RESUME')
    },
    fetchPlanningAstreintesMensuel({ commit }, _payload) {
      return axios
        .get(
          `${process.env.VUE_APP_URL_BACK_SERENITY}/astreinte/etablissement/${_payload.etablissementId}/planning?_annee=${_payload.annee}&_mois=${_payload.mois}`
        )
        .then((response) => commit('SET_PLANNING_ASTREINTES_MENSUEL', response.data))
    },
    discardPlanningAstreintesMensuel({ commit }) {
      commit('RESET_PLANNING')
    },
    // eslint-disable-next-line no-empty-pattern
    fetchAstreintesDues({}, _payload) {
      return axios.get(
        `${process.env.VUE_APP_URL_BACK_SERENITY}/astreinte/etablissement/${_payload.etablissementId}/duesContractuellement?_date=${_payload.date}`
      )
    },
    genererModeleAstreinte(context, _payload) {
      return axios
        .post(
          `${process.env.VUE_APP_URL_BACK_SERENITY}/astreinte/etablissement/${_payload.etablissementId}/generer`,
          _payload
        )
        .then((response) => {
          return response.data
        })
    },
    publierModeleAstreinte(context, _payload) {
      return axios.post(
        `${process.env.VUE_APP_URL_BACK_SERENITY}/astreinte/etablissement/${_payload.etablissementId}`,
        _payload
      )
    },
    modifierLemployeDastreinteAu(context, _payload) {
      axios.post(`${process.env.VUE_APP_URL_BACK_SERENITY}/astreinte/modification`, _payload.command).then(() => {
        context.dispatch('fetchPlanningAstreintesMensuel', _payload.fetchPlanningAstreintesMensuelQuery)
        context.dispatch('fetchResumeAnnuelDesAstreintes', _payload.fetchResumeAnnuelDesAstreintesQuery)
      })
    },
    modifierLemployeDastreintePourLaSemaine(context, _payload) {
      axios
        .post(`${process.env.VUE_APP_URL_BACK_SERENITY}/astreinte/modification/semaine`, _payload.command)
        .then(() => {
          context.dispatch('fetchPlanningAstreintesMensuel', _payload.fetchPlanningAstreintesMensuelQuery)
          context.dispatch('fetchResumeAnnuelDesAstreintes', _payload.fetchResumeAnnuelDesAstreintesQuery)
        })
    },
    supprimerLemployeDastreinteAu(context, _payload) {
      axios.post(`${process.env.VUE_APP_URL_BACK_SERENITY}/astreinte/suppression`, _payload.command).then(() => {
        context.dispatch('fetchPlanningAstreintesMensuel', _payload.fetchPlanningAstreintesMensuelQuery)
        context.dispatch('fetchResumeAnnuelDesAstreintes', _payload.fetchResumeAnnuelDesAstreintesQuery)
      })
    },
    supprimerLemployeDastreintePourLaSemaine(context, _payload) {
      axios
        .post(`${process.env.VUE_APP_URL_BACK_SERENITY}/astreinte/suppression/semaine`, _payload.command)
        .then(() => {
          context.dispatch('fetchPlanningAstreintesMensuel', _payload.fetchPlanningAstreintesMensuelQuery)
          context.dispatch('fetchResumeAnnuelDesAstreintes', _payload.fetchResumeAnnuelDesAstreintesQuery)
        })
    },
    actualiserLesRedirectionsDastreintes() {
      axios.post(`${process.env.VUE_APP_URL_BACK_SERENITY}/astreinte/actualiserLesRedirections`)
    },
  },
}

<template>
  <div class="planning-body-jour-container">
    <modele-planning-body-jour
      v-if="jourNuit === 'jour'"
      :jour="jour"
      :semaine="semaine"
      :membres="membres"
      :selectedMembre="selectedMembre"
      :slot-height="slotHeight"
    />
    <modele-planning-body-nuit
      v-if="jourNuit === 'nuit'"
      :jour="jour"
      :semaine="semaine"
      :membres="membres"
      :selectedMembre="selectedMembre"
      :slot-height="slotHeight"
    />
  </div>
</template>

<script>
import ModelePlanningBodyJour from '@/components/modelePlanning/body/modele-planning-body-jour.vue'
import ModelePlanningBodyNuit from '@/components/modelePlanning/body/modele-planning-body-nuit.vue'

export default {
  name: 'modele-planning-body',
  components: {
    ModelePlanningBodyNuit,
    ModelePlanningBodyJour,
  },
  props: {
    slotHeight: {
      type: Number,
      required: true,
    },
    semaine: {
      type: Object,
      required: true,
    },
    jour: {
      type: Object,
      required: true,
    },
    jourNuit: {
      type: String,
      required: true,
    },
    membres: {
      type: Array,
      required: true,
    },
    selectedMembre: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
}
</script>

<style scoped>
.planning-body-jour-container {
  flex: 1 1 60%;
}
</style>

<template>
  <s-main-panel v-if="loaded">
    <v-card class="ma-3 fixed-height" flat>
      <perfect-scrollbar class="scrollable">
        <v-container>
          <v-row>
            <v-col cols="2" class="text-h6 pb-0">
              <utilisateur-avatar-update :size="96" :utilisateur="utilisateur"></utilisateur-avatar-update>
            </v-col>
            <v-col cols="10" class="text-h6 pb-0">
              Etat Civil
              <v-divider />
              <v-row>
                <s-text-info :cols="2" v-model="utilisateur.prenom" label="Prénom" editable required />
                <s-text-info :cols="2" v-model="utilisateur.nom" label="Nom" editable required />
                <s-text-info
                  :cols="2"
                  v-model="utilisateur.dateDeNaissance"
                  label="Date de naissance"
                  type="date"
                  editable
                />
                <s-text-info :cols="2" v-model="utilisateur.numeroSecu" label="Numéro de sécu" editable />
              </v-row>
            </v-col>

            <v-col cols="12" class="text-h6 pt-6 pb-0">
              Compte utilisateur
              <v-divider />
            </v-col>
            <s-text-info :cols="2" v-model="utilisateur.email" label="E-mail personnel" required />
            <update-password-dialog />
          </v-row>
        </v-container>
      </perfect-scrollbar>
    </v-card>
  </s-main-panel>
</template>

<script>
import UpdatePasswordDialog from './update-password-dialog'
import { mapGetters, mapActions } from 'vuex'
import SMainPanel from '@/components/global/s-main-panel.vue'
import STextInfo from '@/components/global/s-text-info.vue'
import UtilisateurAvatarUpdate from '../../components/utilisateur/utilisateur-avatar-update.vue'

export default {
  name: 'utilisateur-informations',
  components: {
    UtilisateurAvatarUpdate,
    STextInfo,
    SMainPanel,
    UpdatePasswordDialog,
  },
  data() {
    return {
      editing: false,
    }
  },
  computed: {
    ...mapGetters({
      utilisateur: 'security/getUtilisateur',
    }),
    loaded() {
      return this.utilisateur
    },
  },
  watch: {
    utilisateur: {
      handler() {
        this.updateUtilisateur(this.utilisateur)
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      updateUtilisateur: 'utilisateur/updateUtilisateur',
    }),
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.fixed-height {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
}

.scrollable {
  display: flex;
  flex-direction: column;
}
</style>

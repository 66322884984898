<template>
  <v-form v-model="formValid">
    <v-container>
      <v-row dense>
        <v-col cols="12">
          <service-membre-autocomplete
            v-model="modeleCreneau.membreId"
            :serviceId="parseInt(this.$route.params.serviceId, 10)"
            titulaires
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="12">
          <v-autocomplete
            v-model="selectedOrdreSemaine"
            :items="semaines"
            hide-no-data
            hide-details
            item-text="ordre"
            label="Semaine*"
            :rules="[(v) => !!v]"
            required
            return-object
          />
        </v-col>
        <v-col cols="12" sm="12">
          <s-jour-picker v-model="modeleCreneau.jourId" label="Jour*" />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="6">
          <s-borne-horaire-picker
            v-model="modeleCreneau.heureDebut"
            label="Début"
            :borneMax="value.heureFin"
            required
          />
        </v-col>
        <v-col cols="12" sm="6">
          <s-borne-horaire-picker v-model="modeleCreneau.heureFin" label="Fin" :borneMin="value.heureDebut" required />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="12">
          <small>*information obligatoire</small>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import ServiceMembreAutocomplete from '@/components/service/service-membre-autocomplete'
import { mapGetters } from 'vuex'
import SBorneHorairePicker from '@/components/global/s-borne-horaire-picker.vue'
import SJourPicker from '@/components/global/s-jour-picker.vue'

export default {
  name: 'modele-creneau-form',
  components: {
    SJourPicker,
    SBorneHorairePicker,
    ServiceMembreAutocomplete,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formValid: false,
      modeleCreneau: undefined,
    }
  },
  created() {
    this.modeleCreneau = this.value
  },
  computed: {
    ...mapGetters({
      semaines: 'modelePlanning/getModeleSemaines',
    }),
    selectedOrdreSemaine: {
      get: function () {
        return this.semaines.find((s) => s.ordre === this.modeleCreneau.ordreSemaine)
      },
      set: function (_selectedModeleSemaine) {
        this.modeleCreneau.ordreSemaine = _selectedModeleSemaine.ordre
      },
    },
  },
  watch: {
    modeleCreneau: {
      handler() {
        this.$emit('input', this.modeleCreneau)
      },
      deep: true,
    },
    value() {
      this.modeleCreneau = this.value
    },
    formValid() {
      this.$emit('updateFormValid', this.formValid)
    },
  },
}
</script>

<style scoped></style>

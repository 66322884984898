<template>
  <v-chip label :color="chipColor">
    <v-icon left> {{ chipIcon }}</v-icon>
    {{ chipStatus }}
  </v-chip>
</template>
<script>
export default {
  name: 'statut-fiche-aim',
  props: {
    statutFiche: {
      type: String,
      required: true,
    },
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    chipColor() {
      switch (this.statutFiche) {
        case 'EN_PREPARATION':
          return 'warning'
        case 'EN_ATTENTE_DE_VALIDATION':
          return 'info'
        case 'VALIDEE':
          return 'success'
        default:
          console.error('Illegal state : Le statut de la fiche ne correspon à aucun statut prévus')
      }
    },
    // eslint-disable-next-line vue/return-in-computed-property
    chipIcon() {
      switch (this.statutFiche) {
        case 'EN_PREPARATION':
          return 'mdi-progress-wrench'
        case 'EN_ATTENTE_DE_VALIDATION':
          return 'mdi-progress-question'
        case 'VALIDEE':
          return 'mdi-check-circle-outline'
        default:
          console.error('Illegal state : Le statut de la fiche ne correspon à aucun statut prévus')
      }
    },
    // eslint-disable-next-line vue/return-in-computed-property
    chipStatus() {
      switch (this.statutFiche) {
        case 'EN_PREPARATION':
          return 'En préparation'
        case 'EN_ATTENTE_DE_VALIDATION':
          return 'En attente de validation'
        case 'VALIDEE':
          return 'Validée'
        default:
          console.error('Illegal state : Le statut de la fiche ne correspon à aucun statut prévus')
      }
    },
  },
}
</script>

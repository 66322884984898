<template>
  <v-container v-if="loaded">
    <v-row>
      <v-col cols="9" class="py-0">
        <contrat-type-autocomplete v-model="contrat.type" />
      </v-col>
      <v-col cols="3" class="py-0">
        <v-text-field
          v-model="contrat.heuresHebdo"
          :rules="[(v) => !isNaN(v) && v >= 0]"
          hide-no-data
          type="number"
          label="Par semaines*"
          suffix="heures(s)"
          required
        />
      </v-col>
      <v-col cols="6" class="py-0">
        <metier-autocomplete v-model="contrat.metier" />
      </v-col>
      <v-col cols="3" class="py-0">
        <s-date-picker v-model="contrat.dateDebut" label="Date de début" required />
      </v-col>
      <v-col cols="3" class="py-0">
        <s-date-picker
          v-if="dateFinAvailable"
          v-model="contrat.dateFin"
          label="Date de fin"
          :required="dateFinRequired"
        />
      </v-col>
      <v-col cols="6" sm="12" md="6" class="py-0">
        <v-switch v-model="contrat.cadre" inset label="Statut cadre ? " />
      </v-col>
      <v-col v-if="contrat.cadre" cols="6" class="py-0">
        <v-text-field
          v-model="contrat.nbJoursAstreintesAnnuels"
          :rules="[(v) => !isNaN(v) && v >= 0]"
          hide-no-data
          type="number"
          label="Jours d'astreintes par an*"
          suffix="jour(s)"
          required
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import metierAutocomplete from './metier-autocomplete'
import contratTypeAutocomplete from './contrat-type-autocomplete'

export default {
  name: 'contrat-form',
  components: {
    metierAutocomplete,
    contratTypeAutocomplete,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    formValid: false,
    numericFieldRule: [(v) => !isNaN(v) || 'Entrer une valeur numérique'],
    contrat: undefined,
  }),
  computed: {
    loaded() {
      return this.contrat
    },
    dateFinAvailable() {
      if (this.contrat && this.contrat.type && this.contrat.type.contratTypeId === 1 && this.contrat.contratId === 0) {
        return false
      } else {
        return true
      }
    },
    dateFinRequired() {
      if (this.contrat && this.contrat.type && this.contrat.type.contratTypeId === 1) {
        return false
      } else {
        return true
      }
    },
  },
  created() {
    this.contrat = this.value
    this.fetchContratTypes()
    this.fetchMetiers()
  },
  watch: {
    createContratCommand() {
      this.$emit('input', this.contrat)
    },
    dateFinRequired() {
      if (!this.dateFinRequired) {
        this.contrat.dateFin = undefined
      }
    },
  },
  methods: {
    ...mapActions({
      fetchContratTypes: 'contrat/fetchContratTypes',
      fetchMetiers: 'contrat/fetchMetiers',
    }),
  },
}
</script>

<style scoped></style>

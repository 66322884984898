<template>
  <v-menu top offset-y nudge-left="60" center origin="bottom center" transition="scale-transition" :disabled="disabled">
    <template v-slot:activator="{ on, attrs }">
      <div class="planning-astreintes-colonne-jour" :style="style" v-bind="attrs" v-on="on" :id="jourId">
        {{ jourDuMois }}
      </div>
    </template>
    <astreintes-planning-employe-select
      :employeIdSelectionne="employeIdSelectionne"
      :titre="'D\'astreinte le ' + getDateToDisplay(date)"
      @selection="modifierLemployeDastreinteAu"
    />
  </v-menu>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Color from 'color'
import AstreintesPlanningEmployeSelect from './astreintes-planning-employe-select.vue'

export default {
  name: 'astreintes-planning-jour',
  components: {
    AstreintesPlanningEmployeSelect,
  },
  props: {
    employeId: {
      type: Number,
      required: false,
    },
    date: {
      type: Date,
      required: true,
    },
    annee: {
      type: Number,
      required: true,
    },
    mois: {
      type: Number,
      required: true,
    },
    etablissementId: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      employeIdSelectionne: undefined,
    }
  },
  computed: {
    ...mapGetters({
      employesDastreintes: 'astreinte/getEmployesDastreinte',
      getEmployeById: 'employe/getEmployeById',
      getDateToDisplay: 'calendrier/formatDateToDisplay',
      getDayOfTheWeekOfDate: 'calendrier/getDayOfTheWeekOfDate',
    }),
    style() {
      return {
        'background-color': this.employeId ? this.color : 'white',
        'border-radius': '2px',
        color: this.textColor,
        'font-weight': this.fontWeight,
        border: this.border,
        'margin-left': this.jourId > 1 ? '5px' : '0px',
      }
    },
    employe() {
      let resultat = this.getEmployeById(this.employeId)

      if (this.employeId && !resultat) {
        this.findEmployeById(this.employeId)
      }

      return resultat
    },
    color() {
      if (this.employe) {
        if (this.moisDemande) {
          return this.employe.couleur
        } else {
          return Color(this.employe.couleur).fade(0.7).toString()
        }
      } else {
        return 'white'
      }
    },
    fontWeight() {
      if (this.moisDemande) {
        return 'bold'
      } else {
        return 'normal'
      }
    },
    textColor() {
      if (this.moisDemande) {
        return 'black'
      } else {
        return Color('#000000').fade(0.5)
      }
    },
    moisDemande() {
      return this.date.getMonth() === this.mois
    },
    jourDuMois() {
      return this.date.getDate()
    },
    jourId() {
      return this.getDayOfTheWeekOfDate(this.date).jourId
    },
  },
  created() {
    this.employeIdSelectionne = this.employeId
  },
  watch: {
    employeId() {
      this.employeIdSelectionne = this.employeId
    },
  },
  methods: {
    ...mapActions({
      findEmployeById: 'employe/findById',
      sendModifierLemployeDastreinteAu: 'astreinte/modifierLemployeDastreinteAu',
      sendSupprimerLemployeDastreinteAu: 'astreinte/supprimerLemployeDastreinteAu',
    }),
    modifierLemployeDastreinteAu(_employeId) {
      let payload = {
        fetchPlanningAstreintesMensuelQuery: {
          etablissementId: this.etablissementId,
          annee: this.annee,
          mois: this.mois + 1,
        },
        fetchResumeAnnuelDesAstreintesQuery: {
          etablissementId: this.etablissementId,
          annee: this.annee,
        },
        etablissementId: this.etablissementId,
      }

      if (_employeId) {
        this.employeIdSelectionne = _employeId
        payload.command = {
          dateAModifier: this.date,
          employeIdAMettreDastreinte: _employeId,
          etablissementIdDuPlanningAModifier: this.etablissementId,
        }

        this.sendModifierLemployeDastreinteAu(payload)
      } else {
        this.employeIdSelectionne = undefined
        payload.command = {
          dateAVider: this.date,
          etablissementIdDuPlanningAModifier: this.etablissementId,
        }

        this.sendSupprimerLemployeDastreinteAu(payload)
      }

      this.$refs.menu.save()
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

<template>
  <v-dialog v-model="showDialog" persistent width="330px">
    <template #activator="{ on }">
      <div class="ma-0 pa-0" v-on="on">
        <slot />
      </div>
    </template>
    <v-card v-if="showDialog">
      <s-toolbar text="Créer un créneau" create @close="close()" />
      <v-form ref="form" v-model="formValid">
        <v-card-text class="pt-0">
          <modele-creneau-form v-model="modeleCreneau" @updateFormValid="updateFormValid" />
        </v-card-text>
      </v-form>
      <v-card-actions>
        <v-spacer />
        <v-btn color="grey darken-1" text @click="close()"> Annuler </v-btn>
        <v-btn color="success" :disabled="!formValid" @click="validate()"> Créer </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import ModeleCreneauForm from '@/components/modelePlanning/creneau/modele-creneau-form.vue'
import SToolbar from '@/components/global/s-toolbar.vue'

export default {
  name: 'modele-planning-creneau-create',
  components: {
    SToolbar,
    ModeleCreneauForm,
  },
  props: {
    membreId: {
      type: Number,
      required: false,
      default: undefined,
    },
    ordreSemaine: {
      type: Number,
      required: false,
      default: undefined,
    },
    jourId: {
      type: Number,
      required: false,
      default: undefined,
    },
    copy: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      showDialog: false,
      formValid: false,
      modeleCreneau: undefined,
    }
  },
  created() {
    this.initialize()
  },
  watch: {
    showDialog() {
      if (this.showDialog) {
        this.initialize()
      }
    },
  },
  methods: {
    ...mapActions({
      createModeleCreneau: 'modelePlanning/createModeleCreneau',
    }),
    initialize() {
      if (this.copy) {
        this.modeleCreneau = {
          modeleCreneauDeTravailId: 0,
          jourId: this.copy.jourId,
          ordreSemaine: this.copy.ordreSemaine,
          heureDebut: this.copy.heureDebut,
          heureFin: this.copy.heureFin,
          membreId: this.copy.membreId,
        }
      } else {
        this.modeleCreneau = {
          modeleCreneauDeTravailId: 0,
          jourId: this.jourId,
          ordreSemaine: this.ordreSemaine,
          heureDebut: undefined,
          heureFin: undefined,
          membreId: this.membreId,
        }
      }
    },
    updateFormValid(_validity) {
      this.formValid = _validity
    },
    validate() {
      if (this.formValid) {
        this.createModeleCreneau(this.modeleCreneau)
        this.close()
      }
    },
    close() {
      this.initialize()
      this.showDialog = false
    },
  },
}
</script>

<style scoped></style>

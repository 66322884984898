<template>
  <v-dialog v-model="showDialog" persistent max-width="800px">
    <template v-slot:activator="{ on }">
      <v-list-item v-on="on">
        <v-list-item-icon>
          <v-icon color="success">fa-clock</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Demande d'Heures complémentaires</v-list-item-title>
      </v-list-item>
    </template>
    <v-card v-if="showDialog">
      <s-toolbar text="Demande d'heures complémentaires" create @close="close()" />
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="6">
              <v-form v-model="formValid">
                <v-row>
                  <v-col cols="12" v-if="etablissements.length > 1">
                    <etablissement-autocomplete
                      v-model="selectedEtablissement"
                      :list-etablissements="etablissements"
                      required
                      :disabled="!!demandes"
                    />
                  </v-col>
                  <v-col cols="12">
                    <service-autocomplete
                      v-if="selectedEtablissement && services.length > 1"
                      v-model="selectedService"
                      :list-services="services"
                      required
                      :disabled="!!demandes"
                    />
                  </v-col>
                  <v-col cols="12">
                    <s-date-picker
                      v-if="selectedService"
                      v-model="evalHeuresSupp.date"
                      label="Date"
                      required
                      defaultCurrent
                      :disabled="!!demandes"
                    />
                  </v-col>
                </v-row>
                <v-row v-if="!demandes">
                  <v-col cols="12" sm="6">
                    <s-borne-horaire-picker
                      v-model="evalHeuresSupp.heureDebut"
                      label="Heure de début"
                      :borneMax="evalHeuresSupp.heureFin"
                      required
                    />
                  </v-col>
                  <!-- Timepicker Heure de fin -->
                  <v-col cols="12" sm="6">
                    <s-borne-horaire-picker
                      v-model="evalHeuresSupp.heureFin"
                      label="Heure de fin"
                      :borneMin="evalHeuresSupp.heureDebut"
                      required
                    />
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col cols="12">
                    <demande-heures-supp-list-item
                      v-for="(demande, index) in demandes"
                      :key="index"
                      :demande="demande"
                      @annuler="annuler(index)"
                    />
                  </v-col>
                  <v-col cols="12" v-if="!!demandes">
                    <v-textarea
                      v-model="commentaire"
                      rows="2"
                      auto-grow
                      clearable
                      clear-icon="mdi-close-circle"
                      label="Commentaire"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
            <v-col cols="6">
              <demande-heures-supp-apercu
                v-if="evalHeuresSupp.date && selectedService"
                :creneaux="creneauxApercu"
                :service="selectedService"
                :date="evalHeuresSupp.date"
                :max-height="500"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn color="grey darken-1" text @click="close()">Fermer</v-btn>
        <v-spacer />
        <v-btn v-if="!demandes" color="info" :disabled="!formValid" @click="evaluer()"> Évaluer </v-btn>
        <v-btn v-if="demandes" color="warning" :disabled="!formValid" @click="demandes = undefined"> Modifier </v-btn>
        <v-btn v-if="demandes" color="success" :disabled="!formValid" @click="envoyerTout()"> {{ envoiLabel }} </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import demandeHeuresSuppApercu from './demande-heures-supp-apercu'
import etablissementAutocomplete from '../../etablissement/etablissement-autocomplete'
import serviceAutocomplete from '../../service/service-autocomplete'
import { mapGetters, mapActions } from 'vuex'
import demandeHeuresSuppListItem from './demande-heures-supp-list-item'

export default {
  name: 'demande-heures-supp-create-dialog',
  components: {
    demandeHeuresSuppListItem,
    etablissementAutocomplete,
    serviceAutocomplete,
    demandeHeuresSuppApercu,
  },
  props: {
    membreId: {
      type: Number,
      required: false,
      default: undefined,
    },
    date: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      showDialog: false,
      formValid: false,
      selectedEmploye: undefined,
      creneaux: [],
      evalHeuresSupp: {
        membreId: undefined,
        date: undefined,
        heureDebut: undefined,
        heureFin: undefined,
      },
      commentaire: undefined,
      demandes: undefined,
    }
  },
  computed: {
    ...mapGetters({
      user: 'security/getUtilisateur',
      currentDate: 'calendrier/getCurrentDate',
      getEtablissementById: 'etablissement/getEtablissementById',
      getEmployesByUtilisateurId: 'employe/getEmployesByUtilisateurId',
      getMembresByEmployeId: 'membre/getMembresByEmployeId',
      getMembreById: 'membre/getMembreById',
      getServices: 'service/getServices',
      getServiceById: 'service/getServiceById',
    }),
    employes() {
      return this.getEmployesByUtilisateurId(this.user.utilisateurId)
    },
    initialMembreId() {
      if (this.membreId) {
        return this.membreId
      } else {
        if (this.membres && this.membres.length === 1) {
          return this.membres[0].membreId
        } else {
          return undefined
        }
      }
    },
    etablissements() {
      return this.employes.map((emp) => emp.etablissement)
    },
    selectedEtablissement: {
      get() {
        if (this.selectedEmploye) {
          return this.employes.find((e) => e.employeId === this.selectedEmploye.employeId).etablissement
        } else {
          return undefined
        }
      },
      set(_etablissement) {
        this.selectedEmploye = this.employes.find(
          (e) => e.etablissement.etablissementId === _etablissement.etablissementId
        )
      },
    },
    membres() {
      if (this.selectedEmploye) {
        return this.getMembresByEmployeId(this.selectedEmploye.employeId)
      } else {
        return undefined
      }
    },
    services() {
      return this.membres.map((m) => this.getServiceById(m.serviceId))
    },
    selectedService: {
      get() {
        if (this.evalHeuresSupp && this.evalHeuresSupp.membreId) {
          return this.getServiceById(this.membres.find((m) => m.membreId === this.evalHeuresSupp.membreId).serviceId)
        } else {
          return undefined
        }
      },
      set(_service) {
        if (_service) {
          this.evalHeuresSupp.membreId = this.membres.find((m) => m.serviceId === _service.serviceId).membreId
        } else {
          this.evalHeuresSupp.membreId = undefined
        }
      },
    },
    selectedDate() {
      if (this.evalHeuresSupp) {
        return this.evalHeuresSupp.date
      } else {
        return undefined
      }
    },
    creneauxApercu() {
      if (this.demandes) {
        return this.creneaux.concat(this.demandes.map((d) => d.creneau))
      } else {
        return this.creneaux
      }
    },
    envoiLabel() {
      if (this.demandes && this.demandes.length > 1) {
        return 'Tout envoyer'
      } else {
        return 'Envoyer'
      }
    },
  },
  created() {
    this.initialize()
  },
  watch: {
    value() {
      this.showDialog = this.value
    },
    membres() {
      if (this.services.length === 1) {
        this.evalHeuresSupp.membreId = this.membres[0].membreId
      }
    },
    selectedDate() {
      this.updateApercu()
    },
    selectedService() {
      this.updateApercu()
    },
    demandes() {
      if (this.demandes && this.demandes.length === 0) {
        this.demandes = undefined
      }
    },
  },
  methods: {
    ...mapActions({
      createDemandeHeuresSupp: 'demande/createDemandeHeuresSupp',
      evaluerDemandeHeuresSupp: 'demande/evaluerDemandeHeuresSupp',
      fetchCreneauxByServiceIdAndDate: 'planning/fetchCreneauxByServiceIdAndDate',
    }),
    initialize() {
      this.demandes = undefined
      this.commentaire = undefined
      this.creneaux = []

      if (this.employes && this.employes.length === 1) {
        this.selectedEmploye = this.employes[0]
      }

      this.evalHeuresSupp = {
        membreId: this.initialMembreId,
        date: this.date ? this.date : this.currentDate,
        heureDebut: undefined,
        heureFin: undefined,
      }
    },
    updateApercu() {
      if (this.selectedDate && this.selectedService) {
        this.fetchCreneauxByServiceIdAndDate({
          serviceId: this.selectedService.serviceId,
          date: this.selectedDate,
        }).then((response) => (this.creneaux = response.data))
      } else {
        this.creneaux = []
      }
    },
    updateFormValid(_validity) {
      this.formValid = _validity
    },
    evaluer() {
      if (this.formValid) {
        this.evaluerDemandeHeuresSupp(this.evalHeuresSupp).then((response) => {
          response.data.demandes.forEach((d) => (d.creneau.membre = this.getMembreById(this.evalHeuresSupp.membreId)))
          this.demandes = response.data.demandes
        })
      }
    },
    annuler(_index) {
      this.demandes.splice(_index, 1)
    },
    envoyerTout() {
      if (this.demandes) {
        for (let i = 0; i < this.demandes.length; i++) {
          this.demandes[i].commentaire = this.commentaire

          if (i === this.demandes.length - 1) {
            this.createDemandeHeuresSupp(this.demandes[i]).then((demande) => {
              this.$router.push({ name: 'demandeHeuresSuppDetail', params: { demandeId: demande.demandeId } })
            })
          } else {
            this.createDemandeHeuresSupp(this.demandes[i])
          }
        }

        this.close()
      }
    },
    close() {
      this.initialize()
      this.showDialog = false
    },
  },
}
</script>

<style scoped></style>

export default {
  namespaced: true,
  state: {
    ajustementSelectionneId: undefined,
    ajustementSurvoleId: undefined,
  },
  mutations: {
    SET_AJUSTEMENT_SELECTIONNE(state, _ajustementId) {
      state.ajustementSelectionneId = _ajustementId
    },
    RAZ_AJUSTEMENT_SELECTIONNE(state) {
      state.ajustementSelectionneId = undefined
    },
    SET_AJUSTEMENT_SURVOLE(state, _ajustementId) {
      state.ajustementSurvoleId = _ajustementId
    },
    RAZ_AJUSTEMENT_SURVOLE(state) {
      state.ajustementSurvoleId = undefined
    },
  },
  getters: {
    estAjustementConcerneParAjustementSelectionne: (state) => (_ajustementId) => {
      if (state.ajustementSelectionneId === undefined) {
        return false
      }
      return state.ajustementSelectionneId === _ajustementId
    },
    estAjustementConcerneParAjustementSurvole: (state) => (_ajustementId) => {
      if (state.ajustementSurvoleId === undefined) {
        return false
      }
      return state.ajustementSurvoleId === _ajustementId
    },
    estSelectionne: (state) => state.ajustementSelectionneId !== undefined,
  },
  actions: {
    setAjustementSelectionne({ commit }, _ajustementId) {
      commit('SET_AJUSTEMENT_SELECTIONNE', _ajustementId)
      commit('SET_AJUSTEMENT_SURVOLE', _ajustementId)
    },
    setAjustementSurvole({ commit }, _ajustementId) {
      commit('SET_AJUSTEMENT_SURVOLE', _ajustementId)
    },
    reinitializerAjustementSelectionne({ commit }) {
      commit('RAZ_AJUSTEMENT_SELECTIONNE')
      commit('RAZ_AJUSTEMENT_SURVOLE')
    },
    reinitializerAjustementSurvole({ commit }) {
      commit('RAZ_AJUSTEMENT_SURVOLE')
    },
  },
}

<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="py-0">
        <v-toolbar flat dense class="recuperations-toolbar">
          <v-toolbar-title class="text-h6" v-text="'Historique des récupérations'" />
        </v-toolbar>
        <v-list flat class="pa-0">
          <v-list-group
            v-for="(date, index) in Object.keys(recuperations.historique)"
            :key="index"
            color="primary"
            no-action
          >
            <template v-slot:activator>
              <v-list-item>
                <v-list-item-subtitle>
                  <v-chip
                    label
                    :class="classColor(recuperations.historique[date])"
                    v-text="solde(recuperations.historique[date]) + 'h'"
                  />
                </v-list-item-subtitle>
                <v-list-item-title :class="'text-body-1 ' + isFutur(date)" v-text="formatDateToDisplay(date)" />
              </v-list-item>
            </template>
            <heures-credit-list-item
              v-for="credit in getCreditsByDate(date)"
              :key="credit.heureCreditId"
              :credit="credit"
            />
            <ecart-planning-modele-list-item
              v-for="ecart in getEcartsByDate(date)"
              :key="ecart.creneauTriId"
              :ecart="ecart"
            />
          </v-list-group>
        </v-list>
        <v-divider />
        <v-footer padless color="white">
          <v-col class="text-center text-body-1" cols="12">
            Solde de récupération initial: {{ recuperations.soldeRecupInitial / 60 }}h
          </v-col>
        </v-footer>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EcartPlanningModeleListItem from './ecart-planning-modele-list-item'
import HeuresCreditListItem from './heures-credit-list-item'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'employe-detail-recuperation',
  components: {
    EcartPlanningModeleListItem,
    HeuresCreditListItem,
  },
  data() {
    return {
      selectedSolde: undefined,
    }
  },
  computed: {
    ...mapGetters({
      getEmployeById: 'employe/getEmployeById',
      formatDateToDisplay: 'calendrier/formatDateToDisplay',
    }),
    employe() {
      return this.getEmployeById(parseInt(this.$route.params.employeId, 10))
    },
    recuperations() {
      return this.employe.recuperations
    },
  },
  methods: {
    getEcartsByDate(_date) {
      return this.recuperations.historique[_date].filter((r) => r.type !== 'HeureCredit')
    },
    getCreditsByDate(_date) {
      return this.recuperations.historique[_date].filter((r) => r.type === 'HeureCredit')
    },
    solde(_creneaux) {
      let solde = 0

      _creneaux.forEach((c) => {
        solde += c.soldeEnMinute / 60
      })

      return Math.round(solde * 100) / 100
    },
    color(_soldeEnMinute) {
      if (_soldeEnMinute > 0) {
        return 'success'
      } else {
        return 'error'
      }
    },
    classColor(_creneau) {
      return `${this.color(this.solde(_creneau))} lighten-4 ` + this.textColor(this.solde(_creneau))
    },
    textColor(_soldeEnMinute) {
      return `${this.color(_soldeEnMinute)}--text text--darken-4`
    },
    isFutur(_date) {
      if (moment(_date, 'YYYY-MM-DD').isAfter(moment())) {
        return 'grey--text'
      } else {
        return ''
      }
    },
  },
}
</script>

<style scoped>
.recuperations-toolbar {
  border-bottom: 2px solid var(--v-primary-lighten5);
}
</style>

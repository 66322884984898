<template>
  <s-page v-if="loaded">
    <template v-slot:main-panel>
      <conges-apercu :etablissementId="selectedEtablissement.etablissementId" />
    </template>
  </s-page>
</template>

<script>
import CongesApercu from '../../components/conges/apercu/conges-apercu'
import { mapGetters } from 'vuex'
import { initialDataLoaded } from '../../store/modules/helpers'

export default {
  name: 'etablissement-tableau-conges',
  components: {
    CongesApercu,
  },
  data() {
    return {}
  },
  computed: {
    ...initialDataLoaded,
    ...mapGetters({
      etablissements: 'etablissement/getEtablissements',
      selectedEtablissement: 'application/getSelectedEtablissement',
      isInRole: 'security/isInRole',
    }),
    loaded() {
      return initialDataLoaded && this.selectedEtablissement
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

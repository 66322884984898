<template>
  <v-autocomplete
    v-model="selectedEmploye"
    ref="employeAutocomplete"
    :items="employeSearchItems"
    :loading="isLoading"
    :search-input.sync="searchEmployes"
    item-text="nomComplet"
    item-value="employeId"
    hide-no-data
    :hide-details="hideDetails"
    :label="computedLabel"
    :rules="[(v) => !!v]"
    :clearable="true"
    :required="required"
    return-object
    :readonly="readonly"
    :disabled="readonly"
    :multiple="multiple"
    :chips="multiple"
    closable-chips
  >
    <template v-slot:chip="{ props, item }">
      <v-chip
        @click.close="console.log('closing' + item.nomComplet)"
        close
        v-bind="props"
        :text="item.nomComplet"
      ></v-chip>
    </template>
    <template v-slot:item="data">
      <template>
        <v-list-item-avatar>
          <utilisateur-avatar :utilisateur="data.item.utilisateur" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-html="data.item.nomComplet" />
          <v-list-item-subtitle v-if="!etablissement" v-html="data.item.etablissement.raisonSociale" />
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
import _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import UtilisateurAvatar from '../utilisateur/utilisateur-avatar'

export default {
  name: 'employe-autocomplete',
  components: { UtilisateurAvatar },
  props: {
    value: {
      type: undefined,
      required: false,
      default: undefined,
    },
    label: {
      type: String,
      required: false,
      default: 'Employé',
    },
    etablissement: {
      type: Object,
      required: false,
      default: undefined,
    },
    titulaires: {
      type: Boolean,
      required: false,
      default: false,
    },
    vacataires: {
      type: Boolean,
      required: false,
      default: false,
    },
    cadres: {
      type: Boolean,
      required: false,
      default: false,
    },
    nonCadres: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      required: false,
      default: false,
    },
    employesAExclure: {
      type: Array,
      required: false,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    role: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      listEmployes: undefined,
      selectedEmploye: undefined,
      searchEmployes: '',
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters({
      getEmployes: 'employe/getEmployes',
    }),
    employeSearchItems() {
      let resultat = this.listEmployes ? this.listEmployes : this.getEmployes
      resultat = this.appliquerLesFiltres(resultat)
      resultat = this.garantirLaPresenceDeLemployeSelectionner(resultat)
      return resultat
    },
    computedLabel() {
      if (this.required) {
        return this.label + '*'
      } else {
        return this.label
      }
    },
  },
  created() {
    if (this.value) {
      this.selectedEmploye = this.value
    }
  },
  watch: {
    selectedEmploye() {
      if (this.selectedEmploye !== this.value) {
        this.$emit('input', this.selectedEmploye)
      }
    },
    value() {
      this.selectedEmploye = this.value
    },
    searchEmployes() {
      // Items have already been requested
      if (this.searchEmployes !== null && this.searchEmployes !== '') {
        if (!this.isLoading) {
          this.isLoading = true
          // Lazily load input items
          this.debouncedSearch()
        }
      }
    },
  },
  methods: {
    ...mapActions({
      searchEmploye: 'employe/recherche',
    }),
    debouncedSearch: _.debounce(function () {
      this.searchEmploye({
        recherche: this.searchEmployes,
        etablissementId: this.etablissement ? this.etablissement.etablissementId : undefined,
      })
        .then((result) => {
          this.listEmployes = result
        })
        .finally(() => (this.isLoading = false))
    }, 500),
    appliquerLesFiltres(_listeDemployesAFiltrer) {
      _listeDemployesAFiltrer = _listeDemployesAFiltrer.filter((e) => {
        if (this.titulaires) {
          return e.titulaire
        } else if (this.vacataires) {
          return !e.titulaire
        } else {
          return true
        }
      })

      _listeDemployesAFiltrer = _listeDemployesAFiltrer.filter((e) => {
        for (const exclu in this.employesAExclure) {
          if (e.employeId === exclu.employeId) {
            return false
          }
        }

        return true
      })

      _listeDemployesAFiltrer = _listeDemployesAFiltrer.filter((e) => {
        if (this.cadre) {
          return e.cadre
        } else if (this.nonCadres) {
          return !e.cadre
        } else {
          return true
        }
      })

      return _listeDemployesAFiltrer
    },
    garantirLaPresenceDeLemployeSelectionner(_dansCettelisteDemployes) {
      if (this.selectedEmploye) {
        let employeSelectionneDejaDansLaListe = false

        for (const employe in _dansCettelisteDemployes) {
          if (employe.employeId === this.selectedEmploye.employeId) {
            employeSelectionneDejaDansLaListe = true
            break
          }
        }

        if (!employeSelectionneDejaDansLaListe) {
          _dansCettelisteDemployes.push(this.selectedEmploye)
        }
      }

      return _dansCettelisteDemployes
    },
  },
}
</script>

<style scoped></style>

<template>
  <v-card>
    <v-card-title v-if="title">
      {{ title }}
      <v-spacer />
      <v-card-actions class="py-0"><slot name="actions" /></v-card-actions>
    </v-card-title>
    <v-card-text>
      <slot />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 's-section',
  props: {
    title: {
      type: String,
      required: false,
      default: undefined,
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>

<template>
  <s-main-panel toolbar title="Déclaration d'un nouvel employé" :scrollbar="true">
    <template v-slot:actions>
      <v-btn color="grey darken-1" text @click="annuler()">Annuler</v-btn>
      <v-btn color="success" :disabled="!formValid" @click="valider"> Créer </v-btn>
    </template>
    <v-container v-if="createEmployeCommand">
      <v-row>
        <!-- Compte Utilisateur -->
        <v-col cols="12" xl="4">
          <s-section title="Compte utilisateur">
            <utilisateur-form v-model="createEmployeCommand.utilisateur" />
          </s-section>
        </v-col>
        <!-- Employe -->
        <v-col cols="12" xl="4">
          <s-section v-if="createEmployeCommand.utilisateur.utilisateurId !== undefined" title="Employé">
            <employe-form v-model="employe" create @updateFormValidity="updateFormValid" />
          </s-section>
        </v-col>
        <!-- Service(s) -->
        <v-col cols="12" xl="4">
          <s-section title="Service(s)" v-if="createEmployeCommand.utilisateur.utilisateurId !== undefined">
            <!-- Bouton d'ajout de services -->
            <template v-slot:actions>
              <v-dialog v-model="selectionServiceVisible" persistent max-width="600px">
                <template v-slot:activator="{ on }">
                  <v-btn color="green darken-1" small outlined v-on="on"> + service </v-btn>
                </template>
                <v-card>
                  <v-card-title class="headline"> Sélection des services </v-card-title>
                  <v-card-text>
                    <v-combobox
                      v-model="services"
                      :items="getServices"
                      item-text="nom"
                      label="Services"
                      multiple
                    ></v-combobox>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn color="green darken-1" text @click="selectionServiceVisible = false"> Fermer </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
            <v-col
              v-for="membre in createEmployeCommand.createMembreCommands"
              :key="membre.serviceId"
              cols="12"
              class="py-0"
            >
              <v-text-field
                v-model="membre.heuresHebdo"
                :rules="numericFieldRule"
                label="Heures par semaine"
                suffix="heure(s)"
                required
              >
                <template v-slot:prepend>
                  <service-chip :serviceId="membre.serviceId" color="primary" outlined />
                </template>
              </v-text-field>
            </v-col>
          </s-section>
        </v-col>
      </v-row>
    </v-container>
  </s-main-panel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import UtilisateurForm from '../../components/utilisateur/utilisateur-form'
import EmployeForm from '../../components/employe/employe-form'
import ServiceChip from '../../components/service/service-chip'

export default {
  name: 'employe-create',
  components: { UtilisateurForm, ServiceChip, EmployeForm },
  data: () => ({
    formValid: false,
    numericFieldRule: [(v) => !isNaN(v) || 'Entrer une valeur numérique'],
    prevRoute: undefined,
    selectionServiceVisible: false,
    createEmployeCommand: {},
  }),
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from
    })
  },
  computed: {
    ...mapGetters({
      getEmployeByUtilisateurId: 'employe/getEmployeByUtilisateurId',
      getServices: 'service/getServices',
      etablissement: 'application/getSelectedEtablissement',
    }),
    utilisateur() {
      return this.createEmployeCommand.utilisateur
    },
    employe: {
      get() {
        return {
          emailPro: this.createEmployeCommand.emailPro,
          delegueDuPersonnel: this.createEmployeCommand.delegueDuPersonnel,
          administrateur: this.createEmployeCommand.administrateur,
          soldeRecupInitial: this.createEmployeCommand.soldeRecupInitial,
          dateEntreeAnciennete: this.createEmployeCommand.dateEntreeAnciennete,
          soldeInitialCongesPayes: this.createEmployeCommand.soldeInitialCongesPayes,
          soldeInitialCongesTrimestriels: this.createEmployeCommand.soldeInitialCongesTrimestriels,
        }
      },
      set(_employe) {
        this.createEmployeCommand.emailPro = _employe.emailPro
        this.createEmployeCommand.delegueDuPersonnel = _employe.delegueDuPersonnel
        this.createEmployeCommand.administrateur = _employe.administrateur
        this.createEmployeCommand.soldeRecupInitial = _employe.soldeRecupInitial
        this.createEmployeCommand.dateEntreeAnciennete = _employe.dateEntreeAnciennete
        this.createEmployeCommand.soldeInitialCongesPayes = _employe.soldeInitialCongesPayes
        this.createEmployeCommand.soldeInitialCongesTrimestriels = _employe.soldeInitialCongesTrimestriels
      },
    },
    services: {
      // getter
      get: function () {
        return this.getServices.filter((s) => {
          return this.createEmployeCommand.createMembreCommands.find((m) => m.serviceId === s.serviceId) !== undefined
        })
      },
      // setter
      set: function (_selectedServices) {
        this.createEmployeCommand.createMembreCommands = []
        _selectedServices.forEach((s) => {
          this.createEmployeCommand.createMembreCommands.push({
            serviceId: s.serviceId,
            employeId: this.createEmployeCommand.employeId,
            heuresHebdo: 0,
          })
        })
      },
    },
  },
  created() {
    this.initialiserFormulaire()
  },
  watch: {
    utilisateur() {
      if (
        this.createEmployeCommand &&
        this.createEmployeCommand.utilisateur &&
        this.createEmployeCommand.utilisateur.utilisateurId
      ) {
        //On vérifie qu'un employé n'existe pas déjà pour l'établissement courant
        const foundEmploye = this.getEmployeByUtilisateurId(this.createEmployeCommand.utilisateur.utilisateurId)

        if (foundEmploye) {
          this.$router.push({
            name: 'employe',
            params: { employeId: String(foundEmploye.employeId) },
          })
        }
      }
    },
  },
  methods: {
    ...mapActions({
      createEmploye: 'employe/createEmploye',
    }),
    valider() {
      this.createEmploye(this.createEmployeCommand).then((createdEmploye) => {
        this.$router.push({
          name: 'employe',
          params: { employeId: String(createdEmploye.employeId) },
        })
      })
    },
    updateFormValid(_validity) {
      this.formValid = _validity
    },
    initialiserFormulaire() {
      this.createEmployeCommand = {
        utilisateur: {
          utilisateurId: undefined,
          nom: undefined,
          prenom: undefined,
          email: undefined,
          numeroSecu: undefined,
          genre: undefined,
        },
        etablissementId: this.etablissement.etablissementId,
        emailPro: undefined,
        delegueDuPersonnel: undefined,
        administrateur: undefined,
        soldeRecupInitial: undefined,
        dateEntreeAnciennete: undefined,
        soldeInitialCongesPayes: undefined,
        soldeInitialCongesTrimestriels: undefined,
        createMembreCommands: [],
      }
    },
    annuler() {
      this.initialiserFormulaire()
      this.$router.push(this.prevRoute)
    },
  },
}
</script>

<style scoped></style>

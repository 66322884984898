<template>
  <v-dialog v-model="showDialog" persistent max-width="600px">
    <membre-remplacer-dialog v-model="showRemplacerDialog" :membre="membre" />
    <v-card v-if="showDialog">
      <s-toolbar text="Modifier un membre" update @close="close()" />
      <v-card-text>
        <membre-form v-model="membreToUpdate" @updateFormValid="updateFormValid" />
      </v-card-text>
      <v-card-actions>
        <v-btn icon @click="sendDeleteMembre()">
          <v-icon small color="red">fa-trash</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" text @click="showDialog = false">Annuler</v-btn>
        <v-btn color="info" :disabled="!formValid" @click="validate()">Mettre à jour</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import membreForm from './membre-form'
import membreRemplacerDialog from '../../views/modele-planning/modele-planning-interchanger-membre-dialog'
import { mapActions } from 'vuex'

export default {
  name: 'membre-update-dialog',
  components: {
    membreForm,
    membreRemplacerDialog,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    membre: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDialog: false,
      showRemplacerDialog: false,
      membreToUpdate: undefined,
      formValid: false,
    }
  },
  created() {
    this.showDialog = this.value
    this.initialize()
  },
  watch: {
    value() {
      this.showDialog = this.value
    },
    showDialog() {
      this.$emit('input', this.showDialog)
    },
  },
  methods: {
    ...mapActions({
      dispatchUpdateMembreCommand: 'membre/updateMembre',
      deleteMembre: 'membre/deleteMembre',
    }),
    updateFormValid(_validity) {
      this.formValid = _validity
    },
    initialize() {
      this.membreToUpdate = JSON.parse(JSON.stringify(this.membre))
    },
    validate() {
      if (this.formValid) {
        this.dispatchUpdateMembreCommand({
          membreId: this.membreToUpdate.membreId,
          heuresHebdo: this.membreToUpdate.heuresHebdo,
          couleur: this.membreToUpdate.couleur,
        })
        this.close()
      }
    },
    sendDeleteMembre() {
      this.deleteMembre(this.membreToUpdate.membreId)
      this.close()
    },
    ouvrirRemplacerDialog() {
      this.close()
      this.showRemplacerDialog = true
    },
    close() {
      this.showDialog = false
    },
  },
}
</script>

<style scoped></style>

<template>
  <v-dialog v-model="showDialog" persistent max-width="600px" heigth="400px">
    <v-card v-if="showDialog">
      <s-toolbar text="Exporter les éléments de paye" @close="close()" />
      <v-card-text style="min-height: 125px">
        <v-form v-if="!commandeEnvoyee" v-model="formValid">
          <v-row>
            <v-col cols="6" class="py-0">
              <s-date-picker v-model="exportPayeCommand.dateDebut" label="Du" required />
            </v-col>
            <v-col cols="6" class="py-0">
              <s-date-picker v-model="exportPayeCommand.dateFin" label="Au" required />
            </v-col>
          </v-row>
        </v-form>
        <v-row v-else-if="telechargementEnCours" class="fill-height" align-content="center" justify="center">
          <v-col class="text-subtitle-1 text-center" cols="12"> Calculs en cours </v-col>
          <v-col cols="6">
            <v-progress-linear color="primary accent-4" indeterminate rounded height="6"></v-progress-linear>
          </v-col>
        </v-row>
        <v-row v-else class="fill-height" align-content="center" justify="center">
          <v-col class="text-subtitle-1 text-center" cols="12"> Téléchargement terminé </v-col>
          <v-col cols="6" align="center">
            <v-icon color="success" x-large>fa-check</v-icon>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions v-if="!commandeEnvoyee || telechargementEnCours">
        <v-spacer />
        <v-btn color="grey" text @click="close()">Annuler</v-btn>
        <v-btn color="info" :disabled="!formValid || telechargementEnCours" @click="validate()"> Exporter </v-btn>
      </v-card-actions>
      <v-card-actions v-else>
        <v-spacer />
        <v-btn color="grey" text @click="close()">Fermer</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'service-export-paye-dialog',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    service: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDialog: undefined,
      formValid: false,
      exportPayeCommand: undefined,
      commandeEnvoyee: false,
      telechargementEnCours: false,
    }
  },
  created() {
    this.showDialog = this.value
    this.initialize()
  },
  watch: {
    showDialog() {
      if (this.showDialog === false) {
        this.initialize()
      }
      this.$emit('input', this.showDialog)
    },
    value() {
      this.showDialog = this.value
    },
    service() {
      this.initialize()
    },
  },
  methods: {
    ...mapActions({
      exportPaye: 'service/exportPaye',
    }),
    initialize() {
      this.telechargementEnCours = false
      this.commandeEnvoyee = false
      this.exportPayeCommand = {
        serviceId: this.service.serviceId,
        dateDebut: this.service.dateDernierExportElementsPaye,
        dateFin: undefined,
      }
    },
    validate() {
      this.telechargementEnCours = true
      this.commandeEnvoyee = true
      this.exportPaye(this.exportPayeCommand).then(() => {
        this.telechargementEnCours = false
      })
    },
    close() {
      this.showDialog = false
    },
  },
}
</script>

<style scoped></style>

<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card-text class="align-center">
          <p>Choisissez la date de mise en application du nouveau modèle à créer:</p>
          <v-form v-model="dateMiseEnApplicationFormValid">
            <v-date-picker
              v-model="dateMiseEnApplication"
              class="elevation-0"
              :allowed-dates="seulementLesLundis"
              no-title
              flat
              full-width
              locale="fr"
              min="2022-12-26"
              first-day-of-week="1"
              locale-first-day-of-year="4"
              show-week
              reactive
            />
            <v-text-field v-model="dateMiseEnApplication" :rules="[(v) => !!v]" class="hidden-screen-only" />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="$emit('annuler')">Annuler</v-btn>
          <v-btn color="success" :disabled="!dateMiseEnApplicationFormValid" @click="$emit('suivant')">Suivant</v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'modele-astreintes-create-step1-date',
  props: {
    value: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      dateMiseEnApplicationFormValid: false,
      dateMiseEnApplication: undefined,
      seulementLesLundis: (val) => new Date(val).getDay() === 1,
    }
  },
  created() {
    this.dateMiseEnApplication = this.value
  },
  watch: {
    dateMiseEnApplication: {
      handler() {
        this.$emit('input', this.dateMiseEnApplication)
      },
      deep: true,
    },
    value() {
      this.dateMiseEnApplication = this.value
    },
  },
}
</script>

<style scoped></style>

<template>
  <v-form v-model="formValid">
    <v-container>
      <v-row>
        <v-col cols="6">
          <v-row>
            <!-- Selection de l'employe -->
            <v-col cols="12">
              <employe-autocomplete
                v-model="employe"
                label="Employé"
                :etablissement="etablissement"
                :required="create"
                :readonly="!create"
              />
            </v-col>
            <!-- Selection du type de congés -->
            <v-col cols="12">
              <v-select
                :items="congesTypes"
                v-model="congesCredit.type"
                item-text="label"
                item-value="type"
                label="Type de congés*"
                dense
                :rules="[(v) => !!v]"
                return-object
                :disabled="!exceptionnel"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6">
          <v-row>
            <v-col cols="12">
              <!-- Selection des dates -->
              <s-date-picker
                v-model="congesCredit.date"
                label="Date du crédit"
                required
                defaultCurrent
                :disabled="!exceptionnel"
              />
            </v-col>
            <v-col cols="12">
              <!-- Selection des dates -->
              <s-date-picker
                v-model="congesCredit.dateExpiration"
                label="Date d'expiration"
                dense
                required
                :disabled="!exceptionnel"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="congesCredit.nbJours"
            :rules="numericFieldRule"
            hide-no-data
            type="number"
            label="Crédités*"
            suffix="jour(s)"
            required
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="congesCredit.commentaire"
            no-resize
            rows="2"
            hide-no-data
            clearable
            clear-icon="mdi-close-circle"
            label="Commentaire"
          ></v-textarea>
        </v-col>
      </v-row>
      <small>*Information obligatoire</small>
    </v-container>
  </v-form>
</template>

<script>
import EmployeAutocomplete from '@/components/employe/employe-autocomplete'
import { mapGetters } from 'vuex'

export default {
  name: 'conges-credit-form',
  components: {
    EmployeAutocomplete,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    create: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      formValid: false,
      congesCredit: undefined,
      numericFieldRule: [
        (v) => !!v || 'Information obligatoire',
        (v) => !isNaN(v) || 'Entrez une valeur numérique',
        (v) => v > 0 || 'Entrez une valeur positive',
      ],
    }
  },
  computed: {
    ...mapGetters({
      etablissement: 'application/getSelectedEtablissement',
      employes: 'employe/getEmployes',
      getEmployeById: 'employe/getEmployeById',
      getCongesTypes: 'conges/getCongesTypes',
    }),
    exceptionnel() {
      return this.congesCredit.exceptionnel
    },
    congesTypes() {
      return this.getCongesTypes.filter((t) => t.avecSolde)
    },
    employe: {
      get() {
        return this.getEmployeById(this.congesCredit.employeId)
      },
      set(_employe) {
        this.congesCredit.employeId = _employe.employeId
      },
    },
  },
  created() {
    this.congesCredit = this.value
  },
  watch: {
    congesCredit: {
      handler() {
        this.$emit('input', this.congesCredit)
      },
      deep: true,
    },
    value() {
      this.congesCredit = this.value
    },
    formValid() {
      this.$emit('updateFormValid', this.formValid)
    },
  },
}
</script>

<style scoped></style>

<template>
  <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        ref="field"
        :value="displayedSelectedDate"
        :label="displayedLabel"
        prepend-icon="fa-calendar-alt"
        :rules="rules"
        readonly
        :dense="dense"
        :hide-details="hideDetails"
        :disabled="disabled"
        :clearable="!required"
        :hint="hint"
        :persistent-hint="!!hint"
        v-bind="attrs"
        @click:clear="selectedDate = undefined"
        v-on="on"
      />
    </template>
    <v-date-picker
      v-model="selectedDate"
      @input="menu = false"
      locale="fr-FR"
      first-day-of-week="1"
      :allowed-dates="(date) => isAllowed(date)"
      :min="minISODate"
      :max="maxISODate"
    />
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 's-date-picker',
  props: {
    value: {
      type: String,
      required: false,
      default: undefined,
    },
    label: {
      type: String,
      required: true,
    },
    defaultCurrent: {
      type: Boolean,
      required: false,
      default: false,
    },
    minISODate: {
      type: String,
      required: false,
      default: undefined,
    },
    maxISODate: {
      type: String,
      required: false,
      default: undefined,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    dense: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    lundiUniquemement: {
      type: Boolean,
      required: false,
      default: false,
    },
    hint: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      selectedDate: undefined,
      menu: false,
    }
  },
  computed: {
    ...mapGetters({
      formatDateToDisplay: 'calendrier/formatDateToDisplay',
      currentDate: 'calendrier/getCurrentDate',
    }),
    rules() {
      if (this.required) {
        return [(v) => !!v]
      } else {
        return []
      }
    },
    displayedSelectedDate() {
      if (this.selectedDate) {
        return this.formatDateToDisplay(this.selectedDate)
      } else {
        return undefined
      }
    },
    displayedLabel() {
      if (this.required) {
        return this.label + '*'
      } else {
        return this.label
      }
    },
  },
  created() {
    if (this.value) {
      this.selectedDate = this.value
    } else {
      if (this.defaultCurrent) {
        this.selectedDate = this.currentDate
      }
    }
  },
  watch: {
    selectedDate() {
      this.$emit('input', this.selectedDate)
    },
    value() {
      if (this.value) {
        this.selectedDate = this.value
      } else {
        if (this.defaultCurrent) {
          this.selectedDate = this.currentDate
        } else {
          this.selectedDate = undefined
        }
      }
    },
  },
  methods: {
    isAllowed(_date) {
      if (this.lundiUniquement) {
        return new Date(_date).getDay() === 0
      } else {
        return true
      }
    },
    focus() {
      setTimeout(() => {
        this.$refs.field.focus()
        this.menu = true
      })
    },
  },
}
</script>

<style scoped></style>

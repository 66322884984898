import axios from 'axios'
import moment from 'moment'

export default {
  namespaced: true,
  state: {
    borneHoraireList: [],
    jours: [],
    joursFeries: [],
    monthFormatter: new Intl.DateTimeFormat('fr-FR', { month: 'long' }),
  },
  mutations: {
    SET_BORNE_HORAIRE_LIST(state, _borneHoraireList) {
      state.borneHoraireList = _borneHoraireList.sort(function (a, b) {
        return a.valeur - b.valeur
      })
    },
    SET_JOUR_LIST(state, _jourList) {
      state.jours = _jourList.sort(function (a, b) {
        return a.jourId - b.jourId
      })
    },
    SET_JOURS_FERIES(state, _joursFeries) {
      state.joursFeries = _joursFeries
    },
  },
  getters: {
    getCurrentDate() {
      return moment().format('YYYY-MM-DD')
    },
    getAllBornesHoraire(state) {
      return state.borneHoraireList
    },
    getBornesHoraire(state) {
      return state.borneHoraireList.filter((bh) => bh.minute % 15 === 0)
    },
    getDebutJour(state) {
      //7h00
      return state.borneHoraireList.find((bh) => bh.valeur === 420)
    },
    getDebutNuit(state) {
      //23h00
      return state.borneHoraireList.find((bh) => bh.valeur === 1380)
    },
    isJourNuit: (state, getters) => (_borneHoraire) => {
      if (_borneHoraire.valeur >= getters.getDebutJour.valeur && _borneHoraire.valeur < getters.getDebutNuit.valeur) {
        return 'jour'
      } else {
        return 'nuit'
      }
    },
    getBornesHoraireMatin: (state, getters) => () => {
      return getters.getBornesHoraire.filter((b) => b.valeur < getters.getDebutJour.valeur + 30)
    },
    getBornesHoraireSoir: (state, getters) => () => {
      return getters.getBornesHoraire.filter((b) => b.valeur >= getters.getDebutNuit.valeur - 30)
    },
    getBornesHoraireJournee: (state, getters) => () => {
      return getters.getBornesHoraire.filter(
        (b) => b.valeur >= getters.getDebutJour.valeur - 30 && b.valeur < getters.getDebutNuit.valeur + 30
      )
    },
    getJours(state) {
      return state.jours
    },
    getDateOfMonth: () => (_annee, _mois) => {
      const result = moment(_annee + '-' + _mois + '-01', 'YYYY-MM-DD')

      return result
    },
    getMonthOfDate: () => (_date) => {
      const result = {
        mois: moment(_date).month() + 1,
        annee: moment(_date).year(),
      }

      return result
    },
    getMonthLabel: (state) => (_month) => {
      const date = new Date()
      date.setDate(1)
      date.setMonth(_month - 1)
      return state.monthFormatter.format(date)
    },
    getNbDaysInMonth: () => (_month) => {
      const result = new Date(_month.annee, _month.mois, 0).getDate()

      return result
    },
    getDateOfISOWeek: () => (_annee, _semaine) => {
      return moment(_annee + '-' + _semaine + '-0', 'gggg-ww-e').format('YYYY-MM-DD')
    },
    getDateOfISOWeekDay: () => (_annee, _semaine, _jourId) => {
      return moment(_annee + '-' + _semaine + '-' + (_jourId - 1), 'gggg-ww-e').format('YYYY-MM-DD')
    },
    getISOWeekOfDate: () => (_date) => {
      return {
        semaine: moment(_date).week(),
        annee: moment(_date).weekYear(),
        numero: moment(_date).week() + '-' + moment(_date).weekYear(),
      }
    },
    getDayOfTheWeekOfDate: (state) => (_date) => {
      return state.jours.find((j) => j.jourId === moment(_date).isoWeekday())
    },
    isISODateFerie: (state) => (_date) => {
      return state.joursFeries.includes(_date)
    },
    formatDateToDisplay: () => (_date) => {
      return moment(_date).format('DD/MM/YYYY')
    },
    formatDateTimeToDisplay: () => (_date) => {
      return moment(_date).format('DD/MM/YYYY à HH:mm')
    },
  },
  actions: {
    fetchBorneHoraireList() {
      return axios.get(`${process.env.VUE_APP_URL_BACK_SERENITY}/calendrier/borneHoraire`).then((response) => {
        this.commit('calendrier/SET_BORNE_HORAIRE_LIST', response.data)
      })
    },
    fetchJourList() {
      return axios.get(`${process.env.VUE_APP_URL_BACK_SERENITY}/calendrier/jour`).then((response) => {
        this.commit('calendrier/SET_JOUR_LIST', response.data)
      })
    },
    fetchJoursFeries() {
      return axios.get(`${process.env.VUE_APP_URL_BACK_SERENITY}/calendrier/joursFeries`).then((response) => {
        this.commit('calendrier/SET_JOURS_FERIES', response.data)
      })
    },
  },
}

<template>
  <v-list-item :to="{ name: 'employe', params: { employeId: employeId } }">
    <v-list-item-avatar>
      <utilisateur-avatar :utilisateur="employe.utilisateur" :size="36" />
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title v-text="employe.nomComplet" />
      <v-list-item-subtitle v-text="employe.complementStatus" />
    </v-list-item-content>
    <v-card-actions class="py-0"><slot name="actions" /></v-card-actions>
  </v-list-item>
</template>

<script>
import utilisateurAvatar from '@/components/utilisateur/utilisateur-avatar'
import { mapGetters } from 'vuex'

export default {
  name: 'employe-list-item',
  components: {
    utilisateurAvatar,
  },
  props: {
    employeId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getEmployeById: 'employe/getEmployeById',
    }),
    employe() {
      return this.getEmployeById(this.employeId)
    },
  },
}
</script>

<style scoped></style>

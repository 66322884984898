<template>
  <v-list-item class="py-0" @click="showDialog = true">
    <conges-update-dialog v-model="showDialog" :congesToUpdate="conges" :employe="employe" />
    <v-list-item-content class="py-1">
      <v-list-item-title justify="center">
        <span>{{ datesToDisplay }}</span>
      </v-list-item-title>
      <v-list-item-subtitle> Demandé le {{ formatDateToDisplay(conges.dateDemande) }} </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <div>
        <!-- plein si remplacé, outlined si reste à remplacer -->
        <contrat-de-remplacement-create-dialog :employe-remplace="employe" :conges="conges" v-if="canUpdate" />
        <v-badge :color="color" :content="content" overlap>
          <v-chip outlined label>
            {{ conges.type.label }}
          </v-chip>
        </v-badge>
      </div>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import CongesUpdateDialog from './conges-update-dialog'
import ContratDeRemplacementCreateDialog from '../contrat/contrat-de-remplacement-create-dialog'
import { mapGetters } from 'vuex'

export default {
  name: 'conges-list-item',
  components: {
    CongesUpdateDialog,
    ContratDeRemplacementCreateDialog,
  },
  props: {
    conges: {
      type: Object,
      required: true,
    },
    employeId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showDialog: false,
    }
  },
  computed: {
    ...mapGetters({
      isInRole: 'security/isInRole',
      getEmployeById: 'employe/getEmployeById',
      formatDateToDisplay: 'calendrier/formatDateToDisplay',
    }),
    datesToDisplay() {
      if (this.conges.dateDebut === this.conges.dateFin) {
        if (this.conges.demiJournee) {
          return 'le ' + this.formatDateToDisplay(this.conges.dateDebut) + ' (' + this.conges.demiJourneeLabel + ')'
        } else {
          return 'le ' + this.formatDateToDisplay(this.conges.dateDebut)
        }
      } else {
        return (
          'du ' +
          this.formatDateToDisplay(this.conges.dateDebut) +
          ' au ' +
          this.formatDateToDisplay(this.conges.dateFin)
        )
      }
    },
    employe() {
      return this.getEmployeById(this.employeId)
    },
    canUpdate() {
      return this.employe && this.isInRole('ADMINISTRATEUR_' + this.employe.etablissement.etablissementId)
    },
    congesTypeAvecCompteur() {
      return this.conges.type.avecSolde
    },
    content() {
      if (this.congesTypeAvecCompteur) {
        return '-' + this.conges.nbJours
      } else {
        return this.conges.nbJours
      }
    },
    color() {
      if (this.congesTypeAvecCompteur) {
        return 'error lighten-4 error--text text--darken-4'
      } else {
        return 'info lighten-4 info--text text--darken-4'
      }
    },
  },
}
</script>

<style scoped></style>

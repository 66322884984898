<template>
  <v-container v-if="loaded">
    <v-row>
      <v-col cols="12" class="py-0">
        <v-toolbar flat dense class="contrats-toolbar">
          <v-toolbar-title class="text-h6" v-text="'Contrats'" />
          <v-spacer />
        </v-toolbar>
        <v-list two-line flat class="pa-0">
          <perfect-scrollbar>
            <v-list-item-group color="primary">
              <contrat-list-item
                v-for="contrat in contrats"
                :key="contrat.contratId"
                :contrat="contrat"
                :employe="employe"
              />
            </v-list-item-group>
          </perfect-scrollbar>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ContratListItem from '../../../components/contrat/contrat-list-item'
import { mapGetters } from 'vuex'

export default {
  name: 'employe-detail-contrat',
  components: {
    ContratListItem,
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      getEmployeById: 'employe/getEmployeById',
    }),
    loaded() {
      return this.employe
    },
    employe() {
      return this.getEmployeById(parseInt(this.$route.params.employeId, 10))
    },
    contrats() {
      return this.employe.contrats
    },
  },
}
</script>

<style scoped>
.contrats-toolbar {
  border-bottom: 2px solid var(--v-primary-lighten5);
}
</style>

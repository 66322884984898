<template>
  <div v-if="membre" class="planning-header-semaine-temps-membre">
    <v-avatar :color="membre.couleur" size="12px"> </v-avatar>
    {{ nombreHeures }}h
  </div>
</template>

<script>
export default {
  name: 'planning-header-semaine-membre',
  components: {},
  props: {
    creneauxMembre: {
      type: Array,
      required: true,
    },
    membre: {
      type: Object,
      required: true,
    },
  },
  computed: {
    employe() {
      return this.membre.employe
    },
    nombreHeures() {
      let result =
        this.creneauxMembre
          .filter((c) =>
            [
              'CreneauDeTravail',
              'CreneauHeuresSupplementaires',
              'CreneauDeRemplacement',
              'CreneauDeFormation',
            ].includes(c.type)
          )
          .map((c) => c.heureFin.valeur - c.heureDebut.valeur)
          .reduce((a, b) => a + b, 0) / 60

      return Math.round(result * 100) / 100
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.planning-header-semaine-temps-membre {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.v-avatar {
  margin-right: 2px;
}
</style>

<template>
  <v-dialog v-model="showDialog" persistent max-width="800px">
    <template v-slot:activator="{ on }">
      <v-btn v-if="mode === 'button'" v-on="on" small color="success" dark outlined>
        <v-icon x-small>fa-plus</v-icon>
        Congés
      </v-btn>
      <v-list-item v-else-if="mode === 'list'" v-on="on">
        <v-list-item-icon>
          <v-icon color="success">fa-calendar-times</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Poser des Congés</v-list-item-title>
      </v-list-item>
    </template>
    <v-card v-if="showDialog">
      <s-toolbar text="Créer un congé" create @close="close()" />
      <v-card-text>
        <conges-form v-model="conges" :etablissement="etablissement" create @updateFormValid="updateFormValid" />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="grey darken-1" text @click="close()">Annuler</v-btn>
        <v-btn color="success" :disabled="!formValid" @click="validate()"> Créer </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CongesForm from './conges-form'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'conges-create-dialog',
  components: {
    CongesForm,
  },
  props: {
    mode: {
      type: String,
      required: false,
      default: '',
    },
    employe: {
      type: Object,
      required: false,
      default: undefined,
    },
    dateDebut: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      showDialog: false,
      formValid: false,
      conges: undefined,
    }
  },
  computed: {
    ...mapGetters({
      etablissement: 'application/getSelectedEtablissement',
    }),
  },
  created() {
    this.initialize()
  },
  watch: {
    value() {
      this.showDialog = this.value
    },
    showDialog() {
      this.initialize()
    },
  },
  methods: {
    ...mapActions({
      createConges: 'conges/createConges',
    }),
    initialize() {
      this.conges = {
        employeId: this.employe ? this.employe.employeId : undefined,
        dateDebut: this.dateDebut ? this.dateDebut : new Date().toISOString().split('T')[0],
        dateFin: this.dateDebut ? this.dateDebut : new Date().toISOString().split('T')[0],
        type: undefined,
      }
    },
    updateFormValid(_validity) {
      this.formValid = _validity
    },
    validate() {
      if (this.formValid) {
        this.createConges(this.conges)
        this.close()
      }
    },
    close() {
      this.showDialog = false
    },
  },
}
</script>

<style scoped></style>

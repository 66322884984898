<template>
  <v-dialog v-model="showDialog" persistent max-width="800px">
    <template v-slot:activator="{ on }">
      <v-btn v-if="mode === 'button'" v-on="on" small color="success" dark outlined class="my-2">
        <v-icon x-small>fa-minus</v-icon>
        Crédit
      </v-btn>
      <v-list-item v-else-if="mode === 'list'" v-on="on">
        <v-list-item-icon>
          <v-icon color="error">fa-calendar-minus</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Débiter des congés</v-list-item-title>
      </v-list-item>
    </template>
    <v-card v-if="showDialog">
      <s-toolbar text="Débiter des congés" create @close="close()" />
      <v-card-text>
        <conges-debit-form v-model="createCongesDebitCommand" create @updateFormValid="updateFormValid" />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="grey darken-1" text @click="close()">Annuler</v-btn>
        <v-btn color="success" :disabled="!formValid" @click="validate()"> Confirmer </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import CongesDebitForm from './conges-debit-form.vue'
import SToolbar from '../../global/s-toolbar.vue'

export default {
  name: 'conges-debit-create-dialog',
  components: {
    SToolbar,
    CongesDebitForm,
  },
  props: {
    mode: {
      type: String,
      required: false,
      default: '',
    },
    employe: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      showDialog: false,
      formValid: false,
      createCongesDebitCommand: undefined,
    }
  },
  created() {
    this.initialize()
  },
  methods: {
    ...mapActions({
      createCongesDebit: 'conges/createCongesCredit',
    }),
    initialize() {
      this.createCongesDebitCommand = {
        employeId: this.employe ? this.employe.employeId : undefined,
        type: undefined,
        date: undefined,
        nbJours: undefined,
        exceptionnel: true,
        dateExpiration: undefined,
        commentaire: undefined,
      }
    },
    updateFormValid(_validity) {
      this.formValid = _validity
    },
    validate() {
      if (this.formValid) {
        this.createCongesDebit(this.createCongesDebitCommand)
        this.close()
      }
    },
    close() {
      this.initialize()
      this.showDialog = false
    },
  },
}
</script>

<style scoped></style>

<script setup>
import SCardWithToolbar from '@/components/global/s-card-with-toolbar.vue'
import AjustementListItem from '@/components/fiche-aim/ajustements/ajustement-list-item.vue'
import FormCreationAjustement from '@/components/fiche-aim/preparationFicheAim/ajustement/form-operations-ajustement.vue'
</script>

<template ref="ajustementVue">
  <s-card-with-toolbar>
    <template #titre>Ajustements</template>
    <template #default>
      <div>
        <v-scroll-x-transition>
          <v-row v-if="etat === 'LISTE_AJUSTEMENT'">
            <v-col cols="12">
              <perfect-scrollbar>
                <v-list>
                  <ajustement-list-item
                    :read-only="readOnly"
                    v-for="ajustement in ajustements"
                    :key="ajustement.ajustementId"
                    :ajustement="ajustement"
                  />
                </v-list>
              </perfect-scrollbar>
            </v-col>
          </v-row>
        </v-scroll-x-transition>
        <v-scroll-x-transition>
          <v-container v-if="etat === 'CREATION_AJUSTEMENT'">
            <v-textarea label="Justification" v-model="ajustementEnCreation.explication" required></v-textarea>
            <form-creation-ajustement :ajustement="ajustementEnCreation"></form-creation-ajustement>
          </v-container>
        </v-scroll-x-transition>
      </div>
    </template>
    <template #bottom>
      <v-card>
        <v-card-actions>
          <v-spacer />
          <v-btn v-if="etat === 'LISTE_AJUSTEMENT'" color="primary" @click="setupCreationAjustement">
            <v-icon class="pr-2">mdi-tune-variant</v-icon>
            Ajuster la fiche
          </v-btn>
          <div>
            <v-btn class="mx-1" v-if="etat === 'CREATION_AJUSTEMENT'" color="grey" @click="tearDownCreationAjustement">
              Annuler
            </v-btn>
            <v-btn
              class="mx-1"
              v-if="etat === 'CREATION_AJUSTEMENT'"
              :disabled="!estValideAjustementEnCreation"
              color="primary"
              @click="envoyerCommandeAjusterFiche"
            >
              Confirmer
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </template>
  </s-card-with-toolbar>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ajustements-aim',
  data() {
    return {
      etat: 'LISTE_AJUSTEMENT',
      ajustementEnCreation: {},
    }
  },
  props: {
    ajustements: {
      type: Array,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ficheAimId() {
      return parseInt(this.$route.params.ficheAimId, 10)
    },
    nbMotsExplication() {
      return this.ajustementEnCreation.explication.trim().split(' ').length
    },
    estValideAjustementEnCreation() {
      return this.nbMotsExplication >= 5 && Object.values(this.ajustementEnCreation.operations).length > 0
    },
    ops() {
      return Object.values(this.ajustementEnCreation.operations)
    },
    nbOperations() {
      return this.ops.length
    },
  },
  methods: {
    ...mapActions({
      ajusterFicheAIM: 'ficheAIM/AjusterFicheAIM',
    }),
    miseEnFormeCommande() {
      let command = {
        ficheAIMId: this.ficheAimId,
        explication: this.ajustementEnCreation.explication,
        operations: {},
      }
      for (let posteAIM in this.ajustementEnCreation.operations) {
        if (Object.hasOwn(this.ajustementEnCreation.operations, posteAIM)) {
          command.operations[posteAIM] = this.ajustementEnCreation.operations[posteAIM].montant
        }
      }
      return command
    },
    envoyerCommandeAjusterFiche() {
      this.ajusterFicheAIM(this.miseEnFormeCommande())
      this.tearDownCreationAjustement()
    },
    razAjustementEnCreation() {
      this.ajustementEnCreation = {
        ficheAIMId: this.ficheAimId,
        operations: {},
        explication: '',
      }
    },
    setupCreationAjustement() {
      this.razAjustementEnCreation()
      this.etat = 'CREATION_AJUSTEMENT'
    },
    tearDownCreationAjustement() {
      this.razAjustementEnCreation()
      this.etat = 'LISTE_AJUSTEMENT'
    },
  },
}
</script>

<style>
.fixed-height {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
}
</style>

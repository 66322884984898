<template>
  <v-dialog v-model="showDialog" persistent max-width="600px">
    <template v-slot:activator="{ on }">
      <v-btn class="mx-2" small fab dark color="white" v-on="on">
        <v-icon color="info">fa-edit</v-icon>
      </v-btn>
    </template>
    <v-card v-if="showDialog">
      <s-toolbar :text="'Modifier la fiche de ' + employe.nomComplet" update @close="close()" />
      <v-card-text>
        <employe-form v-model="updateEmployeCommand" @updateFormValidity="updateFormValid" />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" text @click="showDialog = false">Annuler</v-btn>
        <v-btn color="info" :disabled="!formValid" @click="sendUpdateEmploye()">Mettre à jour</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import employeForm from '../employe/employe-form'
import { mapActions } from 'vuex'

export default {
  name: 'employe-update-dialog',
  components: {
    employeForm,
  },
  props: {
    employe: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDialog: false,
      updateEmployeCommand: undefined,
      formValid: true,
    }
  },
  created() {
    this.initialize()
  },
  watch: {
    employe() {
      this.initialize()
    },
  },
  methods: {
    ...mapActions({
      updateEmploye: 'employe/updateEmploye',
    }),
    initialize() {
      this.updateEmployeCommand = {
        employeId: this.employe.employeId,
        emailPro: this.employe.emailPro,
        delegueDuPersonnel: this.employe.delegueDuPersonnel,
        administrateur: this.employe.administrateur,
        soldeRecupInitial: this.employe.soldeRecupInitial,
        dateEntreeAnciennete: this.employe.dateEntreeAnciennete,
      }
    },
    close() {
      this.initialize()
      this.showDialog = false
    },
    updateFormValid(_validity) {
      this.formValid = _validity
    },
    sendUpdateEmploye() {
      this.updateEmploye(this.updateEmployeCommand)
      this.close()
    },
  },
}
</script>

<style scoped></style>

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loaded)?_c('s-page',{scopedSlots:_vm._u([{key:"lateral-panel",fn:function(){return [_c('s-lateral-panel',{attrs:{"consistent":false}},[_c('v-container',{staticClass:"pb-0",attrs:{"justify":"space-between"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('span',{staticClass:"title"},[_vm._v("Planning")])]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"absolute":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"info"}},on),[_c('v-icon',[_vm._v(" fa-cog ")])],1)]}}],null,false,3324515246),model:{value:(_vm.menuVisible),callback:function ($$v) {_vm.menuVisible=$$v},expression:"menuVisible"}},[(_vm.service)?_c('v-list',{staticClass:"pb-0"},[_c('v-list-item',[_c('s-jour-nuit-btn-toggle',{model:{value:(_vm.jour_nuit),callback:function ($$v) {_vm.jour_nuit=$$v},expression:"jour_nuit"}})],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s('Afficher les absences')}})],1),_c('v-list-item-action',[_c('v-switch',{attrs:{"inset":""},model:{value:(_vm.afficherAbsences),callback:function ($$v) {_vm.afficherAbsences=$$v},expression:"afficherAbsences"}})],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s('Afficher les RC/RH')}})],1),_c('v-list-item-action',[_c('v-switch',{attrs:{"inset":"","loading":_vm.loadingJourDeRepos},model:{value:(_vm.afficherJourDeRepos),callback:function ($$v) {_vm.afficherJourDeRepos=$$v},expression:"afficherJourDeRepos"}})],1)],1),_c('v-divider'),(
                    _vm.isInRole('CHEF_DE_SERVICE_' + _vm.service.serviceId) ||
                    _vm.isInRole('ADMINISTRATEUR_' + _vm.service.etablissement.etablissementId)
                  )?_c('v-list-item',{attrs:{"to":{
                    name: 'modelePlanning',
                    params: { serviceId: _vm.service.serviceId },
                  }}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"info"}},[_vm._v("fa-calendar-alt")])],1),_c('v-list-item-title',[_vm._v("Modifier le modèle")])],1):_vm._e(),_c('telecharger-planning-modal',{attrs:{"creneaux":_vm.creneaux,"jours-de-repos":_vm.joursDeRepos,"semaines":_vm.selectedSemaines,"membres":_vm.sortedSelectedMembres,"jour_nuit":_vm.jour_nuit,"service":_vm.service,"display-jours-de-repos":_vm.afficherJourDeRepos}})],1):_vm._e()],1)],1)],1)],1),(_vm.loaded && _vm.selectedSemaines[0])?_c('v-container',{staticClass:"pa-2 pt-0",attrs:{"color":"white"}},[_c('membres-checkbox-group',{attrs:{"group-title":_vm.service.nom,"membres":_vm.membres,"semaine":_vm.selectedSemaines[0],"expand":""},model:{value:(_vm.selectedMembres),callback:function ($$v) {_vm.selectedMembres=$$v},expression:"selectedMembres"}})],1):_vm._e()],1)]},proxy:true},{key:"main-panel",fn:function(){return [_c('s-main-panel',{attrs:{"color":"white"}},[_c('planning-week-picker',{attrs:{"display-jours-de-repos":_vm.afficherJourDeRepos},model:{value:(_vm.selectedSemaines),callback:function ($$v) {_vm.selectedSemaines=$$v},expression:"selectedSemaines"}}),_c('planning-body',{attrs:{"creneaux":_vm.creneaux,"jours-de-repos":_vm.joursDeRepos,"semaines":_vm.selectedSemaines,"membres":_vm.sortedSelectedMembres,"jour_nuit":_vm.jour_nuit,"service":_vm.service,"display-jours-de-repos":_vm.afficherJourDeRepos,"affichage-impression":false}}),(
          _vm.selectedSemaines[0] &&
          (_vm.isInRole('CHEF_DE_SERVICE_' + _vm.service.serviceId) ||
            _vm.isInRole('ADMINISTRATEUR_' + _vm.service.etablissement.etablissementId))
        )?_c('creneau-de-travail-create-dialog',{attrs:{"date":_vm.currentDate,"mode":"fab"}}):_vm._e()],1)]},proxy:true}],null,false,321904525)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
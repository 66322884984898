<template>
  <s-page v-if="loaded">
    <template v-slot:lateral-panel>
      <s-lateral-panel>
        <v-text-field placeholder="Rechercher" hide-details dense clearable v-model="recherche" class="ma-2" />
        <perfect-scrollbar style="height: 100%" id="services">
          <v-list>
            <v-list-item-group color="primary">
              <template v-for="(service, index) in serviceList">
                <service-list-item :key="index + 1" :service="service" />
                <v-divider v-if="index < services.length - 1" :key="-(index + 1)"></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </perfect-scrollbar>
        <service-create-dialog v-if="isInRole('ADMINISTRATEUR_' + selectedEtablissement.etablissementId)" />
      </s-lateral-panel>
    </template>
    <template v-slot:main-panel>
      <router-view />
    </template>
  </s-page>
</template>

<script>
import serviceCreateDialog from '@/components/service/service-create-dialog'
import serviceListItem from '@/components/service/service-list-item'
import { mapGetters } from 'vuex'
import { initialDataLoaded } from '../../store/modules/helpers'

export default {
  name: 'service',
  components: {
    serviceCreateDialog,
    serviceListItem,
  },
  data() {
    return {
      recherche: null,
    }
  },
  computed: {
    ...initialDataLoaded,
    ...mapGetters({
      services: 'service/getServices',
      selectedEtablissement: 'application/getSelectedEtablissement',
      isInRole: 'security/isInRole',
    }),
    loaded() {
      return initialDataLoaded && this.services
    },
    serviceList() {
      if (this.recherche === null || this.recherche === '') {
        return this.services
      } else {
        return this.services.filter((s) => s.nom.toLowerCase().indexOf(this.recherche.toLowerCase()) <= -1)
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

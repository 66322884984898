<template>
  <div class="s-lateral-panel" v-bind:class="{ consistent: consistent }">
    <slot />
  </div>
</template>

<script>
export default {
  name: 's-lateral-panel',
  props: {
    consistent: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.s-lateral-panel {
  display: flex;
  flex-direction: column;
  flex: 1 !important;
  align-items: stretch;
  justify-content: flex-start;
  overflow: hidden;

  width: 300px;
  max-width: 300px;
  min-width: 300px;

  max-height: 100%;
}

.consistent {
  /*box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);*/
  background-color: white;
}
</style>

<template>
  <v-dialog v-model="showDialog" persistent max-width="800px">
    <v-card v-if="loaded && showDialog">
      <s-toolbar :text="'Créer un contrat pour ' + employe.nomComplet" create @close="close()" />
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-card-text>
              <v-form v-model="contratFormValid">
                <contrat-form v-model="createContratCommand.contrat" />
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="grey darken-1" text @click="showDialog = false">Annuler</v-btn>
              <v-btn color="success" :disabled="!contratFormValid" @click="createContrat()">Créer</v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import contratForm from './contrat-form'
import { mapActions } from 'vuex'

export default {
  name: 'contrat-create-dialog',
  components: {
    contratForm,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    employe: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDialog: false,
      createContratCommand: undefined,
      contratFormValid: false,
    }
  },
  computed: {
    loaded() {
      return this.createContratCommand && this.createContratCommand.contrat
    },
  },
  created() {
    this.showDialog = this.value
    this.initialiserCommand()
  },
  watch: {
    showDialog() {
      this.$emit('input', this.showDialog)
    },
    value() {
      this.showDialog = this.value
    },
  },
  methods: {
    ...mapActions({
      dispatchCreateContrat: 'employe/createContrat',
    }),
    close() {
      this.showDialog = false
      this.initialiserCommand()
    },
    initialiserCommand() {
      this.createContratCommand = {
        contrat: {
          metier: undefined,
          type: undefined,
          heuresHebdo: undefined,
          dateDebut: undefined,
          dateFin: undefined,
          cadre: false,
          nbJoursAstreintesAnnuels: 0,
        },
        employeId: this.employe.employeId,
      }
    },
    createContrat() {
      this.dispatchCreateContrat(this.createContratCommand)
      this.close()
    },
  },
}
</script>

<style scoped></style>

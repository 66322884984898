<template>
  <div class="planning-astreintes-ligne">
    <div class="planning-astreintes-colonne-semaine">{{ astreintesHebdomadaire.semaine.semaine }}</div>
    <astreintes-planning-jour
      v-for="jour in jours"
      :key="jour.jourId"
      :employeId="astreintesHebdomadaire.planningDeLaSemaine[jour.jourId]"
      :date="getDate(jour.jourId)"
      :annee="annee"
      :mois="mois"
      :etablissementId="etablissementId"
      :disabled="disabled"
    />
    <v-menu v-if="!disabled" top offset-y offset-x left center origin="bottom center" transition="scale-transition">
      <template v-slot:activator="{ on, attrs }">
        <div class="planning-astreintes-colonne-assignement">
          <v-btn icon small color="primary" v-on="on" v-bind="attrs">
            <v-icon>mdi-calendar-week</v-icon>
          </v-btn>
        </div>
      </template>
      <astreintes-planning-employe-select
        :employeIdSelectionne="employeIdSelectionne"
        :titre="'D\'astreinte pour la semaine ' + astreintesHebdomadaire.semaine.numero"
        selection-hebdomadaire
        @selection="modifierLemployeDastreintePourLaSemaine"
      />
    </v-menu>
    <astreintes-planning-semaine-validation v-if="!disabled" :semaine="astreintesHebdomadaire.semaine" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AstreintesPlanningSemaineValidation from './astreintes-planning-semaine-validation.vue'
import AstreintesPlanningEmployeSelect from './astreintes-planning-employe-select.vue'
import AstreintesPlanningJour from './astreintes-planning-jour.vue'

export default {
  name: 'astreintes-planning-semaine',
  components: {
    AstreintesPlanningJour,
    AstreintesPlanningSemaineValidation,
    AstreintesPlanningEmployeSelect,
  },
  props: {
    astreintesHebdomadaire: {
      type: Object,
      required: true,
    },
    annee: {
      type: Number,
      required: true,
    },
    mois: {
      type: Number,
      required: true,
    },
    etablissementId: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      jours: 'calendrier/getJours',
      getDateOfISOWeekDay: 'calendrier/getDateOfISOWeekDay',
      employesDastreintes: 'astreinte/getEmployesDastreinte',
      getEmployeAstreinteCouleur: 'astreinte/getCouleurByEmployeId',
    }),
    employeIdSelectionne() {
      let employeIdDAstreinte = this.astreintesHebdomadaire.planningDeLaSemaine[1]

      for (let i = 2; i <= 7; i++) {
        if (this.astreintesHebdomadaire.planningDeLaSemaine[i] !== employeIdDAstreinte) {
          return undefined
        }
      }

      return employeIdDAstreinte
    },
  },
  methods: {
    ...mapActions({
      sendModifierLemployeDastreintePourLaSemaine: 'astreinte/modifierLemployeDastreintePourLaSemaine',
      sendSupprimerLemployeDastreintePourLaSemaine: 'astreinte/supprimerLemployeDastreintePourLaSemaine',
    }),
    modifierLemployeDastreintePourLaSemaine(_employeId) {
      let payload = {
        fetchPlanningAstreintesMensuelQuery: {
          etablissementId: this.etablissementId,
          annee: this.annee,
          mois: this.mois + 1,
        },
        fetchResumeAnnuelDesAstreintesQuery: {
          etablissementId: this.etablissementId,
          annee: this.annee,
        },
        etablissementId: this.etablissementId,
      }

      if (_employeId) {
        this.employeIdSelectionne = _employeId
        payload.command = {
          semaine: this.astreintesHebdomadaire.semaine.semaine,
          annee: this.astreintesHebdomadaire.semaine.annee,
          employeIdAMettreDastreinte: _employeId,
          etablissementIdDuPlanningAModifier: this.etablissementId,
        }

        this.sendModifierLemployeDastreintePourLaSemaine(payload)
      } else {
        this.employeIdSelectionne = undefined
        payload.command = {
          semaine: this.astreintesHebdomadaire.semaine.semaine,
          annee: this.astreintesHebdomadaire.semaine.annee,
          employeIdAMettreDastreinte: _employeId,
          etablissementIdDuPlanningAModifier: this.etablissementId,
        }
        this.sendSupprimerLemployeDastreintePourLaSemaine(payload)
      }
    },
    getDate(_jourId) {
      return new Date(
        this.getDateOfISOWeekDay(
          this.astreintesHebdomadaire.semaine.annee,
          this.astreintesHebdomadaire.semaine.semaine,
          _jourId
        )
      )
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

<template>
  <div>
    <v-avatar :color="membre.couleur" size="24px"> </v-avatar>
    {{ nombreHeures }}h
    {{ membre.nomComplet }}
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'legende-planning-semaine',
  props: {
    affichageImpression: {
      type: Boolean,
      default: false,
    },
    creneaux: {
      type: Array,
      required: true,
    },
    membre: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      shortLabel: false,
    }
  },
  computed: {
    ...mapGetters({
      getDateOfISOWeekDay: 'calendrier/getDateOfISOWeekDay',
      jours: 'calendrier/getJours',
    }),
    nombreHeures: function () {
      let result =
        this.creneaux
          .filter((c) =>
            [
              'CreneauDeTravail',
              'CreneauHeuresSupplementaires',
              'CreneauDeRemplacement',
              'CreneauDeFormation',
            ].includes(c.type)
          )
          .map((c) => c.heureFin.valeur - c.heureDebut.valeur)
          .reduce((a, b) => a + b, 0) / 60

      return Math.round(result * 100) / 100
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.planning-header-semaine {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  border-bottom: 1px solid #c0c0c0;
}

.planning-header-semaine-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
}

.planning-header-semaine-temps {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  align-self: stretch;
  justify-content: space-between;

  margin: 5px 10px;
}

.planning-header-semaine-jours {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
}

.planning-header-jour {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;

  border-left: 5px solid #c0c0c0;
  color: #000;
}
</style>

<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="py-0">
        <v-checkbox
          class="text-body-2"
          v-model="allSelected"
          :value="allSelected"
          :indeterminate="indeterminate"
          color="primary"
          :label="groupTitle"
          hide-details
          :dense="dense"
          :prepend-icon="expanded ? 'mdi-minus' : 'mdi-plus'"
          @click:prepend="expanded = !expanded"
        />
      </v-col>
      <v-expand-transition>
        <v-col v-show="expanded" cols="12" class="py-0 pl-15">
          <membre-checkbox
            v-for="membre in membres"
            :key="membre.membreId"
            v-model="selectedMembres"
            :membresSelectionnables="membres"
            :membre="membre"
            :semaine="semaine"
            :afficher="afficher"
            :dense="dense"
          />
        </v-col>
      </v-expand-transition>
    </v-row>
  </v-container>
</template>

<script>
import membreCheckbox from './membre-checkbox'

export default {
  name: 'membre-checkbox-group',
  components: {
    membreCheckbox,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    semaine: {
      type: Object,
      required: true,
    },
    groupTitle: {
      type: String,
      required: true,
    },
    membres: {
      type: Array,
      required: true,
    },
    afficher: {
      type: String,
      required: false,
      default: 'Employe',
    },
    dense: {
      type: Boolean,
      required: false,
      default: false,
    },
    expand: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selectedMembres: [],
      expanded: false,
    }
  },
  computed: {
    indeterminate() {
      //Le status est indéterminé au moins un des membres est sélectionné mais pas tous
      return this.selectedMembres.some((m) => this.contientLeMembreId(this.membres, m.membreId)) && !this.allSelected
    },
    allSelected: {
      get() {
        return this.membres.every((m) => this.contientLeMembreId(this.selectedMembres, m.membreId))
      },
      set() {
        //Si tous les membres sont sélectionnés, on déséctionne tout
        if (this.membres.every((m) => this.contientLeMembreId(this.selectedMembres, m.membreId))) {
          this.selectedMembres = this.selectedMembres.filter((m) =>
            this.neContientPasLeMembreId(this.membres, m.membreId)
          )
        } else {
          //sinon on ajoute les membres manquants
          this.selectedMembres = this.selectedMembres.concat(
            this.membres.filter((m) => this.neContientPasLeMembreId(this.selectedMembres, m.membreId))
          )
        }
      },
    },
  },
  created() {
    this.selectedMembres = this.value
    this.expanded = this.expand
  },
  watch: {
    selectedMembres() {
      this.$emit('input', this.selectedMembres)
    },
    value() {
      this.selectedMembres = this.value
    },
  },
  methods: {
    contientLeMembreId(_tableauDesMembres, _idATrouver) {
      for (const membre of _tableauDesMembres) {
        if (parseInt(membre.membreId) === parseInt(_idATrouver)) {
          return true
        }
      }

      return false
    },
    neContientPasLeMembreId(_tableauDesMembres, _idATrouver) {
      for (const membre of _tableauDesMembres) {
        if (parseInt(membre.membreId) === parseInt(_idATrouver)) {
          return false
        }
      }

      return true
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

<script setup>
import SInfoMontant from '@/components/global/s-info-montant.vue'
import LigneOperationAjustement from '@/components/fiche-aim/preparationFicheAim/ajustement/ligne-operation-ajustement.vue'
import AjouterOperationAjustementForm from '@/components/fiche-aim/preparationFicheAim/ajustement/ajouter-operation-ajustement-form.vue'

defineProps({
  ajustement: {
    required: true,
    type: Object,
    default: () => ({
      operations: {},
    }),
  },
})

defineEmits(['update:ajustement'])
</script>

<template>
  <v-form>
    <v-card-subtitle>Montants à ajuster</v-card-subtitle>
    <ajouter-operation-ajustement-form
      :postes-non-definits="postesNonDefinits"
      @add="ajouterOperation"
    ></ajouter-operation-ajustement-form>
    <v-sheet color="red" elevation="5" v-if="nbOperations > 0">
      <v-simple-table height="400px" class="table" fixed-header>
        <thead>
          <tr>
            <th>Poste</th>
            <th class="text-right">montant</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <ligne-operation-ajustement
            v-for="(op, i) in Object.values(ajustement.operations)"
            :key="i"
            :operation="op"
            @del="supprimerOperation(op.posteAIM.posteAIMId)"
          />
          <tr>
            <th class="text-right"></th>
            <th class="text-right">Total : <s-info-montant :montant="totalAjustement"></s-info-montant></th>
          </tr>
        </tbody>
      </v-simple-table>
    </v-sheet>
    <v-chip class="ma-4" v-else>
      <v-icon class="pr-2">mdi-alert-circle</v-icon>
      Aucune opération pour cet ajustement
    </v-chip>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      postesDisponibles: 'posteAIM/getPostes',
    }),
    postesNonDefinits() {
      return this.postesDisponibles.filter((posteAIM) => !this.existeDansAjustement(posteAIM))
    },
    totalAjustement() {
      let sum = 0
      for (var i = 0; i < this.nbOperations; i++) {
        sum += this.ops[i].montant
      }
      return sum
    },
    ops() {
      return Object.values(this.ajustement.operations)
    },
    nbOperations() {
      return this.ops.length
    },
  },
  methods: {
    ...mapActions({
      fetchPostes: 'posteAIM/fetchPostes',
    }),
    existeDansAjustement(posteAIM) {
      return posteAIM.posteAIMId in this.ajustement.operations
    },
    ajouterOperation(operation) {
      this.$set(this.ajustement.operations, operation.posteAIM.posteAIMId, operation)
    },
    supprimerOperation(posteAIMId) {
      this.$delete(this.ajustement.operations, posteAIMId)
    },
  },
  mounted() {
    this.fetchPostes()
  },
}
</script>

<style scoped></style>

<template>
  <v-menu
    top
    offset-y
    offset-x
    left
    center
    origin="bottom center"
    transition="scale-transition"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <div class="planning-astreintes-colonne-validation">
        <v-btn icon small color="grey" v-on="on" v-bind="attrs">
          <v-icon>mdi-check-circle-outline</v-icon>
        </v-btn>
      </div>
    </template>
    <v-card>
      <v-toolbar dense color="primary" dark> Validations de la semaine {{ semaine.numero }} </v-toolbar>
      <v-list class="transparent py-0" three-line>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Validation de l'établissement</v-list-item-title>
            <v-list-item-subtitle> Validé par: - </v-list-item-subtitle>
            <v-list-item-subtitle> Le: - </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn v-if="false" icon>
              <v-icon large color="grey">mdi-check-circle-outline</v-icon>
            </v-btn>
            <v-icon v-else large color="green">mdi-check-circle-outline</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Validation de l'association</v-list-item-title>
            <v-list-item-subtitle> Validé par: - </v-list-item-subtitle>
            <v-list-item-subtitle> Le: - </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn v-if="false" icon>
              <v-icon large color="grey">mdi-check-circle</v-icon>
            </v-btn>
            <v-icon v-else large color="green">mdi-check-circle</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'astreintes-planning-semaine-validation',
  components: {},
  props: {
    semaine: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {},
  methods: {},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

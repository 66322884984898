import axios from 'axios'
export default {
  namespaced: true,
  state: {
    types: [],
  },
  mutations: {
    SET_CONGES_TYPES(state, _types) {
      state.types = _types
    },
  },
  getters: {
    getCongesTypes(state) {
      return state.types
    },
  },
  actions: {
    fetchCongesType({ commit }) {
      return axios.get(`${process.env.VUE_APP_URL_BACK_SERENITY}/conges/types`).then((response) => {
        commit('SET_CONGES_TYPES', response.data)
      })
    },
    fetchApercuCongesMensuel(context, _query) {
      return axios.get(
        `${process.env.VUE_APP_URL_BACK_SERENITY}/conges/apercu/mensuel?_etablissementId=${_query.etablissementId}&_annee=${_query.annee}&_mois=${_query.mois}`
      )
    },
    fetchApercuCongesTrimestriel(state, _query) {
      return axios.get(
        `${process.env.VUE_APP_URL_BACK_SERENITY}/conges/apercu/trimestriel?_etablissementId=${_query.etablissementId}&_annee=${_query.annee}&_semaine=${_query.semaine}`
      )
    },
    evaluerConges(state, _toEvaluer) {
      return axios.post(`${process.env.VUE_APP_URL_BACK_SERENITY}/conges/evaluer`, _toEvaluer).then((response) => {
        return response.data
      })
    },
    createConges(state, _toCreate) {
      return axios.post(`${process.env.VUE_APP_URL_BACK_SERENITY}/conges`, _toCreate).then((response) => {
        this.dispatch('employe/setEmploye', response.data)
      })
    },
    updateConges(state, _toUpdate) {
      return axios
        .put(`${process.env.VUE_APP_URL_BACK_SERENITY}/conges/${_toUpdate.congesId}`, _toUpdate)
        .then((response) => {
          this.dispatch('employe/setEmploye', response.data)
        })
    },
    deleteConges(state, _toDeleteId) {
      return axios.delete(`${process.env.VUE_APP_URL_BACK_SERENITY}/conges/${_toDeleteId}`).then((response) => {
        if (response.status === 200) {
          this.dispatch('employe/setEmploye', response.data)
        }
      })
    },
    simulerCongesCreditAnnuel() {
      return axios
        .get(`${process.env.VUE_APP_URL_BACK_SERENITY}/conges/credit/conges_annuels.csv`)
        .then((response) => {
          const blob = new Blob([response.data], { type: 'text/csv' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = 'conges_annuels.csv'
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch(console.error)
    },
    crediterCongesTrimestriels() {
      return axios.post(`${process.env.VUE_APP_URL_BACK_SERENITY}/conges/credit/congesTrimestriels`)
    },
    crediterCongesAnnuels() {
      return axios.post(`${process.env.VUE_APP_URL_BACK_SERENITY}/conges/credit/congesAnnuels`)
    },
    createCongesCredit(state, _toCreate) {
      return axios.post(`${process.env.VUE_APP_URL_BACK_SERENITY}/conges/credit`, _toCreate).then((response) => {
        this.dispatch('employe/setEmploye', response.data)
      })
    },
    updateCongesCredit(state, _toUpdate) {
      return axios
        .put(`${process.env.VUE_APP_URL_BACK_SERENITY}/conges/credit/${_toUpdate.congesCreditId}`, _toUpdate)
        .then((response) => {
          this.dispatch('employe/setEmploye', response.data)
        })
    },
    deleteCongesCredit(state, _toDeleteId) {
      return axios.delete(`${process.env.VUE_APP_URL_BACK_SERENITY}/conges/credit/${_toDeleteId}`).then((response) => {
        if (response.status === 200) {
          this.dispatch('employe/setEmploye', response.data)
        }
      })
    },
  },
}

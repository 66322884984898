<template>
  <v-dialog v-if="validCreneauType" v-model="showDialog" persistent max-width="600px">
    <template v-slot:activator="{ on }">
      <v-list-item v-if="mode === 'update'" v-on="on">
        <v-list-item-icon>
          <v-icon color="info">fa-clock</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Déplacer</v-list-item-title>
      </v-list-item>
      <v-list-item v-else-if="mode === 'recuperation'" v-on="on">
        <v-list-item-icon>
          <v-icon color="error">fa-clock</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Heures de récupération</v-list-item-title>
      </v-list-item>
      <v-list-item v-else-if="mode === 'heuresSupp'" v-on="on">
        <v-list-item-icon>
          <v-icon color="success">fa-clock</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Heures complémentaires</v-list-item-title>
      </v-list-item>
    </template>

    <v-card v-if="showDialog">
      <v-toolbar class="pt-1 center" :color="membre.couleur" flat :dark="headerDark" dense>
        <utilisateur-avatar :utilisateur="employe.utilisateur" :size="36" />
        <v-toolbar-title class="pl-2" v-text="employe.nomComplet" />
        <v-spacer />
        <v-btn icon @click="close()">
          <v-icon medium color="grey">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-list class="pa-0" :dark="headerDark">
        <v-list-item :style="{ 'background-color': membre.couleur }" dense>
          <v-list-item-content>
            <v-list-item-title class="text-body-2">
              <v-icon>fa-calendar-alt</v-icon>
              {{ date }}
              <v-icon>fa-clock</v-icon>
              {{ creneau.heureDebut.toHeureMinute + ' à ' + creneau.heureFin.toHeureMinute }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-card-text>
        <v-form ref="form" v-model="formValid">
          <v-container>
            <v-row v-if="mode === 'update'" dense>
              <!-- Timepicker Heure de début -->
              <v-col cols="12" sm="6">
                <s-borne-horaire-picker
                  v-model="updatedCreneau.heureDebut"
                  label="Heure de début"
                  :borneMax="updatedCreneau.heureFin"
                  required
                />
              </v-col>
              <!-- Timepicker Heure de fin -->
              <v-col cols="12" sm="6">
                <s-borne-horaire-picker
                  v-model="updatedCreneau.heureFin"
                  label="Heure de fin"
                  :borneMin="updatedCreneau.heureDebut"
                  required
                />
              </v-col>
            </v-row>
            <v-row v-else-if="mode === 'recuperation'" dense>
              <!-- Timepicker Heure de début -->
              <v-col cols="12" sm="6">
                <s-borne-horaire-picker
                  v-model="updatedCreneau.heureDebut"
                  label="Commencer plus tard"
                  :borneMin="creneau.heureDebut"
                  :borneMax="updatedCreneau.heureFin"
                  required
                />
              </v-col>
              <!-- Timepicker Heure de fin -->
              <v-col cols="12" sm="6">
                <s-borne-horaire-picker
                  v-model="updatedCreneau.heureFin"
                  label="Finir plus tot"
                  :borneMin="updatedCreneau.heureDebut"
                  :borneMax="creneau.heureFin"
                  required
                />
              </v-col>
            </v-row>
            <v-row v-else-if="mode === 'heuresSupp'" dense>
              <!-- Timepicker Heure de début -->
              <v-col cols="12" sm="6">
                <s-borne-horaire-picker
                  v-model="updatedCreneau.heureDebut"
                  label="Commencer plus tôt"
                  :borneMax="creneau.heureDebut"
                  required
                />
              </v-col>
              <!-- Timepicker Heure de fin -->
              <v-col cols="12" sm="6">
                <s-borne-horaire-picker
                  v-model="updatedCreneau.heureFin"
                  label="Finir plus tard"
                  :borneMin="creneau.heureFin"
                  required
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <v-col cols="12" align="center">
          <div class="pb-2">Impact sur le solde de récupération</div>
          <v-progress-circular
            class="text-h6"
            :rotate="360"
            :value="100"
            :size="120"
            :width="12"
            :color="valeurRecupColor"
            :indeterminate="false"
          >
            {{ Math.round((displayedValeurRecup / 60) * 100) / 100 }}h
          </v-progress-circular>
        </v-col>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="grey darken-1" text @click="close()"> Annuler </v-btn>
        <v-btn color="success" :disabled="!formValid" @click="validateForm()"> Confirmer </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import UtilisateurAvatar from '@/components/utilisateur/utilisateur-avatar'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'creneau-update-dialog',
  components: {
    UtilisateurAvatar,
  },
  props: {
    creneau: {
      type: Object,
      required: true,
    },
    indisponibilite: {
      type: Object,
      required: false,
      default: undefined,
    },
    mode: {
      type: String,
      required: false,
      default: 'update',
    },
  },
  data() {
    return {
      showDialog: false,
      formValid: false,
      updatedCreneau: undefined,
      interval: false,
      displayedValeurRecup: 0,
    }
  },
  created() {
    this.updatedCreneau = JSON.parse(JSON.stringify(this.creneau))
  },
  computed: {
    ...mapGetters({
      getTextColor: 'application/getTextColor',
      formatDateToDisplay: 'calendrier/formatDateToDisplay',
      getGranulariteMinutes: 'application/getGranularite',
    }),
    validCreneauType() {
      return this.creneau.type === 'CreneauDeTravail' && !this.indisponibilite
    },
    membre() {
      return this.creneau.membre
    },
    employe() {
      return this.membre.employe
    },
    date() {
      return this.formatDateToDisplay(this.creneau.date)
    },
    valeurRecupColor() {
      if (this.valeurRecup === 0) {
        return 'grey'
      } else {
        return this.valeurRecup > 0 ? 'success' : 'error'
      }
    },
    valeurRecup() {
      return this.nouvelleDuree - this.dureeOrigine
    },
    dureeOrigine() {
      return this.creneau.heureFin.valeur - this.creneau.heureDebut.valeur
    },
    nouvelleDuree() {
      return this.updatedCreneau
        ? this.updatedCreneau.heureFin.valeur - this.updatedCreneau.heureDebut.valeur
        : this.dureeOrigine
    },
    headerDark() {
      return this.getTextColor(this.membre.couleur) === 'white'
    },
  },
  watch: {
    creneau() {
      this.updatedCreneau = JSON.parse(JSON.stringify(this.creneau))
    },
    valeurRecup() {
      clearInterval(this.interval)

      if (this.valeurRecup === this.displayedValeurRecup) {
        return
      }

      this.interval = window.setInterval(() => {
        if (this.valeurRecup > this.displayedValeurRecup) {
          this.displayedValeurRecup += this.getGranulariteMinutes
        } else {
          if (this.valeurRecup < this.displayedValeurRecup) {
            this.displayedValeurRecup -= this.getGranulariteMinutes
          }
        }
      }, 500 / Math.abs((this.valeurRecup - this.displayedValeurRecup) / this.getGranulariteMinutes))
    },
  },
  methods: {
    ...mapActions({
      updateCreneauDeTravail: 'servicePlanning/updateCreneauDeTravail',
    }),
    validateForm() {
      this.$refs.form.validate()
      if (this.formValid) {
        this.updateCreneauDeTravail(this.updatedCreneau).then(() => this.close())
      }
    },
    close() {
      this.showDialog = false
      this.updatedCreneau = JSON.parse(JSON.stringify(this.creneau))
    },
  },
}
</script>

<style scoped></style>

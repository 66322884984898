<template>
  <v-list-item v-if="aRemplacer" @click="nePasRemplacer(creneau.creneauId)">
    <v-list-item-icon>
      <v-icon :color="color">fa-people-arrows</v-icon>
    </v-list-item-icon>
    <v-list-item-title>{{ label }}</v-list-item-title>
  </v-list-item>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'creneau-switch-ne-pas-remplacer',
  props: {
    creneau: {
      type: Object,
      required: true,
    },
    indisponibilite: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  computed: {
    ...mapGetters({
      getRemplacants: 'servicePlanning/getRemplacants',
    }),
    aRemplacer() {
      return this.creneau.type === 'CreneauDeTravail' && this.indisponibilite && !this.remplacePar
    },
    remplacePar() {
      return this.getRemplacants(this.creneau).length > 0
    },
    color() {
      if (this.creneau.nePasRemplacer) {
        return 'warning'
      } else {
        return 'error'
      }
    },
    label() {
      if (this.creneau.nePasRemplacer) {
        return 'À remplacer'
      } else {
        return 'Ne pas remplacer'
      }
    },
  },
  methods: {
    ...mapActions({
      nePasRemplacer: 'servicePlanning/nePasRemplacer',
    }),
  },
}
</script>

<style scoped></style>

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loaded)?_c('s-main-panel',[_c('v-container',{staticClass:"fixed-height"},[_c('v-card',{staticClass:"fixed-height"},[_c('v-toolbar',{attrs:{"color":"white","flat":""},scopedSlots:_vm._u([{key:"extension",fn:function(){return [_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider',{attrs:{"color":"secondary"}}),_c('v-tab',{key:"Membres"},[_vm._v(" Membres "),(
                  _vm.tab === 0 &&
                  _vm.serviceId !== 0 &&
                  (_vm.isInRole('CHEF_DE_SERVICE_' + _vm.serviceId) ||
                    _vm.isInRole('ADMINISTRATEUR_' + _vm.service.etablissement.etablissementId))
                )?_c('membre-create-dialog',{attrs:{"service":_vm.service}}):_vm._e()],1),(_vm.serviceId !== 0)?_c('v-tab',{key:"Tableau des congés"},[_vm._v(" Tableau des congés ")]):_vm._e(),(
                _vm.serviceId !== 0 &&
                (_vm.isInRole('CHEF_DE_SERVICE_' + _vm.serviceId) ||
                  _vm.isInRole('ADMINISTRATEUR_' + _vm.service.etablissement.etablissementId))
              )?_c('v-tab',{key:"Administration"},[_vm._v(" Administration ")]):_vm._e(),(
                _vm.isAdmin ||
                (false &&
                  _vm.serviceId !== 0 &&
                  (_vm.isInRole('CHEF_DE_SERVICE_' + _vm.serviceId) ||
                    _vm.isInRole('ADMINISTRATEUR' + _vm.service.etablissement.etablissementId)))
              )?_c('v-tab',{key:"AIM"},[_vm._v(" Aide Indiv. Mensuelle ")]):_vm._e()],1)]},proxy:true}],null,false,2063801424)},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.service.nom))]),_c('v-spacer'),(
            _vm.serviceId !== 0 &&
            (_vm.isInRole('CHEF_DE_SERVICE_' + _vm.serviceId) ||
              _vm.isInRole('COMPTABLE_' + _vm.service.etablissement.etablissementId) ||
              _vm.isInRole('ADMINISTRATEUR_' + _vm.service.etablissement.etablissementId))
          )?[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"info","outlined":"","raised":""},on:{"click":function($event){_vm.showExportPayeDialog = true}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("fa-file-export")]),_vm._v(" Export Paye ")],1),_c('service-export-paye-dialog',{attrs:{"service":_vm.service},model:{value:(_vm.showExportPayeDialog),callback:function ($$v) {_vm.showExportPayeDialog=$$v},expression:"showExportPayeDialog"}})]:_vm._e(),(_vm.serviceId !== 0)?_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","dark":"","to":{
            name: 'service-planning',
            params: { serviceId: _vm.serviceId },
          }}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("fa-calendar-alt")]),_vm._v(" Planning ")],1):_vm._e()],2),_c('v-tabs-items',{staticClass:"fixed-height",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{key:"Membres",staticClass:"fixed-height"},[_c('v-card',{staticClass:"fixed-height",attrs:{"flat":""}},[_c('v-list',{staticClass:"pb-0 overflow-y-auto",staticStyle:{"max-height":"100%","overflow":"hidden"}},[_c('v-list',{staticClass:"py-0",attrs:{"subheader":""}},[(_vm.service.serviceId !== 0)?_c('v-subheader',[_vm._v("Titulaires")]):_vm._e(),_vm._l((_vm.membresTitulaires),function(membre,index){return [_c('membre-list-item',{key:index + 1,attrs:{"membre":membre}}),(index < _vm.membresTitulaires.length - 1)?_c('v-divider',{key:-(index + 1)}):_vm._e()]})],2),(_vm.service.serviceId !== 0 && _vm.membreRemplacants.length > 0)?_c('v-list',{staticClass:"py-0",attrs:{"subheader":"","color":"grey lighten-3"}},[_c('v-subheader',[_vm._v("Remplacants")]),_vm._l((_vm.membreRemplacants),function(membre,index){return [_c('membre-list-item',{key:index + 1,attrs:{"membre":membre}}),(index < _vm.membreRemplacants.length - 1)?_c('v-divider',{key:-(index + 1)}):_vm._e()]})],2):_vm._e()],1)],1)],1),_c('v-tab-item',{key:"Tableau des congés",staticClass:"fixed-height"},[_c('conges-apercu',{attrs:{"etablissementId":_vm.service.etablissement.etablissementId,"serviceId":_vm.service.serviceId}})],1),(
            _vm.serviceId !== 0 &&
            (_vm.isInRole('CHEF_DE_SERVICE_' + _vm.serviceId) ||
              _vm.isInRole('ADMINISTRATEUR_' + _vm.service.etablissement.etablissementId))
          )?_c('v-tab-item',{key:"Administration",staticClass:"fixed-height"},[_c('service-detail-administration',{attrs:{"service":_vm.service}})],1):_vm._e(),_c('v-tab-item',{key:"AIM",staticClass:"fixed-height"},[_c('service-detail-modele-aim',{attrs:{"modele-aim-actuel":_vm.service.modeleAIM}})],1)],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
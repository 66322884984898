<template>
  <div class="planning-week-picker">
    <v-btn class="planning-week-picker-btn-scroll" depressed @click="scrollClicked(-1)">
      <v-icon large>mdi-chevron-left</v-icon>
    </v-btn>
    <div class="planning-week-picker-list" ref="planningWeekPicker" v-on:wheel="scrolled">
      <planning-week-picker-button
        v-for="semaine in semaines"
        :key="semaine.semaine"
        :semaine="semaine"
        :selected="isSelected(semaine)"
        :display-jours-de-repos="displayJoursDeRepos"
        @click="setSelectedSemaine(semaine)"
        @ctrlClick="addSelectedSemaine(semaine)"
      />
    </div>
    <v-btn class="planning-week-picker-btn-scroll" depressed @click="scrollClicked(1)">
      <v-icon large>mdi-chevron-right</v-icon>
    </v-btn>
  </div>
</template>

<script>
import planningWeekPickerButton from './planning-semaine-picker-button'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'planning-semaine-picker',
  components: {
    planningWeekPickerButton,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    displayJoursDeRepos: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selectedSemaines: [],
      position: undefined,
    }
  },
  computed: {
    ...mapGetters({
      getISOWeekOfDate: 'calendrier/getISOWeekOfDate',
      getDateOfISOWeek: 'calendrier/getDateOfISOWeek',
    }),
    positionInitiale() {
      return this.$route.params.numeroSemaine
    },
    semaines() {
      let date = new Date(this.getDateOfISOWeek(this.position.annee, this.position.semaine))
      date.setDate(date.getDate() - 7)
      const semaines = []

      for (let i = 0; i < 14; i++) {
        semaines.push(this.getISOWeekOfDate(date))
        date.setDate(date.getDate() + 7)
      }

      return semaines
    },
  },
  created() {
    if (this.positionInitiale) {
      this.position = {
        semaine: this.positionInitiale.split('-')[0],
        annee: this.positionInitiale.split('-')[1],
        numero: this.$route.params.numeroSemaine,
      }
    } else {
      this.position = this.getISOWeekOfDate(new Date())
    }
    this.selectedSemaines = this.value
  },
  watch: {
    selectedSemaines() {
      this.$emit('input', this.selectedSemaines)
    },
    value() {
      this.selectedSemaines = this.value
    },
  },
  methods: {
    setSelectedSemaine(_semaine) {
      this.selectedSemaines = []
      this.addSelectedSemaine(_semaine)
    },
    addSelectedSemaine(_semaine) {
      var semaineIndex = this.selectedSemaines.indexOf(_semaine)
      if (semaineIndex >= 0 && this.selectedSemaines.length >= 2) {
        this.selectedSemaines.splice(semaineIndex, 1)
      } else {
        if (this.selectedSemaines.length <= 2) {
          this.selectedSemaines.push(_semaine)
        }
      }
    },
    isSelected(_semaine) {
      return this.selectedSemaines.some((s) => s.semaine === _semaine.semaine && s.annee === _semaine.annee)
    },
    getPreviousSemaine(_semaine) {
      let referenceDate = moment(this.getDateOfISOWeek(_semaine.annee, _semaine.semaine)).subtract(7, 'd')
      return this.getISOWeekOfDate(referenceDate)
    },
    getNextSemaine(_semaine) {
      let referenceDate = moment(this.getDateOfISOWeek(_semaine.annee, _semaine.semaine)).add(7, 'd')
      return this.getISOWeekOfDate(referenceDate)
    },
    scrolled(_event) {
      let delta
      //détermine la direction du scroll (horizontal ou vertical)
      if (Math.abs(_event.deltaY) >= Math.abs(_event.deltaX)) {
        //Scroll vertical
        delta = _event.deltaY
      } else {
        //Scroll horizontal
        delta = _event.deltaX
      }
      if (delta > 0) {
        this.position = this.getNextSemaine(this.position)
      } else {
        this.position = this.getPreviousSemaine(this.position)
      }
    },
    scrollClicked(_direction) {
      if (_direction > 0) {
        this.position = this.getNextSemaine(this.position)
      } else {
        this.position = this.getPreviousSemaine(this.position)
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.planning-week-picker {
  display: flex;
  flex-direction: row;
}

.planning-week-picker-btn-scroll {
  border-radius: 0;

  padding: 0 !important;
  height: 100% !important;
  width: 48px !important;
  min-width: 48px !important;
  max-width: 48px !important;
}

.planning-week-picker-list {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;

  min-height: 48px;
  max-height: 48px;
  height: 48px;

  overflow: hidden;
  /*Hide scrollbar in IE and Edge https://www.w3schools.com/howto/howto_css_hide_scrollbars.asp */
  -ms-overflow-style: none;
  /*Hide scrollbar in Firefox https://stackoverflow.com/questions/20997183/how-to-hide-scrollbar-in-firefox */
  scrollbar-width: none;
}

/*Hide scrollbar in Chrone, Safari, Opera https://www.w3schools.com/howto/howto_css_hide_scrollbars.asp */
.planning-week-picker::-webkit-scrollbar {
  display: none;
}
</style>

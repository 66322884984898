<script setup>
import SInfoMontant from '@/components/global/s-info-montant.vue'

defineEmits(['del'])
</script>

<template>
  <tr class="ml-2">
    <td class="text-wrap">{{ operation.posteAIM.libelle }}</td>
    <td class="text-right montant">
      <s-info-montant :colorisation="true" :montant="operation.montant"></s-info-montant>
    </td>
    <td class="line-action">
      <v-btn small icon @click="$emit('del')"><v-icon>mdi-delete</v-icon></v-btn>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'ligne-operation-ajustement',
  props: {
    operation: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>
.montant {
  width: 50%;
}
</style>

<template>
  <s-page>
    <template v-slot:lateral-panel>
      <s-lateral-panel>
        <etablissement-create-dialog v-if="isInRole('Administrateur')" />
      </s-lateral-panel>
    </template>
    <template v-slot:main-panel> </template>
  </s-page>
</template>

<script>
import etablissementCreateDialog from '../../components/etablissement/etablissement-create-dialog'
import { mapGetters } from 'vuex'

export default {
  name: 'etablissement',
  components: {
    etablissementCreateDialog,
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      isInRole: 'security/isInRole',
    }),
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

<template>
  <v-card-text v-if="etablissement">
    <v-row>
      <v-col cols="12" md="6" lg="6">
        <span class="text-h6" style="color: black">Organisation</span>
        <s-text-info v-model="etablissement.raisonSociale" label="Raison sociale" required />
        <s-text-info v-model="etablissement.numeroFINESS" label="Numéro FINESS" required />
        <s-text-info v-model="etablissement.numeroSIRET" label="Numéro SIRET" required />
      </v-col>
      <v-col cols="12" md="6" lg="6">
        <span class="text-h6" style="color: black">Personnel</span>
        <etablissement-designer-directeur :etablissement="etablissement"></etablissement-designer-directeur>
        <etablissement-designer-comptables :etablissement="etablissement"></etablissement-designer-comptables>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
import { mapGetters } from 'vuex'
import STextInfo from '@/components/global/s-text-info.vue'
import EtablissementDesignerDirecteur from '@/components/etablissement/administration/etablissement-designer-directeur.vue'
import EtablissementDesignerComptables from '@/components/etablissement/administration/etablissement-designer-comptables.vue'

export default {
  name: 'etablissement-detail-administration',
  components: {
    EtablissementDesignerComptables,
    EtablissementDesignerDirecteur,
    STextInfo,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      etablissement: undefined,
    }
  },
  computed: {
    ...mapGetters({
      isInRole: 'security/isInRole',
    }),
  },
  created() {
    this.etablissement = this.value
  },
  watch: {
    value() {
      this.etablissement = this.value
    },
    etablissement: {
      handler() {
        this.$emit('input', this.etablissement)
      },
      deep: true,
    },
  },
}
</script>

<template>
  <v-btn-toggle v-model="selectedJourNuit" shaped class="btn-toggle-jour-nuit" mandatory>
    <v-btn
      class="btn-jour-nuit"
      :color="selectedJourNuit === 'jour' ? (color = '#EDAA00') : (color = '#FFEE93')"
      value="jour"
    >
      <v-icon color="black" small> mdi-white-balance-sunny </v-icon>
    </v-btn>
    <v-btn
      class="btn-jour-nuit"
      :color="selectedJourNuit === 'nuit' ? (color = '#2233DD') : (color = '#6699FF')"
      value="nuit"
    >
      <v-icon color="black" small> mdi-weather-night </v-icon>
    </v-btn>
  </v-btn-toggle>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 's-jour-nuit-btn-toggle',
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedJourNuit: undefined,
    }
  },
  computed: {
    ...mapGetters({
      debutJour: 'calendrier/getDebutJour',
      debutNuit: 'calendrier/getDebutNuit',
    }),
  },
  created() {
    this.selectedJourNuit = this.value
  },
  watch: {
    value() {
      this.selectedJourNuit = this.value
    },
    selectedJourNuit() {
      this.$emit('input', this.selectedJourNuit)
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn-toggle-jour-nuit {
  display: flex;
  flex-direction: row;
  flex: 2;
  align-items: stretch;
  justify-content: stretch;
}

.btn-jour-nuit {
  flex: 1;
  max-height: 36px;
}
</style>

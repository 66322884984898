<template>
  <v-list-item :to="{ name: 'service', params: { serviceId: service.serviceId } }">
    <v-list-item-content>
      <v-list-item-title v-text="service.nom" />
      <v-list-item-subtitle v-text="'Dirigé par ' + chefDeService.nomComplet" />
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'service-list-item',
  props: {
    service: {
      type: Object,
      required: true,
    },
  },
  computed: {
    chefDeService() {
      return this.service.chefDeService
    },
  },
}
</script>

<template>
  <v-dialog v-model="showDialog" persistent max-width="600px">
    <template v-slot:activator="{ on }">
      <v-btn max-height="35px" color="green" tile block dark v-on="on">Créer</v-btn>
    </template>
    <v-card>
      <s-toolbar text="Créer une association" create @close="close()" />
      <v-card-text>
        <association-form
          v-if="showDialog"
          v-model="createAssociationCommand"
          @updateFormValidity="updateFormValidity"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="grey" text @click="close()">Annuler</v-btn>
        <v-btn color="success" :disabled="!formValid" @click="sendCreateAssociationCommand()"> Créer </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import associationForm from './association-form'
import { mapActions } from 'vuex'

export default {
  name: 'association-create-dialog',
  components: {
    associationForm,
  },
  data() {
    return {
      showDialog: false,
      formValid: false,
      createAssociationCommand: undefined,
    }
  },
  mounted() {
    this.reset()
  },
  methods: {
    ...mapActions({
      createAssociation: 'etablissement/createAssociation',
    }),
    updateFormValidity(_validity) {
      this.formValid = _validity
    },
    sendCreateAssociationCommand() {
      this.createAssociation(this.createAssociationCommand)
      this.close()
    },
    close() {
      this.showDialog = false
      this.reset()
    },
    reset() {
      this.createAssociationCommand = {
        nom: undefined,
        SIREN: undefined,
        FINESSSiegeSocial: undefined,
        extensionSIRETSiegeSocial: undefined,
      }
    },
  },
}
</script>

<style scoped></style>

<template>
  <v-card class="ma-3 mt-0 fixed-height" flat v-if="!loading">
    <v-toolbar flat dense class="primary-lighten-3">
      <v-toolbar-title class="text-h6" v-text="'Accueils'" />
      <v-spacer />
    </v-toolbar>
    <v-container class="fixed-height">
      <v-row>
        <v-col cols="12" class="py-0">
          <perfect-scrollbar>
            <v-list two-line flat class="pa-0">
              <v-list-item-group color="primary">
                <accueil-usager-list-item
                  v-for="accueil in accueilsUsager"
                  :key="accueil.accueilId"
                  :accueil="accueil"
                />
              </v-list-item-group>
            </v-list>
          </perfect-scrollbar>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AccueilUsagerListItem from '@/components/usager/accueil/accueil-usager-list-item.vue'

export default {
  name: 'historique-accueil-usager',
  components: {
    AccueilUsagerListItem,
  },
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    ...mapGetters({
      getAccueilByUsagerId: 'accueil/getAccueilByUsagerId',
    }),
    usagerId() {
      return parseInt(this.$route.params.usagerId)
    },
    accueilsUsager() {
      return this.getAccueilByUsagerId(this.usagerId)
    },
  },
  methods: {
    ...mapActions({
      fetchAccueilsUsager: 'accueil/fetchAccueilsByUsagerId',
    }),
  },
  mounted() {
    this.fetchAccueilsUsager(this.usagerId).then(() => (this.loading = false))
  },
}
</script>

<style scoped>
.primary-lighten-3 {
  background-color: var(--v-primary-lighten3) !important;
}

.fixed-height {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
}

.scrollable {
  display: flex;
  flex-direction: column;
}
</style>

<template>
  <v-col :cols="cols" class="py-1">
    <v-row v-if="editing" class="s-text-info" align="center" :no-gutters="noGutters">
      <v-col cols="9">
        <v-text-field
          v-if="type === 'text'"
          ref="field"
          v-model="content"
          hide-no-data
          :label="label"
          :suffix="suffix"
          :required="required"
          @keydown.escape="cancelUpdate"
          @keydown.enter="validateUpdate"
        />
        <s-date-picker
          v-else-if="type === 'date'"
          ref="field"
          v-model="content"
          :label="label"
          :required="required"
          @keydown.escape="cancelUpdate"
          @keydown.enter="validateUpdate"
        />
      </v-col>
      <v-col cols="1">
        <v-btn icon small color="grey" @click="cancelUpdate">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="1">
        <v-btn icon small color="success" @click="validateUpdate">
          <v-icon small> fa-check </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-else class="s-text-info" align="center" @mouseover="showEditButton" @mouseleave="hideEditButton">
      <v-col cols="10">
        <div class="text-overline grey--text text--darken-1">{{ label }} {{ suffix }}</div>
        <div :class="textClass">
          {{ contentToDisplay }}
        </div>
      </v-col>
      <v-col cols="2" v-if="editButtonVisible">
        <v-spacer />
        <v-btn justify-self="end" icon x-small color="info" @click="startUpdate">
          <v-icon dark> fa-edit </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 's-text-info',
  props: {
    value: {
      type: String,
      required: false,
      default: '',
    },
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    label: {
      type: String,
      required: true,
    },
    cols: {
      type: Number,
      required: false,
      default: 12,
    },
    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    suffix: {
      type: String,
      required: false,
      default: '',
    },
    noGutters: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      content: undefined,
      editing: false,
      editButtonVisible: false,
    }
  },
  computed: {
    ...mapGetters({
      formatDateToDisplay: 'calendrier/formatDateToDisplay',
    }),
    contentToDisplay() {
      if (!this.content || this.content === '') {
        return 'Non renseigné'
      } else {
        if (moment(this.content, 'YYYY-MM-DD', true).isValid()) {
          return this.formatDateToDisplay(this.content)
        } else {
          return this.content
        }
      }
    },
    textClass() {
      if (!this.content || this.content === '') {
        return 'text-body-2 error--text'
      } else {
        return 'text-body-2 black--text'
      }
    },
  },
  created() {
    this.content = this.value
  },
  watch: {
    value() {
      this.content = this.value
    },
  },
  methods: {
    showEditButton() {
      if (this.editable) {
        this.editButtonVisible = true
      }
    },
    hideEditButton() {
      this.editButtonVisible = false
    },
    startUpdate() {
      this.editing = true
      setTimeout(() => {
        this.$refs.field.focus()
      })
    },
    validateUpdate() {
      this.$emit('input', this.content)
      this.editing = false
    },
    cancelUpdate() {
      this.content = this.value
      this.editing = false
    },
  },
}
</script>

<style scoped>
.s-text-info {
  height: 95px;
  min-height: 95px;
  max-height: 95px;
}
</style>

import axios from 'axios'

export default {
  namespaced: true,
  state: {
    modelePlanning: undefined,
    selectedModeleSemainesOrdre: [],
    loading: false,
  },
  mutations: {
    SET_MODELE_PLANNING(state, _planning) {
      state.modelePlanning = _planning
    },
    SET_LOADING(state, _loading) {
      state.loading = _loading
    },
  },
  getters: {
    getModelePlanning(state) {
      return state.modelePlanning
    },
    getModeleSemaines(state) {
      return state.modelePlanning.semaines
    },
    getLoading(state) {
      return state.loading
    },
    getNbHeuresParSemaine: (state) => (_membreId, _type) => {
      let nbHeures = state.modelePlanning.infos.find((i) => i.membreId === _membreId && i.type === _type)

      if (nbHeures) {
        nbHeures = nbHeures.valeur
      } else {
        nbHeures = 0
      }

      return Math.round(nbHeures * 100) / 100
    },
  },
  actions: {
    fetchModelePlanning(context, _serviceId) {
      this.commit('modelePlanning/SET_MODELE_PLANNING', undefined)

      return axios
        .get(`${process.env.VUE_APP_URL_BACK_SERENITY}/modelePlanning/service/${_serviceId}`)
        .then((response) => {
          if (response.data) {
            this.commit('modelePlanning/SET_MODELE_PLANNING', response.data)
          } else {
            this.dispatch('modelePlanning/createModelePlanning', _serviceId)
          }
        })
    },
    flushModelePlanning() {
      this.commit('modelePlanning/SET_MODELE_PLANNING', undefined)
      this.commit('modelePlanning/SET_LOADING', false)
    },
    publierModelePlanning(context, _semaine) {
      this.commit('modelePlanning/SET_LOADING', true)
      let serviceId = context.state.modelePlanning.serviceId
      return axios
        .put(
          `${process.env.VUE_APP_URL_BACK_SERENITY}/modelePlanning/${context.state.modelePlanning.modelePlanningId}/publier`,
          _semaine
        )
        .then(() => {
          this.dispatch('servicePlanning/flush', serviceId)
        })
    },
    interchangerMembres(context, _interchangerForm) {
      this.commit('modelePlanning/SET_LOADING', true)
      _interchangerForm.modelePlanningId = context.state.modelePlanning.modelePlanningId
      return axios
        .put(
          `${process.env.VUE_APP_URL_BACK_SERENITY}/modelePlanning/${context.state.modelePlanning.modelePlanningId}/interchangerMembre`,
          _interchangerForm
        )
        .then((response) => {
          this.commit('modelePlanning/SET_MODELE_PLANNING', response.data)
          this.commit('modelePlanning/SET_LOADING', false)
        })
        .catch(() => {
          this.commit('modelePlanning/SET_LOADING', false)
        })
    },
    createModeleJourDeRepos(context, _payload) {
      _payload.modelePlanningId = context.state.modelePlanning.modelePlanningId
      this.commit('modelePlanning/SET_LOADING', true)
      return axios
        .post(
          `${process.env.VUE_APP_URL_BACK_SERENITY}` +
            `/modelePlanning/${_payload.modelePlanningId}` +
            `/modeleSemaine/${_payload.ordreSemaine}` +
            `/modeleJourDeRepos`,
          _payload
        )
        .then((response) => {
          this.commit('modelePlanning/SET_MODELE_PLANNING', response.data)
          this.commit('modelePlanning/SET_LOADING', false)
        })
    },
    updateModeleJourDeRepos(context, _payload) {
      _payload.modelePlanningId = context.state.modelePlanning.modelePlanningId
      this.commit('modelePlanning/SET_LOADING', true)
      return axios
        .put(
          `${process.env.VUE_APP_URL_BACK_SERENITY}` +
            `/modelePlanning/${_payload.modelePlanningId}` +
            `/modeleSemaine/${_payload.ordreSemaine}` +
            `/modeleJourDeRepos/${_payload.modeleJourDeReposId}`,
          _payload
        )
        .then((response) => {
          this.commit('modelePlanning/SET_MODELE_PLANNING', response.data)
          this.commit('modelePlanning/SET_LOADING', false)
        })
    },
    deleteModeleJourDeRepos(context, _payload) {
      this.commit('modelePlanning/SET_LOADING', true)
      return axios
        .delete(
          `${process.env.VUE_APP_URL_BACK_SERENITY}` +
            `/modelePlanning/${context.state.modelePlanning.modelePlanningId}` +
            `/modeleSemaine/${_payload.ordreSemaine}` +
            `/modeleJourDeRepos/${_payload.modeleJourDeReposId}`
        )
        .then((response) => {
          this.commit('modelePlanning/SET_MODELE_PLANNING', response.data)
          this.commit('modelePlanning/SET_LOADING', false)
        })
    },
    addModeleSemaine({ state, commit }) {
      commit('SET_LOADING', true)

      return axios
        .post(
          `${process.env.VUE_APP_URL_BACK_SERENITY}/modelePlanning/${state.modelePlanning.modelePlanningId}/modeleSemaine`
        )
        .then((response) => {
          this.commit('modelePlanning/SET_MODELE_PLANNING', response.data)
          this.commit('modelePlanning/SET_LOADING', false)
        })
    },
    copyModeleSemaine({ state, commit }, _ordre) {
      commit('SET_LOADING', true)

      return axios
        .post(
          `${process.env.VUE_APP_URL_BACK_SERENITY}/modelePlanning/${state.modelePlanning.modelePlanningId}/modeleSemaine/${_ordre}/copy`
        )
        .then((response) => {
          this.commit('modelePlanning/SET_MODELE_PLANNING', response.data)
          this.commit('modelePlanning/SET_LOADING', false)
        })
    },
    deleteModeleSemaine(context, _ordre) {
      this.commit('modelePlanning/SET_LOADING', true)

      return axios
        .delete(
          `${process.env.VUE_APP_URL_BACK_SERENITY}/modelePlanning/${context.state.modelePlanning.modelePlanningId}/modeleSemaine/${_ordre}`
        )
        .then((response) => {
          this.commit('modelePlanning/SET_MODELE_PLANNING', response.data)
          this.commit('modelePlanning/SET_LOADING', false)
        })
    },
    createModeleCreneau(context, _toCreate) {
      this.commit('modelePlanning/SET_LOADING', true)
      return axios
        .post(
          `${process.env.VUE_APP_URL_BACK_SERENITY}` +
            `/modelePlanning/${context.state.modelePlanning.modelePlanningId}` +
            `/modeleSemaine/${_toCreate.ordreSemaine}` +
            `/modeleCreneau`,
          _toCreate
        )
        .then((response) => {
          this.commit('modelePlanning/SET_MODELE_PLANNING', response.data)
          this.commit('modelePlanning/SET_LOADING', false)
        })
    },
    updateModeleCreneau(context, _toUpdate) {
      this.commit('modelePlanning/SET_LOADING', true)

      return axios
        .put(
          `${process.env.VUE_APP_URL_BACK_SERENITY}` +
            `/modelePlanning/${context.state.modelePlanning.modelePlanningId}` +
            `/modeleSemaine/${_toUpdate.ordreSemaine}` +
            `/modeleCreneau/${_toUpdate.modeleCreneauDeTravailId}`,
          _toUpdate
        )
        .then((response) => {
          this.commit('modelePlanning/SET_MODELE_PLANNING', response.data)
          this.commit('modelePlanning/SET_LOADING', false)
        })
    },
    deleteModeleCreneau(context, _payload) {
      this.commit('modelePlanning/SET_LOADING', true)

      return axios
        .delete(
          `${process.env.VUE_APP_URL_BACK_SERENITY}` +
            `/modelePlanning/${context.state.modelePlanning.modelePlanningId}` +
            `/modeleSemaine/${_payload.ordreSemaine}` +
            `/modeleCreneau/${_payload.modeleCreneauDeTravailId}`
        )
        .then((response) => {
          this.commit('modelePlanning/SET_MODELE_PLANNING', response.data)
          this.commit('modelePlanning/SET_LOADING', false)
        })
    },
  },
}

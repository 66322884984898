<template>
  <div class="modele-planning-body-jour" v-bind:class="{ 'modele-planning-body-jour-lundi': jour.jourId === 1 }">
    <div class="modele-planning-body-jour-container">
      <planning-body-slot
        v-for="borneHoraire in bornesHoraire"
        :key="borneHoraire.borneHoraireId"
        :borneHoraire="borneHoraire"
        :jour="jour"
        :jour_nuit="isJourNuit(borneHoraire)"
        :slot-height="slotHeight"
      />
      <modele-planning-creneau-container
        v-if="bornesHoraire"
        :creneaux="creneaux"
        :borneDebut="bornesHoraire[0]"
        :borneFin="bornesHoraire[bornesHoraire.length - 1]"
        :semaine="semaine"
        :jour="jour"
        :membres="membres"
        :selectedMembre="selectedMembre"
        :slot-height="slotHeight"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PlanningBodySlot from '@/components/planning/body/planning-body-slot.vue'
import ModelePlanningCreneauContainer from '@/components/modelePlanning/creneau/modele-planning-creneau-container.vue'

export default {
  name: 'modele-planning-body-jour',
  components: {
    ModelePlanningCreneauContainer,
    PlanningBodySlot,
  },
  props: {
    slotHeight: {
      type: Number,
      required: true,
    },
    semaine: {
      type: Object,
      required: true,
    },
    jour: {
      type: Object,
      required: true,
    },
    membres: {
      type: Array,
      required: true,
    },
    selectedMembre: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  computed: {
    ...mapGetters({
      modelePlanning: 'modelePlanning/getModelePlanning',
      getBornesHorairesJournee: 'calendrier/getBornesHoraireJournee',
      isJourNuit: 'calendrier/isJourNuit',
    }),
    bornesHoraire() {
      return this.getBornesHorairesJournee()
    },
    creneaux() {
      return this.semaine.creneaux.filter((creneau) => creneau.jourId === this.jour.jourId)
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.modele-planning-body-jour {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex: 1;
  position: relative;

  border-left: 5px solid #c0c0c0;
}

.modele-planning-body-jour-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex: 1;
  position: relative;
}
</style>

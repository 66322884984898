<template>
  <v-list-item :to="{ name: 'association', params: { associationId: association.associationId } }">
    <v-list-item-content>
      <v-list-item-title v-text="association.nom" />
      <v-list-item-subtitle v-text="'SIREN: ' + association.siren" />
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'association-list-item',
  props: {
    association: {
      type: Object,
      required: true,
    },
  },
}
</script>

<template>
  <keep-alive>
    <s-page v-if="loaded">
      <template v-slot:lateral-panel>
        <s-lateral-panel>
          <perfect-scrollbar style="height: 100%">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-toolbar flat dense class="text-h6" v-text="'Horaires'" />
                  <s-jour-nuit-btn-toggle v-model="jour_nuit" />
                </v-col>
                <modele-planning-setup-semaines v-model="selectedModeleSemaines" />
                <v-col cols="12">
                  <v-toolbar flat dense class="text-h6" v-text="'Équipe'" />
                  <v-expansion-panels>
                    <modele-planning-membre
                      v-for="membre in membres"
                      :key="membre.membreId"
                      :membre="membre"
                      :selected="isSelected(membre)"
                      :allowDelete="false"
                      @click="clickedMembre(membre)"
                    />
                  </v-expansion-panels>
                </v-col>
                <v-spacer />
              </v-row>
            </v-container>
          </perfect-scrollbar>
          <v-divider class="elevation-20" />
          <modele-planning-publication />
        </s-lateral-panel>
      </template>
      <template v-slot:main-panel>
        <modele-planning-main-panel
          :modelePlanning="modelePlanning"
          :jour_nuit="jour_nuit"
          :selectedModeleSemaines="selectedModeleSemaines"
          :membres="membres"
          :selectedMembre="selectedMembre"
        />
      </template>
    </s-page>
  </keep-alive>
</template>

<script>
import modelePlanningMainPanel from '../../components/modelePlanning/modele-planning-main-panel.vue'
import modelePlanningMembre from './modele-planning-membre'
import modelePlanningSetupSemaines from './modele-planning-setup-semaines'
import modelePlanningPublication from './modele-planning-mise-en-application'
import { mapGetters, mapActions } from 'vuex'
import { initialDataLoaded } from '../../store/modules/helpers'

export default {
  name: 'modele-planning',
  components: {
    modelePlanningMainPanel,
    modelePlanningMembre,
    modelePlanningSetupSemaines,
    modelePlanningPublication,
  },
  data() {
    return {
      jour_nuit: 'jour',
      selectedModeleSemaines: [],
      selectedMembre: undefined,
    }
  },
  computed: {
    ...initialDataLoaded,
    ...mapGetters({
      modelePlanning: 'modelePlanning/getModelePlanning',
    }),
    loaded() {
      return initialDataLoaded && this.modelePlanning && this.membres
    },
    serviceId() {
      return parseInt(this.$route.params.serviceId, 10)
    },
    membres() {
      return this.modelePlanning ? this.modelePlanning.membres : []
    },
  },
  created() {
    this.fetchModelePlanning(this.serviceId)
  },
  watch: {
    serviceId() {
      this.fetchModelePlanning(this.serviceId)
    },
  },
  methods: {
    ...mapActions({
      fetchModelePlanning: 'modelePlanning/fetchModelePlanning',
    }),
    clickedMembre(_membre) {
      if (this.isSelected(_membre)) {
        this.selectedMembre = undefined
      } else {
        this.selectedMembre = _membre
      }
    },
    isSelected(_membre) {
      return this.selectedMembre === _membre
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

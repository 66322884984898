<template>
  <v-dialog v-model="showDialog" persistent max-width="600px">
    <template v-slot:activator="{ on }">
      <v-btn max-height="35px" color="green" tile block dark v-on="on">Créer</v-btn>
    </template>
    <v-card v-if="showDialog">
      <s-toolbar text="Créer un service" create @close="close()" />
      <v-card-title>
        <span class="headline">Ouvrir un service</span>
      </v-card-title>
      <v-card-text>
        <service-form v-model="createServiceCommand" @updateFormValidity="updateFormValidity" />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="grey" text @click="close()">Annuler</v-btn>
        <v-btn color="success" :disabled="!formValid" @click="validate()"> Créer </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import serviceForm from './service-form'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'service-create',
  components: {
    serviceForm,
  },
  data() {
    return {
      showDialog: false,
      formValid: false,
      createServiceCommand: undefined,
    }
  },
  computed: {
    ...mapGetters({
      selectedEtablissement: 'application/getSelectedEtablissement',
    }),
  },
  mounted() {
    this.initialize()
  },
  watch: {
    selectedEtablissement() {
      this.createServiceCommand.etablissementId = this.selectedEtablissement.etablissementId
    },
  },
  methods: {
    ...mapActions({
      createService: 'service/createService',
    }),
    initialize() {
      this.createServiceCommand = {
        nom: '',
        chefDeServiceId: undefined,
        etablissementId: this.selectedEtablissement.etablissementId,
      }
    },
    updateFormValidity(_validity) {
      this.formValid = _validity
    },
    validate() {
      this.createServiceCommand.chefDeServiceId = this.createServiceCommand.chefDeService.employeId
      this.createService(this.createServiceCommand)
      this.close()
    },
    close() {
      this.initialize()
      this.showDialog = false
    },
  },
}
</script>

<style scoped></style>

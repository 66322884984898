<template>
  <v-hover>
    <template v-slot:default="{ hover }">
      <v-list-item v-if="loaded" :key="membre.membreId">
        <v-list-item-avatar>
          <utilisateur-avatar :utilisateur="employe.utilisateur" :size="36" />
        </v-list-item-avatar>
        <v-list-item-content>
          <membre-update-dialog v-model="showMembreUpdateDialog" :membre="membre" @click="(e) => e.stopPropagation()" />
          <membre-remplacer-dialog v-model="showMembreRemplacerDialog" :membreARemplacer="membre" />
          <v-list-item-title v-text="membre.nomComplet" />
          <v-list-item-subtitle
            v-if="membre.serviceId !== 0"
            class="text--primary"
            v-text="membre.heuresHebdo + 'h par semaine'"
          />
        </v-list-item-content>
        <v-fade-transition>
          <div
            v-if="
              hover &&
              (isInRole('CHEF_DE_SERVICE_' + service.serviceId) ||
                isInRole('ADMINISTRATEUR_' + service.etablissement.etablissementId))
            "
            class="overlay"
          >
            <v-btn x-large icon color="info" @click="selectEmploye" small>
              <v-icon>fa-eye</v-icon>
            </v-btn>
            <v-btn x-large icon color="info" @click="showMembreUpdateDialog = true">
              <v-icon>fa-edit</v-icon>
            </v-btn>
            <v-btn x-large icon color="success" @click="showMembreRemplacerDialog = true">
              <v-icon>fa-people-arrows</v-icon>
            </v-btn>
          </div>
        </v-fade-transition>
        <v-list-item-action>
          <div
            v-if="membre.serviceId !== 0"
            class="membre-couleur-indicateur"
            v-bind:style="{
              'background-color': membre.couleur,
              opacity: membre.titulaire ? 1 : 0,
            }"
          />
        </v-list-item-action>
      </v-list-item>
    </template>
  </v-hover>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import utilisateurAvatar from '@/components/utilisateur/utilisateur-avatar'
import membreUpdateDialog from '@/components/membre/membre-update-dialog'
import MembreRemplacerDialog from './membre-remplacer-dialog.vue'

export default {
  name: 'membre-list-item',
  components: {
    MembreRemplacerDialog,
    utilisateurAvatar,
    membreUpdateDialog,
  },
  props: {
    membre: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showMembreUpdateDialog: false,
      showMembreRemplacerDialog: false,
    }
  },
  computed: {
    ...mapGetters({
      getServiceById: 'service/getServiceById',
      isInRole: 'security/isInRole',
    }),
    loaded() {
      return this.employe && this.service
    },
    employe() {
      return this.membre.employe
    },
    service() {
      return this.getServiceById(this.membre.serviceId)
    },
  },
  methods: {
    ...mapActions({
      deleteMembreAction: 'membre/deleteMembre',
    }),
    deleteMembre(_clickEvent) {
      this.deleteMembreAction(this.membre.membreId)
      _clickEvent.stopPropagation()
    },
    selectEmploye() {
      this.$router.push({
        name: 'employe',
        params: { employeId: String(this.membre.employe.employeId) },
      })
    },
  },
}
</script>

<style scoped>
.overlay {
  position: absolute;
  margin-left: -16px;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  z-index: 2;
  color: white;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.overlayAction {
  opacity: 1;
}

.membre-couleur-indicateur {
  min-width: 28px;
  width: 28px;
  max-width: 28px;
  min-height: 28px;
  height: 28px;
  max-height: 28px;
  border-radius: 50%;
  align-self: flex-start;
}
</style>

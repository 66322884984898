<template>
  <s-page v-if="loaded">
    <template v-slot:lateral-panel>
      <s-lateral-panel>
        <v-text-field placeholder="Rechercher" hide-details dense clearable v-model="recherche" class="ma-2" />
        <perfect-scrollbar style="height: 100%">
          <v-list>
            <v-list-group :value="true">
              <template v-slot:activator>
                <v-list-item-title>Usagers</v-list-item-title>
              </template>

              <template v-for="(usager, index) in usagerList">
                <usager-list-item :usager="usager" :key="usager.usagerId"></usager-list-item>
                <v-divider v-if="index < employesAVenir.length - 1" :key="usager.usagerId"></v-divider>
              </template>
            </v-list-group>
          </v-list>
        </perfect-scrollbar>
        <v-btn max-height="35px" color="green" tile block dark> accueillir un usager </v-btn>
      </s-lateral-panel>
    </template>
    <template v-slot:main-panel>
      <router-view :key="$route.fullPath" />
    </template>
  </s-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { initialDataLoaded } from '@/store/modules/helpers'
import UsagerListItem from '@/components/usager/usager-list-item.vue'

export default {
  name: 'usagers',
  components: { UsagerListItem },
  data() {
    return {
      recherche: null,
    }
  },
  computed: {
    ...initialDataLoaded,
    ...mapGetters({
      usagers: 'usager/getUsagers',
      etablissement: 'application/getSelectedEtablissement',
    }),
    loaded() {
      return initialDataLoaded && this.usagers
    },
    usagerList() {
      if (this.recherche === null || this.recherche === '') {
        return this.usagers
      } else {
        return this.usagers.filter(
          (u) => (u.nom + ' ' + u.prenom).toLowerCase().indexOf(this.recherche.toLowerCase()) >= 0
        )
      }
    },

    employesAVenir() {
      return this.usagerList.filter((e) => e.status === 'A venir')
    },
  },
  methods: {
    ...mapActions({
      fetchUsager: 'usager/fetchUsagers',
      fetchServicesByEtablissement: 'service/fetchServicesByEtablissement',
    }),
  },
  mounted() {
    this.fetchUsager()
    this.fetchServicesByEtablissement(this.etablissement)
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

<template functional>
  <v-chip
    label
    small
    :text-color="
      isNaN(props.valeur)
        ? 'grey darken-1'
        : props.valeur > 0
        ? 'success darken-4'
        : props.valeur < 0
        ? 'error darken-4'
        : 'grey darken-1'
    "
    :color="
      isNaN(props.valeur)
        ? 'grey lighten-1'
        : props.valeur > 0
        ? 'success lighten-4'
        : props.valeur < 0
        ? 'error lighten-4'
        : 'grey lighten-1'
    "
  >
    {{ props.valeur ? props.valeur : '-' }}{{ props.valeur ? props.unite : '' }}
  </v-chip>
</template>

<script>
export default {
  name: 'conges-solde-chip',
  props: {
    valeur: {
      required: false,
      default: undefined,
    },
    unite: {
      type: String,
      required: false,
      default: 'j',
    },
  },
}
</script>

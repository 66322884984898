<template>
  <v-dialog v-model="showDialog" persistent max-width="600px">
    <v-card v-if="showDialog">
      <s-toolbar text="Mettre à jour les ACLs" update @close="close()" />
      <v-card-text>
        Classes disponibles
        <v-form v-if="loaded">
          <v-select v-model="refreshAclsCommand.repository" :items="aclClasses" label="Repository"></v-select>
          <v-text-field
            v-model="refreshAclsCommand.idDebut"
            :rules="[(v) => !isNaN(v) && v >= 0]"
            hide-no-data
            type="number"
            label="ID Début"
            hint="Laisser à 0 si pas d'ID minimum"
            required
          />
          <v-text-field
            v-model="refreshAclsCommand.idFin"
            :rules="[(v) => !isNaN(v) && v >= 0]"
            hide-no-data
            type="number"
            label="ID Fin"
            hint="Laisser à 0 si pas d'ID maximum"
            required
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="grey" text @click="close()">Annuler</v-btn>
        <v-btn color="success" :disabled="!formValid" @click="validate()"> Mettre à jour </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import EventBus from '../../EventBus'

export default {
  name: 'acl-refresh-dialog',
  data() {
    return {
      showDialog: false,
      aclClasses: undefined,
      refreshAclsCommand: undefined,
    }
  },
  computed: {
    loaded() {
      return this.aclClasses && this.refreshAclsCommand
    },
    formValid() {
      return this.refreshAclsCommand && this.refreshAclsCommand.repository
    },
  },
  created() {
    this.aclClasses = this.findAllAclClass().then((response) => (this.aclClasses = response))
    EventBus.$on('showAclRefreshDialog', () => {
      console.log('showAclRefreshDialog event received')
      this.showDialog = true
    })
    this.initialize()
  },
  methods: {
    ...mapActions({
      findAllAclClass: 'admin/findAllAclClass',
      refreshAcls: 'admin/refreshAcls',
    }),
    initialize() {
      this.refreshAclsCommand = {
        repository: undefined,
        idDebut: 0,
        idFin: 0,
      }
    },
    validate() {
      this.refreshAcls(this.refreshAclsCommand)
      this.close()
    },
    close() {
      console.log('acl-refresh-dialog.close')
      this.showDialog = false
      this.initialize()
    },
  },
}
</script>

<style scoped></style>

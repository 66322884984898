<template>
  <v-slide-x-transition>
    <tr @dblclick.stop="setupEdit">
      <td v-if="!editing" class="fixed-width">{{ ligneModeleAIM.posteAIM.libelle }}</td>
      <td v-else class="fixed-width">
        <v-select
          v-model="tempLigneModeleAIM.posteAIM.posteAIMId"
          :items="postesSelectionnables"
          :item-value="'posteAIMId'"
          :item-text="'libelle'"
          label="Poste"
        >
        </v-select>
      </td>

      <td v-if="!editing" class="text-right fixed-width">
        <s-info-montant :montant="ligneModeleAIM.budgetParDefaut"></s-info-montant>
      </td>
      <td v-else class="text-right fixed-width">
        <v-text-field
          ref="fieldMontant"
          @keydown.enter="confirmEdit"
          @keydown.esc="teardownEdit"
          v-model="tempLigneModeleAIM.budgetParDefaut"
          :rules="[(v) => (!isNaN(v) && v > 0) || 'Entrer une valeur numérique']"
          type="number"
          label="Montant"
        />
      </td>

      <td v-if="!editing" class="fixed-width">{{ ligneModeleAIM.posteAIM.libellePeriodiciteDAIM }}</td>
      <td v-else class="fixed-width">
        {{
          posteSelectionne
            ? posteSelectionne.libellePeriodiciteDAIM
            : tempLigneModeleAIM.posteAIM.libellePeriodiciteDAIM
        }}
      </td>

      <td v-if="!editing" class="modele-table-aim-actions">
        <v-row align="center">
          <v-btn class="mx-1 pt-1 pb-1" :disabled="disabled" small icon color="info" @click="setupEdit"
            ><v-icon>fa-edit</v-icon></v-btn
          >
          <s-confirmation-dialog
            :disabled="disabled"
            info-level="error"
            confirm-btn-icon="fa-trash"
            dialog-title="Suppression d'un poste d'AIM"
            message="Êtes vous sûr de vouloir supprimer ce poste
            d'AIM des postes prévus par défaut pour ce service ?"
            button-title="Supprimer"
            @confirmation="supprimerLigneModeleAIM"
          >
            <v-btn class="mx-1" :disabled="disabled" small icon color="error"><v-icon>mdi-delete</v-icon></v-btn>
          </s-confirmation-dialog>
          <v-col>
            <v-btn
              class="mx-1 pt-1 pb-1"
              :disabled="disabled || !canOrderUp"
              small
              icon
              color="primary"
              @click="$emit('order-up')"
            >
              <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
            <v-btn
              class="mx-1"
              :disabled="disabled || !canOrderDown"
              small
              icon
              color="primary"
              @click="$emit('order-down')"
            >
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </td>
      <td v-else class="modele-table-aim-actions">
        <v-tooltip left>
          Valider la modification
          <template #activator="{ on, attrs }">
            <v-btn
              class="mx-1"
              :disabled="disabled"
              small
              icon
              v-on="on"
              v-bind="attrs"
              color="success"
              @click="confirmEdit"
              ><v-icon>mdi-check</v-icon>
            </v-btn>
          </template>
        </v-tooltip>
        <v-tooltip bottom>
          Annuler la modification
          <template #activator="{ on, attrs }">
            <v-btn
              class="mx-1"
              :disabled="disabled"
              small
              icon
              v-on="on"
              v-bind="attrs"
              color="grey"
              @click="teardownEdit"
              ><v-icon>mdi-cancel</v-icon>
            </v-btn>
          </template>
        </v-tooltip>
      </td>
    </tr>
  </v-slide-x-transition>
</template>

<script>
import SConfirmationDialog from '@/components/global/s-confirmation-dialog.vue'
import SInfoMontant from '@/components/global/s-info-montant.vue'

export default {
  components: { SInfoMontant, SConfirmationDialog },
  data() {
    return {
      tempLigneModeleAIM: {},
      editing: false,
    }
  },
  props: {
    loaded: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    canOrderUp: {
      type: Boolean,
      required: true,
    },
    canOrderDown: {
      type: Boolean,
      required: true,
    },
    ligneModeleAIM: {
      type: Object,
      required: true,
    },
    postesNonDefinits: {
      type: Array,
      requires: true,
    },
  },
  computed: {
    postesSelectionnables() {
      let eee = JSON.parse(JSON.stringify(this.postesNonDefinits))
      eee.push(this.ligneModeleAIM.posteAIM)
      return eee
    },
    posteSelectionne() {
      return this.postesSelectionnables.filter(
        (value) => value.posteAIMId === this.tempLigneModeleAIM.posteAIM.posteAIMId
      )[0]
    },
  },
  methods: {
    setupEdit() {
      this.tempLigneModeleAIM = JSON.parse(JSON.stringify(this.ligneModeleAIM))
      this.editing = true
    },
    confirmEdit() {
      if (!this.$refs.fieldMontant.validate()) return
      if (
        this.tempLigneModeleAIM.budgetParDefaut !== this.ligneModeleAIM.budgetParDefaut ||
        this.posteSelectionne.posteAIMId !== this.ligneModeleAIM.posteAIM.posteAIMId ||
        this.posteSelectionne.periodicite !== this.ligneModeleAIM.posteAIM.periodicite
      ) {
        this.$emit('update', {
          posteAIM: this.posteSelectionne,
          budgetParDefaut: this.tempLigneModeleAIM.budgetParDefaut,
        })
      }
      this.teardownEdit()
    },
    supprimerLigneModeleAIM() {
      this.$emit('delete', this.ligneModeleAIM)
    },
    teardownEdit() {
      this.editing = false
      this.tempLigneModeleAIM = {}
    },
  },
}
</script>
<style scoped>
.fixed-width {
  width: 30%;
}

.modele-table-aim-actions {
  width: 10% !important;
}
</style>

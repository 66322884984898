import { render, staticRenderFns } from "./etablissement-form.vue?vue&type=template&id=651ea59f&scoped=true"
import script from "./etablissement-form.vue?vue&type=script&lang=js"
export * from "./etablissement-form.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "651ea59f",
  null
  
)

export default component.exports
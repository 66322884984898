<template>
  <v-dialog v-model="showDialog" persistent max-width="600px">
    <template v-slot:activator="{ on }">
      <v-btn v-if="mode === 'button'" v-on="on" small color="green darken-1" dark outlined class="mr-2">
        <v-icon x-small>fa-plus</v-icon>
        Crédit
      </v-btn>
    </template>
    <v-card v-if="showDialog">
      <s-toolbar text="Modifier le crédit" update @close="close()" />
      <v-card-text>
        <conges-credit-form v-model="updateCongesCreditCommand" @updateFormValid="updateFormValid" />
      </v-card-text>
      <v-card-actions>
        <v-btn icon>
          <v-icon small color="red" @click="deleteCongesCredit()">fa-trash</v-icon>
        </v-btn>
        <v-spacer />
        <v-btn color="grey darken-1" text @click="close()">Annuler</v-btn>
        <v-btn color="info" :disabled="!formValid" @click="validate()"> Mettre à jour </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CongesCreditForm from './conges-credit-form'
import { mapActions } from 'vuex'

export default {
  name: 'conges-credit-update-dialog',
  components: {
    CongesCreditForm,
  },
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
    mode: {
      type: String,
      required: false,
      default: '',
    },
    employe: {
      type: Object,
      required: true,
    },
    congesCreditToUpdate: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formValid: false,
      updateCongesCreditCommand: undefined,
    }
  },
  computed: {
    showDialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  created() {
    this.initialize()
  },
  watch: {
    congesCreditToUpdate() {
      this.initialize()
    },
  },
  methods: {
    ...mapActions({
      updateCongesCredit: 'conges/updateCongesCredit',
      dispatchDeleteCongesCredit: 'conges/deleteCongesCredit',
    }),
    initialize() {
      this.updateCongesCreditCommand = JSON.parse(JSON.stringify(this.congesCreditToUpdate))
      this.updateCongesCreditCommand.employeId = this.employe.employeId
    },
    updateFormValid(_validity) {
      this.formValid = _validity
    },
    deleteCongesCredit() {
      this.dispatchDeleteCongesCredit(this.updateCongesCreditCommand.congesCreditId)
      this.close()
    },
    validate() {
      if (this.formValid) {
        this.updateCongesCredit(this.updateCongesCreditCommand)
        this.close()
      }
    },
    close() {
      this.showDialog = false
    },
  },
}
</script>

<style scoped></style>

<template>
  <s-page v-if="loaded">
    <template v-slot:lateral-panel>
      <s-lateral-panel>
        <perfect-scrollbar style="height: 100%">
          <v-list class="py-0">
            <v-list-item-group color="primary">
              <v-list-item
                :to="{ name: 'utilisateur-informations', params: { utilisateurId: utilisateur.utilisateurId } }"
              >
                <v-list-item-icon>
                  <v-icon>mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="'Informations'" />
                </v-list-item-content>
              </v-list-item>
              <v-list-item :to="{ name: 'utilisateur-soldes', params: { utilisateurId: utilisateur.utilisateurId } }">
                <v-list-item-icon>
                  <v-icon>fa-coins</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="'Soldes'" />
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </perfect-scrollbar>
        <v-btn max-height="35px" color="secondary" tile block dark @click="logout">
          <v-icon>mdi-exit-to-app</v-icon>
          Déconnexion
        </v-btn>
      </s-lateral-panel>
    </template>
    <template v-slot:main-panel>
      <router-view />
    </template>
  </s-page>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'profil',
  data() {
    return {
      recherche: null,
    }
  },
  computed: {
    ...mapGetters({
      utilisateur: 'security/getUtilisateur',
    }),
    loaded() {
      return this.utilisateur
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('security/logout')
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

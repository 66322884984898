<template>
  <v-container class="px-0">
    <v-row>
      <v-col cols="12" class="py-0">
        <v-list-item :style="style" class="mb-2">
          <v-list-item-content :style="style">
            <v-list-item-title v-text="titre" />
            <v-list-item-subtitle v-text="sousTitre" />
          </v-list-item-content>
          <v-list-item-action v-if="utilisateurPeutValider">
            <div class="demande-validation-list-item-action">
              <v-btn v-if="enAttente" class="mr-2" color="error" fab x-small @click="refuserDemande">
                <v-icon small>fa-ban</v-icon>
              </v-btn>
              <v-btn v-if="enAttente" color="success" fab x-small @click="validerDemande">
                <v-icon small>fa-check</v-icon>
              </v-btn>
            </div>
          </v-list-item-action>
        </v-list-item>
      </v-col>
      <v-col cols="12" v-if="validation.sousValidations.length > 0" class="py-0">
        <template v-for="(v, index) in validation.sousValidations">
          <v-row :key="index">
            <v-col cols="1" align="right" class="pr-2">
              <div :style="subValidationArrowStyle" :key="-v.validationId"></div>
            </v-col>
            <v-col cols="11" class="pl-0">
              <demande-validation-list-item
                :key="v.validationId"
                :validation="v"
                :demande="demande"
                :parent-validation="validation"
              />
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'demande-validation-list-item',
  props: {
    validation: {
      type: Object,
      required: true,
    },
    demande: {
      type: Object,
      required: true,
    },
    parentValidation: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  computed: {
    ...mapGetters({
      utilisateur: 'security/getUtilisateur',
      isInRole: 'security/isInRole',
      formatDate: 'calendrier/formatDateToDisplay',
    }),
    utilisateurPeutValider() {
      return this.isInRole(this.validation.authority) && !this.autoValidation
    },
    autoValidation() {
      return (
        this.demande.employe.utilisateur.utilisateurId === this.utilisateur.utilisateurId &&
        this.validation.authority !== this.utilisateur.email
      )
    },
    enAttente() {
      return this.validation.accepte !== false && this.validation.accepte !== true
    },
    accepte() {
      return this.validation.accepte === true || (this.parentValidation && this.parentValidation.accepte === true)
    },
    refuse() {
      return this.validation.accepte === false || (this.parentValidation && this.parentValidation.accepte === false)
    },
    style() {
      return {
        color: 'var(--v-' + this.color + '-darken2)',
        'background-color': 'var(--v-' + this.color + '-lighten5)',
        'outline-color': 'var(--v-' + this.color + '-darken2)',
      }
    },
    subValidationArrowStyle() {
      return {
        height: '50%',
        width: '50%',
        'border-style': 'solid',
        'border-width': '0px 0px 5px 5px',
        'border-color': 'var(--v-' + this.color + '-lighten5)',
      }
    },
    color() {
      if (this.accepte) {
        return 'success'
      } else if (this.refuse) {
        return 'error'
      } else {
        return 'info'
      }
    },
    titre() {
      return this.validation.validateur
    },
    sousTitre() {
      if (this.accepte) {
        if (this.validation.accepte === true) {
          return 'Accepté le ' + this.formattedDateValidation + ' par ' + this.validation.validePar.nomComplet
        } else {
          return '-'
        }
      } else if (this.refuse) {
        if (this.validation.accepte === false) {
          return 'Refusé le ' + this.formattedDateValidation + ' par ' + this.validation.validePar.nomComplet
        } else {
          return '-'
        }
      } else {
        return 'En attente de validation'
      }
    },
    formattedDateValidation() {
      if (this.validation.dateValidation) {
        return this.formatDate(this.validation.dateValidation)
      } else {
        return ''
      }
    },
  },
  methods: {
    ...mapActions({
      valider: 'demande/validerDemande',
      refuser: 'demande/refuserDemande',
    }),
    validerDemande() {
      this.valider({ validationId: this.validation.validationId, demandeId: this.demande.demandeId })
    },
    refuserDemande() {
      this.refuser({ validationId: this.validation.validationId, demandeId: this.demande.demandeId })
    },
  },
}
</script>

<style scoped>
.demande-validation-list-item-action {
  display: flex;
  flex-direction: row;
}
</style>

<template>
  <v-list-item :to="{ name: 'usager', params: { usagerId: usager.usagerId } }">
    <v-list-item-avatar>
      <v-avatar></v-avatar>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title v-text="nomComplet" />
      <v-list-item-subtitle v-text="usager.genre" />
    </v-list-item-content>
    <v-card-actions class="py-0"><slot name="actions" /></v-card-actions>
  </v-list-item>
</template>

<script>
export default {
  name: 'usager-list-item',
  props: {
    usager: {
      type: Object,
      required: true,
    },
  },
  computed: {
    nomComplet() {
      return this.usager.nom + ' ' + this.usager.prenom
    },
  },
}
</script>

<style scoped></style>

<template>
  <v-dialog v-model="showDialog" persistent max-width="600px">
    <v-card v-if="showDialog">
      <s-toolbar :text="'Remplacer ' + membreARemplacer.nomComplet" create @close="close()" />
      <v-card-text>
        <v-form v-model="formValid">
          <membre-employe-autocomplete
            v-model="employe"
            label="Remplacant"
            :service-id="membreARemplacer.serviceId"
            required
          />
          <s-date-picker
            v-model="remplacerMembreCommand.dateDuRemplacement"
            label="Date de remplacement"
            required
            hide-details
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" text @click="close()">Annuler</v-btn>
        <v-btn color="success" :disabled="!formValid" @click="validate()">Remplacer</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import membreEmployeAutocomplete from './membre-employe-autocomplete.vue'

export default {
  name: 'membre-remplacer-dialog',
  components: {
    membreEmployeAutocomplete,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    membreARemplacer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDialog: false,
      remplacerMembreCommand: undefined,
      formValid: false,
    }
  },
  computed: {
    ...mapGetters({
      getEmployeById: 'employe/getEmployeById',
    }),
    employe: {
      get() {
        return this.getEmployeById(this.remplacerMembreCommand.employeRemplacantId)
      },
      set(_employe) {
        this.remplacerMembreCommand.employeRemplacantId = _employe.employeId
      },
    },
  },
  created() {
    this.showDialog = this.value
  },
  watch: {
    showDialog() {
      if (this.showDialog) {
        this.initialize()
      }
      this.$emit('input', this.showDialog)
    },
    value() {
      this.showDialog = this.value
    },
  },
  methods: {
    ...mapActions({
      remplacerMembre: 'membre/remplacerMembre',
    }),
    initialize() {
      this.remplacerMembreCommand = {
        membreARemplacerId: this.membreARemplacer.membreId,
        employeRemplacantId: undefined,
        dateDuRemplacement: undefined,
      }
    },
    validate() {
      if (this.formValid) {
        this.remplacerMembre(this.remplacerMembreCommand)
        this.close()
      }
    },
    close() {
      this.showDialog = false
    },
  },
}
</script>

<style scoped></style>

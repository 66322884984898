var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loaded)?_c('v-expansion-panel',{style:({
    color: _vm.color,
  }),attrs:{"active-class":"active","rounded":"0"},on:{"click":function($event){return _vm.emitClick()}}},[_c('v-expansion-panel-header',{staticClass:"pa-1 pr-3",scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{staticClass:"membre-couleur-indicateur",style:({
          'background-color': _vm.membre.couleur,
        })})]},proxy:true}],null,false,1063887846)},[_c('div',{staticClass:"pa-1 membre-card-content"},[_c('utilisateur-avatar',{attrs:{"utilisateur":_vm.employe.utilisateur,"size":36}}),_c('span',{staticClass:"body-1 ml-2"},[_vm._v(" "+_vm._s(_vm.nomAAfficher)+" ")]),_c('v-spacer')],1)]),_c('v-expansion-panel-content',{staticClass:"py-0 modele-planning-membre-expansion-panel-content",style:({
      color: 'black',
    })},[_c('v-row',{staticClass:"text-caption pb-2 px-2",attrs:{"justify":"space-around","no-gutters":""}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-icon',{staticClass:"fas fa-dot-circle",attrs:{"x-small":"","color":"info"}}),_vm._v(" "+_vm._s(_vm.nbHeuresParSemaineMoyenne)+"h ")],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-icon',{staticClass:"fas fa-arrow-circle-up",attrs:{"x-small":"","color":"green"}}),_vm._v(" "+_vm._s(_vm.nbHeuresParSemaineMax)+"h ")],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-icon',{staticClass:"fas fa-arrow-circle-down",attrs:{"x-small":"","color":"red"}}),_vm._v(" "+_vm._s(_vm.nbHeuresParSemaineMin)+"h ")],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('interchanger-membre-dialog',{attrs:{"membre":_vm.membre},model:{value:(_vm.showInterchangerDialog),callback:function ($$v) {_vm.showInterchangerDialog=$$v},expression:"showInterchangerDialog"}}),_c('v-btn',{attrs:{"small":"","block":"","tile":"","color":"info"},on:{"click":function($event){_vm.showInterchangerDialog = true}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-people-arrows")])],1)],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
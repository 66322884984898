<template>
  <v-list-item
    class="py-0"
    :to="{
      name: 'service-planning',
      params: {
        serviceId: ecart.serviceId,
        numeroSemaine: ISOWeek.numero,
      },
    }"
    v-ripple="{ class: textColor }"
  >
    <v-list-item-content class="py-1">
      <v-list-item-title v-text="heures" :class="textColor" />
    </v-list-item-content>
    <v-list-item-action class="pr-5">
      <v-badge :color="classColor" :content="duree" overlap>
        <v-chip outlined label>
          <v-icon left> {{ icon }} </v-icon>
          {{ label }}
        </v-chip>
      </v-badge>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ecart-planning-modele-list-item',
  props: {
    ecart: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getISOWeekOfDate: 'calendrier/getISOWeekOfDate',
    }),
    ISOWeek() {
      return this.getISOWeekOfDate(this.ecart.date)
    },
    membre() {
      return this.ecart.membre
    },
    heures() {
      return 'De ' + this.ecart.heureDebut.toHeureMinute + ' à ' + this.ecart.heureFin.toHeureMinute
    },
    icon() {
      switch (this.ecart.type) {
        case 'Heures complémentaires':
          return 'mdi-plus'
        case 'Heures de formation':
          return 'mdi-school'
        case 'Récupération':
          return 'mdi-sleep'
        default:
          return ''
      }
    },
    label() {
      return this.ecart.type
    },
    duree() {
      let result = this.ecart.soldeEnMinute / 60
      return Math.round(result * 100) / 100 + 'h'
    },
    color() {
      if (this.ecart.soldeEnMinute > 0) {
        return 'success'
      } else {
        return 'error'
      }
    },
    classColor() {
      return `${this.color} lighten-4 ` + this.textColor
    },
    textColor() {
      return `${this.color}--text text--darken-4`
    },
  },
}
</script>

<style scoped></style>

import axios from 'axios'

function base64toFile(base64String, filename, mimeType) {
  // Convert the Base64 string to a Blob
  const byteCharacters = atob(base64String.split(';base64,').pop())
  const byteArrays = []
  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512)
    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }
  const blob = new Blob(byteArrays, { type: mimeType })

  // Create a new File object
  const file = new File([blob], filename, { type: mimeType })

  return file
}

export default {
  namespaced: true,
  state: {
    avatars: [],
  },
  mutations: {
    ADD_AVATAR(state, { _utilisateurId, _avatar }) {
      let userIndex = state.avatars.findIndex((u) => u.utilisateurId === _utilisateurId)
      if (userIndex >= 0) {
        state.avatars.splice(userIndex, 1)
      }
      state.avatars.push({
        utilisateurId: _utilisateurId,
        avatar: _avatar,
      })
    },
  },
  getters: {
    getAvatarByUserId: (state) => (_utilisateurId) => {
      let userWithAvatar = state.avatars.find((u) => u.utilisateurId === _utilisateurId)
      if (userWithAvatar !== undefined) {
        return userWithAvatar.avatar
      }
    },
  },
  actions: {
    searchUtilisateurByEmail(state, _email) {
      return axios
        .get(`${process.env.VUE_APP_URL_BACK_SERENITY}/utilisateur/email?_search=${_email}`)
        .catch((error) => {
          console.log(error)
        })
        .then((response) => {
          return response.data
        })
    },
    updatePassword(state, _updatePasswordCommand) {
      return axios.put(
        `${process.env.VUE_APP_URL_BACK_SERENITY}/utilisateur/${_updatePasswordCommand.utilisateurId}/password`,
        _updatePasswordCommand
      )
    },
    updateUtilisateur(state, _toUpdate) {
      return axios
        .put(`${process.env.VUE_APP_URL_BACK_SERENITY}/utilisateur/${_toUpdate.utilisateurId}`, _toUpdate)
        .then((response) => {
          return response.data
        })
    },
    updateUtilisateurAvatar({ dispatch }, _payload) {
      const FormData = require('form-data')
      const form = new FormData()
      let fileToSend = base64toFile(_payload.avatar, 'avatar.png', 'image/png')
      form.append('avatar', fileToSend)
      return axios
        .put(`${process.env.VUE_APP_URL_BACK_SERENITY}/utilisateur/${_payload.utilisateurId}/avatar`, form, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          if (response.data.hasAvatar) {
            dispatch('fetchAvatarUtilisateur', response.data.utilisateurId)
          }
        })
    },
    fetchAvatarUtilisateur({ commit }, _utilisateurId) {
      return axios
        .get(`${process.env.VUE_APP_URL_BACK_SERENITY}/utilisateur/${_utilisateurId}/avatar`, {
          responseType: 'blob',
        })
        .then((response) => {
          if (response.data) {
            commit('ADD_AVATAR', {
              _utilisateurId: _utilisateurId,
              _avatar: URL.createObjectURL(response.data),
            })
          }
        })
    },
  },
}

<template>
  <v-card>
    <v-toolbar dense color="primary" dark>{{ titre }} </v-toolbar>
    <v-list class="py-0">
      <v-list-item-group v-model="employeIdInterne" active-class="primary--text">
        <template v-for="(employe, index) in employesDastreintes">
          <v-list-item
            :key="employe.employeId"
            :value="employe.employeId"
            @click="employeIdInterne = employe.employeId"
          >
            <v-list-item-avatar :color="employe.couleur" size="32"></v-list-item-avatar>
            <v-list-item-title>{{ employe.nomComplet }}</v-list-item-title>
          </v-list-item>
          <v-divider :key="index" />
        </template>
      </v-list-item-group>
      <v-list-item v-if="employeInterne || selectionHebdomadaire" dense @click="supprimer">
        <v-list-item-icon><v-icon color="error">mdi-delete</v-icon></v-list-item-icon>
        <v-list-item-title>Personne</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="!autre"
        dense
        @click="
          (e) => {
            e.stopPropagation()
            autre = true
          }
        "
      >
        <v-list-item-icon><v-icon>mdi-dots-horizontal</v-icon></v-list-item-icon>
        <v-list-item-title>Autre</v-list-item-title>
      </v-list-item>
      <v-list-item v-else @click="(e) => e.stopPropagation()">
        <employe-autocomplete v-if="autre" v-model="employeInterne" :employes-a-exclure="getEmployesDastreintes" />
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import EmployeAutocomplete from '../employe/employe-autocomplete.vue'

export default {
  name: 'astreinte-planning-employe-select',
  components: { EmployeAutocomplete },
  props: {
    employeIdSelectionne: {
      type: Number,
      required: false,
      default: undefined,
    },
    titre: {
      type: String,
      required: true,
    },
    selectionHebdomadaire: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      autre: false,
      employeIdInterne: undefined,
    }
  },
  computed: {
    ...mapGetters({
      getEmployesDastreintes: 'astreinte/getEmployesDastreinte',
      etablissement: 'application/getSelectedEtablissement',
      getEmployeById: 'employe/getEmployeById',
    }),
    employesDastreintes() {
      let employeDansLaListe = false

      this.getEmployesDastreintes.forEach((e) => {
        if (this.employeInterne && e.employeId === this.employeInterne.employeId) {
          employeDansLaListe = true
        }
      })

      if (this.employeInterne && employeDansLaListe === false) {
        return [...this.getEmployesDastreintes, this.employeInterne]
      } else {
        return this.getEmployesDastreintes
      }
    },
    employeInterne: {
      get() {
        let employe = this.getEmployeById(this.employeIdInterne)
        return employe
      },
      set(_employe) {
        if (_employe) {
          this.employeIdInterne = _employe.employeId
        }
      },
    },
  },
  created() {
    this.employeIdInterne = this.employeIdSelectionne
  },
  watch: {
    employeIdInterne() {
      if (this.employeIdInterne && this.employeIdInterne !== this.employeIdSelectionne) {
        this.$emit('selection', this.employeIdInterne)
      }
    },
  },
  methods: {
    initialiser() {
      this.autre = false
    },
    supprimer() {
      this.employeIdInterne = undefined
      this.$emit('selection', undefined)
    },
  },
}
</script>

<style scoped></style>

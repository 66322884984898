<template>
  <v-checkbox
    v-model="selectedMembresId"
    :value="membre.membreId"
    :color="membre.couleur"
    :label="label"
    hide-details
    :dense="dense"
  >
    <template v-slot:append>
      <v-menu v-model="menuVisible" close-on-content-click absolute offset-y>
        <template v-slot:activator="{ on }">
          <v-btn
            v-if="
              isInRole('CHEF_DE_SERVICE_' + membre.serviceId) ||
              isInRole('ADMINISTRATEUR_' + employe.etablissement.etablissementId)
            "
            icon
            small
            v-on="on"
          >
            <v-icon> mdi-dots-horizontal </v-icon>
          </v-btn>
        </template>
        <v-list class="py-0" dense>
          <v-list-item
            v-if="isInRole('ADMINISTRATEUR_' + employe.etablissement.etablissementId)"
            :to="{ name: 'employe', params: { employeId: employe.employeId } }"
          >
            <v-list-item-icon>
              <v-icon color="primary">fa-eye</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Fiche employé</v-list-item-title>
          </v-list-item>
          <conges-create-dialog :employe="employe" :dateDebut="defaultDate" mode="list" />
          <creneau-de-travail-create-dialog :date="defaultDate" :membreId="membre.membreId" mode="list" />
          <creneau-de-formation-create-dialog :date="defaultDate" :membreId="membre.membreId" />
          <!--<creneau-de-delegation-create-dialog :date="defaultDate" :membreId="membre.membreId" />-->
        </v-list>
      </v-menu>
    </template>
  </v-checkbox>
</template>

<script>
import CongesCreateDialog from '../conges/conges-create-dialog'
import CreneauDeTravailCreateDialog from '@/components/creneau/creneau-de-travail-create-dialog'
import CreneauDeFormationCreateDialog from '@/components/creneau/creneau-de-formation-create-dialog'
import { mapGetters } from 'vuex'

export default {
  name: 'membre-checkbox',
  components: {
    CongesCreateDialog,
    CreneauDeTravailCreateDialog,
    CreneauDeFormationCreateDialog,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    membresSelectionnables: {
      type: Array,
      required: true,
    },
    semaine: {
      type: Object,
      required: false,
    },
    membre: {
      type: Object,
      required: true,
    },
    afficher: {
      type: String,
      required: false,
      default: 'Employe',
    },
    dense: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selectedMembres: [],
      menuVisible: false,
    }
  },
  computed: {
    ...mapGetters({
      isInRole: 'security/isInRole',
      getEmployeById: 'employe/getEmployeById',
      getServiceById: 'service/getServiceById',
      getDateOfISOWeek: 'calendrier/getDateOfISOWeek',
    }),
    selectedMembresId: {
      get() {
        return this.selectedMembres.map((m) => m.membreId)
      },
      set(_newSelectedMembreIds) {
        //1. On retire les membres qui sont dans la liste des séléctionnés mais pas dans la liste passées en paramètre
        this.selectedMembres = this.selectedMembres.filter((m) => _newSelectedMembreIds.includes(m.membreId))

        //2. On ajoute les membres qui ne sont pas encore dans la liste mais qui sont dans la liste passées en paramètre
        _newSelectedMembreIds.forEach((membreId) => {
          let newSelectedMembre = this.membresSelectionnables.find((membre) => membre.membreId === membreId)
          if (this.neContientPasLeMembreId(this.selectedMembres, membreId) && newSelectedMembre) {
            this.selectedMembres.push(newSelectedMembre)
          }
        })
      },
    },
    employe() {
      return this.membre.employe
    },
    defaultDate() {
      return this.getDateOfISOWeek(this.semaine.annee, this.semaine.semaine)
    },
    label() {
      if (this.afficher === 'Employe') {
        return this.employe.nomComplet
      } else {
        return this.getServiceById(this.membre.serviceId).nom
      }
    },
  },
  created() {
    this.selectedMembres = this.value
  },
  watch: {
    selectedMembres() {
      this.$emit('input', this.selectedMembres)
    },
    value() {
      this.selectedMembres = this.value
    },
  },
  methods: {
    contientLeMembreId(_tableauDesMembres, _idATrouver) {
      for (const membre of _tableauDesMembres) {
        if (parseInt(membre.membreId) === parseInt(_idATrouver)) {
          return true
        }
      }

      return false
    },
    neContientPasLeMembreId(_tableauDesMembres, _idATrouver) {
      for (const membre of _tableauDesMembres) {
        if (parseInt(membre.membreId) === parseInt(_idATrouver)) {
          return false
        }
      }

      return true
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

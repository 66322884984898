<template>
  <v-form ref="form" v-model="formValid">
    <v-container>
      <v-row dense>
        <!-- Selection de membre -->
        <v-col cols="12">
          <service-membre-autocomplete
            v-model="membreId"
            :serviceId="parseInt(this.$route.params.serviceId, 10)"
            :disabled="disabled"
            titulaires
            vacataires
          />
        </v-col>
        <v-col cols="12">
          <s-date-picker
            v-model="creneau.date"
            label="Date"
            required
            :disabled="!editableDate || disabled"
            hide-details
          />
        </v-col>
        <!-- Timepicker Heure de début -->
        <v-col cols="12" sm="6">
          <s-borne-horaire-picker
            v-model="creneau.heureDebut"
            label="Début"
            :borneMax="creneau.heureFin"
            :disabled="disabled"
            required
          />
        </v-col>
        <!-- Timepicker Heure de fin -->
        <v-col cols="12" sm="6">
          <s-borne-horaire-picker
            v-model="creneau.heureFin"
            label="Fin"
            :borneMin="creneau.heureDebut"
            :disabled="disabled"
            required
          />
        </v-col>
      </v-row>
    </v-container>
    <small v-if="!disabled">*information obligatoire</small>
    <v-card-actions v-if="!disabled">
      <v-spacer />
      <v-btn color="grey darken-1" text @click="close()"> Annuler </v-btn>
      <v-btn color="success" :disabled="!formValid" @click="validateForm()"> Confirmer </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import ServiceMembreAutocomplete from '../service/service-membre-autocomplete'
import { mapGetters } from 'vuex'

export default {
  name: 'creneau-form',
  components: {
    ServiceMembreAutocomplete,
  },
  props: {
    value: {
      type: Object,
      required: false,
    },
    membres: {
      type: Array,
      required: true,
    },
    editableDate: {
      type: Boolean,
      required: false,
      default: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      menu: false,
      formValid: false,
      creneau: undefined,
    }
  },
  computed: {
    ...mapGetters({
      getMembreById: 'membre/getMembreById',
    }),
    membreId: {
      get() {
        if (this.creneau.membre) {
          return this.creneau.membre.membreId
        } else {
          return this.creneau.membreId
        }
      },
      set(_membreId) {
        this.creneau.membreId = _membreId
      },
    },
  },
  created() {
    this.creneau = JSON.parse(JSON.stringify(this.value))
  },
  watch: {
    creneau() {
      this.$emit('input', this.creneau)
    },
    menu() {
      if (this.menu === false) {
        this.$refs.form.reset()
      }
    },
  },
  methods: {
    validateForm() {
      this.$refs.form.validate()
      if (this.formValid) {
        this.$emit('validated', this.creneau)
      }
    },
    close() {
      this.$emit('close')
      this.menu = false
    },
  },
}
</script>

<style scoped></style>

<template>
  <v-dialog v-model="showDialog" persistent max-width="800px">
    <template v-slot:activator="{ on }">
      <v-list-item v-on="on">
        <v-list-item-icon>
          <v-icon color="info">fa-calendar-times</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Demande de Congés</v-list-item-title>
      </v-list-item>
    </template>
    <v-card v-if="showDialog">
      <s-toolbar text="Demande de congés" create @close="close()" />
      <v-card-text>
        <conges-form v-model="demandeConges" create @updateFormValid="updateFormValid" />
        <v-textarea
          v-model="demandeConges.commentaire"
          rows="2"
          auto-grow
          clearable
          clear-icon="mdi-close-circle"
          label="Commentaire"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="grey darken-1" text @click="close()">Annuler</v-btn>
        <v-btn color="success" :disabled="!formValid" @click="validate()"> Créer </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CongesForm from '../../conges/conges-form'
import { mapActions } from 'vuex'

export default {
  name: 'demande-conges-create-dialog',
  components: {
    CongesForm,
  },
  props: {
    employe: {
      type: Object,
      required: false,
      default: undefined,
    },
    dateDebut: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      showDialog: false,
      formValid: false,
      demandeConges: undefined,
    }
  },
  created() {
    this.initialize()
  },
  watch: {
    value() {
      this.showDialog = this.value
    },
  },
  methods: {
    ...mapActions({
      createDemandeConges: 'demande/createDemandeConges',
    }),
    initialize() {
      this.demandeConges = {
        employeId: this.employe ? this.employe.employeId : undefined,
        dateDebut: this.dateDebut ? this.dateDebut : new Date().toISOString().split('T')[0],
        dateFin: this.dateDebut ? this.dateDebut : new Date().toISOString().split('T')[0],
        type: undefined,
        commentaire: undefined,
      }
    },
    updateFormValid(_validity) {
      this.formValid = _validity
    },
    validate() {
      if (this.formValid) {
        this.createDemandeConges(this.demandeConges)
        this.close()
      }
    },
    close() {
      this.initialize()
      this.showDialog = false
    },
  },
}
</script>

<style scoped></style>

<template>
  <s-main-panel color="white">
    <div class="modele-planning">
      <div class="modele-planning-header">
        <!-- espacement pour l'affichage des heures à gauche du composant -->
        <div class="modele-planning-header-interval" />
        <modele-planning-header-semaine
          v-for="semaine in selectedModeleSemaines"
          :key="semaine.ordre"
          :semaine="semaine"
          :membres="membres"
          :shortLabel="shortLabel"
        />
        <!-- espacement pour l'affichage des heures à droite du composant -->
        <div class="modele-planning-header-interval" />
      </div>
      <div class="modele-planning-body">
        <perfect-scrollbar class="modele-planning-body-scrollable">
          <div class="modele-planning-pane">
            <modele-planning-body-interval :jour_nuit="jour_nuit" position="left" :slot-height="slotHeight" />
            <div class="modele-planning-body-semaine" v-for="semaine in selectedModeleSemaines" :key="semaine.ordre">
              <modele-planning-body
                :jour-nuit="jour_nuit"
                v-for="jour in jours"
                :key="jour.jourId"
                :jour="jour"
                :semaine="semaine"
                :membres="membres"
                :selectedMembre="selectedMembre"
                :slot-height="slotHeight"
              />
            </div>
            <modele-planning-body-interval :jour_nuit="jour_nuit" position="right" :slot-height="slotHeight" />
          </div>
        </perfect-scrollbar>
      </div>
    </div>
    <modele-planning-creneau-create>
      <v-btn absolute dark fab bottom right class="mb-10" color="success">
        <v-icon large>fa-plus</v-icon>
      </v-btn>
    </modele-planning-creneau-create>
  </s-main-panel>
</template>

<script>
import { mapGetters } from 'vuex'
import ModelePlanningCreneauCreate from '@/components/modelePlanning/creneau/modele-creneau-create-dialog.vue'
import ModelePlanningHeaderSemaine from '@/components/modelePlanning/modele-planning-header-semaine.vue'
import SMainPanel from '@/components/global/s-main-panel.vue'
import ModelePlanningBodyInterval from '@/components/planning/body/planning-body-interval.vue'
import ModelePlanningBody from '@/components/modelePlanning/body/modele-planning-body.vue'

export default {
  name: 'modele-planning',
  components: {
    ModelePlanningBody,
    ModelePlanningBodyInterval,
    SMainPanel,
    ModelePlanningHeaderSemaine,
    ModelePlanningCreneauCreate,
  },
  props: {
    jour_nuit: {
      type: String,
      required: true,
    },
    selectedModeleSemaines: {
      type: Array,
      required: true,
    },
    membres: {
      type: Array,
      required: true,
    },
    selectedMembre: undefined,
  },
  computed: {
    ...mapGetters({
      jours: 'calendrier/getJours',
    }),
    shortLabel() {
      return this.selectedModeleSemaines.length >= 2
    },
    slotHeight() {
      return parseInt(
        this.affichageImpression === true ? process.env.VUE_APP_PRINT_SLOT_HEIGHT : process.env.VUE_APP_SLOT_HEIGHT
      )
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.modele-planning {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;

  overflow: hidden;
}

.modele-planning-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
}

.modele-planning-header-interval {
  width: 40px !important;
  min-width: 40px !important;
  max-width: 40px !important;
}

.modele-planning-body {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.modele-planning-body-scrollable {
  display: flex;
  flex-direction: column;
}

.modele-planning-pane {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
}

.modele-planning-body-semaine {
  display: flex;
  flex-direction: row;
  flex: 1 1 60%;
  position: relative;
}
</style>

<template>
  <v-dialog v-model="showDialog" persistent width="600px">
    <template v-slot:activator="{ on }">
      <v-btn v-if="mode === 'fab'" absolute dark fab bottom right class="mb-10" color="success" v-on="on">
        <v-icon large>fa-plus</v-icon>
      </v-btn>
      <v-list-item v-else-if="mode === 'list'" v-on="on">
        <v-list-item-icon>
          <v-icon color="success">fa-clock</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Heures complémentaires</v-list-item-title>
      </v-list-item>
    </template>
    <v-card v-if="showDialog">
      <s-toolbar text="Ajouter des heures complémentaires" create @close="close()" />
      <v-card-text>
        <v-container>
          <v-row v-if="loaded">
            <v-col cols="12">
              <creneau-form v-model="creneau" :membres="membres" @close="close()" @validated="sendCreateCreneau()" />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import CreneauForm from './creneau-form'
import { mapActions, mapGetters } from 'vuex'
import SToolbar from '@/components/global/s-toolbar.vue'

export default {
  name: 'creneau-create-dialog',
  components: {
    SToolbar,
    CreneauForm,
  },
  props: {
    date: {
      type: String,
      required: true,
    },
    membreId: {
      type: Number,
      required: false,
      default: undefined,
    },
    mode: {
      type: String,
      required: false,
      default: 'fab',
    },
  },
  data() {
    return {
      showDialog: false,
      creneau: {
        creneauId: 0,
        date: undefined,
        heureDebut: undefined,
        heureFin: undefined,
        membreId: undefined,
      },
    }
  },
  computed: {
    ...mapGetters({
      getMembresByServiceId: 'membre/getMembresByServiceId',
      getMembreById: 'membre/getMembreById',
    }),
    membres() {
      return this.getMembresByServiceId(parseInt(this.$route.params.serviceId, 10))
    },
    loaded() {
      return this.membres
    },
  },
  created() {
    this.creneau.date = this.date
    this.creneau.membre = this.getMembreById(this.membreId)
  },
  methods: {
    ...mapActions({
      createCreneauDeTravail: 'servicePlanning/createCreneauDeTravail',
    }),
    sendCreateCreneau() {
      this.createCreneauDeTravail(this.creneau)
      this.close()
    },
    close() {
      this.showDialog = false
    },
  },
}
</script>

<style scoped></style>

<template>
  <v-dialog v-model="visible" persistent max-width="600px">
    <template v-slot:activator="{ on }">
      <v-btn color="red" fab v-on="on" small><v-icon small color="white">fa-trash</v-icon></v-btn>
    </template>
    <v-card>
      <v-card-title class="headline">{{ dialogTitle }}</v-card-title>
      <v-card-text>{{ dialogMessage }}</v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="grey darken-1" text @click="visible = false">Annuler</v-btn>
        <v-btn color="red darken-1" dark @click="emitDelete()">{{ buttonTitle }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 's-delete-dialog',
  props: {
    buttonTitle: {
      type: String,
      required: false,
      default: 'Supprimer',
    },
    dialogTitle: {
      type: String,
      required: false,
      default: 'Suppression',
    },
    dialogMessage: {
      type: String,
      required: false,
      default: 'Etes-vous sûr de vouloir supprimer cet objet ?',
    },
  },
  data() {
    return {
      visible: false,
    }
  },
  methods: {
    emitDelete() {
      this.visible = false
      this.$emit('delete')
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>

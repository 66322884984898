<template>
  <s-page v-if="loaded">
    <template v-slot:lateral-panel>
      <s-lateral-panel :consistent="false">
        <v-container class="pb-0">
          <div class="header">
            <span class="title">Planning</span>
            <v-spacer />
          </div>
        </v-container>
        <v-container class="pt-0" color="white" v-if="selectedSemaines[0]">
          <membres-checkbox-group
            v-for="group in orderedMembresGroups"
            :key="group.serviceId"
            v-model="selectedMembres"
            :group-title="group.nom"
            :membres="group.membres"
            :semaine="selectedSemaines[0]"
            :afficher="group.serviceId === 0 ? 'Service' : 'Employe'"
            :expand="group.serviceId === 0"
            dense
          />
        </v-container>
      </s-lateral-panel>
    </template>
    <template v-slot:main-panel>
      <s-main-panel color="white">
        <planning-week-picker v-model="selectedSemaines" :display-jours-de-repos="afficherJourDeRepos" />
        <planning-body
          :creneaux="creneaux"
          :jours-de-repos="[]"
          :semaines="selectedSemaines"
          :membres="sortedSelectedMembres"
          jour_nuit="jour"
          :display-jours-de-repos="afficherJourDeRepos"
          :affichage-impression="false"
        />
      </s-main-panel>
    </template>
  </s-page>
</template>

<script>
import membresCheckboxGroup from '../../components/membre/membre-checkbox-group'
import planningBody from '../../components/planning/planning'
import planningWeekPicker from '../../components/planning/semainePicker/planning-semaine-picker'
import { mapGetters } from 'vuex'
import { initialDataLoaded } from '../../store/modules/helpers'
import SMainPanel from '@/components/global/s-main-panel.vue'
import SPage from '@/components/global/s-page.vue'
import SLateralPanel from '@/components/global/s-lateral-panel.vue'

export default {
  name: 'planning',
  components: {
    SLateralPanel,
    SPage,
    SMainPanel,
    membresCheckboxGroup,
    planningBody,
    planningWeekPicker,
  },
  data() {
    return {
      selectedMembres: [],
      selectedSemaines: [],
      afficherJourDeRepos: false,
    }
  },
  computed: {
    ...initialDataLoaded,
    ...mapGetters({
      getISOWeekOfDate: 'calendrier/getISOWeekOfDate',
      user: 'security/getAuthenticatedUser',
      getCreneaux: 'planning/getCreneaux',
      getEmployeByUtilisateurId: 'employe/getEmployeByUtilisateurId',
      getServiceById: 'service/getServiceById',
      getMembreById: 'membre/getMembreById',
    }),
    loaded() {
      return initialDataLoaded && this.getCreneaux && this.sortedSelectedMembres
    },
    authenticatedEmploye() {
      return this.getEmployeByUtilisateurId(this.user.id)
    },
    creneaux() {
      return this.getCreneaux().filter((creneau) =>
        this.selectedSemaines.map((semaine) => semaine.numero).includes(this.getISOWeekOfDate(creneau.date).numero)
      )
    },
    membres() {
      let membres = []
      this.creneaux.forEach((c) => {
        if (this.neContientPasLeMembreId(membres, c.membre.membreId)) {
          membres.push(c.membre)
        }
      })

      return membres
    },
    orderedMembresGroups() {
      let m, g
      let membresTemp = [...this.membres]
      let authenticatedUserMembres = []
      let groups = []

      while ((m = membresTemp.shift()) !== undefined && this.authenticatedEmploye) {
        if (m.employe.employeId === this.authenticatedEmploye.employeId) {
          authenticatedUserMembres.push(m)
        } else {
          if ((g = groups.find((g) => g.serviceId === m.serviceId)) !== undefined) {
            g.membres.push(m)
          } else {
            groups.push({ serviceId: m.serviceId, membres: [m], nom: this.getServiceById(m.serviceId).nom })
          }
        }
      }

      groups.unshift({ serviceId: 0, membres: authenticatedUserMembres, nom: 'Moi' })
      groups.forEach((g) => g.membres.sort((mA, mB) => mA - mB))

      return groups.sort((gA, gB) => gA.serviceId - gB.serviceId)
    },
    sortedSelectedMembres() {
      return this.orderedMembresGroups
        .map((g) => g.membres)
        .flat()
        .filter((m) => this.contientLeMembreId(this.selectedMembres, m.membreId))
    },
  },
  watch: {
    membres() {
      if (this.membres[0] && this.selectedMembres.length === 0) {
        this.selectedMembres = [...this.membres.filter((m) => m.employe.utilisateur.email === this.user.username)]
      }
    },
  },
  methods: {
    contientLeMembreId(_tableauDesMembres, _idATrouver) {
      for (const membre of _tableauDesMembres) {
        if (parseInt(membre.membreId) === parseInt(_idATrouver)) {
          return true
        }
      }

      return false
    },
    neContientPasLeMembreId(_tableauDesMembres, _idATrouver) {
      for (const membre of _tableauDesMembres) {
        if (parseInt(membre.membreId) === parseInt(_idATrouver)) {
          return false
        }
      }

      return true
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

<template>
  <v-card class="surface-lighten-2">
    <v-toolbar flat dense class="primary-lighten-3">
      <v-toolbar-title class="text-h6">
        <slot name="titre"></slot>
      </v-toolbar-title>
      <v-spacer />
      <slot name="actions"></slot>
    </v-toolbar>

    <div class="top primary-lighten-5">
      <slot name="top"></slot>
      <v-divider></v-divider>
    </div>
    <v-container class="fixed-height pa-0">
      <slot name="default"></slot>
    </v-container>
    <div class="bottom">
      <v-divider></v-divider>
      <slot name="bottom"></slot>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    isExtented: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
.primary-lighten-3 {
  background-color: var(--v-primary-lighten3) !important;
}
.primary-lighten-5 {
  background-color: var(--v-primary-lighten5) !important;
}
.v-card {
  height: 100% !important;
}
.bottom {
  position: relative;
  bottom: 0;
}
</style>

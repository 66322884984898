<template>
  <v-dialog v-model="showDialog" max-width="1000px">
    <template v-slot:activator="{ on }">
      <v-btn class="mr-2" color="success" fab outlined x-small v-on="on">
        <v-icon color="success">fa-people-arrows</v-icon>
      </v-btn>
    </template>
    <v-card v-if="loaded">
      <s-toolbar :text="'Remplacer' + employeRemplace.nomComplet" create @close="close()" />
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-card-text>
              <contrat-de-remplacement-form
                v-model="createContratDeRemplacementCommand"
                :employeRemplace="employeRemplace"
                @formValidity="(v) => (this.contratFormValid = v)"
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="grey darken-1" text @click="showDialog = false">Annuler</v-btn>
              <v-btn color="success" :disabled="!contratFormValid" @click="dispatchCommand()">Créer</v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import contratDeRemplacementForm from './contrat-de-remplacement-form'
import { mapActions } from 'vuex'

export default {
  name: 'contrat-de-remplacement-create-dialog',
  components: {
    contratDeRemplacementForm,
  },
  props: {
    employeRemplace: {
      type: Object,
      required: true,
    },
    conges: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDialog: false,
      createContratDeRemplacementCommand: undefined,
      contratFormValid: false,
    }
  },
  computed: {
    loaded() {
      return this.createContratDeRemplacementCommand
    },
  },
  created() {
    this.initialiserCommand()
  },
  methods: {
    ...mapActions({
      createContratDeRemplacement: 'employe/createContratDeRemplacement',
    }),
    close() {
      this.showDialog = false
      this.initialiserCommand()
    },
    initialiserCommand() {
      this.createContratDeRemplacementCommand = {
        type: undefined,
        dateDebut: this.conges.dateDebut,
        dateFin: this.conges.dateFin,
        remplacantId: undefined,
        remplaceId: this.employeRemplace.employeId,
      }
    },
    dispatchCommand() {
      this.createContratDeRemplacement(this.createContratDeRemplacementCommand)
      this.close()
    },
  },
}
</script>

<style scoped></style>

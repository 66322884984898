<template>
  <s-main-panel v-if="loaded" :scrollbar="true">
    <v-container fluid>
      <v-row>
        <v-col :cols="hasVerticalSlot ? 9 : 12">
          <v-row>
            <v-col cols="12">
              <v-card>
                <v-card-title v-text="titre" />
                <v-card-subtitle v-text="sousTitre" />
                <slot name="demande" />
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    v-if="
                      (utilisateur.utilisateurId === demande.createur.utilisateurId || isInRole('Administrateur')) &&
                      demande.statut.demandeStatutId === 1
                    "
                    @click="deleteDemande"
                    color="error"
                    v-text="'Annuler'"
                  />
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="12" class="pt-0">
              <v-card>
                <v-card-title v-text="'Validations'" />
                <v-card-text>
                  <demande-validation-list-item
                    v-for="(validation, index) in demande.validations"
                    :key="index"
                    :validation="validation"
                    :demande="demande"
                  />
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" class="pt-0">
              <v-card>
                <v-card-title v-text="'Commentaires'" />
                <v-card-text>
                  <div class="demande-detail-nouveau-commentaire">
                    <v-textarea
                      v-model="nouveauCommentaire"
                      background-color="primary lighten-5"
                      filled
                      auto-grow
                      rows="1"
                      hide-details
                    />
                    <v-btn
                      @click="posterCommentaire()"
                      :disabled="nouveauCommentaire === undefined || nouveauCommentaire === ''"
                      color="info"
                      fab
                      x-small
                      class="ml-3"
                    >
                      <v-icon color="white">mdi-send</v-icon>
                    </v-btn>
                  </div>
                  <v-list>
                    <template v-for="(commentaire, index) in demande.commentaires">
                      <demande-commentaire-list-item :key="index + 1" :commentaire="commentaire" :demande="demande" />
                      <v-divider inset :key="-(index + 1)" />
                    </template>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-if="hasVerticalSlot" cols="3" class="pl-0">
          <v-card>
            <slot name="vertical" />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </s-main-panel>
</template>

<script>
import DemandeValidationListItem from '../../components/demande/demande-validation'
import DemandeCommentaireListItem from '../../components/demande/demande-commentaire-list-item'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'demande-detail',
  components: {
    DemandeValidationListItem,
    DemandeCommentaireListItem,
  },
  data() {
    return {
      nouveauCommentaire: undefined,
    }
  },
  computed: {
    ...mapGetters({
      utilisateur: 'security/getUtilisateur',
      getDemandeById: 'demande/getDemandeById',
      formatDateToDisplay: 'calendrier/formatDateToDisplay',
      isInRole: 'security/isInRole',
    }),
    loaded() {
      return this.demande !== undefined
    },
    demande() {
      return this.getDemandeById(parseInt(this.$route.params.demandeId, 10))
    },
    titre() {
      let lettresAApostrophe = ['a', 'e', 'i', 'o', 'u', 'y', 'h', 'A', 'E', 'I', 'O', 'U', 'Y', 'H']

      if (lettresAApostrophe.includes(this.demande.typeDemande.charAt(0))) {
        return "Demande d'" + this.demande.typeDemande
      } else {
        return 'Demande de ' + this.demande.typeDemande
      }
    },
    sousTitre() {
      return (
        'créé par ' +
        this.demande.createur.nomComplet +
        ', pour ' +
        this.demande.employe.utilisateur.nomComplet +
        ', le ' +
        this.formatDateToDisplay(this.demande.dateDemande)
      )
    },
    hasVerticalSlot() {
      return !!this.$slots['vertical']
    },
  },
  watch: {
    demande() {
      this.nouveauCommentaire = undefined
    },
  },
  methods: {
    ...mapActions({
      createCommentaire: 'demande/createCommentaire',
      dispatchDeleteDemande: 'demande/deleteDemande',
    }),
    posterCommentaire() {
      this.createCommentaire({ demandeId: this.demande.demandeId, commentaire: this.nouveauCommentaire })
      this.nouveauCommentaire = undefined
    },
    deleteDemande() {
      this.dispatchDeleteDemande(this.demande.demandeId)
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.demande-detail-nouveau-commentaire {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
</style>

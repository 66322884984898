<template>
  <div :class="classes">
    <v-card-title v-if="toolbar">
      {{ title }}
      <v-spacer v-if="title" />
      <slot name="actions"></slot>
    </v-card-title>
    <perfect-scrollbar v-if="scrollbar">
      <slot />
    </perfect-scrollbar>
    <slot v-else />
  </div>
</template>

<script>
export default {
  name: 's-main-panel',
  props: {
    title: {
      type: String,
      required: false,
      default: undefined,
    },
    toolbar: {
      type: Boolean,
      required: false,
      default: false,
    },
    scrollbar: {
      type: Boolean,
      required: false,
      default: false,
    },
    color: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  computed: {
    classes() {
      let classes = 's-main-panel '

      if (this.color) {
        classes += this.color
      } else {
        classes += 'primary lighten-5'
      }

      return classes
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.s-main-panel {
  display: flex;
  flex-direction: column;

  height: 100%;
  max-height: 100%;

  /*box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  background-color: #f0f0f8;*/

  flex: 2;
  overflow: hidden;
}
</style>

<template>
  <div class="s-page">
    <slot name="lateral-panel" />
    <slot name="main-panel" />
  </div>
</template>

<script>
export default {
  name: 's-page',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.s-page {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;

  height: 100%;
  max-height: 100%;
}
</style>

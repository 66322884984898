<template>
  <v-autocomplete
    v-model="selectedService"
    :items="listServices"
    hide-no-data
    hide-selected
    item-text="nom"
    :label="label"
    :rules="required ? [(v) => !!v] : []"
    :placeholder="label"
    :clearable="true"
    :required="required"
    return-object
    :readonly="readonly"
    :dense="dense"
    :disabled="disabled"
  />
</template>

<script>
export default {
  name: 'service-autocomplete',
  data() {
    return {
      selectedService: undefined,
    }
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: undefined,
    },
    label: {
      type: String,
      required: false,
      default: 'Service',
    },
    listServices: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    dense: {
      type: Boolean,
      required: false,
      default: false,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  created() {
    this.selectedService = this.value
  },
  computed: {
    computedLabel() {
      return this.required ? this.label + '*' : this.label
    },
  },
  watch: {
    value() {
      this.selectedService = this.value
    },
    selectedService() {
      this.$emit('input', this.selectedService)
    },
  },
}
</script>

<style scoped></style>

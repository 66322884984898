<template>
  <div v-if="membre" class="planning-creneau-container-membre" :style="style">
    <creneau-de-recuperation
      v-for="creneau in creneauxDeRecuperation"
      :key="creneau.creneauId"
      :creneau="creneau"
      :borneDebut="borneDebut"
      :disabled="disabled"
      :slot-height="slotHeight"
    />
    <creneau-de-travail
      v-for="creneau in creneauxDeTravail"
      :key="creneau.creneauId"
      :creneau="creneau"
      :borneDebut="borneDebut"
      :couleur="membre.couleur"
      :indisponibilite="indisponibilite"
      :disabled="disabled"
      :slot-height="slotHeight"
    />
    <creneau-heures-supplementaires
      v-for="(creneau, index) in creneauxHeuresSupplementaires"
      :key="index"
      :creneau="creneau"
      :borneDebut="borneDebut"
      :indisponibilite="indisponibilite"
      :disabled="disabled"
      :slot-height="slotHeight"
    />
    <creneau-de-formation
      v-for="creneau in creneauxDeFormation"
      :key="creneau.creneauId"
      :creneau="creneau"
      :borneDebut="borneDebut"
      :indisponibilite="indisponibilite"
      :disabled="disabled"
      :slot-height="slotHeight"
    />
  </div>
</template>

<script>
import creneauDeTravail from './creneau-de-travail'
import creneauHeuresSupplementaires from './creneau-heures-supplementaires'
import creneauDeFormation from './creneau-de-formation'
import creneauDeRecuperation from './creneau-de-recuperation'
import { mapGetters } from 'vuex'
import Color from 'color'

export default {
  name: 'planning-creneau-container-membre',
  components: {
    creneauDeTravail,
    creneauHeuresSupplementaires,
    creneauDeFormation,
    creneauDeRecuperation,
  },
  props: {
    creneaux: {
      type: Array,
      required: true,
    },
    jourDeRepos: {
      type: Object,
      required: false,
      default: undefined,
    },
    semaine: {
      type: Object,
      required: true,
    },
    jour: {
      type: Object,
      required: true,
    },
    borneDebut: {
      type: Object,
      required: true,
    },
    borneFin: {
      type: Object,
      required: true,
    },
    membre: {
      type: Object,
      required: true,
    },
    displayJoursDeRepos: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    slotHeight: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getMembreById: 'membre/getMembreById',
    }),
    creneauxDeTravail() {
      return this.creneaux.filter((c) => c.type === 'CreneauDeTravail')
    },
    creneauxDeRemplacement() {
      return this.creneaux.filter((c) => c.type === 'CreneauDeRemplacement')
    },
    creneauxDeFormation() {
      return this.creneaux.filter((c) => c.type === 'CreneauDeFormation')
    },
    creneauxHeuresSupplementaires() {
      return this.creneaux.filter((c) => c.type === 'CreneauHeuresSupplementaires')
    },
    creneauxDeRecuperation() {
      return this.creneaux.filter((c) => c.type === 'CreneauDeRecuperation')
    },
    couleur() {
      return this.membre.couleur
    },
    couleurRayureClaire() {
      return Color(this.membre.couleur).lightness(95).hex()
    },
    couleurRayureSombre() {
      return Color(this.membre.couleur).lightness(90).hex()
    },
    style() {
      let style = {}
      //Si c'est un jour de repos plein et qu'on doit afficher les jours de repos OU une absence/indisponibilité
      if ((this.displayJoursDeRepos && this.jourDeRepos && !this.jourDeRepos.demiJour) || this.indisponibilite) {
        style.background = `repeating-linear-gradient(
          45deg,
          ${this.couleurRayureSombre},
          ${this.couleurRayureSombre} 8px,
          ${this.couleurRayureClaire} 8px,
          ${this.couleurRayureClaire} 16px)`
      }

      //Si c'est un jour de repos (demi ou non) et qu'on doit afficher les jours de repos
      if (this.displayJoursDeRepos && this.jourDeRepos && !this.indisponibilite) {
        style.borderColor = this.membre.couleur
        style.color = this.membre.couleur
        style.borderStyle = 'double'
        style.borderWidth = '4px'
      }

      return style
    },
    employe() {
      return this.membre.employe
    },
    indisponibilite() {
      return this.employe.indisponibilites.find(
        (i) =>
          i.jour.jourId === this.jour.jourId &&
          i.semaine.semaine === this.semaine.semaine &&
          i.semaine.annee === this.semaine.annee
      )
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.planning-creneau-container-membre {
  position: relative;
  display: block;
  flex: 1;
  pointer-events: none;
}
</style>

<script setup>
import SInfoMontant from '@/components/global/s-info-montant.vue'
import DetailOperationLigneAim from '@/components/fiche-aim/ligne/detail-operation-ligne-aim.vue'
</script>

<template>
  <v-expansion-panel :class="expansionPanelBgColor">
    <v-expansion-panel-header class="py-0">
      <v-row no-gutters align="center">
        <v-col cols="4" align-self="center">
          <v-icon class="pr-2" :color="iconeColor">{{ icone }}</v-icon>
          {{ ligneFicheAim.poste.libelle }}
        </v-col>
        <v-col cols="8" class="text--secondary">
          <v-row no-gutters style="width: 100%" align="center">
            <v-col class="text-right pr-6" cols="4">
              <s-info-montant :montant="getMontant(ligneFicheAim.poste.posteAIMId)"></s-info-montant>
            </v-col>
            <v-col class="text-right pr-6" cols="4">
              <s-info-montant :montant="ligneFicheAim.montantInitial"></s-info-montant>
            </v-col>
            <v-col class="text-right pr-6" cols="4">
              <s-info-montant :montant="ligneFicheAim.montantFinal"></s-info-montant>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <detail-operation-ligne-aim
        :accent="estConcerneParSelection || estConcerneParSurvol"
        :ligne-fiche-aim="ligneFicheAim"
      />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'fiche-aim-usager-expension-panel',
  props: {
    ligneFicheAim: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getIconePourPoste: 'posteAIM/getIconePourPosteAimId',
      estAjustementConcerneParAjustementSurvole: 'renduAIM_IHM_helper/estAjustementConcerneParAjustementSurvole',
      estAjustementConcerneParAjustementSelectionne:
        'renduAIM_IHM_helper/estAjustementConcerneParAjustementSelectionne',
    }),
    estConcerneParSurvol() {
      return this.ligneFicheAim.operations.some((op) => this.estAjustementConcerneParAjustementSurvole(op.ajustementId))
    },
    estConcerneParSelection() {
      return this.ligneFicheAim.operations.some((op) =>
        this.estAjustementConcerneParAjustementSelectionne(op.ajustementId)
      )
    },
    icone() {
      return this.getIconePourPoste(this.ligneFicheAim.poste.posteAIMId)
    },
    iconeColor() {
      return this.estConcerneParSurvol || this.estConcerneParSelection ? 'secondary' : 'primary'
    },
    expansionPanelBgColor() {
      if (this.estConcerneParSelection) {
        return 'bg-secondary-lighten-4'
      } else if (this.estConcerneParSurvol) {
        return 'bg-secondary-lighten-5'
      } else {
        return 'bg-surface-lighten-2'
      }
    },
    texteLabelOperations() {
      if (this.ligneFicheAim.operations.length === 0) return "Pas d'opérations"
      if (this.ligneFicheAim.operations.length > 1) return this.ligneFicheAim.operations.length + ' opérations'
      return this.ligneFicheAim.operations.length + ' opération'
    },
    labelOperationsColors() {
      if (this.ligneFicheAim.operations.length === 0) return ''
      return 'bg-primary-lighten-2'
    },
  },
  methods: {
    getMontant(_posteId) {
      switch (_posteId) {
        case 'HABILLEMENT':
          return 120
        case 'EPARGNE':
          return 120
        case 'ARGENT_DE_POCHE':
          return -20
        default:
          return 0
      }
    },
  },
}
</script>

<style scoped>
.v-expansion-panel-header {
  min-height: 38px;
  padding: 0px 12px;
}
</style>

<template>
  <v-menu v-if="isInRole('Administrateur')" tile offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-on="on" v-bind="attrs" text tile color="white"> Administration </v-btn>
    </template>
    <v-list class="py-0">
      <v-list-item-group color="primary">
        <v-list-item :to="{ name: 'associations' }">
          <v-list-item-icon><v-icon color="primary">fa-building</v-icon></v-list-item-icon>
          <v-list-item-title>Associations</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'etablissements' }">
          <v-list-item-icon><v-icon color="primary">fa-home</v-icon></v-list-item-icon>
          <v-list-item-title>Établissements</v-list-item-title>
        </v-list-item>
        <v-list-item @click="simulerCongesCreditAnnuel()">
          <v-list-item-icon><v-icon color="primary">fa-calendar</v-icon></v-list-item-icon>
          <v-list-item-title>Simuler congés annuels</v-list-item-title>
        </v-list-item>
        <v-list-item @click="crediterCongesAnnuels()">
          <v-list-item-icon><v-icon color="primary">fa-plus</v-icon></v-list-item-icon>
          <v-list-item-title>Créditer les congés annuels</v-list-item-title>
        </v-list-item>
        <v-list-item @click="crediterCongesTrimestriels()">
          <v-list-item-icon><v-icon color="primary">fa-plus</v-icon></v-list-item-icon>
          <v-list-item-title>Créditer les congés du trimestre</v-list-item-title>
        </v-list-item>
        <v-list-item @click="openAclRefreshDialog()">
          <v-list-item-icon><v-icon color="primary">fa-cogs</v-icon></v-list-item-icon>
          <v-list-item-title>Refresh Acls</v-list-item-title>
        </v-list-item>
        <v-list-item @click="updateDemandesStatut()">
          <v-list-item-icon><v-icon color="primary">fa-cogs</v-icon></v-list-item-icon>
          <v-list-item-title>Mettre à jour le statut des demandes</v-list-item-title>
        </v-list-item>
        <v-list-item @click="openEnvoyerMailTestDialog()">
          <v-list-item-icon><v-icon color="primary">fa-envelope</v-icon></v-list-item-icon>
          <v-list-item-title>Tester les mails</v-list-item-title>
        </v-list-item>
        <v-list-item @click="actualiserLesRedirectionsDastreintes()">
          <v-list-item-icon><v-icon color="primary">fa-phone</v-icon></v-list-item-icon>
          <v-list-item-title>Actualiser les redirections</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import EventBus from '../../EventBus'

export default {
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      isInRole: 'security/isInRole',
    }),
  },
  methods: {
    ...mapActions({
      updateDemandesStatut: 'admin/updateDemandesStatut',
      simulerCongesCreditAnnuel: 'conges/simulerCongesCreditAnnuel',
      crediterCongesTrimestriels: 'conges/crediterCongesTrimestriels',
      crediterCongesAnnuels: 'conges/crediterCongesAnnuels',
      actualiserLesRedirectionsDastreintes: 'astreinte/actualiserLesRedirectionsDastreintes',
    }),
    openAclRefreshDialog() {
      EventBus.$emit('showAclRefreshDialog', true)
    },
    openEnvoyerMailTestDialog() {
      EventBus.$emit('showEnvoyerMailTestDialog', true)
    },
  },
}
</script>

<style></style>

<script setup>
import SCardWithToolbar from '@/components/global/s-card-with-toolbar.vue'
</script>

<template>
  <s-card-with-toolbar>
    <template #titre>Versements</template>
  </s-card-with-toolbar>
</template>

<script>
export default {
  name: 'versements-aim',
  methods: {
    submitAjustements() {
      this.$emit('next-step')
    },
  },
}
</script>

<style>
.fixed-height {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
}
</style>

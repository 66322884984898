<template>
  <v-dialog v-model="showDialog" persistent max-width="600px" heigth="400px">
    <v-card v-if="showDialog">
      <s-toolbar :text="'Fermer le service ' + this.service.nom" delete @close="close()" />
      <v-card-text>
        <p style="text-align: justify">
          Cette action entrainera la fermeture du service. Cela aura pour effet de supprimer l'ensemble des heures de
          travail (y compris les heures complémentaires et les récupérations) ultérieures à la date choisie. Toute
          fermeture de service est <b>définitive</b>.
        </p>
        <p>Afin de confirmer cette action, veuillez choisir la date de fermeture du service.</p>
        <s-date-picker v-model="fermerUnServiceCommand.dateDeFermeture" label="Fermeture à compter du" required />
      </v-card-text>
      <v-card-actions v-if="!commandeEnvoyee">
        <v-spacer />
        <v-btn color="grey" text @click="close()">Annuler</v-btn>
        <v-btn color="error" :disabled="!formValid" @click="validate()"> Fermer </v-btn>
      </v-card-actions>
      <v-card-actions v-else>
        <v-spacer />
        <v-btn color="grey" text @click="close()">Fermer</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'fermer-service-dialog',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    service: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDialog: undefined,
      fermerUnServiceCommand: undefined,
      commandeEnvoyee: false,
    }
  },
  computed: {
    formValid() {
      return this.fermerUnServiceCommand.dateDeFermeture
    },
  },
  created() {
    this.showDialog = this.value
    this.initialize()
  },
  watch: {
    showDialog() {
      if (this.showDialog === false) {
        this.initialize()
      }
      this.$emit('input', this.showDialog)
    },
    value() {
      this.showDialog = this.value
    },
    service() {
      this.initialize()
    },
  },
  methods: {
    ...mapActions({
      fermerUnService: 'service/fermerUnService',
    }),
    initialize() {
      this.commandeEnvoyee = false
      this.fermerUnServiceCommand = {
        idDuServiceAFermer: this.service.serviceId,
        dateDeFermeture: undefined,
      }
    },
    validate() {
      this.commandeEnvoyee = true
      this.fermerUnService(this.fermerUnServiceCommand).then(() => {})
    },
    close() {
      this.showDialog = false
    },
  },
}
</script>

<style scoped></style>

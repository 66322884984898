<template>
  <s-info-modifiable
    titre="Nom du service"
    :valeur="service.nom"
    titre-action="Renommer"
    @validateUpdate="envoyerUneCommandeDeChangementDeNom"
    @cancelUpdate="annulerModification"
  >
    <template v-slot:champ>
      <v-text-field
        v-model="nomDuService"
        hide-no-data
        label="Nom du service"
        :required="true"
        @keydown.escape="$emit('cancelUpdate')"
        @keydown.enter="$emit('validateUpdate')"
      />
    </template>
  </s-info-modifiable>
</template>

<script>
import { mapActions } from 'vuex'
import SInfoModifiable from '../../../components/global/s-info-modifiable.vue'

export default {
  name: 'service-renommer',
  components: { SInfoModifiable },
  props: {
    service: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      nomDuService: undefined,
    }
  },
  created() {
    this.nomDuService = this.service.nom
  },
  watch: {
    service: {
      handler() {
        this.nomDuService = this.service.nom
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      renommerService: 'service/renommerService',
    }),
    envoyerUneCommandeDeChangementDeNom() {
      if (this.nomDuService !== this.service.nom) {
        this.renommerService({
          serviceId: this.service.serviceId,
          nouveauNom: this.nomDuService,
        })
      }
    },
    annulerModification() {
      this.nomDuService = this.service.nom
    },
  },
}
</script>

<style scoped></style>

import axios from 'axios'

export default {
  namespaced: true,
  state: {
    fichesAim: [],
    preparationDeFichesAim: [],
  },
  mutations: {
    SET_FICHES_AIMS(state, _fichesAim) {
      state.fichesAim = _fichesAim
    },
    ADD_OR_UPDATE_PREPARATION_FICHE_AIM(state, _preparationDeFichesAim) {
      const indexToUpdate = state.preparationDeFichesAim.findIndex(
        (prepa) => prepa.ficheAIMId === _preparationDeFichesAim.ficheAIMId
      )

      if (indexToUpdate >= 0) {
        state.preparationDeFichesAim.splice(indexToUpdate, 1, _preparationDeFichesAim)
      } else {
        state.preparationDeFichesAim.push(_preparationDeFichesAim)
      }
    },
    ADD_OR_UPDATE_FICHE_AIM(state, _fichesAim) {
      const indexToUpdate = state.fichesAim.findIndex((fiche) => fiche.ficheAIMId === _fichesAim.ficheAIMId)

      if (indexToUpdate >= 0) {
        state.fichesAim.splice(indexToUpdate, 1, _fichesAim)
      } else {
        state.fichesAim.push(_fichesAim)
      }
    },
  },
  getters: {
    getFicheAimByUsagerId: (state) => (_usagerId) => {
      return state.fichesAim.filter((ficheAim) => ficheAim.usager.usagerId === _usagerId)
    },
    getFicheAimById: (state) => (_ficheAimId) => {
      return state.fichesAim.find((ficheAim) => ficheAim.ficheAIMId === _ficheAimId)
    },
  },
  actions: {
    fetchFicheAimsUsagerId({ commit }, _usagerId) {
      return axios.get(`${process.env.VUE_APP_URL_BACK_SERENITY}/ficheAIM/usager/` + _usagerId).then((response) => {
        commit('SET_FICHES_AIMS', response.data)
      })
    },
    fetchFicheAimByFicheId({ commit }, _ficheAimId) {
      return axios.get(`${process.env.VUE_APP_URL_BACK_SERENITY}/ficheAIM/` + _ficheAimId).then((response) => {
        commit('ADD_OR_UPDATE_FICHE_AIM', response.data)
      })
    },
    fetchPreparationFicheAimsUsagerId({ commit }, _ficheAimId) {
      return axios
        .get(`${process.env.VUE_APP_URL_BACK_SERENITY}/ficheAIM/` + _ficheAimId + `/preparation`)
        .then((response) => {
          commit('ADD_OR_UPDATE_PREPARATION_FICHE_AIM', response.data)
        })
    },
    AjusterFicheAIM({ commit }, _ajusterFicheAimCommand) {
      return axios
        .post(
          `${process.env.VUE_APP_URL_BACK_SERENITY}/ficheAIM/` + _ajusterFicheAimCommand.ficheAIMId + `/ajuster`,
          _ajusterFicheAimCommand
        )
        .then((response) => {
          commit('ADD_OR_UPDATE_FICHE_AIM', response.data)
        })
    },
  },
}

<template>
  <s-page>
    <template v-slot:main-panel>
      <s-main-panel v-if="loaded" :scrollbar="true">
        <v-container fluid class="fixed-height detail-container">
          <v-card class="fixed-height">
            <v-toolbar color="white" flat>
              <v-toolbar-title>{{ etablissement.raisonSociale }}</v-toolbar-title>
              <template v-slot:extension>
                <v-tabs v-model="tab">
                  <v-tabs-slider color="secondary" />
                  <v-tab key="Astreintes"> Astreintes </v-tab>
                  <v-tab
                    v-if="etablissement.etablissementId !== 0 && isInRole('DIRECTEUR_' + etablissement.etablissementId)"
                    key="Administration"
                  >
                    Administration
                  </v-tab>
                </v-tabs>
              </template>
            </v-toolbar>
            <v-tabs-items v-model="tab" class="fixed-height">
              <v-tab-item key="Astreintes" class="fixed-height">
                <etablissement-detail-astreintes
                  :disabled="
                    !isInRole('ADMINISTRATEUR_' + etablissement.etablissementId) &&
                    !isInRole('ADMINISTRATEUR_' + etablissement.siegeSocialId)
                  "
                  v-if="etablissement"
                  :etablissement="etablissement"
                />
              </v-tab-item>
              <v-tab-item key="Administration" class="fixed-height">
                <etablissement-detail-admin v-if="etablissement" v-model="etablissement" />
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-container>
      </s-main-panel>
    </template>
  </s-page>
</template>

<script>
import etablissementDetailAdmin from './administration/etablissement-detail-administration.vue'
import etablissementDetailAstreintes from './etablissement-detail-astreintes.vue'
import { mapGetters } from 'vuex'
import SPage from '@/components/global/s-page.vue'
import SMainPanel from '@/components/global/s-main-panel.vue'

export default {
  name: 'etablissement-detail',
  components: {
    SMainPanel,
    SPage,
    etablissementDetailAdmin,
    etablissementDetailAstreintes,
  },
  data() {
    return {
      tab: 0,
    }
  },
  computed: {
    ...mapGetters({
      isInRole: 'security/isInRole',
      getSelectedEtablissement: 'application/getSelectedEtablissement',
    }),
    loaded() {
      return this.etablissement !== undefined
    },
    etablissement: {
      get() {
        return this.getSelectedEtablissement
      },
      set(_etablissement) {
        console.log(_etablissement)
      },
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.fixed-height {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
}
</style>

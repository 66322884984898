<template>
  <v-autocomplete
    v-model="selectedMembre"
    :items="membresItems"
    hide-no-data
    hide-details
    dense
    item-text="nomComplet"
    label="Éducateur*"
    :rules="[(v) => !!v]"
    required
    return-object
    :disabled="disabled"
  >
    <template #item="data">
      <template>
        <v-list-item-icon>
          <v-icon :color="data.item.couleur"> mdi-brightness-1 </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-html="data.item.nomComplet" />
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'service-membre-autocomplete',
  data() {
    return {
      selectedMembreId: undefined,
    }
  },
  props: {
    value: {
      type: Number,
      required: false,
      default: undefined,
    },
    serviceId: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    titulaires: {
      type: Boolean,
      required: false,
      default: false,
    },
    vacataires: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  created() {
    if (this.value) {
      this.selectedMembreId = this.value
    }
  },
  computed: {
    ...mapGetters({
      getMembresByServiceId: 'membre/getMembresByServiceId',
      isEmployeTitulaire: 'employe/isTitulaireById',
    }),
    membres() {
      return this.getMembresByServiceId(this.serviceId)
    },
    membresItems() {
      let result = []

      if (this.titulaires) {
        result.push({ header: 'Titulaires' })
        result.push(...this.membres.filter((m) => this.isEmployeTitulaire(m.employe.employeId)))
      }

      if (this.vacataires) {
        if (this.titulaires) {
          result.push({ divider: true })
        }
        result.push({ header: 'Vacataires' })
        result.push(...this.membres.filter((m) => !this.isEmployeTitulaire(m.employe.employeId)))
      }
      return result
    },
    selectedMembre: {
      get: function () {
        return this.membres.find((membre) => membre.membreId === this.selectedMembreId)
      },
      set: function (_selectedMembre) {
        this.selectedMembreId = _selectedMembre.membreId
      },
    },
  },
  watch: {
    value() {
      this.selectedMembreId = this.value
    },
    selectedMembreId() {
      this.$emit('input', this.selectedMembreId)
    },
  },
}
</script>

<style scoped></style>

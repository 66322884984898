<template>
  <v-form ref="form" v-model="formValid">
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-row>
            <!-- Selection de l'employe -->
            <v-col cols="6">
              <employe-autocomplete
                v-model="employe"
                label="Employé"
                :required="create"
                :readonly="!create"
                :etablissement="etablissement"
                :disabled="disabled"
              />
            </v-col>
            <!-- Selection du type de congés -->
            <v-col cols="6">
              <v-select
                :items="congesTypes"
                v-model="conges.type"
                item-text="label"
                item-value="type"
                label="Type de congés*"
                :rules="[(v) => !!v]"
                return-object
                :disabled="disabled"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col cols="6">
              <v-row style="height: 100%" align-content="space-between">
                <v-col cols="12">
                  <v-row dense>
                    <v-col cols="6">
                      <v-text-field
                        v-model="dateDebut"
                        :rules="[(v) => !!v]"
                        :label="dateFin ? 'Du' : 'Le'"
                        disabled
                        hide-details
                      />
                    </v-col>
                    <v-col v-if="dateFin" cols="6">
                      <v-text-field v-model="dateFin" :rules="[(v) => !!v]" label="Au" disabled hide-details />
                    </v-col>
                    <v-col v-else-if="conges.type && conges.type.congesTypeId === 7" cols="12">
                      <v-radio-group v-model="conges.demiJournee" row dense mandatory :disabled="disabled">
                        <v-radio label="Journée" :value="undefined" />
                        <v-radio label="Matin" :value="1" />
                        <v-radio label="Après-midi" :value="2" />
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col v-if="conges.type && conges.type.avecSolde && !conges.congesId" cols="12">
                  <v-row>
                    <v-col cols="4" class="d-flex flex-column align-center">
                      Solde Actuel
                      <conges-solde-chip :valeur="soldeActuel" />
                    </v-col>
                    <v-col cols="4" class="d-flex flex-column align-center">
                      Demande
                      <conges-solde-chip :valeur="-evaluationConges" />
                    </v-col>
                    <v-col cols="4" class="d-flex flex-column align-center">
                      Futur Solde
                      <conges-solde-chip :valeur="soldeFutur" />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6" class="flex">
              <!-- Selection des dates -->
              <v-date-picker
                v-model="dates"
                no-title
                :events="congesEvents"
                :allowed-dates="allowedDates"
                range
                dense
                full-width
                locale="fr"
                first-day-of-week="1"
                locale-first-day-of-year="4"
                flat
                show-week
                reactive
                :disabled="disabled"
              />
              <v-text-field v-model="dates" :rules="[(v) => !!v]" class="hidden-screen-only" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import CongesSoldeChip from '../conges/solde/conges-solde-chip'
import EmployeAutocomplete from '../employe/employe-autocomplete'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import _ from 'lodash'

export default {
  name: 'conges-form',
  components: { EmployeAutocomplete, CongesSoldeChip },
  props: {
    value: {
      type: Object,
      required: true,
    },
    create: {
      type: Boolean,
      required: false,
      default: false,
    },
    etablissement: {
      type: Object,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      formValid: false,
      conges: undefined,
      evaluationConges: undefined,
    }
  },
  computed: {
    ...mapGetters({
      formatDate: 'calendrier/formatDateToDisplay',
      isFerie: 'calendrier/isISODateFerie',
      getEmployeById: 'employe/getEmployeById',
      employes: 'employe/getEmployes',
      congesTypes: 'conges/getCongesTypes',
    }),
    employe: {
      get() {
        return this.getEmployeById(this.conges.employeId)
      },
      set(_employe) {
        if (_employe) {
          this.conges.employeId = _employe.employeId
        }
      },
    },
    dateDebut() {
      return this.formatDate(this.conges.dateDebut)
    },
    dateFin() {
      if (this.conges.dateFin && this.conges.dateDebut !== this.conges.dateFin) {
        return this.formatDate(this.conges.dateFin)
      } else {
        return undefined
      }
    },
    dates: {
      get: function () {
        let result = []
        if (this.conges) {
          if (this.conges.dateDebut) {
            result[0] = this.conges.dateDebut
          }
          if (this.conges.dateFin) {
            result[1] = this.conges.dateFin
          }
        }

        if (result.length > 0) {
          return result
        } else {
          return undefined
        }
      },
      set: function (_dates) {
        if (_dates.length > 1 && _dates[0].localeCompare(_dates[1]) > 0) {
          const temp = _dates[0]
          _dates[0] = _dates[1]
          _dates[1] = temp
        }

        if (_dates[0]) {
          this.conges.dateDebut = _dates[0]
        } else {
          this.conges.dateDebut = undefined
        }
        if (_dates[1]) {
          this.conges.dateFin = _dates[1]
        } else {
          if (_dates[0]) {
            this.conges.dateFin = _dates[0]
          }
          this.conges.dateFin = undefined
        }
      },
    },
    jourDeConges() {
      let result = []
      let date
      if (this.employe) {
        this.employe.conges.forEach((c) => {
          if (c.congesId !== this.conges.congesId) {
            date = moment(c.dateDebut)
            result.push({
              date: date.format('YYYY-MM-DD'),
              color: c.type.couleur,
            })
            while (date.isBefore(c.dateFin)) {
              date = date.add(1, 'days')
              result.push({
                date: date.format('YYYY-MM-DD'),
                color: c.type.couleur,
              })
            }
          }
        })
      }
      return result
    },
    soldeActuel() {
      return this.employe.congesSoldes.find((cs) => cs.congesType.congesTypeId === this.conges.type.congesTypeId).solde
    },
    soldeFutur() {
      if (this.soldeActuel && this.evaluationConges) {
        return this.soldeActuel - this.evaluationConges
      } else {
        return undefined
      }
    },
  },
  created() {
    this.conges = this.value
  },
  watch: {
    conges: {
      handler() {
        this.$emit('input', this.conges)
        this.evaluationConges = undefined
        if (this.conges && this.conges.type && this.conges.dateDebut) {
          this.debouncedEvaluation()
        }
      },
      deep: true,
    },
    value() {
      this.conges = this.value
    },
    formValid() {
      this.$emit('updateFormValid', this.formValid)
    },
  },
  methods: {
    ...mapActions({
      evaluerConges: 'conges/evaluerConges',
    }),
    allowedDates(_date) {
      return (
        !this.jourDeConges.find((d) => d.date === _date) || (this.conges.type && this.conges.type.congesTypeId === 4)
      )
    },
    debouncedEvaluation: _.debounce(function () {
      this.evaluerConges(this.conges).then((result) => (this.evaluationConges = result))
    }, 500),
    congesEvents(_date) {
      if (this.isFerie(_date)) {
        return 'yellow'
      } else {
        let event = this.jourDeConges.find((d) => d.date === _date)
        if (event) {
          return event.color
        } else {
          return ''
        }
      }
    },
  },
}
</script>

<style scoped></style>

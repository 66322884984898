<template>
  <v-autocomplete
    v-model="selectedEmploye"
    :items="listEmployes"
    :loading="employeResearchLoading"
    :search-input.sync="searchEmployes"
    hide-no-data
    hide-selected
    item-text="nomComplet"
    :label="computedLabel"
    :rules="[(v) => !!v]"
    placeholder="Veuillez saisir le nom de l'employé"
    :clearable="true"
    :required="required"
    :disabled="disabled"
    return-object
  />
</template>

<script>
import { mapActions } from 'vuex'
import _ from 'lodash'

export default {
  name: 'membre-employe-autocomplete',
  props: {
    value: {
      type: Object,
      required: false,
      default: undefined,
    },
    serviceId: {
      type: Number,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: 'Employé',
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selectedEmploye: undefined,
      employeResearchLoading: false,
      searchEmployes: '',
      listEmployes: [],
    }
  },
  computed: {
    computedLabel() {
      let result = this.label

      if (this.required) {
        result += '*'
      }

      return result
    },
  },
  created() {
    this.selectedEmploye = this.value
  },
  watch: {
    value() {
      this.selectedEmploye = this.value
    },
    selectedEmploye() {
      this.$emit('input', this.selectedEmploye)
    },
    searchEmployes() {
      // Items have already been requested
      if (this.searchEmployes !== null && this.searchEmployes !== '') {
        if (!this.employeResearchLoading) {
          this.employeResearchLoading = true
          // Lazily load input items
          this.debouncedSearch()
        }
      }
    },
  },
  methods: {
    ...mapActions({
      rechercheDeNouveauxMembres: 'employe/rechercheDeNouveauxMembres',
    }),
    initialize() {
      this.searchEmployes = ''
    },
    debouncedSearch: _.debounce(function () {
      this.rechercheDeNouveauxMembres({
        serviceId: this.serviceId,
        search: this.searchEmployes,
      })
        .then((result) => {
          this.listEmployes = result
        })
        .finally(() => (this.employeResearchLoading = false))
    }, 250),
  },
}
</script>

<style scoped></style>

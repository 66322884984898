<script setup></script>

<template>
  <v-list-item @dblclick="handleDbClick" @mouseenter.stop="startHovering" @mouseleave.stop="endHovering">
    <v-list-item-content>
      <v-list-item-title>{{ ajustement.explication }}</v-list-item-title>
      <v-list-item-subtitle>
        <v-icon
          class="ml-1"
          v-for="(icone, i) in iconesPostesConcernes"
          :key="i"
          small
          :color="couleursIconesPostesConcernes[i]"
          >{{ icone }}</v-icon
        >
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-row align="center" justify="end" class="mr-4">
        <v-fab-transition hide-on-leave>
          <v-btn
            fab
            class="mr-3"
            small
            v-if="(isHovered || estConcerneParSurvol) && !estConcerneParSelection"
            color="white"
            @click.stop="selectionnerAjustement"
          >
            <v-icon color="secondary">mdi-eye</v-icon>
          </v-btn>
        </v-fab-transition>
        <v-fab-transition hide-on-leave>
          <v-btn
            fab
            class="mr-3"
            small
            v-if="estConcerneParSelection"
            color="secondary"
            @click.stop="deselectionnerAjustement"
          >
            <v-icon>mdi-eye</v-icon>
          </v-btn>
        </v-fab-transition>
        <v-progress-circular v-if="loading" indeterminate></v-progress-circular>
        <v-tooltip left v-else-if="checked">
          <span v-if="readOnly">
            Vous ne pouvez pas décocher cet ajustement, cette fiche n'est plus en cours de préparation
          </span>
          <span v-else>Ne pas utiliser l'ajustement ce mois-ci</span>
          <template #activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" large color="primary" @click="switchCheck">mdi-checkbox-marked</v-icon>
          </template>
        </v-tooltip>
        <v-tooltip left v-else>
          <span v-if="readOnly">
            Vous ne pouvez pas cocher cet ajustement, cette fiche n'est plus en cours de préparation
          </span>
          <span v-else>Utiliser cet ajustement ce mois-ci</span>
          <template #activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" large @click="switchCheck">mdi-checkbox-blank-outline</v-icon>
          </template>
        </v-tooltip>
      </v-row>
    </v-list-item-action>
    <v-snackbar v-model="showReadOnlyErrorMessage" timeout="5000">
      Vous ne pouvez pas modifier cet ajustement, cette fiche n'est plus en cours de préparation
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="showReadOnlyErrorMessage = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-list-item>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ajustement-list-item',
  props: {
    ajustement: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      getIconePourPoste: 'posteAIM/getIconePourPosteAimId',
      estAjustementConcerneParAjustementSelectionne:
        'renduAIM_IHM_helper/estAjustementConcerneParAjustementSelectionne',
      estAjustementConcerneParAjustementSurvole: 'renduAIM_IHM_helper/estAjustementConcerneParAjustementSurvole',
    }),
    iconesPostesConcernes() {
      return this.ajustement.postes.map((poste) => this.getIconePourPoste(poste))
    },
    couleursIconesPostesConcernes() {
      return this.ajustement.postes.map((poste) =>
        this.ajustement.operations.find((op) => op.posteAIM === poste).montant > 0 ? 'success' : 'error'
      )
    },
    estConcerneParSelection() {
      return this.estAjustementConcerneParAjustementSelectionne(this.ajustement.ajustementId)
    },
    estConcerneParSurvol() {
      return this.estAjustementConcerneParAjustementSurvole(this.ajustement.ajustementId)
    },
  },
  data() {
    return {
      checked: true,
      loading: false,
      isHovered: false,
      showReadOnlyErrorMessage: false,
    }
  },
  methods: {
    ...mapActions({
      setAjustementSelectionne: 'renduAIM_IHM_helper/setAjustementSelectionne',
      setAjustementSurvole: 'renduAIM_IHM_helper/setAjustementSurvole',
      reinitializerAjustementSurvole: 'renduAIM_IHM_helper/reinitializerAjustementSurvole',
      reinitializerAjustementSelectionne: 'renduAIM_IHM_helper/reinitializerAjustementSelectionne',
    }),
    selectionnerAjustement() {
      this.setAjustementSelectionne(this.ajustement.ajustementId)
    },
    deselectionnerAjustement() {
      this.reinitializerAjustementSelectionne()
    },
    startHovering() {
      this.isHovered = true
      this.setAjustementSurvole(this.ajustement.ajustementId)
    },
    endHovering() {
      this.isHovered = false
      this.reinitializerAjustementSurvole()
    },
    switchCheck() {
      if (this.readOnly) {
        this.showReadOnlyErrorMessage = true
      } else {
        this.loading = true
        setTimeout(() => {
          this.checked = !this.checked
          this.loading = false
        }, 500)
      }
    },
    handleDbClick() {
      if (this.estConcerneParSelection) {
        this.deselectionnerAjustement()
      } else {
        this.selectionnerAjustement()
      }
    },
  },
}
</script>

<style scoped></style>

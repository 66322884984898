<template>
  <v-autocomplete
    v-model="selectedContratType"
    :items="selectableTypes"
    hide-no-data
    hide-selected
    item-text="libelle"
    :label="label"
    :rules="[(v) => !!v]"
    :placeholder="label"
    :clearable="true"
    required
    return-object
    :readonly="readonly"
    :disabled="disabled"
  />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'contrat-type-autocomplete',
  data() {
    return {
      selectedContratType: undefined,
    }
  },
  props: {
    value: {
      type: Object,
      required: false,
    },
    label: {
      type: String,
      required: false,
      default: 'Type de contrat*',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    remplacement: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  created() {
    this.selectedContratType = this.value
  },
  computed: {
    ...mapGetters({
      getContratTypes: 'contrat/getContratTypes',
    }),
    selectableTypes() {
      if (this.remplacement) {
        return this.getContratTypes.filter((ct) => ct.eligibleRemplacement)
      } else {
        return this.getContratTypes
      }
    },
  },
  watch: {
    selectedContratType() {
      this.$emit('input', this.selectedContratType)
    },
  },
}
</script>

<style scoped></style>

<script setup>
defineEmits(['add'])
</script>
<template>
  <v-container class="py-0">
    <v-form ref="form-op" @submit.prevent="confirmEdit">
      <v-row>
        <v-col align-self="center" cols="6" md="6" sm="12">
          <v-select
            ref="poste-input"
            v-model="tempOperation.posteAIM"
            :items="postesNonDefinits"
            :item-value="'posteAIMId'"
            :item-text="'libelle'"
            label="Poste"
            :error-messages="posteErrorMessage"
          >
          </v-select>
        </v-col>
        <v-col align-self="center" cols="4" md="4" sm="8">
          <v-text-field
            ref="montant-input"
            v-model="tempOperation.montant"
            :error-messages="montantErrorMessage"
            type="number"
            label="Montant"
          ></v-text-field>
        </v-col>
        <v-col align-self="center" class="mb-3" cols="2" md="2" sm="4">
          <v-tooltip left>
            Ajouter le poste
            <template #activator="{ on, attrs }">
              <v-btn class="mx-1" v-on="on" v-bind="attrs" color="success" type="submit"
                ><v-icon>mdi-plus</v-icon>Ajouter
              </v-btn>
            </template>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
export default {
  name: 'ajouter-operation-ajustement-form',
  data() {
    return {
      formValid: false,
      tempOperation: {},
      adding: false,
      posteErrorMessage: [],
      montantErrorMessage: [],
    }
  },
  props: {
    postesNonDefinits: {
      type: Array,
      requires: true,
    },
  },
  computed: {
    posteSelectionne() {
      return this.postesNonDefinits.filter((value) => value.posteAIMId === this.tempOperation.posteAIM).at(0)
    },
    isValidMontant() {
      return !isNaN(parseFloat(this.tempOperation.montant)) && this.tempOperation.montant.length > 0
    },
    isValidPoste() {
      return this.postesNonDefinits.some((poste) => poste.posteAIMId === this.tempOperation.posteAIM)
    },
  },
  methods: {
    validateMontant() {
      if (!this.isValidMontant) {
        this.montantErrorMessage.push('Entrez une valeur numérique')
        return false
      }
      this.montantErrorMessage = []
      return true
    },
    validatePoste() {
      if (!this.isValidPoste) {
        this.posteErrorMessage.push('Selectionez un poste')
        return false
      }
      this.posteErrorMessage = []
      return true
    },
    confirmEdit() {
      this.validateMontant()
      this.validatePoste()
      if (this.isValidMontant && this.isValidPoste) {
        this.$emit('add', {
          posteAIM: this.posteSelectionne,
          montant: parseFloat(this.tempOperation.montant),
        })
        this.teardownEdit()
      }
    },
    teardownEdit() {
      this.adding = false
      this.tempOperation = {}
      this.posteErrorMessage = []
      this.montantErrorMessage = []
    },
  },
}
</script>
<style scoped>
.modele-table-aim-actions {
  max-width: 6vh !important;
}

tr:hover {
  background-color: transparent !important;
}
</style>

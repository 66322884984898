<template>
  <v-autocomplete
    v-model="selectedMetier"
    :items="getMetiers"
    hide-no-data
    item-text="libelle"
    :label="label"
    :rules="[(v) => !!v]"
    :placeholder="label"
    :clearable="true"
    required
    return-object
    :readonly="readonly"
    :disabled="disabled"
  />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'metier-autocomplete',
  data() {
    return {
      selectedMetier: undefined,
    }
  },
  props: {
    value: {
      type: Object,
      required: false,
    },
    label: {
      type: String,
      required: false,
      default: 'Métier*',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      getMetiers: 'contrat/getMetiers',
    }),
  },
  created() {
    this.selectedMetier = this.value
  },
  watch: {
    selectedMetier() {
      this.$emit('input', this.selectedMetier)
    },
  },
}
</script>

<style scoped></style>

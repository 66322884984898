<template>
  <div class="jour" :style="{ height: '100%', border: 'solid 1px #EEEEEE' }">
    <div v-if="loading" class="conges-apercu-cell-skeleton"></div>
    <div v-else :style="style" class="caption">
      <div v-if="donnees !== undefined">
        <v-icon
          class="icon-cours-validation"
          v-show="!donnees.second && modeImpression"
          size="26px"
          :color="donnees.first.couleur"
          >mdi-progress-clock</v-icon
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'conges-apercu-cellule-employe',
  props: {
    date: {
      type: String,
      required: true,
    },
    donnees: {
      type: Object,
      required: false,
      default: undefined,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    modeImpression: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      getEmployeById: 'employe/getEmployeById',
      getNbDaysInMonth: 'calendrier/getNbDaysInMonth',
      getDayOfTheWeekOfDate: 'calendrier/getDayOfTheWeekOfDate',
      isISODateFerie: 'calendrier/isISODateFerie',
    }),
    isFerie() {
      return this.isISODateFerie(this.date)
    },
    style() {
      let style = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 'bold',
        height: '100%',
        color: 'white',
        opacity: 1,
        padding: '5px',
      }

      switch (this.getDayOfTheWeekOfDate(this.date).jourId) {
        case 6:
          style.backgroundColor = '#e0e0e0'
          break
        case 7:
          style.backgroundColor = '#e0e0e0'
          break
        default:
          style.backgroundColor = 'white'
      }

      if (this.isFerie) {
        style.backgroundColor = '#ffe0e0'
      }

      if (this.donnees) {
        if (this.donnees.second) {
          style.backgroundColor = this.donnees.first.couleur
        } else if (!this.modeImpression) {
          delete style.backgroundColor
          style.color = 'black'
          style.background = `repeating-linear-gradient(135deg, #ffffff, #ffffff 2px, ${this.donnees.first.couleur} 2px, ${this.donnees.first.couleur} 4px)`
        }
      }

      return style
    },
  },
}
</script>

<style scoped>
.jour {
  display: block;
}

.conges-apercu-cell-skeleton {
  display: flex;
  height: 100%;
  background: white;
  overflow: hidden;
}

.icon-cours-validation {
  position: absolute;
  transform: translate(-13px, -13px);
}

.conges-apercu-cell-skeleton::after {
  background: linear-gradient(90deg, hsla(0, 0%, 100%, 0), rgba(0, 0, 0, 0.12), hsla(0, 0%, 100%, 0));
  content: '';
  animation: loading 1.5s infinite;
  height: 100%;
  width: 100%;
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  transform: translateX(-100%);
  z-index: 1;
}
</style>

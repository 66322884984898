<script setup>
import SInfoMontant from '@/components/global/s-info-montant.vue'
</script>

<template>
  <tr
    @dblclick="handleDbClick"
    @mouseenter="startHovering"
    @mouseleave="endHovering"
    class="ml-2"
    :class="expansionPanelBgColor"
  >
    <td class="text-wrap">{{ operation.explication }}</td>
    <td class="text-right montant">
      <s-info-montant :colorisation="true" :montant="operation.montant"></s-info-montant>
    </td>
  </tr>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ligne-operation-aim.vue',
  props: {
    operation: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      estAjustementConcerneParAjustementSelectionne:
        'renduAIM_IHM_helper/estAjustementConcerneParAjustementSelectionne',
      estAjustementConcerneParAjustementSurvole: 'renduAIM_IHM_helper/estAjustementConcerneParAjustementSurvole',
    }),
    estConcerneParSelection() {
      return this.estAjustementConcerneParAjustementSelectionne(this.operation.ajustementId)
    },
    estConcerneParSurvol() {
      return this.estAjustementConcerneParAjustementSurvole(this.operation.ajustementId)
    },
    expansionPanelBgColor() {
      if (this.estConcerneParSelection) return 'secondary-lighten-4'
      if (this.estConcerneParSurvol) return 'secondary-lighten-5'
      return ''
    },
  },
  methods: {
    ...mapActions({
      setAjustementSurvole: 'renduAIM_IHM_helper/setAjustementSurvole',
      reinitializerAjustementSurvole: 'renduAIM_IHM_helper/reinitializerAjustementSurvole',
      setAjustementSelectionne: 'renduAIM_IHM_helper/setAjustementSelectionne',
      reinitializerAjustementSelectionne: 'renduAIM_IHM_helper/reinitializerAjustementSelectionne',
    }),
    startHovering() {
      this.setAjustementSurvole(this.operation.ajustementId)
    },
    endHovering() {
      this.reinitializerAjustementSurvole()
    },
    handleDbClick() {
      if (this.estConcerneParSelection) {
        this.deselectionnerAjustement()
      } else {
        this.selectionnerAjustement()
      }
    },
    selectionnerAjustement() {
      this.setAjustementSelectionne(this.operation.ajustementId)
    },
    deselectionnerAjustement() {
      this.reinitializerAjustementSelectionne()
    },
  },
}
</script>

<style scoped>
.montant {
  width: 50%;
}
.surface-lighten-2 {
  background-color: #e2f3f1 !important;
}
.secondary-lighten-4 {
  background-color: rgb(from var(--v-secondary-lighten4) r g b / 0.75) !important;
}
.secondary-lighten-5 {
  background-color: rgb(from var(--v-secondary-lighten4) r g b / 0.5) !important;
}
</style>

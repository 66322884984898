<template>
  <v-list-item inactive :style="{ 'pointer-events': 'none !important' }">
    <v-list-item-content>
      <v-list-item-title v-text="title" />
      <v-list-item-subtitle v-text="subTitle" />
    </v-list-item-content>
    <v-list-item-action>
      <v-list-item-action class="pr-5">
        <v-badge color="error lighten-4 error--text text--darken-4" :content="duree" overlap>
          <v-icon color="primary"> {{ icon }} </v-icon>
        </v-badge>
      </v-list-item-action>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'demande-recuperation-list-item',
  props: {
    recuperation: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      formatDateToDisplay: 'calendrier/formatDateToDisplay',
    }),
    title() {
      switch (this.recuperation.type) {
        case 'CreneauDeFormation':
          return "Annulation d'heures de formation"
        case 'CreneauDeRemplacement':
          return "Annulation d'un remplacement"
        case 'CreneauHeuresSupplementaires':
          return "Annulation d'heures complémentaires"
        default:
          return 'Récupération'
      }
    },
    subTitle() {
      return (
        this.formatDateToDisplay(this.recuperation.date) +
        ' - ' +
        this.recuperation.heureDebut.toHeureMinute +
        ' à ' +
        this.recuperation.heureFin.toHeureMinute
      )
    },
    icon() {
      switch (this.recuperation.type) {
        case 'CreneauDeFormation':
          return 'mdi-school'
        case 'CreneauHeuresSupplementaires':
          return 'fa-plus-circle'
        default:
          return 'fa-minus-circle'
      }
    },
    duree() {
      let result = -this.recuperation.dureeEnMinute / 60
      return Math.round(result * 100) / 100 + 'h'
    },
  },
}
</script>

<style scoped></style>

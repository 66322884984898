<template>
  <v-toolbar class="mb-4" flat :color="color" dark>
    <v-toolbar-title v-text="text" class="headline" />
    <v-tooltip v-if="tooltip" v-model="showTooltip" top>
      <template v-slot:activator="{ on, attrs }">
        <v-icon class="ml-2" color="white" v-bind="attrs" v-on="on"> mdi-information-outline </v-icon>
      </template>
      <span>{{ tooltip }}</span>
    </v-tooltip>
    <v-spacer />
    <v-btn icon @click="$emit('close')">
      <v-icon medium color="white">mdi-close</v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
export default {
  name: 's-toolbar',
  props: {
    text: {
      type: String,
      required: true,
    },
    create: {
      type: Boolean,
      required: false,
      default: false,
    },
    update: {
      type: Boolean,
      required: false,
      default: false,
    },
    delete: {
      type: Boolean,
      required: false,
      default: false,
    },
    tooltip: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      showTooltip: false,
    }
  },
  computed: {
    color() {
      if (this.create) {
        return 'success'
      }
      if (this.update) {
        return 'info'
      }
      if (this.delete) {
        return 'error'
      }
      return 'primary'
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>

/* eslint-disable no-unused-vars */
import axios from 'axios'
import Vue from 'vue'

const state = {
  creneaux: [],
  joursDeRepos: [],
}

const mutations = {
  ADD_CRENEAUX(state, _creneaux) {
    _creneaux.forEach((_creneau) => {
      state.creneaux = state.creneaux.filter((c) => c.creneauTriId !== _creneau.creneauTriId)
      state.creneaux.push(_creneau)
    })
  },
  ADD_JOURS_DE_REPOS(state, _joursDeRepos) {
    _joursDeRepos.forEach((_jourDeRepos) => {
      state.joursDeRepos = state.joursDeRepos.filter((jr) => jr.jourDeReposId !== _jourDeRepos.jourDeReposId)
      state.creneaux.push(_jourDeRepos)
    })
  },
}

const getters = {
  getCreneaux: (state) => () => {
    return state.creneaux ? state.creneaux : []
  },
  getJoursDeRepos: (state) => () => {
    return state.joursDeRepos ? state.joursDeRepos : []
  },
  getRemplacants: (state) => (_creneau) => {
    return state.creneaux.filter((c) => c.remplace && c.remplace.creneauId === _creneau.creneauId)
  },
}

const actions = {
  fetchCreneauxSemaineByUtilisateur({ commit }, _query) {
    return axios
      .get(
        `${process.env.VUE_APP_URL_BACK_SERENITY}/creneau/utilisateur/${_query.utilisateurId}?_annee=${_query.annee}&_semaine=${_query.semaine}`
      )
      .then((response) => {
        if (response.data) {
          commit('ADD_CRENEAUX', response.data)
        }
      })
  },
  fetchCreneauxByServiceIdAndDate({ commit }, _query) {
    return axios.get(
      `${process.env.VUE_APP_URL_BACK_SERENITY}/creneau/service/${_query.serviceId}/date?_date=${_query.date}`
    )
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

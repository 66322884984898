<template>
  <div class="planning">
    <div class="planning-header">
      <!-- espacement pour l'affichage des heures à gauche du composant -->
      <div class="planning-header-interval" />
      <div class="planning-header-jour text-body-2">
        {{ headerLabel }}
      </div>
    </div>
    <perfect-scrollbar :style="maxHeight ? { 'max-height': maxHeight + 'px' } : {}">
      <div class="planning-panel">
        <planning-body-interval :jour_nuit="'jour'" position="left" :slot-height="hauteurPlanning" />
        <planning-body-jour
          :creneaux="!!creneaux ? creneaux : []"
          :joursDeRepos="[]"
          :semaine="semaine"
          :jour="jour"
          :membres="membres"
          :slot-height="hauteurPlanning"
          apercu
        />
      </div>
      <v-menu
        v-if="membres.length > 0"
        v-model="menu"
        :close-on-content-click="false"
        :close-on-click="false"
        offset-x
        nudge-top="14"
        style="width: 350px"
        :nudge-right="chromaButtonPosition === 'right' ? 16 : 0"
        :nudge-left="chromaButtonPosition === 'left' ? 322 : 0"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            absolute
            dark
            fab
            top
            :right="chromaButtonPosition === 'right'"
            :left="chromaButtonPosition === 'left'"
            class="mt-10"
          >
            <img v-if="!menu" src="../../../assets/cercle_chromatique_ouvrir.png" alt="?" />
            <img v-else src="../../../assets/cercle_chromatique_fermer.png" alt="?" />
          </v-btn>
        </template>
        <v-card>
          <v-list>
            <v-list-item v-for="membre in membres" :key="membre.membreId">
              <v-list-item-avatar :color="membre.couleur" small />
              <v-list-item-content>
                <v-list-item-title>{{ membre.employe.nomComplet }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </perfect-scrollbar>
  </div>
</template>

<script>
import planningBodyJour from '../../planning/body/planning-body-jour.vue'
import planningBodyInterval from '../../planning/body/planning-body-interval'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'demande-heures-supp-apercu',
  components: {
    planningBodyJour,
    planningBodyInterval,
  },
  props: {
    creneaux: {
      type: Array,
      required: false,
      default: undefined,
    },
    service: {
      type: Object,
      required: false,
      default: undefined,
    },
    date: {
      type: String,
      required: false,
      default: undefined,
    },
    chromaButtonPosition: {
      type: String,
      required: false,
      default: 'right',
    },
    maxHeight: {
      type: Number,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      menu: false,
    }
  },
  computed: {
    ...mapGetters({
      getISOWeekOfDate: 'calendrier/getISOWeekOfDate',
      getDayOfTheWeekOfDate: 'calendrier/getDayOfTheWeekOfDate',
      getMembreById: 'membre/getMembreById',
    }),
    semaine() {
      return this.getISOWeekOfDate(this.date)
    },
    jour() {
      return this.getDayOfTheWeekOfDate(this.date)
    },
    hauteurPlanning() {
      return parseInt(
        this.affichageImpression === true ? process.env.VUE_APP_PRINT_SLOT_HEIGHT : process.env.VUE_APP_SLOT_HEIGHT
      )
    },
    headerLabel() {
      let result = ''

      if (this.service) {
        result += this.service.nom + ' - '
      }

      if (this.service && this.date) {
        result += this.jour.label + ' ' + moment(this.date).format('DD/MM/YYYY')
      } else {
        result += '--/--/--'
      }

      return result
    },
    membres() {
      let membres = []
      this.creneaux.forEach((c) => {
        if (this.neContientPasLeMembreId(membres, c.membre.membreId)) {
          membres.push(c.membre)
        }
      })

      return membres.sort(function (mA, mB) {
        return mA - mB
      })
    },
  },
  methods: {
    contientLeMembreId(_tableauDesMembres, _idATrouver) {
      for (const membre of _tableauDesMembres) {
        if (parseInt(membre.membreId) === parseInt(_idATrouver)) {
          return true
        }
      }

      return false
    },
    neContientPasLeMembreId(_tableauDesMembres, _idATrouver) {
      for (const membre of _tableauDesMembres) {
        if (parseInt(membre.membreId) === parseInt(_idATrouver)) {
          return false
        }
      }

      return true
    },
  },
}
</script>

<style scoped>
.planning {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;

  overflow: hidden;
}

.planning-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  margin-bottom: 5px;
}

.planning-header-interval {
  width: 40px !important;
  min-width: 40px !important;
  max-width: 40px !important;
}

.planning-header-jour {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.planning-panel {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
}
</style>

<template>
  <creneau
    :borneDebut="borneDebut"
    :creneau="creneau"
    :indisponibilite="indisponibilite"
    :inline-style="style"
    :disabled="disabled"
    :slot-height="slotHeight"
  >
    <template v-slot:icon>
      <v-icon v-if="aRemplacer" color="error">fa-exclamation</v-icon>
      <v-icon v-else-if="remplacePar || nonRemplace" color="success">fa-check</v-icon>
    </template>
  </creneau>
</template>

<script>
import Creneau from './creneau'
import { mapGetters } from 'vuex'

export default {
  name: 'creneau-de-travail',
  components: {
    Creneau,
  },
  props: {
    slotHeight: {
      type: Number,
      required: true,
    },
    borneDebut: {
      type: Object,
      required: true,
    },
    creneau: {
      type: Object,
      required: true,
    },
    indisponibilite: {
      type: Object,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      getRemplacantsService: 'servicePlanning/getRemplacants',
      getRemplacants: 'planning/getRemplacants',
      getPlanningParameters: 'application/getPlanningParameters',
    }),
    membre() {
      return this.creneau.membre
    },
    aRemplacer() {
      return this.indisponibilite && !(this.remplacePar || this.creneau.nePasRemplacer)
    },
    nonRemplace() {
      return this.indisponibilite && this.creneau.nePasRemplacer
    },
    remplacePar() {
      if (this.$route.name === 'service-planning') {
        return this.getRemplacantsService(this.creneau).length > 0
      } else {
        return this.getRemplacants(this.creneau).length > 0
      }
    },
    style() {
      if (this.creneau.remplace) {
        return {
          borderStyle: 'solid',
          borderWidth: '3px',
          borderColor: this.colorRemplace,
        }
      } else {
        return {}
      }
    },
    colorRemplace() {
      return this.creneau.remplace.membre.couleur
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

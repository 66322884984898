<template>
  <v-dialog v-model="showDialog" persistent width="600px">
    <template v-slot:activator="{ on }">
      <v-list-item v-on="on">
        <v-list-item-icon>
          <v-icon color="success">mdi-school</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Heures de formation</v-list-item-title>
      </v-list-item>
    </template>
    <v-card v-if="showDialog">
      <s-toolbar text="Ajout des heures de formation" create @close="close()" />
      <v-card-text>
        <v-container>
          <v-col cols="12">
            <v-text-field label="Nom de la formation*" v-model="labelFormation" :rules="[(v) => !!v]" required />
          </v-col>
          <v-row v-if="loaded">
            <v-col cols="12">
              <creneau-form v-model="creneau" :membres="membres" @close="close()" @validated="sendCreateCreneau()" />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import CreneauForm from './creneau-form'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'creneau-create-dialog',
  components: {
    CreneauForm,
  },
  props: {
    date: {
      type: String,
      required: true,
    },
    membreId: {
      type: Number,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      showDialog: false,
      creneau: {
        date: undefined,
        heureDebut: undefined,
        heureFin: undefined,
        membreId: undefined,
      },
      labelFormation: undefined,
    }
  },
  computed: {
    ...mapGetters({
      getMembresByServiceId: 'membre/getMembresByServiceId',
      getMembreById: 'membre/getMembreById',
    }),
    membres() {
      return this.getMembresByServiceId(parseInt(this.$route.params.serviceId, 10))
    },
    loaded() {
      return this.membres
    },
  },
  created() {
    this.creneau.date = this.date
    this.creneau.membre = this.getMembreById(this.membreId)
  },
  methods: {
    ...mapActions({
      createCreneauDeFormation: 'servicePlanning/createCreneauDeFormation',
    }),
    sendCreateCreneau() {
      this.createCreneauDeFormation({
        ...this.creneau,
        labelFormation: this.labelFormation,
      }).then(() => this.close())
    },
    close() {
      this.showDialog = false
    },
  },
}
</script>

<style scoped></style>

import axios from 'axios'

// eslint-disable-next-line no-unused-vars
export default {
  namespaced: true,
  state: {
    membres: [],
  },
  mutations: {
    SET_MEMBRES(state, _membres) {
      state.membres = _membres
    },
    ADD_MEMBRE(state, _membreToAdd) {
      //Avant de l'ajouter, on vérifie qu'il ne soit pas déjà présent
      let index = state.membres.findIndex((membre) => membre.membreId === _membreToAdd.membreId)
      //Si on le trouve, on le remplace, sinon on l'ajouter
      if (index >= 0) {
        state.membres.splice(index, 1, _membreToAdd)
      } else {
        state.membres.push(_membreToAdd)
      }
    },
    ADD_MEMBRES(state, _membresToAdd) {
      _membresToAdd.forEach((membre) => {
        //Avant de l'ajouter, on vérifie qu'il ne soit pas déjà présent
        let index = state.membres.findIndex((membreExistant) => membre.membreId === membreExistant.membreId)
        //Si on le trouve, on le remplace, sinon on l'ajouter
        if (index >= 0) {
          state.membres.splice(index, 1, membre)
        } else {
          state.membres.push(membre)
        }
      })
    },
    UPDATE_MEMBRE(state, _membreToUpdate) {
      state.membres.splice(
        state.membres.findIndex((membre) => membre.membreId === _membreToUpdate.membreId),
        1,
        _membreToUpdate
      )
    },
    REMOVE_MEMBRE(state, _membreToRemoveId) {
      let index = state.membres.findIndex((membre) => membre.membreId === _membreToRemoveId)
      //Si on le trouve, on le supprime, sinon on l'ajouter
      if (index >= 0) {
        state.membres.splice(index, 1)
      }
    },
  },
  getters: {
    getMembreById: (state) => (_membreId) => {
      return state.membres.find((m) => m.membreId === _membreId)
    },
    getMembresByServiceId: (state) => (_serviceId) => {
      return state.membres.filter((membre) => membre.serviceId === _serviceId)
    },
    getTitulairesByServiceId: (state) => (_serviceId) => {
      return state.membres.filter((membre) => membre.serviceId === _serviceId && membre.titulaire)
    },
    getRemplacantsByServiceId: (state) => (_serviceId) => {
      return state.membres.filter((membre) => membre.serviceId === _serviceId && !membre.titulaire)
    },
    getMembresByEmployeId: (state) => (_employeId) => {
      return state.membres.filter((membre) => membre.employe.employeId === _employeId)
    },
  },
  actions: {
    fetchMembresByEtablissement({ commit }, _etablissement) {
      return axios
        .get(`${process.env.VUE_APP_URL_BACK_SERENITY}/membre/etablissement/${_etablissement.etablissementId}`)
        .then((response) => {
          commit('SET_MEMBRES', response.data)
        })
    },
    createMembre({ commit }, _membreToCreate) {
      return axios.post(`${process.env.VUE_APP_URL_BACK_SERENITY}/membre`, _membreToCreate).then((response) => {
        commit('ADD_MEMBRE', response.data)
      })
    },
    remplacerMembre({ commit }, _remplacerMembreCommand) {
      return axios
        .post(
          `${process.env.VUE_APP_URL_BACK_SERENITY}/membre/${_remplacerMembreCommand.membreARemplacerId}/remplacer`,
          _remplacerMembreCommand
        )
        .then((response) => {
          commit('REMOVE_MEMBRE', _remplacerMembreCommand.membreARemplacerId)
          commit('ADD_MEMBRE', response.data)
        })
    },
    updateMembre({ commit }, _updateMembreCommand) {
      return axios
        .put(`${process.env.VUE_APP_URL_BACK_SERENITY}/membre/${_updateMembreCommand.membreId}`, _updateMembreCommand)
        .then((response) => {
          commit('UPDATE_MEMBRE', response.data)
        })
    },
    deleteMembre({ commit }, _membreToDeleteId) {
      return axios.delete(`${process.env.VUE_APP_URL_BACK_SERENITY}/membre/${_membreToDeleteId}`).then(() => {
        commit('REMOVE_MEMBRE', _membreToDeleteId)
      })
    },
  },
}

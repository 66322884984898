<template>
  <!-- Affiche sur les heures sur la gauche du composant -->
  <div v-if="jour_nuit === 'jour'" class="planning-body-interval">
    <div
      :style="{
        height: slotHeight + 'px',
      }"
      class="planning-body-interval-slot"
      v-bind:class="{
        left: position === 'left',
        right: position === 'right',
      }"
      v-for="borneHoraire in bornesHoraireJournee"
      :key="borneHoraire.borneHoraireId"
      :borneHoraire="borneHoraire"
    >
      <span v-if="borneHoraire.minute === 0"> {{ borneHoraire.heure }}h </span>
    </div>
  </div>
  <div v-else class="planning-body-interval">
    <div
      :style="{
        height: slotHeight + 'px',
      }"
      class="planning-body-interval-slot"
      v-bind:class="{
        left: position === 'left',
        right: position === 'right',
      }"
      v-for="borneHoraire in bornesHoraireMatin"
      :key="borneHoraire.borneHoraireId"
      :borneHoraire="borneHoraire"
    >
      <span v-if="borneHoraire.minute === 0"> {{ borneHoraire.heure }}h </span>
    </div>
    <!-- Sur le planning de jour, on place le spacer au milieu du planning -->
    <div class="planning-body-interval-spacer" />
    <div
      :style="{
        height: slotHeight + 'px',
      }"
      class="planning-body-interval-slot"
      v-bind:class="{
        left: position === 'left',
        right: position === 'right',
      }"
      v-for="borneHoraire in bornesHoraireSoir"
      :key="borneHoraire.borneHoraireId"
    >
      <span v-if="borneHoraire.minute === 0"> {{ borneHoraire.heure }}h </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'modele-planning-body-interval',
  components: {},
  props: {
    jour_nuit: {
      type: String,
      required: true,
    },
    position: {
      type: String,
      required: true,
    },
    slotHeight: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getBornesHoraireJournee: 'calendrier/getBornesHoraireJournee',
      getBornesHoraireMatin: 'calendrier/getBornesHoraireMatin',
      getBornesHoraireSoir: 'calendrier/getBornesHoraireSoir',
    }),
    bornesHoraireJournee() {
      return this.getBornesHoraireJournee()
    },
    bornesHoraireMatin() {
      return this.getBornesHoraireMatin()
    },
    bornesHoraireSoir() {
      return this.getBornesHoraireSoir()
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.planning-body-interval {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;

  width: 40px !important;
  min-width: 40px !important;
  max-width: 40px !important;
}

.planning-body-interval-spacer {
  height: 30px;
}

.planning-body-interval-slot {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.planning-body-interval-slot.left {
  justify-content: flex-end;
  margin-right: 3px;
}

.planning-body-interval-slot.right {
  justify-content: flex-start;
  margin-left: 3px;
}
</style>

<template>
  <v-dialog v-model="visible" max-width="408">
    <template v-slot:activator="{ on }">
      <div class="avatar mt-2" v-on="on">
        <utilisateur-avatar :utilisateur="utilisateur" :size="size"></utilisateur-avatar>
        <v-btn class="mt-2" small color="info" tile>Changer d'avatar</v-btn>
      </div>
    </template>
    <v-card>
      <v-card-text class="ma-0 px-0"> </v-card-text>
      <v-image-input v-model="editingValue" clearable image-format="png" />
      <v-card-actions>
        <v-spacer />
        <v-btn color="grey darken-1" text @click="close()"> Annuler </v-btn>
        <v-btn color="green darken-1" dark @click="handleImageSelection()"> Confirmer </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import VImageInput from 'vuetify-image-input'
import imageProfilH from '@/assets/H_profil.png'
import imageProfilF from '@/assets/F_profil.png'
import UtilisateurAvatar from '@/components/utilisateur/utilisateur-avatar.vue'
import { mapActions } from 'vuex'

export default {
  name: 'utilisateur-avatar-update',
  components: {
    UtilisateurAvatar,
    VImageInput,
  },
  props: {
    utilisateur: {
      type: Object,
      required: true,
    },
    size: {
      type: Number,
      required: false,
      default: 32,
    },
    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
      editingValue: undefined,
      validatedValue: undefined,
    }
  },
  computed: {
    utilisateurId() {
      return this.utilisateur.utilisateurId
    },
    avatar() {
      if (this.utilisateur.genre === 'Mr') {
        return imageProfilH
      } else {
        return imageProfilF
      }
    },
  },
  watch: {
    utilisateur() {
      this.editingValue = undefined
      this.validatedValue = undefined
    },
  },
  methods: {
    ...mapActions({
      updateAvatar: 'utilisateur/updateUtilisateurAvatar',
    }),
    handleImageSelection() {
      this.validatedValue = this.editingValue
      //Le fichier qu'on récupère est au format Base64
      this.updateAvatar({
        utilisateurId: this.utilisateurId,
        avatar: this.validatedValue,
      })
      this.close()
    },
    close() {
      this.visible = false
      this.editingValue = undefined
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.v-avatar:hover {
  cursor: pointer;
}

.avatar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>

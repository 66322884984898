import axios from 'axios'

export default {
  namespaced: true,
  state: {
    accueils: [],
  },
  mutations: {
    SET_ACCUEILS(state, _accueils) {
      state.accueils = _accueils
    },
  },
  getters: {
    getAccueilByUsagerId: (state) => (_usagerId) => {
      return state.accueils.filter((accueil) => accueil.usagerAccueilli.usagerId === _usagerId)
    },
    getDernierAccueilByUsagerId: (state) => (_usagerId) => {
      let accueilsUsager = state.accueils.filter((accueil) => accueil.usagerAccueilli.usagerId === _usagerId)
      if (accueilsUsager.length > 0) {
        return accueilsUsager.reduce((p, v) => (p.dateDeDebutDAccueil > v.dateDeDebutDAccueil ? p : v))
      }
      return undefined
    },
  },
  actions: {
    async fetchAccueilsByUsagerId({ commit }, usagerId) {
      return axios.get(`${process.env.VUE_APP_URL_BACK_SERENITY}/accueil/usager/` + usagerId).then((response) => {
        commit('SET_ACCUEILS', response.data)
      })
    },
  },
}

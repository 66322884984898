<template>
  <v-card-text>
    <v-row>
      <v-col cols="12" class="text-h6">
        <v-toolbar class="mb-0" dense flat>
          <v-spacer />
          <v-btn icon color="primary" @click="annee -= 1" :disabled="annee <= 2023">
            <v-icon v-if="annee > 2023">mdi-chevron-left</v-icon>
          </v-btn>
          <v-toolbar-title>{{ annee }}</v-toolbar-title>
          <v-btn icon color="primary" @click="annee += 1">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
          <v-btn icon color="primary" @click="moisPrecedent" :disabled="annee === 2023 && mois === 0">
            <v-icon v-if="!(annee === 2023 && mois === 0)">mdi-chevron-left</v-icon>
          </v-btn>
          <v-toolbar-title style="min-width: 120px; text-align: center">{{ moisEnToutesLettres }}</v-toolbar-title>
          <v-btn icon color="primary" @click="moisSuivant">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
          <v-spacer />
          <modele-astreintes-create-dialog
            v-if="!disabled"
            v-model="showModeleAstreintesCreateDialog"
            :etablissement="etablissement"
            :key="'modeleAstreintesCreateDialog.' + etablissementId"
          />
          <v-btn v-if="!disabled" color="success" dark @click="showModeleAstreintesCreateDialog = true">
            <v-icon class="mr-2" small>fa-plus</v-icon>
            Créer un roulement
          </v-btn>
        </v-toolbar>
      </v-col>
      <v-col xl="6" md="12">
        <v-data-iterator
          :loading="resumeDesAstreintes.employes.length === 0"
          :items="resumeDesAstreintes.employes"
          :items-per-page.sync="itemsParPage"
          :page.sync="page"
          hide-default-footer
        >
          <template v-slot:default="props">
            <v-row v-if="resumeDesAstreintes.employes.length > 0">
              <v-col v-for="(item, index) in props.items" :key="item.name" cols="12" sm="6" md="4">
                <resume-astreinte-card :disabled="disabled" :resume="item" :position="index + 1" />
              </v-col>
            </v-row>
          </template>
          <template v-slot:loading>
            <v-row>
              <v-col v-for="index in 3" :key="index" cols="12" sm="6" md="4">
                <v-skeleton-loader elevation="2" type="list-item-avatar, divider, list-item@4" />
              </v-col>
            </v-row>
          </template>
          <template v-slot:footer v-if="nombreDemployesDastreinte > 3">
            <v-row align="center" justify="center">
              <v-btn icon color="primary darken-2" class="mr-1" @click="pagePrecedente">
                <v-icon v-if="page > 1">mdi-chevron-left</v-icon>
              </v-btn>
              <span class="mr-4 grey--text"> Page {{ page }}/{{ nombreDePages }} </span>
              <v-btn icon color="primary darken-2" class="ml-1" @click="pageSuivante">
                <v-icon v-if="page < nombreDePages">mdi-chevron-right</v-icon>
              </v-btn>
            </v-row>
          </template>
        </v-data-iterator>
      </v-col>
      <v-col xl="6" md="12">
        <astreintes-planning :disabled="disabled" :annee="annee" :mois="mois" :etablissement="etablissement" />
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ResumeAstreinteCard from '../../components/astreintes/resume-astreinte-card.vue'
import AstreintesPlanning from '../../components/astreintes/astreintes-planning.vue'
import ModeleAstreintesCreateDialog from '../../components/astreintes/modele-astreintes-create-dialog.vue'

export default {
  name: 'etablissement-detail-astreintes',
  components: {
    AstreintesPlanning,
    ResumeAstreinteCard,
    ModeleAstreintesCreateDialog,
  },
  props: {
    etablissement: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      resumeLoaded: false,
      showModeleAstreintesCreateDialog: false,
      itemsParPage: 3,
      page: 1,
      annee: undefined,
      mois: new Date().getMonth(),
    }
  },
  computed: {
    ...mapGetters({
      resumeDesAstreintes: 'astreinte/getResumeDesAstreintes',
      getTitulairesByServiceId: 'membre/getTitulairesByServiceId',
      getRemplacantsByServiceId: 'membre/getRemplacantsByServiceId',
      isInRole: 'security/isInRole',
    }),
    loaded() {
      return this.etablissement && this.resumeLoaded
    },
    etablissementId() {
      return this.etablissement.etablissementId
    },
    nombreDemployesDastreinte() {
      if (this.resumeDesAstreintes) {
        return this.resumeDesAstreintes.employes.length
      } else {
        return 0
      }
    },
    nombreDePages() {
      if (this.resumeDesAstreintes) {
        return Math.ceil(this.nombreDemployesDastreinte / this.itemsParPage)
      } else {
        return 0
      }
    },
    moisEnToutesLettres() {
      return new Date(this.annee, this.mois, 1).toLocaleString('default', { month: 'long' })
    },
  },
  created() {
    this.annee = new Date().getFullYear()
  },
  watch: {
    etablissement() {
      this.discardResumeDesAstreintes()
      this.resumeLoaded = false
      this.fetchResume().then((response) => {
        this.resumeDesAstreintes = response.data
        this.resumeLoaded = true
      })
    },
    annee() {
      this.discardResumeDesAstreintes()
      this.resumeLoaded = false
      this.fetchResume().then(() => {
        this.resumeLoaded = true
      })
    },
  },
  methods: {
    ...mapActions({
      fetchResumeDesAstreintes: 'astreinte/fetchResumeAnnuelDesAstreintes',
      discardResumeDesAstreintes: 'astreinte/discardResumeAnnuelDesAstreintes',
    }),
    fetchResume() {
      return this.fetchResumeDesAstreintes({
        etablissementId: this.etablissement.etablissementId,
        annee: this.annee,
      })
    },
    moisSuivant() {
      if (this.mois !== 11) {
        this.mois++
      } else {
        this.annee++
        this.mois = 0
      }
    },
    moisPrecedent() {
      if (this.mois !== 0) {
        this.mois--
      } else {
        this.annee--
        this.mois = 11
      }
    },
    pageSuivante() {
      if (this.page + 1 <= this.nombreDePages) this.page += 1
    },
    pagePrecedente() {
      if (this.page - 1 >= 1) this.page -= 1
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

<template>
  <demande-detail>
    <template v-slot:demande>
      <v-card-text>
        <v-row>
          <s-text-info v-model="demande.type.label" label="Type de congés" :cols="4" />
          <s-text-info v-model="dateDebut" type="date" :label="labelDateDebut" :cols="4" />
          <s-text-info v-if="dateFin" v-model="dateFin" type="date" label="Au" :cols="4" />
        </v-row>
      </v-card-text>
    </template>
  </demande-detail>
</template>

<script>
import DemandeDetail from './demande-detail'
import { mapGetters } from 'vuex'

export default {
  name: 'demande-conges-detail',
  components: {
    DemandeDetail,
  },
  computed: {
    ...mapGetters({
      getDemandeById: 'demande/getDemandeById',
    }),
    loaded() {
      return this.demande !== undefined
    },
    demande() {
      return this.getDemandeById(parseInt(this.$route.params.demandeId, 10))
    },
    labelDateDebut() {
      if (this.dateFin) {
        return 'Du'
      } else {
        return 'Le'
      }
    },
    dateDebut() {
      if (this.dateFin && !this.demande.demiJournee) {
        return this.demande.dateDebut
      } else {
        return this.demande.dateDebut + ' (' + this.demande.demiJourneeLabel + ')'
      }
    },
    dateFin() {
      if (this.demande.dateDebut !== this.demande.dateFin) {
        return this.demande.dateFin
      } else {
        return undefined
      }
    },
  },
}
</script>

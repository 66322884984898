import axios from 'axios'

export default {
  namespaced: true,
  state: {
    types: [],
    metiers: [],
  },
  mutations: {
    SET_CONTRAT_TYPES(state, _types) {
      state.types = _types
    },
    SET_METIERS(state, _metiers) {
      state.metiers = _metiers
    },
  },
  getters: {
    getContratTypes(state) {
      return state.types
    },
    getMetiers(state) {
      return state.metiers
    },
  },
  actions: {
    fetchContratTypes() {
      return axios.get(`${process.env.VUE_APP_URL_BACK_SERENITY}/contrat/types`).then((response) => {
        this.commit('contrat/SET_CONTRAT_TYPES', response.data)
      })
    },
    fetchMetiers() {
      return axios.get(`${process.env.VUE_APP_URL_BACK_SERENITY}/contrat/metiers`).then((response) => {
        this.commit('contrat/SET_METIERS', response.data)
      })
    },
  },
}

<template>
  <div class="modele-planning-header-semaine">
    <div class="modele-planning-header-semaine-title">
      Semaine {{ semaine.ordre }}
      <div class="modele-planning-header-semaine-temps">
        <div class="modele-planning-header-semaine-temps-membre" v-for="membre in membres" :key="membre.membreId">
          <v-avatar class="mr-1" :color="membre.couleur" size="12px" />
          {{ nombreHeuresTotales(membre) }}h
        </div>
      </div>
    </div>
    <div class="modele-planning-header-semaine-jours">
      <div class="modele-planning-header-jour" v-for="jour in jours" :key="jour.jourId">
        {{ getJourLabel(jour) }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'modele-planning-header-semaine',
  components: {},
  props: {
    semaine: {
      type: Object,
      required: true,
    },
    membres: {
      type: Array,
      required: true,
    },
    shortLabel: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    serviceId() {
      return parseInt(this.$route.params.serviceId, 10)
    },
    ...mapGetters({
      jours: 'calendrier/getJours',
    }),
    creneaux() {
      return this.semaine.creneaux
    },
  },
  methods: {
    getJourLabel(_jour) {
      if (this.shortLabel === true) {
        return _jour.shortLabel
      } else {
        return _jour.label
      }
    },
    nombreHeuresTotales(_membre) {
      var result = 0

      if (this.creneaux) {
        this.creneaux.forEach((creneau) => {
          if (creneau.membreId === _membre.membreId) {
            result += (creneau.heureFin.valeur - creneau.heureDebut.valeur) / 60
          }
        })
      }

      return Math.round(result * 100) / 100
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.modele-planning-header-semaine {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  border-bottom: 1px solid #c0c0c0;
}

.modele-planning-header-semaine-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
}

.modele-planning-header-semaine-temps {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  align-self: stretch;
  justify-content: space-between;

  margin: 5px 10px;
}

.modele-planning-header-semaine-temps-membre {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.modele-planning-header-semaine-jours {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
}

.modele-planning-header-jour {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;

  border-left: 5px solid #c0c0c0;
}
</style>

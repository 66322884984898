<template>
  <div class="planning-creneau-container">
    <planning-creneau-container-membre
      v-for="membre in membres"
      :key="membre.membreId"
      :membre="membre"
      :creneaux="getCreneauxByMembreId(membre.membreId)"
      :jourDeRepos="getJourDeReposByMembreId(membre.membreId)"
      :semaine="semaine"
      :jour="jour"
      :borneDebut="borneDebut"
      :borneFin="borneFin"
      :displayJoursDeRepos="displayJoursDeRepos"
      :disabled="disabled"
      :slot-height="slotHeight"
    />
  </div>
</template>

<script>
import planningCreneauContainerMembre from './planning-creneau-container-membre'

export default {
  name: 'planning-creneau-container',
  components: {
    planningCreneauContainerMembre,
  },
  props: {
    slotHeight: {
      type: Number,
      required: true,
    },
    creneaux: {
      type: Array,
      required: true,
    },
    joursDeRepos: {
      type: Array,
      required: true,
    },
    semaine: {
      type: Object,
      required: true,
    },
    jour: {
      type: Object,
      required: true,
    },
    membres: {
      type: Array,
      required: true,
    },
    borneDebut: {
      type: Object,
      required: true,
    },
    borneFin: {
      type: Object,
      required: true,
    },
    displayJoursDeRepos: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    getCreneauxByMembreId(_membreId) {
      return this.creneaux.filter((creneau) => creneau.membre.membreId === _membreId)
    },
    getJourDeReposByMembreId(_membreId) {
      return this.joursDeRepos.find((jr) => jr.membre.membreId === _membreId)
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.planning-creneau-container {
  position: absolute;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding-right: 10px;

  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
  pointer-events: none;

  overflow: hidden;
}
</style>

<template>
  <v-list-item class="py-0" v-ripple="{ class: textColor }">
    <v-list-item-content class="py-1">
      <v-list-item-title v-text="titre" :class="textColor" />
      <v-list-item-subtitle v-text="credit.commentaire" :class="textColor" />
    </v-list-item-content>
    <v-list-item-action class="pr-5">
      <v-badge :color="classColor" :content="duree" overlap>
        <v-chip outlined label>
          <v-icon left> {{ icon }} </v-icon>
          Crédit
        </v-chip>
      </v-badge>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'heures-credit-list-item',
  props: {
    credit: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getISOWeekOfDate: 'calendrier/getISOWeekOfDate',
    }),
    ISOWeek() {
      return this.getISOWeekOfDate(this.credit.date)
    },
    membre() {
      return this.credit.membre
    },
    titre() {
      return 'Raison du crédit: ' + this.credit.raison
    },
    icon() {
      return 'mdi-user-clock'
    },
    duree() {
      let result = this.credit.soldeEnMinute / 60
      return Math.round(result * 100) / 100 + 'h'
    },
    color() {
      if (this.credit.soldeEnMinute > 0) {
        return 'success'
      } else {
        return 'error'
      }
    },
    classColor() {
      return `${this.color} lighten-4 ` + this.textColor
    },
    textColor() {
      return `${this.color}--text text--darken-4`
    },
  },
}
</script>

<style scoped></style>

<template>
  <div class="planning-header" v-if="!loading">
    <div class="planning-header-interval" />
    <planning-header-semaine
      v-for="(semaine, index) in semaines"
      :key="index"
      :membres="membres"
      :semaine="semaine"
      :creneaux-semaine="getCreneauxBySemaine(semaine)"
      :affichage-impression="affichageImpression"
    ></planning-header-semaine>
    <div v-if="!affichageImpression" class="planning-header-interval" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PlanningHeaderSemaine from './planning-header-semaine.vue'

export default {
  name: 'planning-header',
  components: {
    PlanningHeaderSemaine,
  },
  props: {
    affichageImpression: {
      type: Boolean,
      default: false,
    },
    semaines: {
      type: Array,
      required: true,
    },
    membres: {
      type: Array,
      required: true,
    },
    creneaux: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      shortLabel: false,
    }
  },
  computed: {
    ...mapGetters({
      getISOWeekOfDate: 'calendrier/getISOWeekOfDate',
      jours: 'calendrier/getJours',
    }),
  },
  methods: {
    getCreneauxBySemaine(_semaine) {
      return this.creneaux.filter((c) => this.getISOWeekOfDate(c.date).numero === _semaine.numero)
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.planning-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
}

.planning-header-interval {
  width: 40px !important;
  min-width: 40px !important;
  max-width: 40px !important;
}
</style>

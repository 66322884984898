<template>
  <v-chip :color="color" :outlined="outlined">
    {{ service.nom }}
  </v-chip>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'service-card',
  props: {
    serviceId: {
      type: Number,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: 'primary',
    },
    outlined: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      getServiceById: 'service/getServiceById',
    }),
    service() {
      return this.getServiceById(this.serviceId)
    },
  },
}
</script>

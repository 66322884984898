<template>
  <creneau
    :borneDebut="borneDebut"
    :creneau="creneau"
    :indisponibilite="indisponibilite"
    :inlineStyle="style"
    :disabled="disabled"
    :slot-height="slotHeight"
  >
    <template v-slot:icon>
      <v-icon :color="color">fa-minus-circle</v-icon>
    </template>
    <template v-slot:actions>
      <v-list-item-group>
        <v-list-item @click="sendAnnulerRecuperation">
          <v-list-item-icon>
            <v-icon color="grey">mdi-history</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Annuler les récupérations</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </template>
  </creneau>
</template>

<script>
import Creneau from './creneau'
import { mapActions } from 'vuex'
import Color from 'color'

export default {
  name: 'creneau-recuperation',
  components: {
    Creneau,
  },
  data() {
    return {
      showAbsenceCreateDialog: false,
    }
  },
  props: {
    slotHeight: {
      type: Number,
      required: true,
    },
    borneDebut: {
      type: Object,
      required: true,
    },
    creneau: {
      type: Object,
      required: true,
    },
    indisponibilite: {
      type: Object,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    membre() {
      return this.creneau.membre
    },
    style() {
      return {
        background: this.colorLighter,
      }
    },
    color() {
      return this.membre.couleur
    },
    colorLighter() {
      return Color(this.membre.couleur).lightness(90).hex()
    },
  },
  methods: {
    ...mapActions({
      restaurerDuTempsDeTravail: 'servicePlanning/restaurerDuTempsDeTravail',
    }),
    sendAnnulerRecuperation() {
      this.restaurerDuTempsDeTravail({
        membreId: this.creneau.membre.membreId,
        dateDuTempsDeTravailARestaurer: this.creneau.date,
        debutDuTempsDeTravailARestaurer: this.creneau.heureDebut,
        finDuTempsDeTravailARestaurer: this.creneau.heureFin,
      })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

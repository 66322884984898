<template>
  <v-autocomplete
    v-model="selectedEtablissement"
    :items="listEtablissements"
    :loading="isLoading"
    :search-input.sync="searchEtablissements"
    item-text="raisonSociale"
    item-value="etablissementId"
    hide-no-data
    :label="computedLabel"
    :rules="[(v) => !!v]"
    :clearable="true"
    :required="required"
    return-object
    :disabled="disabled"
  />
</template>

<script>
export default {
  name: 'etablissement-autocomplete',
  props: {
    value: {
      type: Object,
      required: false,
      default: undefined,
    },
    listEtablissements: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: 'Établissement',
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selectedEtablissement: undefined,
      searchEtablissements: '',
      isLoading: false,
    }
  },
  computed: {
    computedLabel() {
      if (this.required) {
        return this.label + '*'
      } else {
        return this.label
      }
    },
  },
  created() {
    if (this.value) {
      this.selectedEtablissement = this.value
    }
  },
  watch: {
    selectedEtablissement() {
      this.$emit('input', this.selectedEtablissement)
    },
    value() {
      this.selectedEtablissement = this.value
    },
  },
}
</script>

<style scoped></style>

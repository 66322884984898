<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card-text>
          <v-form v-model="publierModeleAstreinteFormValid">
            <v-date-picker
              v-model="publication.dateMiseEnApplication"
              class="elevation-0"
              no-title
              flat
              full-width
              locale="fr"
              first-day-of-week="1"
              locale-first-day-of-year="4"
              min="2022-12-26"
              show-week
              reactive
              disabled
            />
            <v-text-field
              v-model="publication.dateMiseEnApplication"
              :rules="[(v) => !!v]"
              class="hidden-screen-only"
            />
            <v-autocomplete
              v-model="publication.debutCyclePublication"
              return-object
              class="px-5"
              :items="modeleAstreintes.semaines"
              :rules="[(v) => !!v]"
              item-text="ordre"
              label="Semaine de début de roulement"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="grey darken-1" text @click="$emit('precedent')">Précédent</v-btn>
          <v-spacer />
          <v-btn color="success" :disabled="!publierModeleAstreinteFormValid" @click="$emit('publier')">Publier</v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'modele-astreintes-create-step4-publier',
  components: {},
  props: {
    value: {
      type: Object,
      required: true,
    },
    modeleAstreintes: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      publierModeleAstreinteFormValid: false,
      publication: undefined,
    }
  },
  computed: {
    loaded() {
      return this.publication && this.modeleAstreintes
    },
  },
  created() {
    this.publication = this.value
  },
  watch: {
    publication: {
      handler() {
        this.$emit('input', this.publication)
      },
      deep: true,
    },
    value() {
      this.publication = this.value
    },
  },
}
</script>

<style scoped></style>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  montant: {
    type: Number,
    required: true,
  },
  afficherDevise: {
    type: Boolean,
    default: true,
  },
  colorisation: {
    type: Boolean,
    default: false,
  },
})

const formatFrancais = new Intl.NumberFormat('fr-FR', {
  style: props.afficherDevise ? 'currency' : 'decimal',
  currency: 'EUR',
})

const montantFormate = computed(() => {
  return formatFrancais.format(props.montant)
})

const textColor = computed(() => {
  if (!props.colorisation || props.montant === 0) return ''
  return props.montant > 0 ? 'text-success' : 'text-error'
})
</script>

<template>
  <span :class="textColor">{{ montantFormate }}</span>
</template>

<style scoped>
.text-success {
  color: var(--v-success-base);
}

.text-error {
  color: var(--v-error-base);
}
</style>

<template>
  <v-form ref="form" v-model="formValid">
    <v-container>
      <v-row>
        <v-col cols="12" class="py-0 pt-1">
          <v-text-field
            v-model="utilisateur.email"
            :rules="emailRules"
            label="E-mail de l'utilisateur*"
            :disabled="utilisateur.utilisateurId !== undefined"
            @keypress.enter="submitEmail"
            required
          >
            <template v-slot:append>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on"> mdi-help-circle-outline </v-icon>
                </template>
                Adresse E-mail personnelle de l'employé. Cette adresse sera utilisée pour son inscription ainsi que son
                authentification Serenity
              </v-tooltip>
            </template>
            <template v-slot:append-outer>
              <v-btn
                v-if="utilisateur.utilisateurId === undefined"
                color="info"
                x-small
                fab
                :disabled="!rechercheUtilisateurValid"
                @click="submitEmail"
              >
                <v-icon color="white">mdi-magnify</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="2" md="2" sm="4" class="py-0">
          <v-combobox
            v-model="utilisateur.genre"
            :items="['Mr', 'Mme']"
            label="M*"
            :disabled="disabled || utilisateur.utilisateurId !== 0"
            hide-no-data
            required
          ></v-combobox>
        </v-col>
        <v-col cols="5" md="5" sm="8" class="py-0">
          <v-text-field
            v-model="utilisateur.nom"
            :rules="[(v) => !!v || 'Le nom est requis']"
            hide-no-data
            label="Nom de famille*"
            :disabled="disabled || utilisateur.utilisateurId !== 0"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="5" md="5" sm="12" class="py-0">
          <v-text-field
            v-model="utilisateur.prenom"
            :rules="[(v) => !!v || 'Le prenom est requis']"
            hide-no-data
            label="Prénom*"
            :disabled="disabled || utilisateur.utilisateurId !== 0"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="12" sm="12" class="py-0">
          <v-text-field
            v-model="utilisateur.numeroSecu"
            :rules="secuRules"
            hide-no-data
            label="Numéro de sécurité sociale"
            :disabled="disabled || utilisateur.utilisateurId !== 0"
            required
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <small>*information obligatoire</small>
  </v-form>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'utilisateur-form',
  data() {
    return {
      formRechercheUtilisateurValidite: false,
      formValid: false,
      emailRules: [
        (v) => !!v || 'Adresse E-mail obligatoire',
        (v) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(
            v
          ) || "Format de l'adresse E-mail incorrect",
      ],
      secuRules: [
        (v) =>
          !v ||
          /[1-2][0-9]{2}(0[1-9]|1[0-2]|20|3[0-9]|4[0-2]|[5-9][0-9])(0[1-9]|[1-9][0-9]|2A|2B)([0]{2}[1-9]|0[1-9][0-9]|[1-9][0-9]{2})([0]{2}[1-9]|0[1-9][0-9]|[1-9][0-9]{2})|([1-2][9]{12})/.test(
            v
          ) ||
          'Le format est incorrect',
      ],
      utilisateur: undefined,
    }
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    rechercheUtilisateurValid() {
      return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(
        this.utilisateur.email
      )
    },
  },
  created() {
    this.utilisateur = this.value
  },
  watch: {
    utilisateur() {
      this.$emit('input', this.utilisateur)
    },
    value() {
      this.utilisateur = this.value
    },
  },
  methods: {
    ...mapActions({
      searchUtilisateurByEmail: 'utilisateur/searchUtilisateurByEmail',
    }),
    submitEmail() {
      if (this.rechercheUtilisateurValid) {
        this.searchUtilisateurByEmail(this.utilisateur.email)
          .then((foundUtilisateur) => {
            if (foundUtilisateur) {
              this.utilisateur = foundUtilisateur
            } else {
              this.utilisateur.utilisateurId = 0
            }
          })
          .catch(() => {
            this.utilisateur.utilisateurId = 0
          })
      }
    },
  },
}
</script>

<style scoped></style>

<template>
  <v-dialog v-model="showDialog" persistent max-width="800px">
    <v-card v-if="loaded && showDialog">
      <s-toolbar :text="'Modifier le contrat de ' + employe.nomComplet" update @close="close()" />
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-card-text>
              <v-form v-model="contratFormValid">
                <contrat-form v-model="updateContratCommand.contrat" />
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn icon @click="deleteContrat()">
                <v-icon small color="red">fa-trash</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="grey darken-1" text @click="showDialog = false">Annuler</v-btn>
              <v-btn color="info" :disabled="!contratFormValid" @click="createContrat()">Mettre à jour</v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import contratForm from './contrat-form'
import { mapActions } from 'vuex'

export default {
  name: 'contrat-update-dialog',
  components: {
    contratForm,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    contratToUpdate: {
      type: Object,
      required: true,
    },
    employe: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDialog: false,
      updateContratCommand: undefined,
      contratFormValid: false,
    }
  },
  computed: {
    loaded() {
      return this.updateContratCommand && this.updateContratCommand.contrat
    },
  },
  created() {
    this.showDialog = this.value
    this.initialiserCommand()
  },
  watch: {
    showDialog() {
      this.$emit('input', this.showDialog)
    },
    value() {
      this.showDialog = this.value
    },
    contratToUpdate() {
      this.initialiserCommand()
    },
  },
  methods: {
    ...mapActions({
      dispatchUpdateContrat: 'employe/updateContrat',
      dispatchDeleteContrat: 'employe/deleteContrat',
    }),
    close() {
      this.showDialog = false
      this.initialiserCommand()
    },
    initialiserCommand() {
      this.updateContratCommand = {
        contrat: JSON.parse(JSON.stringify(this.contratToUpdate)),
        employeId: this.employe.employeId,
      }
    },
    createContrat() {
      this.dispatchUpdateContrat(this.updateContratCommand)
      this.close()
    },
    deleteContrat() {
      this.dispatchDeleteContrat({
        employeId: this.employe.employeId,
        contratId: this.contratToUpdate.contratId,
      })
      this.close()
    },
  },
}
</script>

<style scoped></style>

<template>
  <v-hover v-slot="{ hover }">
    <v-badge
      v-if="demandesEnAttente"
      :value="demandesEnAttente.length"
      :content="demandesEnAttente.length"
      color="warning"
      overlap
      :dot="!hover"
      bottom
    >
      <v-badge
        v-if="demandesAValider"
        :value="demandesAValider.length"
        :content="demandesAValider.length"
        color="error"
        overlap
        :dot="!hover"
      >
        <v-btn text rounded :to="{ name: 'demande' }"> Demandes </v-btn>
      </v-badge>
    </v-badge>
  </v-hover>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'demande-btn',
  computed: {
    ...mapGetters({
      getDemandesAValider: 'demande/getDemandesAValider',
      getDemandesEnAttente: 'demande/getDemandesEnAttente',
    }),
    demandesAValider() {
      return this.getDemandesAValider()
    },
    demandesEnAttente() {
      return this.getDemandesEnAttente()
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>

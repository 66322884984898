<template>
  <v-dialog v-model="showDialog" persistent max-width="600px">
    <template v-slot:activator="{ on }">
      <v-btn max-height="35px" color="green" tile block dark v-on="on">Créer</v-btn>
    </template>
    <v-card>
      <s-toolbar text="Créer un établissement" create @close="close()" />
      <v-card-text>
        <etablissement-form
          v-if="showDialog"
          v-model="createEtablissementCommand"
          @updateFormValidity="updateFormValidity"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="grey" text @click="close()">Annuler</v-btn>
        <v-btn color="success" :disabled="!formValid" @click="sendCreateEtablissementCommand()"> Créer </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import etablissementForm from './etablissement-form'
import { mapActions } from 'vuex'

export default {
  name: 'etablissement-create-dialog',
  components: {
    etablissementForm,
  },
  data() {
    return {
      showDialog: false,
      formValid: false,
      createEtablissementCommand: undefined,
    }
  },
  mounted() {
    this.reset()
  },
  methods: {
    ...mapActions({
      createEtablissement: 'etablissement/createEtablissement',
    }),
    updateFormValidity(_validity) {
      this.formValid = _validity
    },
    sendCreateEtablissementCommand() {
      this.createEtablissement(this.createEtablissementCommand)
      this.close()
    },
    close() {
      this.showDialog = false
      this.reset()
    },
    reset() {
      this.createEtablissementCommand = {
        //Pour l'instant, qu'une association, je met l'ID en dur
        associationId: 2,
        raisonSociale: undefined,
        numeroFINESS: undefined,
        extensionSIRET: undefined,
      }
    },
  },
}
</script>

<style scoped></style>

import axios from 'axios'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    employes: [],
    findByIdEnAttenteDeReponse: [],
  },
  mutations: {
    ADD_EMPLOYE(state, _employe) {
      state.employes.push(_employe)
    },
    SET_EMPLOYES(state, _employes) {
      state.employes = _employes
    },
    REMOVE_EMPLOYE(state, _employeId) {
      for (let i = 0; i < state.employes.length; i++) {
        if (state.employes[i].employeId === _employeId) {
          state.employes.splice(i, 1)
          break
        }
      }
    },
    SET_EMPLOYE(state, _employe) {
      let updated = false
      for (let i = 0; i < state.employes.length; i++) {
        if (state.employes[i].employeId === _employe.employeId) {
          state.employes.splice(i, 1, _employe)
          updated = true
          break
        }
      }

      if (!updated) {
        state.employes.push(_employe)
      }
    },
    SET_RECUPERATION(state, _recuperation) {
      let employe = state.employes.find((e) => e.employeId === _recuperation.employeId)
      //On lie l'objet employé correspondant au membre qu'on va ajouter
      if (employe.recuperations) {
        employe.recuperations = _recuperation
      } else {
        Vue.set(employe, 'recuperations', _recuperation)
      }
    },
    ADD_FIND_BY_ID(state, _employeId) {
      state.findByIdEnAttenteDeReponse.push(_employeId)
    },
    REMOVE_FIND_BY_ID(state, _employeId) {
      for (let i = 0; i < state.findByIdEnAttenteDeReponse.length; i++) {
        if (state.findByIdEnAttenteDeReponse[i] === _employeId) {
          state.findByIdEnAttenteDeReponse.splice(i, 1)
          break
        }
      }
    },
  },
  getters: {
    getEmployes: (state) => {
      return state.employes
    },
    getSalaries(state) {
      return state.employes.filter((e) => e.salarie)
    },
    getEmployeById: (state) => (_employeId) => {
      return state.employes.find((employe) => employe.employeId === _employeId)
    },
    getEmployeByUtilisateurId: (state) => (_utilisateurId) => {
      return state.employes.find((employe) => employe.utilisateur.utilisateurId === _utilisateurId)
    },
    getEmployesByUtilisateurId: (state) => (_utilisateurId) => {
      return state.employes.filter((employe) => employe.utilisateur.utilisateurId === _utilisateurId)
    },
    isTitulaireById: (state) => (_employeId) => {
      return state.employes.find((employe) => employe.employeId === _employeId).titulaire
    },
    getFindByIdEnAttenteDeReponse(state) {
      return state.findByIdEnAttenteDeReponse
    },
  },
  actions: {
    fetchEmployesByEtablissement({ commit }, _etablissement) {
      return axios
        .get(`${process.env.VUE_APP_URL_BACK_SERENITY}/employe/etablissement/${_etablissement.etablissementId}`)
        .then((response) => {
          commit('SET_EMPLOYES', response.data)
        })
    },
    setEmploye({ commit }, _employe) {
      commit('SET_EMPLOYE', _employe)
    },
    findById({ commit, getters }, _employeId) {
      for (let i = 0; i < getters.getFindByIdEnAttenteDeReponse.length; i++) {
        let employeId = getters.getFindByIdEnAttenteDeReponse[i]
        if (employeId === _employeId) {
          return null
        }
      }

      commit('ADD_FIND_BY_ID', _employeId)
      return axios
        .get(`${process.env.VUE_APP_URL_BACK_SERENITY}/employe/${_employeId}`)
        .then((response) => {
          commit('SET_EMPLOYE', response.data)
          commit('REMOVE_FIND_BY_ID', _employeId)
        })
        .catch(() => commit('REMOVE_FIND_BY_ID', _employeId))
    },
    recherche(state, _payload) {
      let query = `${process.env.VUE_APP_URL_BACK_SERENITY}/employe?_recherche=${_payload.recherche}`
      if (_payload.etablissementId) {
        query += `&_etablissementId=${_payload.etablissementId}`
      }
      return axios.get(query).then((response) => {
        return response.data
      })
    },
    rechercheDeNouveauxMembres(state, _payload) {
      return axios
        .get(
          `${process.env.VUE_APP_URL_BACK_SERENITY}/employe/rechercheDeNouveauxMembres/${_payload.serviceId}?_recherche=${_payload.search}`
        )
        .then((response) => {
          return response.data
        })
    },
    createEmploye({ commit }, _createEmployeCommand) {
      return axios.post(`${process.env.VUE_APP_URL_BACK_SERENITY}/employe`, _createEmployeCommand).then((response) => {
        commit('ADD_EMPLOYE', response.data)
        _createEmployeCommand.createMembreCommands.forEach((m) => {
          console.log('creation dun membre')
          m.employeId = response.data.employeId
          this.dispatch('membre/createMembre', m)
        })
        return response.data
      })
    },
    updateEmploye({ commit }, _employe) {
      return axios
        .put(`${process.env.VUE_APP_URL_BACK_SERENITY}/employe/${_employe.employeId}`, _employe)
        .then((response) => {
          commit('SET_EMPLOYE', response.data)
        })
    },
    fetchRecuperationById({ commit }, _employeId) {
      return axios
        .get(`${process.env.VUE_APP_URL_BACK_SERENITY}/employe/${_employeId}/recuperation`)
        .then((response) => {
          response.data['employeId'] = _employeId
          commit('SET_RECUPERATION', response.data)
        })
    },
    ajouterHeuresCredit({ dispatch }, _ajouterHeuresCreditCommand) {
      return axios
        .post(
          `${process.env.VUE_APP_URL_BACK_SERENITY}/employe/${_ajouterHeuresCreditCommand.employeId}/soldeRecup/credit`,
          _ajouterHeuresCreditCommand
        )
        .then(() => {
          dispatch('fetchRecuperationById', _ajouterHeuresCreditCommand.employeId)
        })
    },
    createContrat({ commit }, _createContratCommand) {
      return axios
        .post(
          `${process.env.VUE_APP_URL_BACK_SERENITY}/employe/${_createContratCommand.employeId}/contrat`,
          _createContratCommand
        )
        .then((response) => {
          commit('SET_EMPLOYE', response.data)
        })
    },
    updateContrat({ commit }, _updateContratCommand) {
      return axios
        .put(
          `${process.env.VUE_APP_URL_BACK_SERENITY}/employe/${_updateContratCommand.employeId}/contrat/${_updateContratCommand.contrat.contratId}`,
          _updateContratCommand
        )
        .then((response) => {
          commit('SET_EMPLOYE', response.data)
        })
    },
    deleteContrat({ commit }, _deleteContratCommand) {
      return axios
        .delete(
          `${process.env.VUE_APP_URL_BACK_SERENITY}/employe/${_deleteContratCommand.employeId}/contrat/${_deleteContratCommand.contratId}`
        )
        .then((response) => {
          commit('SET_EMPLOYE', response.data)
        })
    },
    createContratDeRemplacement({ commit }, _createContratDeRemplacementCommand) {
      return axios
        .post(
          `${process.env.VUE_APP_URL_BACK_SERENITY}/employe/${_createContratDeRemplacementCommand.remplacantId}/contrat/remplacement`,
          _createContratDeRemplacementCommand
        )
        .then((response) => {
          commit('SET_EMPLOYE', response.data)
        })
    },
    deleteEmploye({ commit }, _employeId) {
      return axios.delete(`${process.env.VUE_APP_URL_BACK_SERENITY}/employe/${_employeId}`).then(() => {
        commit('REMOVE_EMPLOYE', _employeId)
      })
    },
  },
}

<template>
  <v-text-field
    v-model="internalValue"
    default-country="France"
    label="Numéro de téléphone pro"
    clearable
    hide-no-data
    :required="required"
  />
</template>

<script>
export default {
  name: 's-phone-number-field',
  components: {},
  props: {
    value: {
      type: String,
      required: false,
      default: undefined,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      internalValue: undefined,
    }
  },
  computed: {
    primary() {
      return this.$vuetify.theme.primary
    },
    success() {
      return this.$vuetify.theme.success
    },
    error() {
      return this.$vuetify.theme.error
    },
  },
  created() {
    this.internalValue = this.value
  },
  watch: {
    value() {
      this.internalValue = this.value
    },
    internalValue() {
      this.$emit('input', this.internalValue)
    },
  },
}
</script>

<style scoped></style>

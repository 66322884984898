import axios from 'axios'

export default {
  namespaced: true,
  state: {
    services: [],
  },
  mutations: {
    SET_SERVICES(state, _services) {
      state.services = _services
    },
    ADD_SERVICE(state, _serviceToAdd) {
      state.services.push(_serviceToAdd)
    },
    UPDATE_SERVICE(state, _serviceToUpdate) {
      state.services.splice(
        state.services.findIndex((service) => service.serviceId === _serviceToUpdate.serviceId),
        1,
        _serviceToUpdate
      )
    },
    REMOVE_SERVICE(state, _serviceToRemoveId) {
      //Ferme le service
      state.services.splice(
        state.services.findIndex((service) => service.serviceId === _serviceToRemoveId),
        1
      )
    },
  },
  getters: {
    getServices(state) {
      return state.services.sort(function (sA, sB) {
        return sA.serviceId - sB.serviceId
      })
    },
    getServiceById: (state) => (_serviceId) => {
      return state.services.find((service) => service.serviceId === _serviceId)
    },
  },
  actions: {
    fetchServicesByEtablissement({ commit }, _etablissement) {
      return axios
        .get(`${process.env.VUE_APP_URL_BACK_SERENITY}/service/etablissement/${_etablissement.etablissementId}`)
        .then((response) => {
          commit('SET_SERVICES', response.data)
        })
    },
    searchServices(state, _search) {
      return axios
        .get(`${process.env.VUE_APP_URL_BACK_SERENITY}/service/search/?_search=${_search}`)
        .then((response) => {
          return response.data
        })
    },
    createService({ commit }, _serviceToCreate) {
      _serviceToCreate.comptablesId = _serviceToCreate.comptables.map((c) => c.utilisateur.utilisateurId)
      return axios.post(`${process.env.VUE_APP_URL_BACK_SERENITY}/service`, _serviceToCreate).then((response) => {
        commit('ADD_SERVICE', response.data)
      })
    },
    renommerService({ commit }, _renommerServiceCommand) {
      return axios
        .put(
          `${process.env.VUE_APP_URL_BACK_SERENITY}/service/${_renommerServiceCommand.serviceId}/renommer`,
          _renommerServiceCommand
        )
        .then((response) => {
          commit('UPDATE_SERVICE', response.data)
        })
    },
    modeliserLesPostesAIM({ commit }, _modeliserLesPostesAIMCommand) {
      const postes = {}
      for (const ligne of _modeliserLesPostesAIMCommand.listePostesAIMPrevus) {
        postes[ligne.posteAIM.posteAIMId] = ligne.budgetParDefaut
      }
      return axios
        .put(`${process.env.VUE_APP_URL_BACK_SERENITY}/service/${_modeliserLesPostesAIMCommand.serviceId}/modeleAIM`, {
          serviceId: _modeliserLesPostesAIMCommand.serviceId,
          postesAIMPrevus: postes,
        })
        .then((response) => {
          commit('UPDATE_SERVICE', response.data)
          return response.data
        })
    },
    placerModelePosteAIMAPosition({ commit }, _placerModelePosteAIMEnPositionCommand) {
      return axios
        .put(
          `${process.env.VUE_APP_URL_BACK_SERENITY}/service/${_placerModelePosteAIMEnPositionCommand.serviceId}/modeleAIM/ordonner`,
          _placerModelePosteAIMEnPositionCommand
        )
        .then((response) => {
          commit('UPDATE_SERVICE', response.data)
          return response.data
        })
    },
    designerChefDeService({ commit }, _designerChefDeServiceCommand) {
      return axios
        .put(
          `${process.env.VUE_APP_URL_BACK_SERENITY}/service/${_designerChefDeServiceCommand.serviceId}/chefDeService`,
          _designerChefDeServiceCommand
        )
        .then((response) => {
          commit('UPDATE_SERVICE', response.data)
        })
    },
    changerDeStrategieHeuresSurJoursFeries({ commit }, _changerDeStrategieHeuresSurJoursFeriesCommand) {
      return axios
        .put(
          `${process.env.VUE_APP_URL_BACK_SERENITY}/service/${_changerDeStrategieHeuresSurJoursFeriesCommand.serviceId}/jourFeriesAuto`,
          _changerDeStrategieHeuresSurJoursFeriesCommand
        )
        .then((response) => {
          commit('UPDATE_SERVICE', response.data)
        })
    },
    changerDeStrategieValidationAutonome({ commit }, _changerDeStrategieValidationAutonomeCommand) {
      return axios
        .put(
          `${process.env.VUE_APP_URL_BACK_SERENITY}/service/${_changerDeStrategieValidationAutonomeCommand.serviceId}/validationAutonome`,
          _changerDeStrategieValidationAutonomeCommand
        )
        .then((response) => {
          commit('UPDATE_SERVICE', response.data)
        })
    },
    fermerUnService({ commit }, _fermerUnServiceCommande) {
      return axios
        .put(
          `${process.env.VUE_APP_URL_BACK_SERENITY}/service/${_fermerUnServiceCommande.idDuServiceAFermer}/fermer`,
          _fermerUnServiceCommande
        )
        .then(() => {
          commit('REMOVE_SERVICE', _fermerUnServiceCommande.idDuServiceAFermer)
        })
    },
    supprimerUnService({ commit }, _serviceToDeleteId) {
      return axios.delete(`${process.env.VUE_APP_URL_BACK_SERENITY}/service/${_serviceToDeleteId}`).then(() => {
        commit('REMOVE_SERVICE', _serviceToDeleteId)
      })
    },
    exportPaye(state, _exportPayeCommand) {
      return axios
        .get(
          `${process.env.VUE_APP_URL_BACK_SERENITY}/service/${_exportPayeCommand.serviceId}/export_elements_paye.csv?_dateDebut=${_exportPayeCommand.dateDebut}&_dateFin=${_exportPayeCommand.dateFin}`
        )
        .then((response) => {
          const blob = new Blob([response.data], { type: 'text/csv' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = 'export_elements_paye.csv'
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch(console.error)
    },
  },
}

<template>
  <v-autocomplete
    v-model="selectedJour"
    :items="pickableJours"
    hide-no-data
    hide-details
    item-text="label"
    :label="label"
    :rules="[(v) => !!v]"
    required
    return-object
    :disabled="disabled"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 's-jour-picker',
  data() {
    return {
      selectedJour: undefined,
    }
  },
  props: {
    value: {
      type: Number,
      required: false,
      default: undefined,
    },
    label: {
      type: String,
      required: false,
      default: 'Jour',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    semaine: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  created() {
    if (this.value) {
      this.selectedJour = this.jours.find((j) => j.jourId === this.value)
    }
  },
  computed: {
    ...mapGetters({
      jours: 'calendrier/getJours',
    }),
    pickableJours() {
      if (this.semaine) {
        let joursAvecDate = this.jours.map((j) => {
          return { ...j }
        })
        joursAvecDate.forEach((j) => (j.label = this.getJourLabel(j)))
        return joursAvecDate
      } else {
        return this.jours
      }
    },
  },
  watch: {
    selectedJour() {
      this.$emit('input', this.selectedJour.jourId)
    },
  },
  methods: {
    getJourLabel(_jour) {
      return _jour.label + ' ' + this.getDate(_jour).format('DD/MM')
    },
    getDate(_jour) {
      return moment().year(this.semaine.annee).isoWeek(this.semaine.semaine).startOf('isoWeek').isoWeekday(_jour.jourId)
    },
  },
}
</script>

<style scoped></style>

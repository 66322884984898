<template>
  <v-dialog v-model="showDialog" persistent max-width="800px">
    <v-stepper v-model="activeStep">
      <v-stepper-header>
        <v-stepper-step :complete="activeStep > 1" step="1"> Date </v-stepper-step>
        <v-divider />
        <v-stepper-step :complete="activeStep > 2" step="2"> Générer </v-stepper-step>
        <v-divider />
        <v-stepper-step :complete="activeStep > 3" step="3"> Ajuster </v-stepper-step>
        <v-divider />
        <v-stepper-step step="4">Publier</v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1" key="step1" class="pa-0">
          <modele-astreintes-create-step1-date
            v-model="publication.dateMiseEnApplication"
            @annuler="close"
            @suivant="activeStep += 1"
          />
        </v-stepper-content>
        <v-stepper-content step="2" key="step2" class="pa-0">
          <modele-astreintes-create-step2-generer
            v-model="modeleAPublier"
            :etablissement="etablissement"
            :dateMiseEnApplication="publication.dateMiseEnApplication"
            @nbJoursParEmployesParAnUpdated="
              (nouveaunbJoursParEmployesParAn) => (nbJoursParEmployesParAn = nouveaunbJoursParEmployesParAn)
            "
            @precedent="activeStep -= 1"
            @suivant="activeStep += 1"
          />
        </v-stepper-content>
        <v-stepper-content step="3" key="step3" class="pa-0">
          <modele-astreintes-create-step3-ajuster
            v-model="modeleAPublier"
            :etablissement="etablissement"
            :nbJoursParEmployesParAn="nbJoursParEmployesParAn"
            @precedent="activeStep -= 1"
            @valider="activeStep += 1"
          />
        </v-stepper-content>
        <v-stepper-content step="4" key="step4" v-if="modeleAPublier" class="pa-0">
          <modele-astreintes-create-step4-publier
            key="step4"
            v-model="publication"
            :modeleAstreintes="modeleAPublier"
            @precedent="activeStep -= 1"
            @publier="publier"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-dialog>
</template>

<script>
import modeleAstreintesCreateStep1Date from './modele-astreintes-create-step1-date.vue'
import modeleAstreintesCreateStep2Generer from './modele-astreintes-create-step2-generer.vue'
import modeleAstreintesCreateStep3Ajuster from './modele-astreintes-create-step3-ajuster.vue'
import modeleAstreintesCreateStep4Publier from './modele-astreintes-create-step4-publier.vue'
import { mapActions } from 'vuex'

export default {
  name: 'modele-astreintes-create-dialog',
  components: {
    modeleAstreintesCreateStep1Date,
    modeleAstreintesCreateStep2Generer,
    modeleAstreintesCreateStep3Ajuster,
    modeleAstreintesCreateStep4Publier,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    etablissement: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDialog: false,
      activeStep: 1,
      modeleAPublier: undefined,
      publication: {
        dateMiseEnApplication: undefined,
        debutCyclePublication: undefined,
      },
      nbJoursParEmployesParAn: undefined,
    }
  },
  computed: {
    loaded() {
      return this.publierModeleAstreinteCommand
    },
  },
  created() {
    this.showDialog = this.value
    this.initialiser()
  },
  watch: {
    showDialog() {
      this.$emit('input', this.showDialog)
      if (this.showDialog === false) {
        this.initialiser()
      }
    },
    value() {
      this.showDialog = this.value
    },
  },
  methods: {
    ...mapActions({
      dispatchPublierModeleAstreintes: 'astreinte/publierModeleAstreinte',
    }),
    initialiser() {
      this.modeleAPublier = undefined
      this.publication = {
        dateMiseEnApplication: undefined,
        debutCyclePublication: undefined,
      }
    },
    publier() {
      this.dispatchPublierModeleAstreintes({
        etablissementId: this.etablissement.etablissementId,
        modeleAPublier: this.modeleAPublier,
        dateMiseEnApplication: this.publication.dateMiseEnApplication,
        debutCyclePublication: this.publication.debutCyclePublication.ordre,
      })
      this.close()
    },
    close() {
      this.showDialog = false
    },
  },
}
</script>

<style scoped></style>

<template>
  <s-page v-if="loaded">
    <template v-slot:lateral-panel>
      <s-lateral-panel :consistent="false">
        <v-container justify="space-between" class="pb-0">
          <v-row>
            <v-col cols="9">
              <span class="title">Planning</span>
            </v-col>
            <v-col cols="3">
              <v-menu v-model="menuVisible" :close-on-content-click="false" absolute offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn icon color="info" v-on="on">
                    <v-icon> fa-cog </v-icon>
                  </v-btn>
                </template>
                <v-list v-if="service" class="pb-0">
                  <v-list-item>
                    <s-jour-nuit-btn-toggle v-model="jour_nuit" />
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title v-text="'Afficher les absences'" />
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-switch v-model="afficherAbsences" inset />
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title v-text="'Afficher les RC/RH'" />
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-switch v-model="afficherJourDeRepos" inset :loading="loadingJourDeRepos" />
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider />
                  <v-list-item
                    v-if="
                      isInRole('CHEF_DE_SERVICE_' + service.serviceId) ||
                      isInRole('ADMINISTRATEUR_' + service.etablissement.etablissementId)
                    "
                    :to="{
                      name: 'modelePlanning',
                      params: { serviceId: service.serviceId },
                    }"
                  >
                    <v-list-item-icon>
                      <v-icon color="info">fa-calendar-alt</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Modifier le modèle</v-list-item-title>
                  </v-list-item>
                  <telecharger-planning-modal
                    :creneaux="creneaux"
                    :jours-de-repos="joursDeRepos"
                    :semaines="selectedSemaines"
                    :membres="sortedSelectedMembres"
                    :jour_nuit="jour_nuit"
                    :service="service"
                    :display-jours-de-repos="afficherJourDeRepos"
                  ></telecharger-planning-modal>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
        <v-container v-if="loaded && selectedSemaines[0]" class="pa-2 pt-0" color="white">
          <membres-checkbox-group
            v-model="selectedMembres"
            :group-title="service.nom"
            :membres="membres"
            :semaine="selectedSemaines[0]"
            expand
          />
        </v-container>
      </s-lateral-panel>
    </template>
    <template v-slot:main-panel>
      <s-main-panel color="white">
        <planning-week-picker v-model="selectedSemaines" :display-jours-de-repos="afficherJourDeRepos" />
        <planning-body
          :creneaux="creneaux"
          :jours-de-repos="joursDeRepos"
          :semaines="selectedSemaines"
          :membres="sortedSelectedMembres"
          :jour_nuit="jour_nuit"
          :service="service"
          :display-jours-de-repos="afficherJourDeRepos"
          :affichage-impression="false"
        />
        <creneau-de-travail-create-dialog
          v-if="
            selectedSemaines[0] &&
            (isInRole('CHEF_DE_SERVICE_' + service.serviceId) ||
              isInRole('ADMINISTRATEUR_' + service.etablissement.etablissementId))
          "
          :date="currentDate"
          mode="fab"
        />
      </s-main-panel>
    </template>
  </s-page>
</template>

<script>
import MembresCheckboxGroup from '../../components/membre/membre-checkbox-group'
import planningBody from '@/components/planning/planning'
import planningWeekPicker from '../../components/planning/semainePicker/planning-semaine-picker'
import creneauDeTravailCreateDialog from '../../components/creneau/creneau-de-travail-create-dialog'
import { mapGetters, mapActions } from 'vuex'
import { initialDataLoaded } from '@/store/modules/helpers'
import SMainPanel from '@/components/global/s-main-panel.vue'
import TelechargerPlanningModal from '@/components/planning/telecharger-planning-modal.vue'
import SJourNuitBtnToggle from '@/components/global/s-jour-nuit-btn-toggle.vue'
import SLateralPanel from '@/components/global/s-lateral-panel.vue'
import SPage from '@/components/global/s-page.vue'

export default {
  name: 'service-planning',
  components: {
    SPage,
    SLateralPanel,
    SJourNuitBtnToggle,
    TelechargerPlanningModal,
    SMainPanel,
    MembresCheckboxGroup,
    planningBody,
    planningWeekPicker,
    creneauDeTravailCreateDialog,
  },
  data() {
    return {
      menuVisible: false,
      selectedMembres: [],
      selectedSemaines: [],
      afficherJourDeReposData: false,
      fetchingJoursDeRepos: [],
    }
  },
  computed: {
    ...initialDataLoaded,
    ...mapGetters({
      isInRole: 'security/isInRole',
      getISOWeekOfDate: 'calendrier/getISOWeekOfDate',
      getDateofISOWeek: 'calendrier/getDateOfISOWeek',
      getServiceById: 'service/getServiceById',
      getMembresByServiceId: 'membre/getMembresByServiceId',
      getCreneaux: 'servicePlanning/getCreneaux',
      getJoursDeRepos: 'servicePlanning/getJoursDeRepos',
      getPlanningParameters: 'application/getPlanningParameters',
      currentDate: 'calendrier/getCurrentDate',
    }),
    loaded() {
      return initialDataLoaded && this.service && this.membres && this.sortedSelectedMembres
    },
    afficherAbsences: {
      get() {
        return this.getPlanningParameters.afficherAbsences
      },
      set() {
        this.switchPlanningAfficherAbsences()
      },
    },
    afficherJourDeRepos: {
      get() {
        return this.afficherJourDeReposData
      },
      set() {
        //Si il n'y a aucun jours de repos de chargés, on fetch les données
        if (!this.afficherJourDeReposData && this.joursDeRepos.length === 0) {
          //On lance la requête pour l'ensemble des semaines sélectionnées
          this.selectedSemaines.forEach((s) => {
            //Pour afficher le status de chargement, on ajoute chaque semaine recherché dans la liste des semaines en cours de fetch
            this.fetchingJoursDeRepos.push(s.numero)
            this.fetchJourDeReposSemaineByService({
              serviceId: this.service.serviceId,
              annee: s.annee,
              semaine: s.semaine,
            }).then(() => {
              this.fetchingJoursDeRepos = this.fetchingJoursDeRepos.filter((e) => e !== s.numero)
            })
          })
        }
        this.afficherJourDeReposData = !this.afficherJourDeReposData
      },
    },
    loadingJourDeRepos() {
      return this.fetchingJoursDeRepos.length > 0
    },
    jour_nuit: {
      get() {
        return this.getPlanningParameters.jour_nuit
      },
      set(_value) {
        this.setPlanningJourNuit(_value)
      },
    },
    service() {
      return this.getServiceById(parseInt(this.$route.params.serviceId, 10))
    },
    creneaux() {
      return this.getCreneaux().filter(
        (creneau) =>
          this.selectedSemaines.map((semaine) => semaine.numero).includes(this.getISOWeekOfDate(creneau.date).numero) &&
          this.service.serviceId === creneau.membre.serviceId
      )
    },
    joursDeRepos() {
      return this.getJoursDeRepos()
    },
    membres() {
      let membres = []
      this.creneaux.forEach((c) => {
        if (this.neContientPasLeMembreId(membres, c.membre.membreId)) {
          membres.push(c.membre)
        }
      })

      return membres.sort(function (mA, mB) {
        return mA - mB
      })
    },
    sortedSelectedMembres() {
      return [...this.selectedMembres].sort(function (mA, mB) {
        return mA - mB
      })
    },
  },
  created() {
    this.selectedMembres = [...this.membres]
  },
  watch: {
    membres() {
      this.selectedMembres = [...this.membres]
    },
  },
  methods: {
    ...mapActions({
      switchPlanningAfficherAbsences: 'application/switchPlanningAfficherAbsences',
      fetchJourDeReposSemaineByService: 'servicePlanning/fetchJourDeReposSemaineByService',
      setPlanningJourNuit: 'application/setPlanningJourNuit',
    }),
    generateReport() {
      this.$refs.html2Pdf.generatePdf()
    },
    contientLeMembreId(_tableauDesMembres, _idATrouver) {
      for (const membre of _tableauDesMembres) {
        if (parseInt(membre.membreId) === parseInt(_idATrouver)) {
          return true
        }
      }

      return false
    },
    neContientPasLeMembreId(_tableauDesMembres, _idATrouver) {
      for (const membre of _tableauDesMembres) {
        if (parseInt(membre.membreId) === parseInt(_idATrouver)) {
          return false
        }
      }

      return true
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

<template>
  <div class="planning-header-semaine">
    <div v-if="!affichageImpression" class="planning-header-semaine-title">Semaine {{ semaine.numero }}</div>
    <div v-if="!affichageImpression" class="planning-header-semaine-temps">
      <planning-header-semaine-membre
        v-for="membre in membres"
        :key="membre.membreId"
        :creneauxMembre="getCreneauxByMembre(membre)"
        :membre="membre"
        :afficher-noms="affichageImpression"
      />
    </div>
    <v-container v-else fluid>
      <v-row no-gutters>
        <v-col cols="6" v-for="membre in membres" :key="membre.membreId">
          <legende-planning-semaine :membre="membre" :creneaux="getCreneauxByMembre(membre)"></legende-planning-semaine>
        </v-col>
      </v-row>
    </v-container>
    <div ref="jours" class="planning-header-semaine-jours">
      <div class="planning-header-jour" v-for="jour in jours" :key="jour.jourId">
        {{ getJourLabel(jour) }}
      </div>
    </div>
  </div>
</template>

<script>
import planningHeaderSemaineMembre from '@/components/planning/header/planning-header-semaine-membre.vue'
import LegendePlanningSemaine from '@/components/planning/header/legende-planning-semaine.vue'
import moment from 'moment/moment'
import { mapGetters } from 'vuex'

export default {
  components: { LegendePlanningSemaine, planningHeaderSemaineMembre },
  props: {
    affichageImpression: {
      type: Boolean,
      default: false,
    },
    semaine: {
      type: Object,
      required: true,
    },
    membres: {
      type: Array,
      required: true,
    },
    creneauxSemaine: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getDateOfISOWeekDay: 'calendrier/getDateOfISOWeekDay',
      jours: 'calendrier/getJours',
    }),
  },
  methods: {
    getDate(_jour) {
      return this.getDateOfISOWeekDay(this.semaine.annee, this.semaine.semaine, _jour.jourId)
    },
    getJourLabel(_jour) {
      if (this.shortLabel === true) {
        return _jour.shortLabel + ' ' + moment(this.getDate(_jour)).format('DD/MM')
      } else {
        return _jour.label + ' ' + moment(this.getDate(_jour)).format('DD/MM')
      }
    },
    getCreneauxByMembre(_membre) {
      return this.creneauxSemaine.filter((creneau) => creneau.membre.membreId === _membre.membreId)
    },
  },
}
</script>

<style scoped>
.planning-header-semaine {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  border-bottom: 1px solid #c0c0c0;
}

.planning-header-semaine-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
}

.planning-header-semaine-temps {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  align-self: stretch;
  justify-content: space-between;

  margin: 5px 10px;
}

.planning-header-semaine-jours {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
}

.planning-header-jour {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;

  border-left: 5px solid #c0c0c0;
  color: #000;
}
</style>

<template>
  <v-dialog v-model="showDialog" persistent max-width="600px">
    <v-card v-if="showDialog">
      <s-toolbar text="Envoyer un e-mail de test" update @close="close()" />
      <v-card-text>
        <v-form v-if="loaded" v-model="formValid">
          <v-text-field
            v-model="envoyerUnMailDeTestCommand.to"
            :rules="emailRules"
            label="Envoyer le test à*"
            required
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="grey" text @click="close()">Annuler</v-btn>
        <v-btn color="success" :disabled="!formValid" @click="validate()"> Envoyer </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import EventBus from '../../EventBus'

export default {
  name: 'envoyer-mail-test-dialog',
  data() {
    return {
      showDialog: false,
      formValid: false,
      emailRules: [
        (v) => !!v || 'Adresse E-mail obligatoire',
        (v) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(
            v
          ) || "Format de l'adresse E-mail incorrect",
      ],
      envoyerUnMailDeTestCommand: {
        to: undefined,
      },
    }
  },
  computed: {
    loaded() {
      return true
    },
  },
  created() {
    EventBus.$on('showEnvoyerMailTestDialog', () => {
      this.showDialog = true
    })
    this.initialize()
  },
  methods: {
    ...mapActions({
      envoyerUnMailDeTest: 'admin/envoyerUnMailDeTest',
    }),
    initialize() {
      this.envoyerUnMailDeTestCommand = {
        to: undefined,
      }
    },
    validate() {
      this.envoyerUnMailDeTest(this.envoyerUnMailDeTestCommand)
      this.close()
    },
    close() {
      this.showDialog = false
      this.initialize()
    },
  },
}
</script>

<style scoped></style>

<template>
  <tr>
    <td v-if="!adding" colspan="4">
      <v-container>
        <v-row justify="center">
          <v-btn class="mt-2" color="success" @click="setupEdit"><v-icon>mdi-plus</v-icon> Ajouter un poste</v-btn>
        </v-row>
      </v-container>
    </td>
    <td v-if="adding">
      <v-select
        v-model="tempLigneModeleAIM.posteAIM"
        :items="postesNonDefinits"
        :item-value="'posteAIMId'"
        :item-text="'libelle'"
        label="Poste"
      >
      </v-select>
    </td>

    <td v-if="adding">
      <v-text-field
        v-model="tempLigneModeleAIM.budgetParDefaut"
        :rules="[(v) => (!isNaN(v) && v > 0) || 'Entrer une valeur numérique']"
        type="number"
        label="Montant"
      ></v-text-field>
    </td>

    <td v-if="adding">{{ posteSelectionne ? posteSelectionne.libellePeriodiciteDAIM : '' }}</td>

    <td v-if="adding" class="modele-table-aim-actions">
      <v-tooltip left>
        Ajouter le poste
        <template #activator="{ on, attrs }">
          <v-btn class="mx-1" small icon v-on="on" v-bind="attrs" color="success" @click="confirmEdit"
            ><v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
      </v-tooltip>
      <v-tooltip bottom>
        Annuler l'ajout
        <template #activator="{ on, attrs }">
          <v-btn class="mx-1" small icon v-on="on" v-bind="attrs" color="grey" @click="teardownEdit"
            ><v-icon>mdi-cancel</v-icon>
          </v-btn>
        </template>
      </v-tooltip>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'ligne-ajout-poste-modele-aim',
  data() {
    return {
      formValid: false,
      tempLigneModeleAIM: {},
      adding: false,
    }
  },
  props: {
    postesNonDefinits: {
      type: Array,
      requires: true,
    },
  },
  computed: {
    posteSelectionne() {
      return this.postesNonDefinits.filter((value) => value.posteAIMId === this.tempLigneModeleAIM.posteAIM).at(0)
    },
  },
  methods: {
    setupEdit() {
      this.formValid = false
      this.tempLigneModeleAIM = {}
      this.adding = true
    },
    confirmEdit() {
      this.$emit('add', {
        posteAIM: this.posteSelectionne,
        budgetParDefaut: this.tempLigneModeleAIM.budgetParDefaut,
      })
      this.teardownEdit()
    },
    teardownEdit() {
      this.adding = false
      this.tempLigneModeleAIM = {}
    },
  },
}
</script>
<style scoped>
.modele-table-aim-actions {
  max-width: 6vh !important;
}

tr:hover {
  background-color: transparent !important;
}
</style>

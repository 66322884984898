<template>
  <v-list-item inactive :style="{ 'pointer-events': 'none !important' }">
    <v-list-item-content>
      <v-list-item-title v-text="title" />
    </v-list-item-content>
    <v-list-item-action>
      <div class="demande-heures-supp-list-item-action">
        <v-btn
          :style="{ 'pointer-events': 'auto !important' }"
          class="mr-2"
          color="warning"
          fab
          x-small
          @click="$emit('annuler')"
        >
          <v-icon small>fa-times</v-icon>
        </v-btn>
      </div>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'demande-heures-supp-list-item',
  props: {
    demande: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      formatDateToDisplay: 'calendrier/formatDateToDisplay',
    }),
    title() {
      return (
        this.formatDateToDisplay(this.demande.date) +
        ' - ' +
        this.demande.heureDebut.toHeureMinute +
        ' à ' +
        this.demande.heureFin.toHeureMinute
      )
    },
  },
}
</script>

<style scoped>
.demande-heures-supp-list-item-action {
  display: flex;
  flex-direction: row;
}
</style>

<template>
  <v-dialog v-model="showDialog" persistent max-width="600px">
    <v-card v-if="showDialog">
      <s-toolbar text="Remplacer un membre" update @close="close()" />
      <v-card-text>
        <v-form ref="form" v-model="formValid">
          <v-row>
            <v-col cols="12">
              <div class="text-overline grey--text text--darken-1">Remplacer</div>
              <div class="text-body-2 black--text">
                {{ membre.employe.nomComplet }}
              </div>
            </v-col>
            <v-col cols="12">
              <service-membre-autocomplete
                v-model="membreRemplacerForm.remplacantMembreId"
                :service-id="membre.serviceId"
                label="Remplacer par"
                titulaires
                required
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" text @click="showDialog = false">Annuler</v-btn>
        <v-btn color="info" :disabled="!formValid" @click="validate()">Remplacer</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import serviceMembreAutocomplete from '../../components/service/service-membre-autocomplete'

export default {
  name: 'modele-planning-interchanger-membre-dialog',
  components: {
    serviceMembreAutocomplete,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    membre: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDialog: false,
      membreRemplacerForm: undefined,
      formValid: false,
    }
  },
  created() {
    this.showDialog = this.value
    this.initialize()
  },
  watch: {
    value() {
      this.showDialog = this.value
    },
    showDialog() {
      this.$emit('input', this.showDialog)
    },
  },
  methods: {
    ...mapActions({
      interchangerMembre: 'modelePlanning/interchangerMembres',
    }),
    updateFormValid(_validity) {
      this.formValid = _validity
    },
    initialize() {
      this.membreRemplacerForm = {
        remplaceMembreId: this.membre.membreId,
        remplacantMembreId: undefined,
      }
    },
    validate() {
      if (this.formValid) {
        this.interchangerMembre(this.membreRemplacerForm)
        this.close()
      }
    },
    close() {
      this.showDialog = false
    },
  },
}
</script>

<style scoped></style>

<template>
  <div>
    <v-card width="600" class="mx-auto mt-5">
      <v-form @submit.prevent="login">
        <v-card-title>
          <h1 class="display-1">Connexion</h1>
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="email" label="email*" prepend-icon="mdi-account-circle" />
          <v-text-field
            v-model="password"
            label="mot de passe*"
            :type="showPassword ? 'text' : 'password'"
            prepend-icon="mdi-lock"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
          />
        </v-card-text>
        <p>{{ error }}</p>
        <v-divider></v-divider>
        <v-card-actions>
          <!--<router-link :to="{ name: 'register' }"> Inscrivez vous </router-link>
          <v-btn color="success">Register</v-btn> -->
          <v-spacer></v-spacer>
          <v-btn color="info" type="submit">Connexion</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      prevRoute: undefined,
      email: '',
      password: '',
      error: null,
      showPassword: false,
    }
  },
  methods: {
    login() {
      this.$store
        .dispatch('security/login', {
          email: this.email,
          password: this.password,
        })
        .then(() => {
          if (this.$route.query.nextUrl) {
            this.$router.push('' + this.$route.query.nextUrl)
          } else {
            this.$router.push({ name: 'planningUtilisateur' })
          }
        })
        .catch((err) => {
          this.error = err.response.data.error
        })
    },
  },
}
</script>

<style lang="scss" scoped></style>

<template>
  <v-container style="height: 100%">
    <v-row no-gutters>
      <v-col cols="2">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="primary" v-bind="attrs" v-on="on">
              <v-icon>fa-info-circle</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="congeType in congesTypes" :key="congeType.congesTypeId">
              <v-list-item-icon>
                <v-icon :color="congeType.couleur">mdi-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ congeType.label }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <impression-conges-apercu
          v-if="!loading"
          :employes="employes"
          :get-donnees-by-employe-id="getDonneesByEmployeId"
          :position="position"
          :date-string="positionToString"
          :loading="loading"
        ></impression-conges-apercu>
      </v-col>
      <v-col cols="10">
        <v-row no-gutters>
          <v-col cols="1">
            <v-btn block tile color="white" depressed @click="scrollClicked(-1)">
              <v-icon large>mdi-chevron-left</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="10">
            <v-row no-gutters>
              <v-col cols="12">
                <div class="mois body-1 text-center">{{ positionToString }}</div>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <ligne-jours-conges-apercu :position="position" />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="1">
            <v-btn block tile color="white" depressed @click="scrollClicked(+1)">
              <v-icon large>mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <perfect-scrollbar style="height: 92%">
      <conges-apercu-ligne-employe
        v-for="(employe, index) in employes"
        :employeId="employe.employeId"
        :mois="position"
        :donnees="getDonneesByEmployeId(employe.employeId)"
        :divider="index !== employes.length - 1"
        :loading="loading"
        :key="employe.employeId"
      />
    </perfect-scrollbar>
  </v-container>
</template>

<script>
import CongesApercuLigneEmploye from './conges-apercu-ligne-employe'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import _ from 'lodash'
import ImpressionCongesApercu from '@/components/conges/apercu/impression-conges-apercu.vue'
import LigneJoursCongesApercu from '@/components/conges/apercu/ligne-jours-conges-apercu.vue'

export default {
  name: 'conges-apercu',
  components: {
    LigneJoursCongesApercu,
    ImpressionCongesApercu,
    CongesApercuLigneEmploye,
  },
  props: {
    etablissementId: {
      type: Number,
      required: true,
    },
    serviceId: {
      type: Number,
      required: false,
      default: undefined,
    },
    positionInitiale: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      position: undefined,
      loading: true,
      donneesApercu: [],
    }
  },
  computed: {
    ...mapGetters({
      getCongesType: 'conges/getCongesTypes',
      getEmployes: 'employe/getSalaries',
      getTitulairesByServiceId: 'membre/getTitulairesByServiceId',
      getMonthLabel: 'calendrier/getMonthLabel',
      getMonthOfDate: 'calendrier/getMonthOfDate',
      getDateOfMonth: 'calendrier/getDateOfMonth',
      getNbDaysInMonth: 'calendrier/getNbDaysInMonth',
      getDayOfTheWeekOfDate: 'calendrier/getDayOfTheWeekOfDate',
    }),
    congesTypes() {
      let items = this.getCongesType
      items.sort((a, b) => a.couleur.localeCompare(b.couleur))
      return items
    },
    employes() {
      if (this.serviceId) {
        return this.getTitulairesByServiceId(this.serviceId).map((m) => m.employe)
      } else {
        return this.getEmployes
      }
    },
    positionToString() {
      return this.getMonthLabel(this.position.mois) + ' ' + this.position.annee
    },
  },
  created() {
    if (!this.positionInitiale) {
      this.position = this.getMonthOfDate(new Date())
    } else {
      this.position = this.positionInitiale
    }
  },
  watch: {
    etablissementId() {
      if (this.position) {
        this.fetchApercuConges({
          etablissementId: this.etablissementId,
          annee: this.position.annee,
          mois: this.position.mois,
        }).then((response) => (this.donneesApercu = response.data))
      }
    },
    position() {
      this.loading = true
      if (this.position) {
        this.donneesApercu = []
        this.debouncedFetchApercuConges()
      }
    },
  },
  methods: {
    ...mapActions({
      fetchApercuConges: 'conges/fetchApercuCongesMensuel',
    }),
    debouncedFetchApercuConges: _.debounce(function () {
      this.fetchApercuConges({
        etablissementId: this.etablissementId,
        annee: this.position.annee,
        mois: this.position.mois,
      })
        .then((response) => {
          this.donneesApercu = response.data
        })
        .finally(() => (this.loading = false))
    }, 500),
    getDonneesByEmployeId(_employeId) {
      return this.donneesApercu.find((a) => a.employeId === _employeId)
    },
    getPreviousMois(_mois) {
      let referenceDate = moment(this.getDateOfMonth(_mois.annee, _mois.mois)).subtract(1, 'M')
      return this.getMonthOfDate(referenceDate)
    },
    getNextMois(_mois) {
      let referenceDate = moment(this.getDateOfMonth(_mois.annee, _mois.mois)).add(1, 'M')
      return this.getMonthOfDate(referenceDate)
    },
    scrollClicked(_direction) {
      if (_direction > 0) {
        this.position = this.getNextMois(this.position)
      } else {
        this.position = this.getPreviousMois(this.position)
      }
    },
  },
}
</script>

<style>
.mois {
  display: inline flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
</style>

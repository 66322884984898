<template>
  <v-form ref="form" v-model="formValid">
    <v-card-text>
      <v-container class="ma-0">
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="etablissement.raisonSociale"
              label="Raison Sociale*"
              :rules="[(v) => !!v]"
              required
            />
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="etablissement.numeroFINESS" label="Numéro FINESS" />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="etablissement.extensionSIRET"
              label="Extension SIRET"
              :rules="[(v) => !!v]"
              required
            />
          </v-col>
        </v-row>
      </v-container>
      <small>*information obligatoire</small>
    </v-card-text>
  </v-form>
</template>

<script>
export default {
  name: 'etablissement-form',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formValid: false,
      etablissement: undefined,
    }
  },
  created() {
    this.etablissement = this.value
  },
  watch: {
    value() {
      this.etablissement = this.value
    },
    formValid() {
      this.$emit('updateFormValidity', this.formValid)
    },
    formService() {
      this.$emit('input', this.etablissement)
    },
  },
}
</script>

<style scoped></style>

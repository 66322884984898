<template>
  <v-hover v-slot="{ hover }">
    <v-list-item>
      <v-list-item-avatar>
        <utilisateur-avatar :utilisateur="commentaire.auteur" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title
          v-html="commentaire.commentaire"
          :class="commentaire.supprime ? 'font-italic grey--text text--lighten-1' : ''"
        ></v-list-item-title>
        <v-list-item-subtitle class="text-caption" v-html="formattedInstantPublication"></v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-btn
          v-if="hover && utilisateur.utilisateurId === commentaire.auteur.utilisateurId && !commentaire.supprime"
          icon
          @click="deleteCommentaire"
          color="error"
          small
        >
          <v-icon small color="error">fa-trash</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </v-hover>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import UtilisateurAvatar from '../utilisateur/utilisateur-avatar'

export default {
  name: 'demande-commentaire-list-item',
  components: { UtilisateurAvatar },
  props: {
    commentaire: {
      type: Object,
      required: true,
    },
    demande: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      utilisateur: 'security/getUtilisateur',
      formatDateTime: 'calendrier/formatDateTimeToDisplay',
    }),
    formattedInstantPublication() {
      return (
        'par ' + this.commentaire.auteur.nomComplet + ' le ' + this.formatDateTime(this.commentaire.instantPublication)
      )
    },
  },
  methods: {
    ...mapActions({
      dispatchDeleteCommentaire: 'demande/deleteCommentaire',
    }),
    deleteCommentaire() {
      this.dispatchDeleteCommentaire({
        demandeId: this.demande.demandeId,
        commentaireId: this.commentaire.commentaireId,
      })
    },
  },
}
</script>

<style scoped>
.demande-validation-list-item-action {
  display: flex;
  flex-direction: row;
}
</style>

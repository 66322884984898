<script setup>
import StatutFicheAim from '@/components/fiche-aim/status-fiche-aim.vue'
</script>

<template>
  <v-list-item :to="to">
    <v-list-item-content>
      <v-list-item-title>Fiche pour {{ monthLabel(ficheAim.mois) }} {{ ficheAim.annee }}</v-list-item-title>
      <v-list-item-subtitle> {{ ficheAim.lignes.length }} postes définits </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <statut-fiche-aim :statut-fiche="ficheAim.statut"></statut-fiche-aim>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'fiche-aim-usager-list-item',
  props: {
    ficheAim: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      monthLabel: 'calendrier/getMonthLabel',
    }),
    estEnPreparation() {
      return this.ficheAim.statut === 'EN_PREPARATION'
    },
    to() {
      return {
        name: this.estEnPreparation ? 'preparation-fiche-aim' : 'fiche-aim',
        params: { ficheAimId: this.ficheAim.ficheAIMId },
      }
    },
  },
}
</script>

<style scoped></style>

<template>
  <v-dialog v-if="aRemplacer" v-model="showDialog" persistent width="900px">
    <template v-slot:activator="{ on }">
      <v-list-item color="green" v-on="on">
        <v-list-item-icon>
          <v-icon color="green">fa-people-arrows</v-icon>
        </v-list-item-icon>
        <v-list-item-title color="green">Remplacer</v-list-item-title>
      </v-list-item>
    </template>
    <v-card v-if="showDialog">
      <s-toolbar text="Remplacement" create @close="close()" />
      <v-card-text>
        <v-row v-if="loaded">
          <v-col cols="6">
            <span class="title">Remplacé(e)</span>
            <creneau-form v-model="creneauRemplaceDisplay" disabled :membres="membres" />
          </v-col>
          <v-col cols="6">
            <span class="title">Remplacant(e)</span>
            <creneau-form
              v-model="creneauRemplacant"
              :editableDate="false"
              :membres="membreRemplacantPossibles"
              @close="close()"
              @validated="createCreneauRemplacement()"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import CreneauForm from './creneau-form'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'creneau-create',
  components: {
    CreneauForm,
  },
  props: {
    creneauRemplace: {
      type: Object,
      required: true,
    },
    indisponibilite: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      showDialog: false,
      creneauRemplacant: undefined,
    }
  },
  computed: {
    ...mapGetters({
      getMembresByServiceId: 'membre/getMembresByServiceId',
      getRemplacants: 'servicePlanning/getRemplacants',
    }),
    aRemplacer() {
      return (
        this.indisponibilite &&
        !(this.getRemplacants(this.creneauRemplace).length > 0 || this.creneauRemplace.nePasRemplacer)
      )
    },
    creneauRemplaceDisplay: {
      get() {
        return this.creneauRemplace
      },
      set() {},
    },
    serviceId() {
      return parseInt(this.$route.params.serviceId, 10)
    },
    membres() {
      return this.getMembresByServiceId(this.serviceId)
    },
    loaded() {
      return this.creneauRemplacant && this.creneauRemplace
    },
    membreRemplacantPossibles() {
      let membreRemplaceIndex = this.membres.findIndex((m) => m.membreId === this.creneauRemplace.membre.membreId)
      return [...this.membres].splice(membreRemplaceIndex, 1)
    },
  },
  created() {
    this.creneauRemplacant = JSON.parse(JSON.stringify(this.creneauRemplace))
    this.creneauRemplacant.creneauId = 0
    this.creneauRemplacant.membre = undefined
  },
  methods: {
    ...mapActions({
      remplacerCreneau: 'servicePlanning/remplacerCreneau',
    }),
    createCreneauRemplacement() {
      this.remplacerCreneau({
        remplaceId: this.creneauRemplace.creneauId,
        remplacant: this.creneauRemplacant.membreId,
        heureDebut: this.creneauRemplacant.heureDebut,
        heureFin: this.creneauRemplacant.heureFin,
      })
      this.close()
    },
    close() {
      this.showDialog = false
    },
  },
}
</script>

<style scoped></style>

<template>
  <v-container>
    <v-row>
      <v-toolbar flat dense>
        <span class="title">Semaines</span>
        <v-spacer />
        <v-btn color="info" fab x-small @click="editing = !editing">
          <v-icon color="white" small v-if="editing === true">fa-check</v-icon>
          <v-icon color="white" small v-else>fa-edit</v-icon>
        </v-btn>
      </v-toolbar>
      <v-col cols="12" v-if="editing">
        <div class="semaine" v-for="semaine in modelePlanning.semaines" :key="semaine.ordre">
          <v-btn small tile depressed disabled> Semaine {{ semaine.ordre }} </v-btn>
          <v-icon class="ml-4" color="success" small @click="copyModeleSemaine(semaine.ordre)">fa-copy</v-icon>
          <v-icon class="ml-4" color="error" small @click="deleteModeleSemaine(semaine.ordre)">fa-trash</v-icon>
        </div>
        <v-btn class="v-btn-createSemaine" small color="green" block dark @click="createModeleSemaine()">
          <v-icon class="mr-1" small color="white">fa-plus</v-icon>
          Semaine
        </v-btn>
      </v-col>
      <v-col cols="12" v-else>
        <v-btn
          v-for="semaine in modelePlanning.semaines"
          :key="semaine.ordre"
          :class="getActiveClass(semaine)"
          @click.exact="setSelectedSemaine(semaine)"
          @click.ctrl="addSelectedSemaine(semaine)"
          color="primary"
          outlined
          small
          tile
          dense
          block
        >
          Semaine {{ semaine.ordre }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'modelePlanningSetupSemaines',
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      editing: false,
      selectedSemaines: undefined,
    }
  },
  computed: {
    ...mapGetters({
      modelePlanning: 'modelePlanning/getModelePlanning',
    }),
  },
  created() {
    this.selectedSemaines = [...this.value]
    if (this.selectedSemaines.length === 0) {
      this.selectedSemaines.push(this.modelePlanning.semaines[0])
    }
  },
  watch: {
    selectedSemaines() {
      this.$emit(
        'input',
        [...this.selectedSemaines].sort((sA, sB) => sA.ordre - sB.ordre)
      )
    },
    modelePlanning: {
      handler() {
        this.selectedSemaines = this.modelePlanning.semaines.filter((semaine) =>
          this.selectedSemaines.map((s) => s.ordre).includes(semaine.ordre)
        )
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      createModeleSemaine: 'modelePlanning/addModeleSemaine',
      copyModeleSemaine: 'modelePlanning/copyModeleSemaine',
      deleteModeleSemaine: 'modelePlanning/deleteModeleSemaine',
    }),
    getActiveClass(_semaine) {
      return {
        'modele-semaine-button': true,
        semaine: true,
        'v-btn--active': this.isSelected(_semaine),
        activable: true,
        disabled: false,
      }
    },
    setSelectedSemaine(_semaine) {
      this.selectedSemaines = []
      this.addSelectedSemaine(_semaine)
    },
    addSelectedSemaine(_semaine) {
      var semaineIndex = this.selectedSemaines.indexOf(_semaine)
      if (semaineIndex >= 0 && this.selectedSemaines.length >= 2) {
        this.selectedSemaines.splice(semaineIndex, 1)
      } else {
        if (this.selectedSemaines.length <= 2) {
          this.selectedSemaines.push(_semaine)
        }
      }
    },
    isSelected(_semaine) {
      return this.selectedSemaines.some((s) => s.ordre === _semaine.ordre)
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn-toggle-semaines {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  align-self: stretch;
}

.v-btn-createSemaine {
  margin-top: 8px;
}

.semaine {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.semaine .v-btn {
  flex: 1;
}

.semaine + .semaine {
  margin-top: 5px;
}

.modele-semaine-button.activable {
  cursor: pointer;
}

.modele-semaine-button.disabled {
  color: #a6a6a6;
  outline-color: #a6a6a6;
}
</style>

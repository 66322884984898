<template>
  <v-card dense>
    <v-app-bar flat dense color="primary">
      <utilisateur-avatar v-if="utilisateur" :utilisateur="utilisateur" :size="36" />
      <v-toolbar-title class="text-h6 white--text pl-0 ml-2">
        {{ resume.nomComplet }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div
        class="salarie-couleur-indicateur"
        v-bind:style="{
          'background-color': couleur,
        }"
      />
    </v-app-bar>
    <v-card-text v-if="!disabled">
      <v-list dense>
        <v-list-item>
          <div class="d-flex flex-row justify-space-between flex-grow-1">
            <div>Nombre de jours dus</div>
            <div>{{ resume.nbJoursDusPourLannee }}</div>
          </div>
        </v-list-item>
        <v-list-item>
          <div class="d-flex flex-row justify-space-between flex-grow-1">
            <div>Nombre de jours faits</div>
            <div>{{ resume.nbJoursFaitsADate }}</div>
          </div>
        </v-list-item>
        <v-list-item>
          <div class="d-flex flex-row justify-space-between flex-grow-1">
            <div>Nombre de jours restants</div>
            <div>{{ resume.nbJoursPrevusSurLeTempsRestant }}</div>
          </div>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <div class="d-flex flex-row justify-space-between flex-grow-1">
            <div>Atterrissage fin d'année</div>
            <div :class="'d-flex justify-end font-weight-bold ' + styleAtterrissageFinDannee">
              {{ atterrissageFinDannee }}
            </div>
          </div>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import UtilisateurAvatar from '../utilisateur/utilisateur-avatar.vue'

export default {
  name: 'resume-astreinte-card',
  components: {
    UtilisateurAvatar,
  },
  props: {
    resume: {
      type: Object,
      required: true,
    },
    position: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      getEmployeById: 'employe/getEmployeById',
      getCouleurByEmployeId: 'astreinte/getCouleurByEmployeId',
    }),
    employe() {
      return this.getEmployeById(this.resume.employeId)
    },
    utilisateur() {
      return this.employe ? this.employe.utilisateur : undefined
    },
    couleur() {
      return this.getCouleurByEmployeId(this.resume.employeId)
    },
    atterrissageFinDannee() {
      return -(
        this.resume.nbJoursDusPourLannee -
        this.resume.nbJoursFaitsADate -
        this.resume.nbJoursPrevusSurLeTempsRestant
      )
    },
    styleAtterrissageFinDannee() {
      if (this.atterrissageFinDannee === 0) {
        return 'success--text text--darken-2'
      } else {
        if (Math.abs(this.atterrissageFinDannee) === 1) {
          return 'warning--text text--darken-2'
        } else {
          return 'error--text text--darken-2'
        }
      }
    },
  },
}
</script>

<style scoped>
.salarie-couleur-indicateur {
  min-width: 28px;
  width: 28px;
  max-width: 28px;
  min-height: 28px;
  height: 28px;
  max-height: 28px;
  border-radius: 50%;
  align-self: center;
}
</style>

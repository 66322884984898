<script setup>
import LigneOperationAim from '@/components/fiche-aim/ligne/ligne-operation-aim.vue'
</script>

<template>
  <v-card class="mb-2 mx-2" outlined :color="outlineColor">
    <v-simple-table class="table" v-if="hasOperations" fixed-header>
      <tbody>
        <ligne-operation-aim v-for="(operation, i) in ligneFicheAim.operations" :key="i" :operation="operation" />
      </tbody>
    </v-simple-table>
    <v-card-text v-else>Aucune opération sur ce poste</v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'detail-operation-ligne-aim.vue',
  props: {
    ligneFicheAim: {
      type: Object,
      required: true,
    },
    accent: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasOperations() {
      return this.ligneFicheAim.operations.length > 0
    },
    outlineColor() {
      if (!this.hasOperations) return ''
      if (this.accent) return 'secondary'
      return 'primary'
    },
  },
}
</script>

<style scoped>
.bg-surface-darken-2 {
  background-color: #e0f2f1 !important;
}
.table {
  margin: 1px !important;
}
</style>

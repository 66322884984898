<template>
  <v-dialog v-model="showDialog" persistent max-width="400px">
    <template v-slot:activator="{ on }">
      <v-list-item v-on="on">
        <v-list-item-icon>
          <v-icon color="error">fa-clock</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Demande de Récupérations</v-list-item-title>
      </v-list-item>
    </template>
    <v-card v-if="showDialog">
      <s-toolbar text="Demande de récupération" create @close="close()" />
      <v-card-text>
        <v-container>
          <v-row no-gutters>
            <v-col cols="12">
              <v-form v-model="formValid">
                <v-row v-if="!recuperations">
                  <v-col cols="12" v-if="etablissements.length > 1">
                    <etablissement-autocomplete
                      v-model="selectedEtablissement"
                      :list-etablissements="etablissements"
                      required
                      :disabled="!!recuperations"
                    />
                  </v-col>
                  <v-col cols="12">
                    <service-autocomplete
                      v-if="selectedEtablissement && services.length > 1"
                      v-model="selectedService"
                      :list-services="services"
                      required
                      :disabled="!!recuperations"
                    />
                  </v-col>
                </v-row>
                <v-row v-if="selectedService && !recuperations">
                  <v-col cols="6">
                    <v-row>
                      <v-col cols="12">
                        <s-date-picker
                          v-if="selectedService"
                          v-model="evalRecuperation.dateDebut"
                          label="Date de début"
                          required
                          defaultCurrent
                        />
                      </v-col>
                      <v-col cols="12">
                        <s-borne-horaire-picker
                          v-model="evalRecuperation.heureDebut"
                          label="Heure de début"
                          :borneMax="
                            evalRecuperation.dateDebut === evalRecuperation.dateFin
                              ? evalRecuperation.heureFin
                              : undefined
                          "
                          required
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6">
                    <v-row>
                      <v-col cols="12">
                        <s-date-picker
                          v-if="selectedService"
                          v-model="evalRecuperation.dateFin"
                          label="Date de fin"
                          required
                          defaultCurrent
                        />
                      </v-col>
                      <v-col cols="12">
                        <s-borne-horaire-picker
                          v-model="evalRecuperation.heureFin"
                          label="Heure de fin"
                          :borneMin="
                            evalRecuperation.dateDebut === evalRecuperation.dateFin
                              ? evalRecuperation.heureDebut
                              : undefined
                          "
                          required
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row v-else-if="recuperations && recuperations.length > 0" no-gutters>
                  <v-col cols="12">
                    <v-list dense>
                      <v-subheader>Résumé de la demande (-{{ nbHeuresRecuperees }}h)</v-subheader>
                      <v-virtual-scroll benched="2" :items="recuperations" height="200" item-height="45">
                        <template v-slot:default="{ item }">
                          <demande-recuperation-list-item :recuperation="item" />
                        </template>
                      </v-virtual-scroll>
                    </v-list>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      v-model="commentaire"
                      rows="2"
                      auto-grow
                      clearable
                      clear-icon="mdi-close-circle"
                      label="Commentaire"
                    />
                  </v-col>
                </v-row>
                <v-row v-else-if="recuperations && recuperations.length === 0">
                  <v-col cols="12" class="text-center">
                    Aucune heure de travail trouvée sur la période demandée.
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn color="grey darken-1" text @click="close()">Fermer</v-btn>
        <v-spacer />
        <v-btn v-if="!recuperations" color="info" :disabled="!formValid" @click="evaluer()"> Évaluer </v-btn>
        <template v-else>
          <v-btn color="warning" :disabled="!formValid" @click="recuperations = undefined"> Modifier </v-btn>
          <v-btn
            color="success"
            :disabled="!formValid || (recuperations && recuperations.length === 0)"
            @click="envoyer()"
          >
            Envoyer
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import etablissementAutocomplete from '../../etablissement/etablissement-autocomplete'
import serviceAutocomplete from '../../service/service-autocomplete'
import { mapGetters, mapActions } from 'vuex'
import demandeRecuperationListItem from './demande-recuperation-list-item'

export default {
  name: 'demande-recuperation-create-dialog',
  components: {
    demandeRecuperationListItem,
    etablissementAutocomplete,
    serviceAutocomplete,
  },
  props: {
    membreId: {
      type: Number,
      required: false,
      default: undefined,
    },
    date: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      showDialog: false,
      formValid: false,
      selectedEmploye: undefined,
      evalRecuperation: undefined,
      commentaire: undefined,
      recuperations: undefined,
    }
  },
  computed: {
    ...mapGetters({
      user: 'security/getUtilisateur',
      getEtablissementById: 'etablissement/getEtablissementById',
      getEmployesByUtilisateurId: 'employe/getEmployesByUtilisateurId',
      getMembresByEmployeId: 'membre/getMembresByEmployeId',
      getMembreById: 'membre/getMembreById',
      getServices: 'service/getServices',
      getServiceById: 'service/getServiceById',
    }),
    employes() {
      return this.getEmployesByUtilisateurId(this.user.utilisateurId)
    },
    etablissements() {
      return this.employes.map((emp) => emp.etablissement)
    },
    selectedEtablissement: {
      get() {
        if (this.selectedEmploye) {
          return this.employes.find((e) => e.employeId === this.selectedEmploye.employeId).etablissement
        } else {
          return undefined
        }
      },
      set(_etablissement) {
        this.selectedEmploye = this.employes.find(
          (e) => e.etablissement.etablissementId === _etablissement.etablissementId
        )
      },
    },
    membres() {
      if (this.selectedEmploye) {
        return this.getMembresByEmployeId(this.selectedEmploye.employeId)
      } else {
        return undefined
      }
    },
    services() {
      return this.membres.map((m) => this.getServiceById(m.serviceId))
    },
    selectedService: {
      get() {
        if (this.evalRecuperation && this.evalRecuperation.membreId) {
          return this.getServiceById(this.membres.find((m) => m.membreId === this.evalRecuperation.membreId).serviceId)
        } else {
          return undefined
        }
      },
      set(_service) {
        if (_service) {
          this.evalRecuperation.membreId = this.membres.find((m) => m.serviceId === _service.serviceId).membreId
        } else {
          this.evalRecuperation.membreId = undefined
        }
      },
    },
    nbHeuresRecuperees() {
      if (this.recuperations) {
        let result = this.recuperations.map((r) => r.dureeEnMinute).reduce((sum, a) => sum + a, 0)
        return Math.round((result / 60) * 100) / 100
      } else {
        return 0
      }
    },
  },
  created() {
    this.initialize()
  },
  watch: {
    value() {
      this.showDialog = this.value
    },
    membres() {
      if (this.services.length === 1) {
        this.evalRecuperation.membreId = this.membres[0].membreId
      }
    },
  },
  methods: {
    ...mapActions({
      createDemandeRecuperation: 'demande/createDemandeRecuperation',
      evaluerDemandeRecuperation: 'demande/evaluerDemandeRecuperation',
    }),
    initialize() {
      this.recuperations = undefined
      this.commentaire = undefined

      if (this.employes && this.employes.length === 1) {
        this.selectedEmploye = this.employes[0]
      }

      this.evalRecuperation = {
        membreId: this.membreId,
        dateDebut: this.date ? this.date : undefined,
        heureDebut: undefined,
        dateFin: this.date ? this.date : undefined,
        heureFin: undefined,
      }
    },
    updateFormValid(_validity) {
      this.formValid = _validity
    },
    evaluer() {
      if (this.formValid) {
        this.evaluerDemandeRecuperation(this.evalRecuperation).then((response) => {
          this.recuperations = response.data.recuperations
        })
      }
    },
    envoyer() {
      this.evalRecuperation.commentaire = this.commentaire
      this.createDemandeRecuperation(this.evalRecuperation)
      this.close()
    },
    close() {
      this.initialize()
      this.showDialog = false
    },
  },
}
</script>

<style scoped></style>

<template>
  <div
    ref="modele-planning-creneau-container"
    class="modele-planning-creneau-container"
    @dragover="(e) => dragOver(e)"
    @dragend="dragEnd()"
  >
    <div class="modele-planning-membre" v-for="membre in membres" :key="membre.membreId">
      <modele-planning-creneau-container-membre
        :borneDebut="borneDebut"
        :creneauxDeTravail="getCreneauxDeTravail(membre)"
        :membre="membre"
        :semaine="semaine"
        :jour="jour"
        :listenForMouseEvent="!listenForMouseEvent"
        :highlight="selectedMembre && membre.membreId === selectedMembre.membreId"
        :slot-height="slotHeight"
      />
    </div>
  </div>
</template>

<script>
import EventBus from '@/EventBus'
import ModelePlanningCreneauContainerMembre from '@/components/modelePlanning/creneau/modele-planning-creneau-container-membre.vue'

export default {
  name: 'modele-planning-creneau-container',
  components: {
    ModelePlanningCreneauContainerMembre,
  },
  props: {
    slotHeight: {
      type: Number,
      required: true,
    },
    borneDebut: {
      type: Object,
      required: true,
    },
    borneFin: {
      type: Object,
      required: true,
    },
    creneaux: {
      type: Array,
      required: true,
    },
    jour: {
      type: Object,
      required: true,
    },
    semaine: {
      type: Object,
      required: true,
    },
    membres: {
      type: Array,
      required: true,
    },
    selectedMembre: undefined,
  },
  data() {
    return {
      listenForMouseEvent: false,

      previouslySentPosition: undefined,
      dragEventCount: 0,
    }
  },
  mounted() {
    this.$refs['modele-planning-creneau-container'].addEventListener('dragover', this.dragOver, false)
  },
  methods: {
    getCreneauxDeTravail(_membre) {
      return this.creneaux.filter((creneau) => this.isCreneauInBornes(creneau) && creneau.membreId === _membre.membreId)
    },
    isCreneauInBornes(_creneau) {
      return !(_creneau.heureDebut.valeur > this.borneFin.valeur || _creneau.heureFin.valeur < this.borneDebut.valeur)
      /*return (
        (_creneau.heureDebut.valeur >= this.borneDebut.valeur && _creneau.heureDebut.valeur <= this.borneFin.valeur) ||
        (_creneau.heureFin.valeur >= this.borneDebut.valeur && _creneau.heureFin.valeur <= this.borneFin.valeur)
      )*/
    },
    getBorneFromPosition(Yposition) {
      //On arrondi au quart d'heure près
      Yposition = Yposition - (Yposition % 12)
      return this.getBorneFromValue((Yposition / 12) * 15 + this.borneDebut.valeur)
    },
    getBorneFromValue(_value) {
      var borneResult = {
        borneHoraireId: undefined,
        heure: undefined,
        minute: undefined,
        valeur: undefined,
      }

      borneResult.heure = (_value - (_value % 60)) / 60
      borneResult.minute = _value % 60
      borneResult.valeur = _value

      return borneResult
    },
    dragOver(event) {
      event.preventDefault()
      //On réserve les mouseEvents à ce composant en passant la variable "ListenForMouseEvent" à true
      this.listenForMouseEvent = true

      if (this.dragEventCount > 5 || this.previouslySentPosition === undefined) {
        this.dragEventCount = 0
        if (this.previouslySentPosition === undefined || Math.abs(this.previouslySentPosition - event.offsetY) >= 2) {
          this.previouslySentPosition = event.offsetY
          EventBus.$emit('DRAGGING', this.getBorneFromPosition(event.offsetY))
        }
      }

      this.dragEventCount++
    },
    dragEnd() {
      //On libère la réservation des mouseEvents à ce composant en passant la variable "ListenForMouseEvent" à false
      this.listenForMouseEvent = false
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.modele-planning-creneau-container {
  position: absolute;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding-right: 10px;

  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
  pointer-events: all;

  overflow: hidden;
}

.modele-planning-membre {
  position: relative;
  display: flex;
  flex: 1;
  pointer-events: none;
}
</style>

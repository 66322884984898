var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modele-planning-creneau",class:{ focused: _vm.focused },style:({
    top: _vm.topPosition + 'px',
    height: _vm.height + 2 * _vm.resizerHeight + 'px',
    'min-height': _vm.height + 2 * _vm.resizerHeight + 'px',
    background: _vm.background,
    pointerEvents: _vm.listenPointerEvent,
  }),on:{"mousedown":(e) => e.stopPropagation(),"click":(e) => e.stopPropagation(),"contextmenu":(e) => e.stopPropagation()}},[_c('div',{ref:"modele-planning-creneau-resizer-top",staticClass:"modele-planning-creneau-resizer-top",style:({
      pointerEvents: _vm.listenPointerEvent,
      height: _vm.resizerHeight + 'px',
    }),attrs:{"draggable":"true"},on:{"dragstart":(e) => _vm.dragStart(e, 'top'),"dragend":function($event){return _vm.dragEnd()}}}),_c('modele-creneau-update-menu',{ref:"details",attrs:{"modeleCreneau":_vm.creneau}},[_c('div',{ref:"creneau",style:({ height: _vm.height + 'px' })})]),_c('div',{ref:"modele-planning-creneau-resizer-bottom",staticClass:"modele-planning-creneau-resizer-bottom",style:({
      pointerEvents: _vm.listenPointerEvent,
      height: _vm.resizerHeight + 'px',
    }),attrs:{"draggable":"true"},on:{"dragstart":(e) => _vm.dragStart(e, 'bottom'),"dragend":function($event){return _vm.dragEnd()}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        Roulement proposé {{ loaded ? '(' + modeleAstreintes.semaines.length + ' semaines)' : '' }}
        <template v-if="loaded">
          <v-list-item v-for="(semaine, index) in semaines" :key="index" two-line>
            <v-list-item-content>
              <v-list-item-title>Semaine {{ semaine.ordre }}</v-list-item-title>
              <v-list-item-subtitle>{{ getEmployeById(semaine.employeId).nomComplet }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn small icon v-if="index !== 0" @click="up(semaine.ordre)">
                <v-icon medium>mdi-menu-up</v-icon>
              </v-btn>
              <v-btn small icon v-if="index !== semaines.length - 1" @click="down(semaine.ordre)">
                <v-icon medium>mdi-menu-down</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </template>
        <template v-else>
          <v-skeleton-loader type="list-item-two-line@4" />
        </template>
      </v-col>
      <v-col cols="6">
        <template v-if="loaded">
          Soldes Prévisionels Annuels Moyens
          <v-list-item v-for="(resumeEmploye, index) in resume" :key="index" two-line>
            <v-list-item-content>
              <v-list-item-title>{{ resumeEmploye.employe.nomComplet }}</v-list-item-title>
              <v-list-item-subtitle>
                Jours dus: {{ resumeEmploye.nbJoursDusParAn }}<br />
                Jours par an: {{ resumeEmploye.nbJoursDastreintesMoyenParAn }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <span> Solde: {{ resumeEmploye.nbJoursDastreintesMoyenParAn - resumeEmploye.nbJoursDusParAn }} </span>
            </v-list-item-action>
          </v-list-item>
        </template>
        <template v-else>
          <v-skeleton-loader type="list-item-two-line@4" />
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'modele-astreintes-form',
  components: {},
  props: {
    value: {
      type: Object,
      required: false,
      default: undefined,
    },
    nbJoursParEmployesParAn: {
      type: Object,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    formValid: false,
    modeleAstreintes: undefined,
  }),
  computed: {
    ...mapGetters({
      getEmployeById: 'employe/getEmployeById',
    }),
    loaded() {
      return this.modeleAstreintes && this.nbJoursParEmployesParAn
    },
    nbSemainesModele() {
      return this.modeleAstreintes.semaines.length
    },
    semaines() {
      return [...this.modeleAstreintes.semaines].sort((a, b) => {
        return a.ordre - b.ordre
      })
    },
    resume() {
      let resume = []

      for (const key in this.nbJoursParEmployesParAn) {
        let employeId = parseInt(key)
        resume.push({
          employe: this.getEmployeById(employeId),
          nbJoursDusParAn: this.nbJoursParEmployesParAn[employeId],
          nbJoursDastreintesMoyenParAn: this.getNbJoursDastreintesMoyenParAn(employeId),
        })
      }

      return resume
    },
  },
  created() {
    this.modeleAstreintes = this.value
  },
  watch: {
    modeleAstreintes() {
      this.$emit('input', this.modeleAstreintes)
    },
    value() {
      this.modeleAstreintes = this.value
    },
    formValid() {
      this.$emit('updateFormValid', this.formValid)
    },
  },
  methods: {
    getNbSemainesByEmployeId(_employeId) {
      return this.modeleAstreintes.semaines.filter((s) => s.employeId === _employeId).length
    },
    getNbJoursDastreintesMoyenParAn(_employeId) {
      return (this.getNbSemainesByEmployeId(_employeId) / this.nbSemainesModele) * 365.25
    },
    down(_ordre) {
      this.modeleAstreintes.semaines.forEach((semaine) => {
        if (semaine.ordre === _ordre) {
          semaine.ordre++
        } else {
          if (semaine.ordre === _ordre + 1) {
            semaine.ordre--
          }
        }
      })
    },
    up(_ordre) {
      this.modeleAstreintes.semaines.forEach((semaine) => {
        if (semaine.ordre === _ordre) {
          semaine.ordre--
        } else {
          if (semaine.ordre === _ordre - 1) {
            semaine.ordre++
          }
        }
      })
    },
  },
}
</script>

<style scoped></style>

<template>
  <v-form ref="form" v-model="formValid">
    <v-container>
      <v-row dense>
        <v-col cols="12" class="py-0">
          <v-text-field
            label="E-mail professionnel"
            v-model="employe.emailPro"
            :rules="emailRulesNonObligatoire"
            hide-no-data
          />
        </v-col>
        <!--<v-col cols="12" class="py-0">
          <s-phone-number-field v-model="employe.telephone" />
        </v-col>-->
        <v-col cols="6" sm="12" md="6" class="py-0">
          <v-text-field
            label="Solde de récupération*"
            v-model="employe.soldeRecupInitial"
            :rules="[(v) => v !== undefined && !isNaN(v)]"
            required
          />
        </v-col>
        <v-col cols="6" sm="12" md="6" class="py-0">
          <s-date-picker
            v-model="employe.dateEntreeAnciennete"
            label="Date d'entrée"
            required
            hint="Date utilisée pour le calcul de l'ancienneté"
          />
        </v-col>
        <v-col cols="12" v-if="create">
          <v-row>
            <v-col cols="6" sm="12" md="6" class="py-0">
              <v-text-field
                v-if="create"
                v-model="employe.soldeInitialCongesPayes"
                :rules="[(v) => v !== undefined && !isNaN(v)]"
                type="number"
                label="Solde Congés payés*"
                suffix="jour(s)"
                required
              />
            </v-col>
            <v-col cols="6" sm="12" md="6" class="py-0">
              <v-text-field
                v-model="employe.soldeInitialCongesTrimestriels"
                :rules="[(v) => v !== undefined && !isNaN(v)]"
                type="number"
                label="Solde Congés trim.*"
                suffix="jour(s)"
                required
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col cols="6" sm="12" md="6">
              <v-switch v-model="employe.delegueDuPersonnel" inset label="Délégué du personnel ? " />
            </v-col>
            <v-col cols="6" sm="12" md="6">
              <v-switch v-model="employe.administrateur" inset label="Administrateur ? " />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <small>*information obligatoire</small>
  </v-form>
</template>

<script>
export default {
  name: 'employe-form',
  components: {},
  props: {
    value: {
      type: Object,
      required: true,
    },
    create: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      formValid: false,
      employe: undefined,
      emailRules: [
        (v) => !!v || 'Adresse E-mail obligatoire',
        (v) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(
            v
          ) || "Format de l'adresse E-mail incorrect",
      ],
      emailRulesNonObligatoire: [
        (v) => !v || true,
        (v) =>
          !v ||
          (v &&
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(
              v
            )) ||
          "Format de l'adresse E-mail incorrect",
      ],
    }
  },
  computed: {},
  created() {
    this.employe = this.value
  },
  watch: {
    value() {
      this.employe = this.value
    },
    formValid() {
      this.$emit('updateFormValidity', this.formValid)
    },
    employe: {
      handler() {
        this.$emit('input', this.employe)
      },
      deep: true,
    },
  },
}
</script>

<style scoped></style>

<template>
  <creneau
    :borneDebut="borneDebut"
    :creneau="creneau"
    :indisponibilite="indisponibilite"
    :inlineStyle="style"
    :disabled="disabled"
    :slot-height="slotHeight"
  >
    <template v-slot:icon>
      <v-icon v-if="aRemplacer" color="error">fa-exclamation</v-icon>
      <v-icon v-else-if="remplacePar || nonRemplace" color="success">fa-check</v-icon>
      <v-icon :color="colorLighter">fa-plus-circle</v-icon>
    </template>
    <template v-slot:actions>
      <v-divider />
      <v-list-item-group>
        <v-list-item @click="annulerHeuresSupplementaires">
          <v-list-item-icon>
            <v-icon color="grey">mdi-history</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Annuler les heures complémentaires</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </template>
  </creneau>
</template>

<script>
import Creneau from './creneau'
import { mapActions, mapGetters } from 'vuex'
import Color from 'color'

export default {
  name: 'creneau-heures-supplementaires',
  components: {
    Creneau,
  },
  data() {
    return {
      showAbsenceCreateDialog: false,
    }
  },
  props: {
    slotHeight: {
      type: Number,
      required: true,
    },
    borneDebut: {
      type: Object,
      required: true,
    },
    creneau: {
      type: Object,
      required: true,
    },
    indisponibilite: {
      type: Object,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      getRemplacants: 'servicePlanning/getRemplacants',
    }),
    membre() {
      return this.creneau.membre
    },
    style() {
      return {
        borderStyle: 'solid',
        borderWidth: '3px',
        borderColor: this.creneau.remplace ? this.colorRemplace : this.colorLighter,
      }
    },
    aRemplacer() {
      return this.indisponibilite && !(this.remplacePar || this.creneau.nePasRemplacer)
    },
    nonRemplace() {
      return this.indisponibilite && this.creneau.nePasRemplacer
    },
    remplacePar() {
      return this.getRemplacants(this.creneau).length > 0
    },
    colorRemplace() {
      return this.creneau.remplace.membre.couleur
    },
    colorLighter() {
      return Color(this.membre.couleur).lightness(90).hex()
    },
  },
  methods: {
    ...mapActions({
      dispatchRetirerDuTempsDeTravailCommand: 'servicePlanning/retirerDuTempsDeTravail',
    }),
    annulerHeuresSupplementaires() {
      this.dispatchRetirerDuTempsDeTravailCommand({
        membreId: this.creneau.membre.membreId,
        dateDebut: this.creneau.date,
        heureDebut: this.creneau.heureDebut,
        dateFin: this.creneau.date,
        heureFin: this.creneau.heureFin,
      })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

<template>
  <v-list-item :to="destination">
    <v-list-item-content>
      <v-list-item-title v-text="demande.employe.nomComplet" />
      <v-list-item-subtitle v-text="demande.typeDemande + ' - ' + formattedDateDemande" />
    </v-list-item-content>
    <v-list-item-action class="text-body-2">
      <div class="grey--text text--lighten-1">
        <v-icon color="grey lighten-1" small> mdi-forum </v-icon> {{ demande.commentaires.length }}
      </div>
      <div id="validations">
        <v-icon color="success" x-small> fa-check </v-icon>
        <span class="success--text mr-2">{{ nbValides }}</span>
        <v-icon color="info" x-small> fa-question </v-icon>
        <span class="info--text mr-2"> {{ nbEnAttentes }}</span>
        <v-icon color="error" x-small> fa-ban </v-icon>
        <span class="error--text"> {{ nbRefuses }}</span>
      </div>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'demande-list-item',
  props: {
    demande: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      formatDate: 'calendrier/formatDateToDisplay',
    }),
    destination() {
      switch (this.demande.typeDemande) {
        case 'Congés':
          return { name: 'demandeCongesDetail', params: { demandeId: this.demande.demandeId } }
        case 'Heures complémentaires':
          return { name: 'demandeHeuresSuppDetail', params: { demandeId: this.demande.demandeId } }
        case 'Récupération':
          return { name: 'demandeRecuperationDetail', params: { demandeId: this.demande.demandeId } }
        default:
          return null
      }
    },
    formattedDateDemande() {
      return this.formatDate(this.demande.dateDemande)
    },
    nbValides() {
      return this.demande.validations.filter((v) => v.accepte === true).length
    },
    nbEnAttentes() {
      return this.demande.validations.filter((v) => v.accepte === null).length
    },
    nbRefuses() {
      return this.demande.validations.filter((v) => v.accepte === false).length
    },
  },
}
</script>

<style scoped></style>

<template functional>
  <div
    :style="{
      height: props.slotHeight + 'px',
    }"
    v-bind:class="{
      'planning-body-slot-midi': props.borneHoraire.heure === 11 && props.borneHoraire.minute === 45,
      'planning-body-slot-hour': props.borneHoraire.heure !== 11 && props.borneHoraire.minute === 45,
      'planning-body-slot-jour': props.jour_nuit === 'jour',
      'planning-body-slot-nuit': props.jour_nuit === 'nuit',
      'planning-body-slot-impair': props.jour.jourId % 2 === 1,
      'planning-body-slot-ferie': props.ferie,
    }"
  />
</template>

<script>
export default {
  name: 'planning-body-slot',
  props: {
    slotHeight: {
      type: Number,
      required: true,
    },
    borneHoraire: {
      type: Object,
      required: true,
    },
    jour: {
      type: Object,
      required: true,
    },
    jour_nuit: {
      type: String,
      required: true,
    },
    ferie: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.planning-body-slot-jour.planning-body-slot-hour {
  border-bottom: solid 1px #c0c0c0;
}

.planning-body-slot-nuit {
  background-color: #d2e1ff;
}

.planning-body-slot-nuit.planning-body-slot-hour {
  border-bottom: solid 1px #c0c0c0;
}

.planning-body-slot-jour.planning-body-slot-impair {
  background-color: #e0e0e0;
}

.planning-body-slot-jour.planning-body-slot-ferie {
  background-color: #ffe0e0;
}

.planning-body-slot-midi {
  border-bottom: solid 2px #c0c0c0;
}
</style>

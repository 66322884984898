<template>
  <div>
    <s-info-modifiable
      titre="Comptables de l'etablissement"
      titre-action="Ajouter"
      @validateUpdate="envoyerRequetteAjouterComptable"
      @cancelUpdate="tempFuturComptable = null"
    >
      <template v-slot:champ>
        <employe-autocomplete
          label="Comptable de l'etablissement"
          :employes-a-exclure="comptables"
          required
          v-model="tempFuturComptable"
          @keydown.escape="$emit('cancelUpdate')"
          @keydown.enter="$emit('validateUpdate')"
        />
      </template>
      <template #contentToDisplay>
        <div>
          <v-chip
            class="ma-1"
            label
            v-for="comptable in comptables"
            :key="comptable.employeId"
            close
            @click:close="initiateDeletion(comptable)"
            >{{ comptable.utilisateur.nom }} {{ comptable.utilisateur.prenom }}
          </v-chip>
        </div>
      </template>
    </s-info-modifiable>
    <v-progress-linear v-show="loading" :indeterminate="loading"></v-progress-linear>
    <v-dialog max-width="500" v-model="dialogConfirmSupression">
      <v-card v-if="possibleSupression">
        <v-card-title>Confirmation ?</v-card-title>
        <v-card-subtitle
          >Voulez vous vraiment retirer {{ possibleSupression.nomComplet }} des comptables de
          {{ etablissement.raisonSociale }}</v-card-subtitle
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="info" @click="confirmPossDeletion">Confirmer</v-btn>
          <v-btn color="grey" @click="cancelPossDeletion">Annuler</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="errorOnComptablesFetch">Echec de la récupération de la liste des comptables</v-snackbar>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import SInfoModifiable from '../../../components/global/s-info-modifiable.vue'
import EmployeAutocomplete from '../../../components/employe/employe-autocomplete.vue'

export default {
  name: 'etablissement-designer-comptables',
  components: { EmployeAutocomplete, SInfoModifiable },
  props: {
    etablissement: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      comptables: undefined,
      possibleSupression: undefined,
      dialogConfirmSupression: false,
      errorOnComptablesFetch: false,
      tempFuturComptable: true,
    }
  },
  methods: {
    ...mapActions({
      ajouterUnComptableAEtablissement: 'etablissement/ajouterUnComptableAEtablissement',
      retirerUnComptableAEtablissement: 'etablissement/retirerUnComptableAEtablissement',
      getComptables: 'etablissement/obtenirListeComptablesEtablissement',
    }),
    initiateDeletion(utilisateurComptable) {
      this.possibleSupression = utilisateurComptable
      this.dialogConfirmSupression = true
    },
    envoyerRequetteAjouterComptable() {
      this.loading = true
      this.ajouterUnComptableAEtablissement({
        etablissementId: this.etablissement.etablissementId,
        nouveauComptableId: this.tempFuturComptable.employeId,
      }).then(() => {
        this.dialogConfirmSupression = false
        this.fetchComptables()
        this.loading = false
      })
    },
    confirmPossDeletion() {
      this.loading = true
      this.retirerUnComptableAEtablissement({
        etablissementId: this.etablissement.etablissementId,
        ancienComptableId: this.possibleSupression.employeId,
      }).then(() => {
        this.loading = true
        this.dialogConfirmSupression = false
        this.fetchComptables().then(() => (this.loading = false))
      })
    },
    cancelPossDeletion() {
      this.possibleSupression = undefined
      this.dialogConfirmSupression = false
    },
    async fetchComptables() {
      this.loading = true
      this.getComptables(this.etablissement.etablissementId).then((value) => {
        if (value.estReussi) {
          this.comptables = value.comptables
          this.loading = false
        } else {
          this.errorOnComptablesFetch = true
          this.loading = false
        }
      })
    },
  },
  created() {
    this.loading = true
    this.fetchComptables()
  },
}
</script>

<style scoped></style>

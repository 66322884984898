<template>
  <v-form ref="form" v-model="formValid">
    <v-card-text>
      <v-container class="ma-0">
        <v-row dense>
          <v-col cols="6">
            <v-text-field v-model="association.nom" label="Nom*" :rules="[(v) => !!v]" required />
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="association.SIREN" label="Numéro SIREN" />
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="association.FINESSSiegeSocial" label="Numéro FINESS" />
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="association.extensionSIRETSiegeSocial" label="Numéro SIRET" />
          </v-col>
        </v-row>
      </v-container>
      <small>*information obligatoire</small>
    </v-card-text>
  </v-form>
</template>

<script>
export default {
  name: 'association-form',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formValid: false,
      association: undefined,
    }
  },
  created() {
    this.association = this.value
  },
  watch: {
    value() {
      this.association = this.value
    },
    formValid() {
      this.$emit('updateFormValidity', this.formValid)
    },
    formService() {
      this.$emit('input', this.association)
    },
  },
}
</script>

<style scoped></style>

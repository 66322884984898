<template>
  <v-list-item class="py-0">
    <v-list-item-content class="py-1">
      <v-list-item-title justify="center">
        <span>{{ accueil.serviceDAccueil.nom }}</span>
      </v-list-item-title>
      <v-list-item-subtitle>{{ dates }}</v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'accueil-usager-list-item',
  props: {
    accueil: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      formatDateToDisplay: 'calendrier/formatDateToDisplay',
    }),
    dates() {
      if (this.accueil.dateDeFinDAccueil) {
        return (
          'Du ' +
          this.formatDateToDisplay(this.accueil.dateDeDebutDAccueil) +
          ' au ' +
          this.formatDateToDisplay(this.accueil.dateDeFinDAccueil)
        )
      } else {
        if (new Date(this.accueil.dateDeDebutDAccueil) < new Date()) {
          return 'Depuis le ' + this.formatDateToDisplay(this.accueil.dateDeDebutDAccueil)
        } else {
          return 'À compter du ' + this.formatDateToDisplay(this.accueil.dateDeDebutDAccueil)
        }
      }
    },
  },
}
</script>

<style scoped></style>

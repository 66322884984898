<template>
  <demande-detail>
    <template v-slot:demande>
      <v-card-text>
        <v-container>
          <v-row>
            <s-text-info v-model="service.nom" label="Service" :cols="3" />
            <s-text-info v-model="demande.date" type="date" label="Date" :cols="3" />
            <s-text-info v-model="demande.heureDebut.toHeureMinute" label="Heure de début" :cols="3" />
            <s-text-info v-model="demande.heureFin.toHeureMinute" label="Heure de fin" :cols="3" />
          </v-row>
        </v-container>
      </v-card-text>
    </template>
    <template v-slot:vertical v-if="demande.statut.demandeStatutId === 1">
      <demande-heures-supp-apercu
        :creneaux="creneaux"
        :service="service"
        :date="demande.date"
        chroma-button-position="left"
      />
    </template>
  </demande-detail>
</template>

<script>
import demandeHeuresSuppApercu from '../../components/demande/planning/demande-heures-supp-apercu'
import demandeDetail from './demande-detail'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'demande-heures-supp-detail',
  components: {
    demandeDetail,
    demandeHeuresSuppApercu,
  },
  data() {
    return {
      creneaux: undefined,
    }
  },
  computed: {
    ...mapGetters({
      getDemandeById: 'demande/getDemandeById',
      getMembreById: 'membre/getMembreById',
      getServiceById: 'service/getServiceById',
    }),
    loaded() {
      return this.demande !== undefined
    },
    demande() {
      return this.getDemandeById(parseInt(this.$route.params.demandeId, 10))
    },
    membre() {
      if (this.demande) {
        return this.getMembreById(this.demande.membreId)
      } else {
        return undefined
      }
    },
    service() {
      if (this.membre) {
        return this.getServiceById(this.membre.serviceId)
      } else {
        return undefined
      }
    },
  },
  created() {
    this.updateApercu()
  },
  watch: {
    service() {
      this.updateApercu()
    },
  },
  methods: {
    ...mapActions({
      fetchCreneauxByServiceIdAndDate: 'planning/fetchCreneauxByServiceIdAndDate',
    }),
    updateApercu() {
      if (this.demande && this.service) {
        this.fetchCreneauxByServiceIdAndDate({
          serviceId: this.service.serviceId,
          date: this.demande.date,
        }).then((response) => {
          this.creneaux = response.data
          this.demande.creneau.membre = this.membre
          this.creneaux.push(this.demande.creneau)
        })
      } else {
        this.creneaux = undefined
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card-text>
          <modele-astreintes-form v-model="modeleAstreintes" :nbJoursParEmployesParAn="nbJoursParEmployesParAn" />
        </v-card-text>
        <v-card-actions>
          <v-btn color="grey darken-1" text @click="$emit('precedent')">Précédent</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" :disabled="!modeleAstreinteFormValid" @click="$emit('valider')">Valider</v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import modeleAstreintesForm from './modele-astreintes-form'

export default {
  name: 'modele-astreintes-create-step3-ajuster',
  components: {
    modeleAstreintesForm,
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: undefined,
    },
    etablissement: {
      type: Object,
      required: true,
    },
    nbJoursParEmployesParAn: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      modeleAstreintes: undefined,
    }
  },
  computed: {
    loaded() {
      return this.modeleAstreintes !== undefined && this.nbJoursParEmployesParAn !== undefined
    },
    modeleAstreinteFormValid() {
      return this.modeleAstreintes && this.modeleAstreintes.semaines.length > 0
    },
  },
  created() {
    this.modeleAstreintes = this.value
  },
  watch: {
    modeleAstreintes() {
      this.$emit('input', this.modeleAstreintes)
    },
    value() {
      this.modeleAstreintes = this.value
    },
  },
}
</script>

<style scoped></style>

<template>
  <s-page v-if="loaded">
    <template v-slot:lateral-panel>
      <s-lateral-panel>
        <v-text-field placeholder="Rechercher" hide-details dense clearable v-model="recherche" class="ma-2" />
        <v-btn-toggle v-model="demandesTypes" dense multiple group color="primary" class="justify-center mb-2">
          <v-btn value="Congés"> Congés </v-btn>
          <v-btn value="Planning"> Planning </v-btn>
        </v-btn-toggle>
        <v-tabs fixed-tabs v-model="tab">
          <v-tab grow href="#a-valider" v-if="demandesAValider.length > 0"> À valider </v-tab>
          <v-tab grow href="#mes-demandes">Mes demandes</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" style="height: 100%">
          <v-tab-item value="a-valider" style="height: 100%">
            <perfect-scrollbar style="height: 100%">
              <v-list class="py-0">
                <v-list-item-group color="primary">
                  <template v-for="(demande, index) in demandesAValider">
                    <demande-list-item :key="index" :demande="demande" />
                    <v-divider v-if="index < demandesAValider.length - 1" :key="-demande.demandeId" />
                  </template>
                </v-list-item-group>
              </v-list>
            </perfect-scrollbar>
          </v-tab-item>
          <v-tab-item value="mes-demandes" style="height: 100%">
            <perfect-scrollbar style="height: 100%">
              <v-list class="py-0">
                <v-subheader>En attente</v-subheader>
                <v-list-item-group color="primary" v-if="demandesEnAttente.length > 0">
                  <template v-for="(demande, index) in demandesEnAttente">
                    <demande-list-item :key="index" :demande="demande" />
                    <v-divider v-if="index < demandesEnAttente.length - 1" :key="-demande.demandeId"></v-divider>
                  </template>
                </v-list-item-group>
                <v-list-item v-else>
                  <v-list-item-icon>
                    <v-icon>mdi-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-subtitle v-text="'Aucune demande en attente'" />
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-list class="py-0" color="#e5e5e5">
                <v-subheader>Historique</v-subheader>
                <v-list-item-group color="primary">
                  <template v-for="(demande, index) in demandesHistorique">
                    <demande-list-item :key="index" :demande="demande" />
                    <v-divider v-if="index < demandesHistorique.length - 1" :key="-demande.demandeId"></v-divider>
                  </template>
                </v-list-item-group>
              </v-list>
            </perfect-scrollbar>
          </v-tab-item>
        </v-tabs-items>
        <v-menu v-if="employe" class="demande-type-menu" tile offset-x offset-y top right>
          <template v-slot:activator="{ attrs, on }">
            <v-btn v-on="on" v-bind="attrs" max-height="35px" color="green" tile block dark> Nouvelle demande </v-btn>
          </template>
          <v-list class="py-0">
            <v-subheader>Types de demande</v-subheader>
            <v-list-item-group>
              <demande-conges-create-dialog :employe="employe" />
              <demande-heures-supp-create-dialog :employe="employe" />
              <demande-recuperation-create-dialog :employe="employe" />
            </v-list-item-group>
          </v-list>
        </v-menu>
      </s-lateral-panel>
    </template>
    <template v-slot:main-panel>
      <router-view />
    </template>
  </s-page>
</template>

<script>
import { mapGetters } from 'vuex'
import { initialDataLoaded } from '../../store/modules/helpers'
import demandeCongesCreateDialog from '../../components/demande/conges/demande-conges-create-dialog'
import demandeHeuresSuppCreateDialog from '../../components/demande/planning/demande-heures-supp-create-dialog'
import demandeRecuperationCreateDialog from '../../components/demande/planning/demande-recuperation-create-dialog'
import demandeListItem from '../../components/demande/demande-list-item'

export default {
  name: 'demande',
  components: {
    demandeCongesCreateDialog,
    demandeListItem,
    demandeHeuresSuppCreateDialog,
    demandeRecuperationCreateDialog,
  },
  data() {
    return {
      recherche: undefined,
      tab: undefined,
      demandesTypes: ['Congés', 'Planning'],
    }
  },
  computed: {
    ...initialDataLoaded,
    ...mapGetters({
      utilisateur: 'security/getUtilisateur',
      getEmployeByUtilisateurId: 'employe/getEmployeByUtilisateurId',
      getDemandesAValider: 'demande/getDemandesAValider',
      getDemandesEnAttente: 'demande/getDemandesEnAttente',
      getDemandesHistorique: 'demande/getDemandesHistorique',
    }),
    loaded() {
      return initialDataLoaded && this.demandesAValider && this.demandesEnAttente && this.utilisateur
    },
    employe() {
      if (this.utilisateur) {
        return this.getEmployeByUtilisateurId(this.utilisateur.utilisateurId)
      } else {
        return undefined
      }
    },
    selectedDemandeTypes() {
      let result = []

      if (this.demandesTypes.includes('Congés')) {
        result.push('Congés')
      }

      if (this.demandesTypes.includes('Planning')) {
        result.push('Heures complémentaires')
        result.push('Récupération')
      }

      return result
    },
    demandesAValider() {
      return this.filtrer(this.getDemandesAValider())
    },
    demandesEnAttente() {
      return this.filtrer(this.getDemandesEnAttente())
    },
    demandesHistorique() {
      return this.filtrer(this.getDemandesHistorique()).sort(function (sA, sB) {
        return sB.demandeId - sA.demandeId
      })
    },
  },
  methods: {
    filtrer(_demandes) {
      return _demandes
        .filter((d) => this.selectedDemandeTypes.includes(d.typeDemande))
        .filter(
          (d) => !this.recherche || this.normaliser(d.employe.nomComplet).includes(this.normaliser(this.recherche))
        )
        .filter((d) => d.createur)
    },
    normaliser(_chaineDeCharacteres) {
      return _chaineDeCharacteres
        .normalize('NFD')
        .replace(/\p{Diacritic}/gu, '')
        .toUpperCase()
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.demande-type-menu {
  left: 0 !important;
}
</style>

<template>
  <s-info-modifiable
    titre="Directeur / Directrice de l'établissement"
    :valeur="directeur.nomComplet"
    titre-action="Désigner"
    @validateUpdate="envoyerUneCommandeDeChangementDeDirecteurEtablissement"
    @cancelUpdate="annulerModification"
  >
    <template v-slot:champ>
      <employe-autocomplete
        v-model="directeur"
        label="Directeur / Directrice de l'établissement"
        required
        @keydown.escape="$emit('cancelUpdate')"
        @keydown.enter="$emit('validateUpdate')"
      />
    </template>
  </s-info-modifiable>
</template>

<script>
import { mapActions } from 'vuex'
import SInfoModifiable from '../../../components/global/s-info-modifiable.vue'
import EmployeAutocomplete from '../../../components/employe/employe-autocomplete.vue'

export default {
  name: 'etablissement-designer-directeur',
  components: { EmployeAutocomplete, SInfoModifiable },
  props: {
    etablissement: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      directeur: undefined,
    }
  },
  created() {
    this.directeur = this.etablissement.directeur
  },
  watch: {
    etablissement: {
      handler() {
        this.directeur = this.etablissement.directeur
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      updateDirecteurEtablissement: 'etablissement/updateDirecteurEtablissement',
    }),
    envoyerUneCommandeDeChangementDeDirecteurEtablissement() {
      if (this.directeur.employeId !== this.etablissement.directeur.employeId) {
        this.updateDirecteurEtablissement({
          etablissementId: this.etablissement.etablissementId,
          employeId: this.directeur.employeId,
        })
      }
    },
    annulerModification() {
      this.directeur = this.etablissement.directeur
    },
  },
}
</script>

<template>
  <v-card flat v-if="service">
    <v-card-text>
      <v-row>
        <v-col cols="6">
          <span class="text-h6" style="color: black">Organisation</span>
          <v-divider />
          <service-renommer :service="service" />
          <service-designer-chef-de-service :service="service" />
          <div class="fermeture-service">
            <fermer-service-dialog v-model="showFermerServiceDialog" :service="service" />
            <v-btn class="s-text-action mt-4 mr-3" outlined color="error" @click="showFermerServiceDialog = true"
              >Fermer le service</v-btn
            >
            <s-confirmation-dialog
              info-level="error"
              message="Cette action entrainera la suppression de l'ensemble des données liées à ce service (modèles de planning, historique des plannings, heures supplémentaire, récupérations). Ces données seront irrémédiablement perdues."
              :texte-de-confirmation="service.nom"
              @confirmation="supprimerUnService"
            >
              <v-btn class="s-text-action mt-4" outlined color="error">Supprimer le service</v-btn>
            </s-confirmation-dialog>
          </div>
        </v-col>
        <v-col cols="6">
          <span class="text-h6" style="color: black">Stratégies</span>
          <v-divider />
          <v-switch
            v-model="heuresSurJoursFeriesAutomatiques"
            label="Heures sur jours fériés"
            inset
            persistent-hint
            :hint="hintHeuresSurJoursFeries"
          />
          <v-switch
            v-model="validationAutonome"
            label="Validation autonome"
            inset
            persistent-hint
            :hint="hintValidationAutonome"
            :disabled="!isInRole('DIRECTEUR_' + service.serviceId)"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ServiceRenommer from './service-renommer.vue'
import ServiceDesignerChefDeService from './service-designer-chef-de-service.vue'
import SConfirmationDialog from '../../../components/global/s-confirmation-dialog.vue'
import FermerServiceDialog from '../../../components/service/fermer-service-dialog.vue'

export default {
  name: 'service-update-dialog',
  components: {
    FermerServiceDialog,
    SConfirmationDialog,
    ServiceDesignerChefDeService,
    ServiceRenommer,
  },
  props: {
    service: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showFermerServiceDialog: false,
    }
  },
  computed: {
    ...mapGetters({
      isInRole: 'security/isInRole',
      getEmployeById: 'employe/getEmployeById',
      selectedEtablissement: 'application/getSelectedEtablissement',
    }),
    nomDuService: {
      get() {
        return this.service.nom
      },
      set(_nouveauNom) {
        if (_nouveauNom !== this.service.nom) {
          this.renommerService({
            serviceId: this.service.serviceId,
            nouveauNom: _nouveauNom,
          })
        }
      },
    },
    chefDuService: {
      get() {
        return this.service.chefDeService
      },
      set(_nouveauChefDuService) {
        if (_nouveauChefDuService.employeId !== this.service.chefDeService.employeId) {
          this.designerChefDeService({
            serviceId: this.service.serviceId,
            employeIdDuNouveauChefDeService: _nouveauChefDuService.employeId,
          })
        }
      },
    },
    heuresSurJoursFeriesAutomatiques: {
      get() {
        return this.service.heuresSurJoursFeriesAutomatiques
      },
      set(_nouveauHeuresSurJoursFeriesAutomatiques) {
        if (_nouveauHeuresSurJoursFeriesAutomatiques !== this.service.heuresSurJoursFeriesAutomatiques) {
          this.changerDeStrategieHeuresSurJoursFeries({
            serviceId: this.service.serviceId,
            nouvelleStrategieHeuresSurJoursFeries: _nouveauHeuresSurJoursFeriesAutomatiques,
          })
        }
      },
    },
    validationAutonome: {
      get() {
        return this.service.validationAutonome
      },
      set(_nouveauValidationAutonome) {
        if (_nouveauValidationAutonome !== this.service.validationAutonome) {
          this.changerDeStrategieValidationAutonome({
            serviceId: this.service.serviceId,
            nouvelleStrategieValidationAutonome: _nouveauValidationAutonome,
          })
        }
      },
    },
    hintValidationAutonome() {
      let hint
      if (this.service.validationAutonome) {
        hint =
          'Désactiver pour que la direction valide les demandes (congés, récup etc...) des employés de ce service. '
      } else {
        hint =
          'Activer pour que la direction ne valide plus les demandes (congés, récup etc...) des employés de ce service. '
      }

      hint += "Ce changement n'affectera pas les demandes déjà envoyées. "

      return hint
    },
    hintHeuresSurJoursFeries() {
      let hint
      if (this.service.heuresSurJoursFeriesAutomatiques) {
        hint = 'Désactiver pour que les jours fériés soit vide à la génération du planning. '
      } else {
        hint =
          'Activer pour que les heures prévues habituellement soit créée sur les jours fériés à la génération du planning. '
      }

      hint += "Ce changement n'affectera pas les planning déjà générés. "

      return hint
    },
  },
  created() {},
  methods: {
    ...mapActions({
      renommerService: 'service/renommerService',
      designerChefDeService: 'service/designerChefDeService',
      changerDeStrategieHeuresSurJoursFeries: 'service/changerDeStrategieHeuresSurJoursFeries',
      changerDeStrategieValidationAutonome: 'service/changerDeStrategieValidationAutonome',
      envoyerSupprimerUnService: 'service/supprimerUnService',
    }),
    supprimerUnService() {
      //Ferme le service sélectionné et le supprime de la liste
      this.envoyerSupprimerUnService(this.service.serviceId)
      this.$router.push({ name: 'services' })
    },
    close() {},
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.fermeture-service {
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: end;
}
</style>

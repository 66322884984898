<template>
  <v-dialog v-model="visible" persistent width="330px">
    <v-card>
      <v-toolbar flat dark color="info">
        <span class="text-h6"> Dimanche à budgeter </span>
        <v-spacer />
        <v-btn icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" v-model="formValid">
          <v-date-picker
            v-model="dimancheABudgeter"
            class="elevation-0"
            no-title
            flat
            full-width
            locale="fr"
            first-day-of-week="1"
            locale-first-day-of-year="4"
            :allowed-dates="seulementLesDimanches"
            show-week
            reactive
            required
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="grey darken-1" text @click="close()"> Annuler </v-btn>
        <v-btn color="info" :disabled="!formValid" @click="validateForm"> Confirmer </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'association-budget-dimanche-dialog',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    associationId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      dimancheABudgeter: undefined,
      visible: false,
      formValid: false,
      seulementLesDimanches: (val) => new Date(val).getDay() === 0,
    }
  },
  computed: {
    ...mapGetters({
      currentDate: 'calendrier/getCurrentDate',
    }),
  },
  created() {
    this.visible = this.value
  },
  watch: {
    value() {
      this.visible = this.value
    },
    visible() {
      this.$emit('input', this.visible)
    },
  },
  methods: {
    ...mapActions({
      telechargerBudgetDimanche: 'etablissement/fetchBudgetDimanche',
    }),
    validateForm() {
      this.$refs.form.validate()
      if (this.formValid) {
        this.telechargerBudgetDimanche({
          associationId: this.associationId,
          dimancheABudgeter: this.dimancheABudgeter,
        })
      }
    },
    close() {
      this.visible = false
      this.dimancheABudgeter = undefined
      this.formValid = false
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

<template>
  <v-expansion-panel
    v-if="loaded"
    active-class="active"
    rounded="0"
    v-bind:style="{
      color: color,
    }"
    @click="emitClick()"
  >
    <v-expansion-panel-header class="pa-1 pr-3">
      <template v-slot:actions>
        <div
          class="membre-couleur-indicateur"
          v-bind:style="{
            'background-color': membre.couleur,
          }"
        />
      </template>
      <div class="pa-1 membre-card-content">
        <utilisateur-avatar :utilisateur="employe.utilisateur" :size="36" />
        <span class="body-1 ml-2">
          {{ nomAAfficher }}
        </span>
        <v-spacer />
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content
      class="py-0 modele-planning-membre-expansion-panel-content"
      v-bind:style="{
        color: 'black',
      }"
    >
      <v-row class="text-caption pb-2 px-2" justify="space-around" no-gutters>
        <v-col cols="4">
          <v-icon x-small color="info" class="fas fa-dot-circle" /> {{ nbHeuresParSemaineMoyenne }}h
        </v-col>
        <v-col cols="4">
          <v-icon x-small color="green" class="fas fa-arrow-circle-up" /> {{ nbHeuresParSemaineMax }}h
        </v-col>
        <v-col cols="4">
          <v-icon x-small color="red" class="fas fa-arrow-circle-down" /> {{ nbHeuresParSemaineMin }}h
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <interchanger-membre-dialog v-model="showInterchangerDialog" :membre="membre" />
          <v-btn small block tile color="info" @click="showInterchangerDialog = true">
            <v-icon small>fa-people-arrows</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import utilisateurAvatar from '../../components/utilisateur/utilisateur-avatar'
import interchangerMembreDialog from './modele-planning-interchanger-membre-dialog'
import { mapGetters } from 'vuex'

export default {
  name: 's-membre-card',
  components: {
    utilisateurAvatar,
    interchangerMembreDialog,
  },
  props: {
    membre: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showInterchangerDialog: false,
    }
  },
  computed: {
    ...mapGetters({
      getNbHeuresParSemaineMoyenne: 'modelePlanning/getNbHeuresParSemaineMoyenne',
      getNbHeuresParSemaineMax: 'modelePlanning/getNbHeuresParSemaineMax',
      getNbHeuresParSemaine: 'modelePlanning/getNbHeuresParSemaine',
    }),
    loaded() {
      return this.employe !== undefined
    },
    employe() {
      return this.membre.employe
    },
    nbHeuresParSemaineMoyenne() {
      return this.getNbHeuresParSemaine(this.membre.membreId, 'MOYENNE')
    },
    nbHeuresParSemaineMax() {
      return this.getNbHeuresParSemaine(this.membre.membreId, 'MAX')
    },
    nbHeuresParSemaineMin() {
      return this.getNbHeuresParSemaine(this.membre.membreId, 'MIN')
    },
    color() {
      if (this.selected) {
        return this.membre.couleur
      } else {
        return '#000000'
      }
    },
    nomAAfficher() {
      let prenom = this.employe.utilisateur.prenom
      let nom = this.employe.utilisateur.nom
      let longueurRestantePourLePrenom = 16 - nom.length
      if (longueurRestantePourLePrenom < prenom.length) {
        return prenom.slice(0, 1) + '. ' + nom
      } else {
        return nom + ' ' + prenom
      }
    },
  },
  methods: {
    emitClick() {
      this.$emit('click')
    },
  },
}
</script>

<style>
.v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.membre-card-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 3px;
}

.membre-couleur-indicateur {
  min-width: 15px;
  width: 15px;
  max-width: 15px;
  min-height: 15px;
  height: 15px;
  max-height: 15px;
  align-self: flex-start;
  justify-self: flex-end;
}
</style>

<template>
  <div class="planning-semaine-picker-button" @click="(e) => clicked(e)" v-bind:class="activeClass">
    <div class="planning-semaine-picker-button-title">
      <span class="body-2">Sem. {{ semaine.numero }}</span>
    </div>
    <div class="planning-semaine-picker-button-text">
      <span class="caption"
        >{{ dateDebut.toISOString().substring(8, 10) }}/{{ dateDebut.toISOString().substring(5, 7) }}</span
      >
      <div class="planning-semaine-picker-button-status">
        <v-progress-circular indeterminate size="16" width="2" v-if="loading" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'

export default {
  name: 'planning-semaine-picker-button',
  props: {
    semaine: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      required: false,
      default: false,
    },
    displayJoursDeRepos: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      loadingQueries: [],
    }
  },
  computed: {
    ...mapGetters({
      user: 'security/getAuthenticatedUser',
    }),
    loading() {
      return this.loadingQueries.length > 0
    },
    initialSemaine() {
      return this.$route.params.numeroSemaine
    },
    semaineEnCours() {
      return this.semaine.semaine === moment().week() && this.semaine.annee === moment().year()
    },
    serviceId() {
      return parseInt(this.$route.params.serviceId, 10)
    },
    dateDebut() {
      return moment().year(this.semaine.annee).isoWeek(this.semaine.semaine).startOf('isoWeek').isoWeekday(2).toDate()
    },
    activeClass() {
      return {
        active: this.selected,
        activable: true,
        disabled: false,
      }
    },
  },
  created() {
    if (this.initialSemaine) {
      if (this.initialSemaine === this.semaine.numero) {
        this.clicked(new MouseEvent('click'))
      }
    } else {
      if (this.semaineEnCours) {
        this.clicked(new MouseEvent('click'))
      }
    }
  },
  methods: {
    ...mapActions({
      fetchCreneauxByService: 'servicePlanning/fetchCreneauxSemaineByService',
      fetchJourDeReposByService: 'servicePlanning/fetchJourDeReposSemaineByService',
      fetchCreneauxByUtilisateur: 'planning/fetchCreneauxSemaineByUtilisateur',
    }),
    clicked(_event) {
      this.loadingQueries.push('creneaux')
      if (this.serviceId) {
        if (this.displayJoursDeRepos) {
          this.loadingQueries.push('joursDeRepos')
          this.fetchJourDeReposByService({
            serviceId: this.serviceId,
            annee: this.semaine.annee,
            semaine: this.semaine.semaine,
          }).then(() => {
            this.loadingQueries = this.loadingQueries.filter((e) => e !== 'joursDeRepos')
          })
        }
        this.fetchCreneauxByService({
          serviceId: this.serviceId,
          annee: this.semaine.annee,
          semaine: this.semaine.semaine,
        }).then(() => {
          this.loadingQueries = this.loadingQueries.filter((e) => e !== 'creneaux')
          if (_event.ctrlKey) {
            this.$emit('ctrlClick', this.semaine)
          } else {
            this.$emit('click', this.semaine)
          }
        })
      } else {
        this.fetchCreneauxByUtilisateur({
          utilisateurId: this.user.id,
          annee: this.semaine.annee,
          semaine: this.semaine.semaine,
        }).then(() => {
          this.loadingQueries = this.loadingQueries.filter((e) => e !== 'creneaux')
          if (_event.ctrlKey) {
            this.$emit('ctrlClick', this.semaine)
          } else {
            this.$emit('click', this.semaine)
          }
        })
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.planning-semaine-picker-button {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;

  border-right: solid 1px #e0e0e0;
  width: 120px;
  min-width: 120px;

  border-bottom: solid 1px #e0e0e0;

  padding: 2px 4px 0 4px;
}

.planning-semaine-picker-button.activable {
  cursor: pointer;
}

.planning-semaine-picker-button.active {
  color: var(--v-primary-darken2);
  background-color: var(--v-primary-lighten5);
  outline-color: var(--v-primary-darken2);
}

.planning-semaine-picker-button.disabled {
  color: #a6a6a6;
  outline-color: #a6a6a6;
}

.planning-semaine-picker-button-title {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.planning-semaine-picker-button-text {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}

.planning-semaine-picker-button-status {
}
</style>

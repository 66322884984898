<template>
  <s-page v-if="loaded">
    <template v-slot:lateral-panel>
      <s-lateral-panel>
        <v-text-field placeholder="Rechercher" hide-details dense clearable v-model="recherche" class="ma-2" />
        <perfect-scrollbar style="height: 100%">
          <v-list>
            <v-list subheader color="info lighten-5">
              <v-subheader>A venir</v-subheader>
              <v-list-item-group color="primary">
                <template v-for="(employe, index) in employesAVenir">
                  <employe-list-item :key="index" :employe-id="employe.employeId" />
                  <v-divider v-if="index < employesAVenir.length - 1" :key="-employe.employeId"></v-divider>
                </template>
              </v-list-item-group>
            </v-list>
            <v-list subheader color="success lighten-5">
              <v-subheader>Présents</v-subheader>
              <v-list-item-group color="primary">
                <template v-for="(employe, index) in employesPresents">
                  <employe-list-item :key="index" :employe-id="employe.employeId" />
                  <v-divider v-if="index < employesPresents.length - 1" :key="-employe.employeId"></v-divider>
                </template>
              </v-list-item-group>
            </v-list>
            <v-list subheader color="warning lighten-5">
              <v-subheader>Enregistrés</v-subheader>
              <v-list-item-group color="primary">
                <template v-for="(employe, index) in employesEnregistres">
                  <employe-list-item :key="index" :employe-id="employe.employeId" />
                  <v-divider v-if="index < employesEnregistres.length - 1" :key="-employe.employeId"></v-divider>
                </template>
              </v-list-item-group>
            </v-list>
            <v-list subheader color="error lighten-5">
              <v-subheader>Sortis</v-subheader>
              <v-list-item-group color="primary">
                <template v-for="(employe, index) in employesSortis">
                  <employe-list-item :key="index" :employe-id="employe.employeId" />
                  <v-divider v-if="index < employesSortis.length - 1" :key="-employe.employeId"></v-divider>
                </template>
              </v-list-item-group>
            </v-list>
          </v-list>
        </perfect-scrollbar>
        <v-btn max-height="35px" color="green" tile block dark :to="{ name: 'employeCreate' }"> Ajouter </v-btn>
      </s-lateral-panel>
    </template>
    <template v-slot:main-panel>
      <router-view />
    </template>
  </s-page>
</template>

<script>
import { mapGetters } from 'vuex'
import EmployeListItem from '@/components/employe/employe-list-item'
import { initialDataLoaded } from '../../store/modules/helpers'

export default {
  name: 'employe',
  components: {
    EmployeListItem,
  },
  data() {
    return {
      recherche: null,
    }
  },
  computed: {
    ...initialDataLoaded,
    ...mapGetters({
      employes: 'employe/getEmployes',
    }),
    loaded() {
      return initialDataLoaded && this.employes
    },
    employeList() {
      if (this.recherche === null || this.recherche === '') {
        return this.employes
      } else {
        return this.employes.filter((e) => e.nomComplet.toLowerCase().indexOf(this.recherche.toLowerCase()) >= 0)
      }
    },
    employesAVenir() {
      return this.employeList.filter((e) => e.status === 'A venir')
    },
    employesPresents() {
      return this.employeList.filter((e) => e.status === 'Présent' || e.status === 'Présente')
    },
    employesEnregistres() {
      return this.employeList.filter((e) => e.status === 'Enregistré' || e.status === 'Enregistrée')
    },
    employesSortis() {
      return this.employeList.filter((e) => e.status === 'Sorti' || e.status === 'Sortie')
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

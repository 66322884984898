<template>
  <v-menu
    ref="menu"
    v-model="menuBorneHorairePicker"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    :close-on-click="true"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="selectedBorneHoraireComputed"
        v-mask="'##:##'"
        placeholder="--:--"
        persistent-placeholder
        :label="computedLabel"
        :name="computedLabel"
        :rules="selectedBorneHoraireIsValid"
        :required="required"
        :disabled="disabled"
      >
        <template v-slot:prepend>
          <v-icon v-on="on">mdi-clock-outline</v-icon>
        </template>
      </v-text-field>
    </template>
    <v-time-picker
      v-if="menuBorneHorairePicker"
      v-model="selectedBorneHoraireComputed"
      format="24hr"
      :allowed-minutes="allowedMinutes"
      :allowed-hours="allowedHours"
      @click:minute="menuBorneHorairePicker = false"
    />
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex'
import { mask } from 'vue-the-mask'

export default {
  name: 's-borne-horaire-picker',
  directives: { mask },
  data() {
    return {
      menuBorneHorairePicker: false,
      selectedBorneHoraire: undefined,
    }
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: undefined,
    },
    label: {
      type: String,
      required: false,
      default: 'Heure',
    },
    borneMin: {
      type: Object,
      required: false,
      default: undefined,
    },
    borneMax: {
      type: Object,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    granularite: {
      type: Number,
      required: false,
      default: undefined,
    },
  },
  created() {
    if (this.value) {
      this.selectedBorneHoraire = this.value
    }
  },
  computed: {
    ...mapGetters({
      allBornesHoraire: 'calendrier/getAllBornesHoraire',
      granulariteEtablissement: 'application/getGranularite',
    }),
    computedLabel() {
      let result = this.label

      if (this.required) {
        result += '*'
      }

      return result
    },
    bornesHoraire() {
      let granularite
      if (this.granularite) {
        granularite = this.granularite
      } else {
        granularite = this.granulariteEtablissement
      }
      return this.allBornesHoraire.filter((bh) => bh.minute % granularite === 0)
    },
    selectedBorneHoraireComputed: {
      get() {
        if (this.selectedBorneHoraire) {
          return this.selectedBorneHoraire.toHeureMinute
        } else {
          return undefined
        }
      },
      set(_selectedHeure) {
        let selectedBorne = this.fromStringToBorneHoraire(_selectedHeure)
        if (selectedBorne) {
          if (this.borneMin && selectedBorne.heure === 0) {
            selectedBorne.heure = 24
          }
          this.selectedBorneHoraire = this.allowedBornes.find(
            (borne) => borne.heure === selectedBorne.heure && borne.minute === selectedBorne.minute
          )
        }
      },
    },
    selectedBorneHoraireIsValid() {
      return [
        () =>
          (this.selectedBorneHoraire &&
            this.allowedBornes.find(
              (borne) =>
                borne.heure === this.selectedBorneHoraire.heure && borne.minute === this.selectedBorneHoraire.minute
            )) ||
          "Cette borne horaire n'est pas valide",
      ]
    },
    allowedBornes() {
      return this.bornesHoraire.filter((bh) => this.isInBornes(bh))
    },
    allowedHours() {
      return [...new Set(this.allowedBornes.map((bh) => (bh.heure === 24 ? 0 : bh.heure)))]
    },
    allowedMinutes() {
      if (this.selectedBorneHoraire) {
        return this.allowedBornes.filter((bh) => this.selectedBorneHoraire.heure === bh.heure).map((bh) => bh.minute)
      } else {
        return [...new Set(this.bornesHoraire.map((bh) => bh.minute))]
      }
    },
  },
  watch: {
    value() {
      this.selectedBorneHoraire = this.value
    },
    selectedBorneHoraire() {
      this.$emit('input', this.selectedBorneHoraire)
    },
  },
  methods: {
    isInBornes(_borneHoraire) {
      let respectMin = this.borneMin ? _borneHoraire.valeur >= this.borneMin.valeur : true
      let respectMax = this.borneMax ? _borneHoraire.valeur <= this.borneMax.valeur : true

      return respectMin && respectMax
    },
    fromStringToBorneHoraire(_temps) {
      if (_temps) {
        if (!(_temps.length < 5)) {
          return {
            heure: parseInt(_temps.split(':')[0], 10),
            minute: parseInt(_temps.split(':')[1], 10),
            valeur: _temps.split(':')[0] * 60 + _temps.split(':')[1],
          }
        }
      }

      return undefined
    },
  },
}
</script>

<style scoped></style>

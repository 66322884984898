<template>
  <div
    class="modele-planning-creneau-container-membre"
    :style="style"
    @mousedown="(e) => mousedown(e)"
    @mousemove="(e) => mousemove(e)"
    @click="(e) => mouseup(e)"
    v-on="highlight ? { contextmenu: showMenu } : {}"
  >
    <v-menu v-model="menu.visible" absolute :close-on-content-click="true" :position-x="menu.x" :position-y="menu.y">
      <v-card v-if="!isJourDeRepos">
        <v-list class="py-0" dense>
          <v-list-item-group color="primary">
            <modele-planning-creneau-create
              :membreId="membre.membreId"
              :ordreSemaine="semaine.ordre"
              :jourId="jour.jourId"
            >
              <v-list-item dense>
                <v-list-item-icon>
                  <v-icon color="success">mdi-plus</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Créneau de travail</v-list-item-title>
              </v-list-item>
            </modele-planning-creneau-create>
            <v-list-item @click="this.dispatchCreateJourDeRepos" :disabled="creneauxDeTravail.length > 0" dense>
              <v-list-item-icon>
                <v-icon color="primary" :disabled="creneauxDeTravail.length > 0">mdi-sleep</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Jour de repos</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
      <v-card v-else>
        <v-btn-toggle v-model="demiJour" color="primary" group>
          <v-btn :value="true"> 1/2 </v-btn>
          <v-btn :value="false" :disabled="creneauxDeTravail.length > 0"> 1 </v-btn>
        </v-btn-toggle>
        <v-list dense shaped>
          <v-list-item-group>
            <v-list-item
              v-for="jour in jours"
              :key="jour.jourId"
              :disabled="!isJourLibre(jour.jourId)"
              @click="updateJour(jour)"
            >
              <v-list-item-title>
                {{ jour.label }}
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-btn @click="dispatchDeleteModeleJourDeRepos" small color="error" dark block tile>
          <v-icon small>fa-trash</v-icon>
        </v-btn>
      </v-card>
    </v-menu>
    <modele-planning-creneau
      class="modele-planning-creneau"
      v-for="creneau in creneauxDeTravail"
      :key="creneau.creneauId"
      :borneDebut="borneDebut"
      :creneau="creneau"
      :couleur="membre.couleur"
      :listenForMouseEvent="!listenForMouseEvent"
      @dragend="$emit('dragend')"
      :slot-height="slotHeight"
    />
    <modele-planning-creneau
      class="modele-planning-creneau-creating"
      ref="creneauCreating"
      v-if="displayCreneauCreating"
      :creneau="creneauCreating"
      :couleur="membre.couleur"
      :borneDebut="borneDebut"
      :slot-height="slotHeight"
    />
  </div>
</template>

<script>
import Color from 'color'
import { mapActions, mapGetters } from 'vuex'
import ModelePlanningCreneau from './modele-planning-creneau.vue'
import ModeleCreneauCreateDialog from './modele-creneau-create-dialog.vue'

export default {
  name: 'modele-planning-creneau-container-membre',
  components: {
    ModelePlanningCreneau,
    ModelePlanningCreneauCreate: ModeleCreneauCreateDialog,
  },
  props: {
    slotHeight: {
      type: Number,
      required: true,
    },
    creneauxDeTravail: {
      type: Array,
      required: true,
    },
    membre: {
      type: Object,
      required: true,
    },
    semaine: {
      type: Object,
      required: true,
    },
    jour: {
      type: Object,
      required: true,
    },
    borneDebut: {
      type: Object,
      required: true,
    },
    highlight: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      listenForMouseEvent: false,
      menu: {
        visible: undefined,
        x: 0,
        y: 0,
      },
      creneauCreating: undefined,
    }
  },
  computed: {
    ...mapGetters({
      jours: 'calendrier/getJours',
    }),
    jourDeRepos() {
      return this.semaine.joursDeRepos.find(
        (jr) => jr.membre.membreId === this.membre.membreId && jr.jour.jourId === this.jour.jourId
      )
    },
    isJourDeRepos() {
      return this.jourDeRepos !== undefined
    },
    tempsDeTravailMax() {
      return this.isJourDeRepos ? (this.jourDeRepos.demiJour ? 210 : 420) : 720
    },
    demiJour: {
      get() {
        if (this.isJourDeRepos) {
          return this.jourDeRepos.demiJour
        } else {
          return false
        }
      },
      set() {
        this.updateDemiJour()
      },
    },
    style() {
      let style = {}

      if (this.isJourDeRepos) {
        if (!this.jourDeRepos.demiJour) {
          style.background = `repeating-linear-gradient(
            45deg,
            ${this.couleurRayureSombre},
            ${this.couleurRayureSombre} 8px,
            ${this.couleurRayureClaire} 8px,
            ${this.couleurRayureClaire} 16px)`
        }
        style.borderColor = this.membre.couleur
        style.color = this.membre.couleur
        style.borderStyle = 'double'
        style.borderWidth = '4px'
        style.pointerEvents = 'auto'
        style.cursor = 'pointer'
      }

      if (this.highlight) {
        style.backgroundColor = Color(this.membre.couleur).fade(0.7)
        style.pointerEvents = 'auto'
        style.cursor = 'pointer'
      }

      return style
    },
    couleurRayureClaire() {
      return Color(this.membre.couleur).lightness(95).hex()
    },
    couleurRayureSombre() {
      return Color(this.membre.couleur).lightness(90).hex()
    },
    displayCreneauCreating() {
      return this.creneauCreating && this.creneauCreating.heureDebut.valeur < this.creneauCreating.heureFin.valeur
    },
  },
  methods: {
    ...mapActions({
      createModeleCreneau: 'modelePlanning/createModeleCreneau',
      createCreneauDeRepos: 'modelePlanning/createModeleJourDeRepos',
      updateModeleJourDeRepos: 'modelePlanning/updateModeleJourDeRepos',
      deleteModeleJourDeRepos: 'modelePlanning/deleteModeleJourDeRepos',
    }),
    isJourLibre(_jourId) {
      return !(
        this.semaine.creneaux
          .filter((c) => c.membreId === this.membre.membreId && c.jourId === _jourId)
          .map((c) => c.heureFin.valeur - c.heureDebut.valeur)
          .reduce((a, b) => a + b, 0) > this.tempsDeTravailMax ||
        this.semaine.joursDeRepos.filter(
          (jr) => jr.membre.membreId === this.membre.membreId && jr.jour.jourId === _jourId
        ).length > 0
      )
    },
    updateJour(_jour) {
      if (this.isJourDeRepos) {
        this.updateModeleJourDeRepos({
          ordreSemaine: this.semaine.ordre,
          modeleJourDeReposId: this.jourDeRepos.modeleJourDeReposId,
          jourId: _jour.jourId,
          demiJour: this.jourDeRepos.demiJour,
        })
      }
    },
    updateDemiJour() {
      if (this.isJourDeRepos) {
        this.updateModeleJourDeRepos({
          ordreSemaine: this.semaine.ordre,
          modeleJourDeReposId: this.jourDeRepos.modeleJourDeReposId,
          jourId: this.jourDeRepos.jour.jourId,
          demiJour: !this.jourDeRepos.demiJour,
        })
      }
    },
    dispatchCreateJourDeRepos() {
      this.createCreneauDeRepos({
        ordreSemaine: this.semaine.ordre,
        jourId: this.jour.jourId,
        membreId: this.membre.membreId,
        demiJour: false,
      })
    },
    dispatchDeleteModeleJourDeRepos() {
      this.deleteModeleJourDeRepos({
        ordreSemaine: this.semaine.ordre,
        modeleJourDeReposId: this.jourDeRepos.modeleJourDeReposId,
      })
    },
    getBorneFromPosition(Yposition) {
      //On arrondi au quart d'heure près
      Yposition = Yposition - (Yposition % 12)
      return this.getBorneFromValue((Yposition / 12) * 15 + this.borneDebut.valeur)
    },
    getBorneFromValue(_value) {
      var borneResult = {
        borneHoraireId: undefined,
        heure: undefined,
        minute: undefined,
        valeur: undefined,
      }

      borneResult.heure = (_value - (_value % 60)) / 60
      borneResult.minute = _value % 60
      borneResult.valeur = _value

      return borneResult
    },
    mousedown(event) {
      //On réserve les mouseEvents à ce composant en passant la variable "ListenForMouseEvent" à true
      this.listenForMouseEvent = true
      //on check que c'est un clique du bouton gauche de la souris
      if (event.buttons === 1 && this.creneauCreating === undefined) {
        this.creneauCreating = {
          modeleCreneauDeTravailId: undefined,
          heureDebut: this.getBorneFromPosition(event.offsetY),
          heureFin: this.getBorneFromPosition(event.offsetY),
          membreId: this.membre.membreId,
          ordreSemaine: this.semaine.ordre,
          jourId: this.jour.jourId,
        }
      }
    },
    mousemove(event) {
      //on check
      if (
        event.buttons === 1 &&
        //Qu'on soit bien en train de créer un créneau
        this.creneauCreating !== undefined &&
        //Que le créneauId soit toujours undefined (on le set à 0 au moment du mouseUp)
        this.creneauCreating.modeleCreneauDeTravailId === undefined
      ) {
        this.creneauCreating.heureFin = this.getBorneFromPosition(event.offsetY)
      }
    },
    mouseup(event) {
      //On libère la réservation des mouseEvents à ce composant en passant la variable "ListenForMouseEvent" à false
      this.listenForMouseEvent = false
      if (this.creneauCreating !== undefined && this.creneauCreating.modeleCreneauDeTravailId === undefined) {
        this.creneauCreating.modeleCreneauDeTravailId = 0
        if (this.creneauCreating.heureDebut.valeur < this.creneauCreating.heureFin.valeur) {
          this.createModeleCreneau(this.creneauCreating)
        } else {
          this.showMenu(event)
        }
      }
      this.creneauCreating = undefined
    },
    //méthode récupéré ici: https://vuetifyjs.com/en/components/menus/#absolute-without-activator
    showMenu(event) {
      event.preventDefault()
      this.menu.visible = false
      this.menu.x = event.clientX
      this.menu.y = event.clientY
      this.$nextTick(() => {
        this.menu.visible = true
      })
      this.menu.visible = true
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.modele-planning-creneau-container-membre {
  position: relative;
  display: block;
  flex: 1;
  pointer-events: none;
}
</style>

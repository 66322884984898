<template>
  <v-avatar :size="size">
    <img :src="avatar" alt="" />
  </v-avatar>
</template>

<script>
import imageProfilH from '@/assets/H_profil.png'
import imageProfilF from '@/assets/F_profil.png'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'utilisateur-avatar',
  props: {
    utilisateur: {
      type: Object,
      required: true,
    },
    size: {
      type: Number,
      required: false,
      default: 32,
    },
  },
  methods: {
    ...mapActions({
      fetchProfilePicture: 'utilisateur/fetchAvatarUtilisateur',
    }),
  },
  computed: {
    ...mapGetters({
      getAvatarByUserId: 'utilisateur/getAvatarByUserId',
    }),
    utilisateurId() {
      return this.utilisateur.utilisateurId
    },
    avatar() {
      if (this.utilisateur.hasAvatar) {
        return this.getAvatarByUserId(this.utilisateur.utilisateurId)
      } else {
        if (this.utilisateur.genre === 'Mr') {
          return imageProfilH
        } else {
          return imageProfilF
        }
      }
    },
  },
  created() {
    if (this.utilisateur.hasAvatar) {
      this.fetchProfilePicture(this.utilisateur.utilisateurId)
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>

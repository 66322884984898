<template>
  <v-menu v-model="menuVisible" close-on-content-click absolute offset-y style="max-width: 600px" :disabled="disabled">
    <template v-slot:activator="{ on }">
      <div ref="creneau" class="creneau" :style="creneauStyle" v-on="on">
        <slot name="icon"></slot>
      </div>
    </template>
    <v-list class="py-0" dense>
      <!-- LIST HEADER -->
      <div :style="style">
        <v-list-item flat :dark="headerDark">
          <v-list-item-avatar>
            <utilisateur-avatar :utilisateur="employe.utilisateur" :size="36" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="text-h6" style="line-height: 1.2em" v-text="employe.nomComplet" />
          </v-list-item-content>
        </v-list-item>
        <v-list-item flat :dark="headerDark" dense>
          <v-list-item-content>
            <v-list-item-title class="text-body-2">
              <v-icon>fa-calendar-alt</v-icon>
              {{ date }}
              <v-icon>fa-clock</v-icon>
              {{ creneau.heureDebut.toHeureMinute + ' à ' + creneau.heureFin.toHeureMinute }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
      <v-divider />
      <!-- LIST CONTENT -->
      <v-list-item-group
        v-if="
          isInRole('CHEF_DE_SERVICE_' + membre.serviceId) ||
          isInRole('ADMINISTRATEUR_' + membre.employe.etablissement.etablissementId)
        "
      >
        <conges-create-dialog
          v-if="!indisponibilite"
          mode="list"
          :employe="membre.employe"
          :dateDebut="creneau.date"
          @close="menuVisible = !menuVisible"
        />
        <creneau-de-remplacement-create-dialog :creneauRemplace="creneau" :indisponibilite="indisponibilite" />
        <creneau-switch-ne-pas-remplacer :creneau="creneau" :indisponibilite="indisponibilite" />
        <creneau-update-dialog :creneau="creneau" :indisponibilite="indisponibilite" mode="heuresSupp" />
        <creneau-update-dialog :creneau="creneau" :indisponibilite="indisponibilite" mode="recuperation" />
        <creneau-update-dialog :creneau="creneau" :indisponibilite="indisponibilite" />
        <creneau-annuler-remplacement :creneau="creneau" />
        <slot name="actions"></slot>
      </v-list-item-group>
      <v-list-item-group v-else-if="owner && !apercu">
        <demande-conges-create-dialog :employe="employe" />
        <demande-heures-supp-dialog :membreId="membre.membreId" :date="creneau.date" />
        <demande-recuperation-dialog :membreId="membre.membreId" :date="creneau.date" />
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import DemandeRecuperationDialog from '../../demande/planning/demande-recuperation-create-dialog.vue'

import DemandeCongesCreateDialog from '../../demande/conges/demande-conges-create-dialog'
import CongesCreateDialog from '../../conges/conges-create-dialog'
import UtilisateurAvatar from '../../utilisateur/utilisateur-avatar'
import CreneauUpdateDialog from '../../creneau/creneau-update-dialog'
import CreneauDeRemplacementCreateDialog from '../../creneau/creneau-de-remplacement-create-dialog'
import CreneauSwitchNePasRemplacer from '../../creneau/creneau-switch-ne-pas-remplacer'
import CreneauAnnulerRemplacement from '../../creneau/creneau-annuler-remplacement'
import { mapGetters } from 'vuex'

export default {
  name: 'creneau',
  components: {
    DemandeRecuperationDialog,
    DemandeCongesCreateDialog,
    CongesCreateDialog,
    CreneauSwitchNePasRemplacer,
    UtilisateurAvatar,
    CreneauUpdateDialog,
    CreneauDeRemplacementCreateDialog,
    CreneauAnnulerRemplacement,
    DemandeHeuresSuppDialog: () => import('../../demande/planning/demande-heures-supp-create-dialog'),
  },
  data() {
    return {
      menuVisible: false,
    }
  },
  props: {
    slotHeight: {
      type: Number,
      required: true,
    },
    borneDebut: {
      type: Object,
      required: true,
    },
    creneau: {
      type: Object,
      required: true,
    },
    indisponibilite: {
      type: Object,
      required: false,
      default: undefined,
    },
    inlineStyle: {
      type: Object,
      required: false,
      default() {
        return {}
      },
    },
    apercu: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      getTextColor: 'application/getTextColor',
      formatDateToDisplay: 'calendrier/formatDateToDisplay',
      getPlanningParameters: 'application/getPlanningParameters',
      isInRole: 'security/isInRole',
      user: 'security/getUtilisateur',
      getRemplacantsService: 'servicePlanning/getRemplacants',
      getRemplacants: 'planning/getRemplacants',
    }),
    membre() {
      return this.creneau.membre
    },
    employe() {
      return this.membre.employe
    },
    owner() {
      return this.user && this.employe && this.user.utilisateurId === this.employe.utilisateur.utilisateurId
    },
    position() {
      return {
        top: this.topPosition + 'px',
        height: this.height + 'px',
      }
    },
    topPosition() {
      if (this.creneauEdited === undefined) {
        return this.getDistance(this.borneDebut, this.creneau.heureDebut)
      } else {
        return this.getDistance(this.borneDebut, this.creneauEdited.heureDebut)
      }
    },
    height() {
      return this.getDistance(this.creneau.heureDebut, this.creneau.heureFin)
    },
    remplacePar() {
      if (this.$route.name === 'service-planning') {
        return this.getRemplacantsService(this.creneau).length > 0
      } else {
        return this.getRemplacants(this.creneau).length > 0
      }
    },
    nonRemplace() {
      return this.indisponibilite && this.creneau.nePasRemplacer
    },
    style() {
      return {
        opacity: this.creneau.type !== 'CreneauDeRecuperation' && this.creneau.creneauId === 0 ? '50%' : '100%',
        background: this.membre.couleur,
        borderColor: this.membre.couleur,
        cursor: this.disabled ? 'default' : 'pointer',
        visibility:
          (this.remplacePar || this.nonRemplace) && !this.getPlanningParameters.afficherAbsences ? 'hidden' : 'visible',
        ...this.inlineStyle,
      }
    },
    creneauStyle() {
      return {
        ...this.position,
        ...this.style,
      }
    },
    date() {
      return this.formatDateToDisplay(this.creneau.date)
    },
    headerDark() {
      return this.getTextColor(this.membre.couleur) === 'white'
    },
    displayIcon() {
      return this.height > 20
    },
  },
  methods: {
    getDistance(_borneReference, _borne) {
      let distance = 0

      distance += ((_borne.valeur - _borneReference.valeur) / 15) * this.slotHeight
      return distance
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.creneau {
  position: absolute;
  overflow: auto;
  width: 100%;
  max-width: 100%;

  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  pointer-events: all;
}
</style>

<template>
  <s-main-panel v-if="loaded">
    <v-card class="ma-3" flat>
      <v-container>
        <v-row align="center">
          <v-col v-for="(employe, index) in employes" :key="index" xl="4" lg="3" md="3" sm="3" justify="center">
            <conges-solde-list v-model="selectedCongesSolde" :employe-id="employe.employeId" displayEtablissement />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card class="ma-3 mt-0 fixed-height" flat>
      <perfect-scrollbar class="scrollable">
        <router-view />
      </perfect-scrollbar>
    </v-card>
  </s-main-panel>
</template>

<script>
import { mapGetters } from 'vuex'
import CongesSoldeList from '../../components/employe/employe-solde-list'

export default {
  name: 'utilisateur-soldes',
  components: { CongesSoldeList },
  data() {
    return {
      selectedCongesSolde: undefined,
    }
  },
  computed: {
    ...mapGetters({
      utilisateur: 'security/getUtilisateur',
      getEmployesByUtilisateurId: 'employe/getEmployesByUtilisateurId',
    }),
    loaded() {
      return this.utilisateur && this.employes
    },
    employes() {
      return this.getEmployesByUtilisateurId(this.utilisateur.utilisateurId)
    },
  },
  watch: {
    selectedCongesSolde() {
      if (this.selectedCongesSolde.congesTypeId >= 0) {
        this.$router.push({
          name: 'UtilisateurDetailsCongesSolde',
          params: this.selectedCongesSolde,
        })
      } else {
        this.$router.push({
          name: 'UtilisateurDetailsRecuperation',
          params: { employeId: this.selectedCongesSolde.employeId },
        })
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.fixed-height {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
}

.scrollable {
  display: flex;
  flex-direction: column;
}
</style>

<template>
  <v-menu
    v-model="menuColorPicker"
    ref="menuColorPicker"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
  >
    <template v-slot:activator="{ on }">
      <v-btn :color="couleur" v-on="on" fab />
    </template>
    <v-color-picker
      v-if="menuColorPicker"
      v-model="couleur"
      show-swatches
      mode="rgba"
      hide-inputs
      :swatches="swatches"
    />
  </v-menu>
</template>

<script>
export default {
  name: 'membre-color-picker',
  props: {
    value: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      menuColorPicker: false,
      couleur: undefined,
      swatches: [
        ['#f6a4a4', '#D500F9', '#4A148C'],
        ['#B50000', '', '#3E2723'],
        ['#E65100', '', '#2962FF'],
        ['#f6bf26', '', '#BBD8F6'],
        ['#76FF03', '#1B5E20', '#18FFFF'],
      ],
    }
  },
  created() {
    this.couleur = this.value
  },
  watch: {
    couleur() {
      this.$emit('input', this.couleur)
    },
  },
}
</script>

<style scoped></style>

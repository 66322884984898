<template>
  <demande-detail>
    <template v-slot:demande>
      <v-card-text>
        <v-container>
          <v-row no-gutters>
            <template v-if="demande.dateDebut === demande.dateFin">
              <s-text-info v-model="service.nom" label="Service" :cols="3" no-gutters />
              <s-text-info v-model="demande.dateDebut" type="date" label="Le" :cols="3" no-gutters />
              <s-text-info v-model="demande.heureDebut.toHeureMinute" label="De" :cols="3" no-gutters />
              <s-text-info v-model="demande.heureFin.toHeureMinute" label="À" :cols="3" no-gutters />
            </template>
            <template v-else>
              <s-text-info v-model="service.nom" label="Service" :cols="4" no-gutters />
              <s-text-info v-model="demande.dateDebut" type="date" label="Du" :cols="4" no-gutters />
              <s-text-info v-model="demande.dateFin" type="date" label="Au" :cols="4" no-gutters />
              <v-col cols="4" />
              <s-text-info v-model="demande.heureDebut.toHeureMinute" label="À" :cols="4" no-gutters />
              <s-text-info v-model="demande.heureFin.toHeureMinute" label="À" :cols="4" no-gutters />
            </template>
          </v-row>
        </v-container>
      </v-card-text>
    </template>
  </demande-detail>
</template>

<script>
import demandeDetail from './demande-detail'
import { mapGetters } from 'vuex'

export default {
  name: 'demande-recuperation-detail',
  components: {
    demandeDetail,
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      getDemandeById: 'demande/getDemandeById',
      getMembreById: 'membre/getMembreById',
      getServiceById: 'service/getServiceById',
    }),
    loaded() {
      return this.demande !== undefined
    },
    demande() {
      return this.getDemandeById(parseInt(this.$route.params.demandeId, 10))
    },
    membre() {
      if (this.demande) {
        return this.getMembreById(this.demande.membreId)
      } else {
        return undefined
      }
    },
    service() {
      if (this.membre) {
        return this.getServiceById(this.membre.serviceId)
      } else {
        return undefined
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

<template>
  <v-form v-model="formValid">
    <v-container>
      <v-row>
        <!-- Selection de l'employe -->
        <v-col cols="12">
          <employe-autocomplete
            v-model="employe"
            label="Employé"
            :etablissement="etablissement"
            :required="create"
            :readonly="!create"
            hide-details
          />
        </v-col>
        <v-col cols="6">
          <!-- Selection des dates -->
          <s-date-picker v-model="heuresCredit.dateDapplication" label="Date du crédit" required defaultCurrent />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="heuresCredit.nbHeures"
            :rules="numericFieldRule"
            type="number"
            label="Crédités*"
            suffix="heure(s)"
            required
          >
            <template v-slot:prepend>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on"> fa-info-circle </v-icon>
                </template>
                Vous pouvez entrer une valeur négative
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
        <!-- Selection du type de congés -->
        <v-col cols="12">
          <v-select :items="raisons" v-model="raison" label="Raison*" dense :rules="[(v) => !!v]" hide-details />
        </v-col>
        <v-col cols="12">
          <v-textarea
            ref="commentaire"
            v-model="heuresCredit.commentaire"
            :rules="[validationCommentaire]"
            no-resize
            rows="2"
            hide-no-data
            clearable
            clear-icon="mdi-close-circle"
            :label="heuresCredit.raison === 'AUTRES' ? 'Commentaire*' : 'Commentaire'"
          ></v-textarea>
        </v-col>
      </v-row>
      <small>*Information obligatoire</small>
    </v-container>
  </v-form>
</template>

<script>
import EmployeAutocomplete from '@/components/employe/employe-autocomplete'
import { mapGetters } from 'vuex'

export default {
  name: 'heures-credit-form',
  components: {
    EmployeAutocomplete,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    create: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      formValid: false,
      raisons: ['PAIEMENT', 'JOURNEE DE SOLIDARITE', 'RÉGULARISATION', 'AUTRES'],
      heuresCredit: undefined,
      numericFieldRule: [
        (v) => !!v || 'Information obligatoire',
        (v) => !isNaN(v) || 'Entrez une valeur numérique',
        (v) => v > 0 || 'Entrez une valeur positive',
      ],
    }
  },
  computed: {
    ...mapGetters({
      etablissement: 'application/getSelectedEtablissement',
      employes: 'employe/getEmployes',
      getEmployeById: 'employe/getEmployeById',
    }),
    employe: {
      get() {
        return this.getEmployeById(this.heuresCredit.employeId)
      },
      set(_employe) {
        this.heuresCredit.employeId = _employe.employeId
      },
    },
    raison: {
      get() {
        return this.heuresCredit ? this.heuresCredit.raison : undefined
      },
      set(_raison) {
        this.heuresCredit.raison = _raison
      },
    },
  },
  created() {
    this.heuresCredit = this.value
  },
  watch: {
    heuresCredit: {
      async handler() {
        this.$emit('input', this.heuresCredit)
      },
      deep: true,
    },
    value() {
      this.heuresCredit = this.value
    },
    formValid() {
      this.$emit('updateFormValid', this.formValid)
    },
    raison() {
      this.$refs.commentaire.validate()
    },
  },
  methods: {
    validationCommentaire(value) {
      if (
        this.heuresCredit.raison === 'AUTRES' &&
        (value === undefined || value.split(' ').filter((word) => word !== '').length < 5)
      ) {
        return 'Veuillez expliquer la raison de ce crédit (au moins 5 mots)'
      } else {
        return true
      }
    },
  },
}
</script>

<style scoped></style>

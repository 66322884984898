<script setup>
import SCardWithToolbar from '@/components/global/s-card-with-toolbar.vue'
import StatutFicheAim from '@/components/fiche-aim/status-fiche-aim.vue'
import FicheAimUsagerExpensionPanel from '@/components/fiche-aim/ligne/ligne-aim-expension-panel.vue'
import ficheAIM from '../../../store/modules/aim/ficheAIM'
</script>

<template>
  <s-card-with-toolbar is-extented>
    <template v-if="!!ficheAIM" #titre>
      Fiche d'Aide Individuelle Mensuelle - {{ ficheAim.mois }}/{{ ficheAim.annee }}
    </template>
    <template #top>
      <v-container class="pr-8">
        <v-row no-gutters align="center">
          <v-col cols="4" align-self="center">
            <span>Poste</span>
          </v-col>
          <v-col cols="8" class="text--secondary">
            <v-row no-gutters style="width: 100%" align="center">
              <v-col class="text-right pr-6" cols="4">
                <span>Solde début de mois</span>
              </v-col>
              <v-col class="text-right pr-6" cols="4">
                <span>Acquis du mois</span>
              </v-col>
              <v-col class="text-right pr-6" cols="4">
                <span>Après ajustements</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template #actions><statut-fiche-aim :statut-fiche="ficheAim.statut" /></template>
    <perfect-scrollbar class="h-100">
      <v-expansion-panels @change="reinitializerAjustementSelectionne" v-model="lignesActives" multiple>
        <fiche-aim-usager-expension-panel
          v-for="ligne in ficheAim.lignes"
          :key="ligne.ligneFicheId"
          :ligne-fiche-aim="ligne"
        />
      </v-expansion-panels>
    </perfect-scrollbar>
    <template #bottom>
      <v-container>
        <v-row>
          <v-spacer />
          <v-btn color="info"> Préparer les versements </v-btn>
        </v-row>
      </v-container>
    </template>
  </s-card-with-toolbar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'rendu-fiche-aim',
  props: {
    ficheAim: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      lignesActives: [],
    }
  },
  computed: {
    ...mapGetters({
      estAjustementConcerneParAjustementSelectionne:
        'renduAIM_IHM_helper/estAjustementConcerneParAjustementSelectionne',
      estSelectionne: 'renduAIM_IHM_helper/estSelectionne',
    }),
    indexLignesConcernesParSelection() {
      let indexLignes = []
      this.ficheAim.lignes.forEach((ligne, i) => {
        if (ligne.operations.some((op) => this.estAjustementConcerneParAjustementSelectionne(op.ajustementId))) {
          indexLignes.push(i)
        }
      })
      return indexLignes
    },
    totalDuFiche() {
      let sum = 0
      for (var i = 0; i < this.ficheAim.lignes.length; i++) {
        sum += this.ficheAim.lignes[i].montantFinal
      }
      return sum
    },
  },
  methods: {
    ...mapActions({
      reinitializerAjustementSelectionne: 'renduAIM_IHM_helper/reinitializerAjustementSelectionne',
    }),
  },
  watch: {
    indexLignesConcernesParSelection: function (indexLignes) {
      if (this.estSelectionne) this.lignesActives = indexLignes
    },
  },
}
</script>

<style>
.h-100 {
  height: 100% !important;
}
</style>

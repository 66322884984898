/* eslint-disable no-unused-vars */
import axios from 'axios'
import Vue from 'vue'

const state = {
  creneaux: [],
  joursDeRepos: [],
}

const mutations = {
  ADD_CRENEAU(state, _creneau) {
    state.creneaux = state.creneaux.filter((c) => c.creneauTriId !== _creneau.creneauTriId)
    state.creneaux.push(_creneau)
  },
  ADD_CRENEAUX(state, _creneaux) {
    _creneaux.forEach((_creneau) => {
      state.creneaux = state.creneaux.filter((c) => c.creneauTriId !== _creneau.creneauTriId)
      state.creneaux.push(_creneau)
    })
  },
  REMOVE_CRENEAUX_DE_TRAVAIL(state, _creneaux) {
    _creneaux.forEach((_creneau) => {
      state.creneaux = state.creneaux.filter((c) => !(c.creneauTriId === _creneau.creneauTriId))
    })
  },
  ADD_JOURS_DE_REPOS(state, _joursDeRepos) {
    _joursDeRepos.forEach((_jourDeRepos) => {
      state.joursDeRepos.push(_jourDeRepos)
    })
  },
  FLUSH(state, _serviceId) {
    state.creneaux = state.creneaux.filter((c) => !(c.membre.serviceId === _serviceId))
    state.joursDeRepos = state.joursDeRepos.filter((jr) => !(jr.membre.serviceId === _serviceId))
  },
  FLUSH_DATE_MEMBRE(state, _flush) {
    state.creneaux = state.creneaux.filter((c) => !(c.date === _flush.date && c.membre.membreId === _flush.membreId))
    /*state.joursDeRepos = state.joursDeRepos.filter(
      (jr) =>
        !(jr.semaine.semaine === _flush.semaine && jr.semaine.annee === _flush.annee && jr.membreId === _flush.membreId)
    )*/
  },
}

const getters = {
  getCreneaux: (state) => () => {
    return state.creneaux ? state.creneaux : []
  },
  getJoursDeRepos: (state) => () => {
    return state.joursDeRepos ? state.joursDeRepos : []
  },
  getCreneauDeTravailById: (state) => (_creneauId) => {
    return state.creneaux.find((c) => c.creneauId === _creneauId)
  },
  getRemplacants: (state) => (_creneau) => {
    return state.creneaux.filter((c) => c.remplace && c.remplace.creneauId === _creneau.creneauId)
  },
}

const actions = {
  flush({ state, commit }, _serviceId) {
    commit('FLUSH', _serviceId)
  },
  refreshDateMembre({ commit }, _query) {
    commit('FLUSH_DATE_MEMBRE', _query)
    return axios
      .get(`${process.env.VUE_APP_URL_BACK_SERENITY}/creneau/membre/${_query.membreId}?_date=${_query.date}`)
      .then((response) => {
        if (response.data) {
          commit('ADD_CRENEAUX', response.data)
        }
      })
  },
  fetchCreneauxSemaineByService({ commit, dispatch }, _query) {
    return axios
      .get(
        `${process.env.VUE_APP_URL_BACK_SERENITY}/creneau/service/${_query.serviceId}/semaine?_annee=${_query.annee}&_semaine=${_query.semaine}`
      )
      .then((response) => {
        if (response.data) {
          commit('ADD_CRENEAUX', response.data)
        }
      })
  },
  fetchJourDeReposSemaineByService({ commit }, _query) {
    return axios
      .get(
        `${process.env.VUE_APP_URL_BACK_SERENITY}/jourDeRepos/service/${_query.serviceId}?_annee=${_query.annee}&_semaine=${_query.semaine}`
      )
      .then((response) => {
        if (response.data) {
          commit('ADD_JOURS_DE_REPOS', response.data)
        }
      })
  },
  remplacerCreneau({ state, commit }, _payload) {
    return axios
      .post(`${process.env.VUE_APP_URL_BACK_SERENITY}/creneau/${_payload.remplaceId}/remplacer`, _payload)
      .then((response) => {
        commit('ADD_CRENEAUX', response.data)
      })
  },
  nePasRemplacer({ state, commit }, _creneauId) {
    return axios
      .put(`${process.env.VUE_APP_URL_BACK_SERENITY}/creneau/${_creneauId}/nePasRemplacer`)
      .then((response) => {
        commit('ADD_CRENEAU', response.data)
      })
  },
  annulerRemplacement({ state, commit }, _creneauId) {
    return axios
      .delete(`${process.env.VUE_APP_URL_BACK_SERENITY}/creneau/${_creneauId}/annulerRemplacement`)
      .then((response) => {
        commit('REMOVE_CRENEAUX_DE_TRAVAIL', response.data)
      })
  },
  createCreneauDeTravail({ state, commit, dispatch }, _toCreate) {
    return axios.post(`${process.env.VUE_APP_URL_BACK_SERENITY}/creneau/travail`, _toCreate).then(() => {
      dispatch('refreshDateMembre', {
        date: _toCreate.date,
        membreId: _toCreate.membreId,
      })
    })
  },
  updateCreneauDeTravail({ state, commit, dispatch }, _toUpdate) {
    return axios
      .put(`${process.env.VUE_APP_URL_BACK_SERENITY}/creneau/${_toUpdate.creneauId}`, {
        creneauId: _toUpdate.creneauId,
        nouvelleHeureDebut: _toUpdate.heureDebut,
        nouvelleHeureFin: _toUpdate.heureFin,
      })
      .then(() => {
        dispatch('refreshDateMembre', {
          date: _toUpdate.date,
          membreId: _toUpdate.membre.membreId,
        })
      })
  },
  restaurerDuTempsDeTravail({ state, dispatch }, _restaurerDuTempsDeTravailCommand) {
    return axios
      .put(
        `${process.env.VUE_APP_URL_BACK_SERENITY}/creneau/restaurerDuTempsDeTravail`,
        _restaurerDuTempsDeTravailCommand
      )
      .then((response) => {
        dispatch('refreshDateMembre', {
          date: _restaurerDuTempsDeTravailCommand.dateDuTempsDeTravailARestaurer,
          membreId: _restaurerDuTempsDeTravailCommand.membreId,
        })
      })
  },
  retirerDuTempsDeTravail({ state, dispatch }, _retirerDuTempsDeTravailCommand) {
    return axios
      .put(`${process.env.VUE_APP_URL_BACK_SERENITY}/creneau/retirerDuTempsDeTravail`, _retirerDuTempsDeTravailCommand)
      .then((response) => {
        dispatch('refreshDateMembre', {
          date: _retirerDuTempsDeTravailCommand.dateDebut,
          membreId: _retirerDuTempsDeTravailCommand.membreId,
        })
      })
  },
  createCreneauDeFormation({ state, commit, dispatch }, _toCreate) {
    return axios.post(`${process.env.VUE_APP_URL_BACK_SERENITY}/creneau/formation`, _toCreate).then(() => {
      dispatch('refreshDateMembre', {
        date: _toCreate.date,
        membreId: _toCreate.membreId,
      })
    })
  },
  annulerCreneauDeFormation({ state, dispatch }, _annuler) {
    return axios
      .delete(`${process.env.VUE_APP_URL_BACK_SERENITY}/creneau/formation/${_annuler.creneauId}`)
      .then((response) => {
        dispatch('refreshDateMembre', {
          date: _annuler.date,
          membreId: _annuler.membre.membreId,
        })
      })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

<template>
  <v-btn large @click="generateCongesPdf" icon color="primary">
    <v-icon>mdi-printer</v-icon>
    <vue-html2pdf
      v-show="false"
      :float-layout="false"
      :enable-download="true"
      :filename="positionToFileName"
      :pdf-quality="2"
      pdf-format="a4"
      pdf-orientation="landscape"
      ref="html2Pdf"
      pdf-content-width="100%"
      :manual-pagination="true"
      @startPagination="impressionEnCours = true"
      @hasDownloaded="impressionEnCours = false"
    >
      <section slot="pdf-content">
        <v-container class="pa-0 ma-0" style="max-width: 100%">
          <v-row class="no-gutters">
            <v-col class="no-gutters" cols="2"></v-col>
            <v-col class="no-gutters">
              <v-row justify="space-between" no-gutters>
                <v-col cols="12">
                  <v-row no-gutters>
                    <v-col cols="12">
                      <div class="mois body-1 text-center">{{ dateString }}</div>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12">
                      <ligne-jours-conges-apercu :position="position" />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <conges-apercu-ligne-employe
            v-for="(employe, index) in employes"
            :class="index === 15 || (index > 16 && index % 16 === 0) ? 'html2pdf__page-break' : ''"
            :employeId="employe.employeId"
            :mois="position"
            :donnees="getDonneesByEmployeId(employe.employeId)"
            :divider="index !== employes.length - 1"
            :loading="loading"
            :key="employe.employeId"
            :mode-impression="true"
          />
        </v-container>
      </section>
    </vue-html2pdf>
    <v-snackbar v-model="impressionEnCours">
      Impression en cours ...
      <template v-slot:actions>
        <v-btn color="blue" variant="text" @click="impressionEnCours = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-btn>
</template>

<script>
import CongesApercuLigneEmploye from './conges-apercu-ligne-employe'
import VueHtml2pdf from 'vue-html2pdf'
import LigneJoursCongesApercu from '@/components/conges/apercu/ligne-jours-conges-apercu.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'impression-conges-apercu',
  components: {
    LigneJoursCongesApercu,
    CongesApercuLigneEmploye,
    VueHtml2pdf,
  },
  data() {
    return {
      impressionEnCours: false,
    }
  },
  props: {
    employes: {
      type: Array,
      required: true,
    },
    getDonneesByEmployeId: {
      type: Function,
      required: true,
    },
    position: {
      type: Object,
      required: true,
    },
    dateString: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getMonthLabel: 'calendrier/getMonthLabel',
    }),
    positionToFileName() {
      return this.getMonthLabel(this.position.mois) + '-' + this.position.annee
    },
  },
  methods: {
    generateCongesPdf() {
      this.$refs.html2Pdf.generatePdf()
    },
  },
}
</script>

<style>
.mois {
  display: inline flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.jours {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
}

.jour {
  display: inline flex;
  flex-direction: column;
  width: 100%;
  padding: 2px;
  align-items: center;
  justify-content: center;
}
</style>

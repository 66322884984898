<template>
  <div class="planning-body-nuit" v-bind:class="{ 'planning-body-nuit-lundi': jour.jourId === 1 }">
    <!-- Slots et container du matin (avant le planning de jour) -->
    <div class="planning-body-nuit-container">
      <planning-body-slot
        v-for="borneHoraire in bornesHoraireMatin"
        :key="borneHoraire.borneHoraireId"
        :borneHoraire="borneHoraire"
        :jour="jour"
        :jour_nuit="isJourNuit(borneHoraire)"
        :slot-height="slotHeight"
      />
      <planning-creneau-container
        :creneaux="creneauxMatin"
        :joursDeRepos="joursDeRepos"
        :semaine="semaine"
        :jour="jour"
        :membres="membres"
        :borneDebut="bornesHoraireMatin[0]"
        :borneFin="bornesHoraireMatin[bornesHoraireMatin.length - 1]"
        :displayJourDeRepos="displayJoursDeRepos"
      />
    </div>
    <div class="planning-body-nuit-spacer" />
    <!-- Slots et container du soir (après le planning de jour) -->
    <div class="planning-body-nuit-container">
      <planning-body-slot
        v-for="borneHoraire in bornesHoraireSoir"
        :key="borneHoraire.borneHoraireId"
        :borneHoraire="borneHoraire"
        :jour="jour"
        :jour_nuit="isJourNuit(borneHoraire)"
        :slot-height="slotHeight"
      />
      <planning-creneau-container
        :creneaux="creneauxSoir"
        :joursDeRepos="joursDeRepos"
        :semaine="semaine"
        :jour="jour"
        :membres="membres"
        :borneDebut="bornesHoraireSoir[0]"
        :borneFin="bornesHoraireSoir[bornesHoraireSoir.length - 1]"
        :displayJoursDeRepos="displayJoursDeRepos"
        :slot-height="slotHeight"
      />
    </div>
  </div>
</template>

<script>
import planningBodySlot from './planning-body-slot'
import planningCreneauContainer from '@/components/planning/creneau/planning-creneau-container'
import { mapGetters } from 'vuex'

export default {
  name: 'planning-body-nuit',
  components: {
    planningBodySlot,
    planningCreneauContainer,
  },
  props: {
    slotHeight: {
      type: Number,
      requred: true,
    },
    creneaux: {
      type: Array,
      required: true,
    },
    joursDeRepos: {
      type: Array,
      required: true,
    },
    semaine: {
      type: Object,
      required: true,
    },
    jour: {
      type: Object,
      required: true,
    },
    membres: {
      type: Array,
      required: true,
    },
    displayJoursDeRepos: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getBornesHoraireMatin: 'calendrier/getBornesHoraireMatin',
      getBornesHoraireSoir: 'calendrier/getBornesHoraireSoir',
      isJourNuit: 'calendrier/isJourNuit',
    }),
    bornesHoraireMatin() {
      return this.getBornesHoraireMatin()
    },
    creneauxMatin() {
      return this.creneaux.filter(
        (c) =>
          (c.heureDebut.valeur >= this.bornesHoraireMatin[0].valeur &&
            c.heureDebut.valeur < this.bornesHoraireMatin[this.bornesHoraireMatin.length - 1].valeur) ||
          (c.heureFin.valeur >= this.bornesHoraireMatin[0].valeur &&
            c.heureFin.valeur < this.bornesHoraireMatin[this.bornesHoraireMatin.length - 1].valeur)
      )
    },
    bornesHoraireSoir() {
      return this.getBornesHoraireSoir()
    },
    creneauxSoir() {
      return this.creneaux.filter(
        (c) =>
          (c.heureDebut.valeur >= this.bornesHoraireSoir[0].valeur &&
            c.heureDebut.valeur < this.bornesHoraireSoir[this.bornesHoraireSoir.length - 1].valeur) ||
          (c.heureFin.valeur > this.bornesHoraireSoir[0].valeur &&
            c.heureFin.valeur <= this.bornesHoraireSoir[this.bornesHoraireSoir.length - 1].valeur)
      )
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.planning-body-nuit {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex: 1;
  position: relative;

  border-left: 5px solid #c0c0c0;
}

.planning-body-nuit-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex: 1;
  position: relative;
}

.planning-body-nuit-lundi {
  border-left: 5px solid #c0c0c0;
}

.planning-body-nuit-spacer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: repeating-linear-gradient(45deg, #ffee93, #ffee93 8px, #edaa00 8px, #edaa00 16px);

  border-bottom: solid 1px #edaa00;
  opacity: 0.3;
  height: 30px;
}
</style>

<template>
  <v-container v-if="loaded" class="pa-0 modele-planning-publication">
    <v-toolbar dense flat>
      <span class="title">Statut</span>
      <v-spacer />
      <v-icon v-if="!loading" color="success">fa-check</v-icon>
      <v-progress-circular v-else :size="28" :width="3" color="secondary" indeterminate />
    </v-toolbar>
    <!--<span class="body-2">Date du dernier modèle publié</span>-->
    <v-dialog v-model="visible" persistent width="330px">
      <template #activator="{ on }">
        <v-btn :disabled="loading" block tile color="success" v-on="on">Mettre en application</v-btn>
      </template>
      <v-container class="modele-planning-publication-dialog pa-0">
        <v-toolbar dark color="success">
          <span class="text-body-1"> Date de mise en application </span>
          <v-spacer />
          <v-btn icon @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-form ref="form" v-model="formValid">
          <v-date-picker
            v-model="publication.semaineMiseEnApplication"
            class="elevation-0"
            no-title
            flat
            full-width
            locale="fr"
            first-day-of-week="1"
            locale-first-day-of-year="4"
            :allowed-dates="seulementLesLundis"
            :min="dateMinimumSelectionable"
            show-week
            reactive
          />
          <v-text-field
            v-model="publication.semaineMiseEnApplication"
            :rules="[(v) => !!v]"
            class="hidden-screen-only"
          />
          <v-autocomplete
            v-model="publication.debutRoulement"
            return-object
            class="px-5"
            :items="getModelePlanning.semaines"
            :rules="[(v) => !!v]"
            item-text="ordre"
            label="Semaine de début de roulement"
          />
        </v-form>
        <v-card-actions>
          <v-spacer />
          <v-btn color="grey darken-1" text @click="close()"> Annuler </v-btn>
          <s-confirmation-dialog
            info-level="warning"
            message="Cette publication va écraser le planning déjà présent à partir de la date choisie. Êtes-vous sûr(e) de vouloir publier ce nouveau modèle ?"
            @confirmation="validateForm()"
          >
            <v-btn color="success" :disabled="!formValid"> Confirmer </v-btn>
          </s-confirmation-dialog>
        </v-card-actions>
      </v-container>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import SConfirmationDialog from '../../components/global/s-confirmation-dialog'

export default {
  name: 'modele-planning-mise-en-application',
  components: { SConfirmationDialog },
  data() {
    return {
      visible: false,
      publication: {
        semaineMiseEnApplication: undefined,
        debutRoulement: undefined,
      },
      formValid: false,
      seulementLesLundis: (val) => new Date(val).getDay() === 1,
    }
  },
  computed: {
    ...mapGetters({
      loading: 'modelePlanning/getLoading',
      getModelePlanning: 'modelePlanning/getModelePlanning',
      currentDate: 'calendrier/getCurrentDate',
      isAdmin: 'security/isAdmin',
    }),
    loaded() {
      return this.publication && this.getModelePlanning
    },
    dateMinimumSelectionable() {
      if (this.isAdmin) {
        return undefined
      } else {
        return this.currentDate
      }
    },
  },
  methods: {
    ...mapActions({
      publier: 'modelePlanning/publierModelePlanning',
      flushModelePlanning: 'modelePlanning/flushModelePlanning',
    }),
    getSemaineOfDate(_date) {
      return {
        semaine: moment(_date).isoWeek(),
        annee: moment(_date).year(),
      }
    },
    validateForm() {
      this.$refs.form.validate()
      if (this.formValid) {
        this.publier({
          modelePlanningId: this.getModelePlanning.modelePlanningId,
          semaineMiseEnApplication: this.getSemaineOfDate(this.publication.semaineMiseEnApplication),
          debutCyclePublication: this.publication.debutRoulement.ordre,
        }).then(() => {
          this.close()
          this.$router.push({
            name: 'service-planning',
            params: { serviceId: `${this.$route.params.serviceId}` },
          })
          this.flushModelePlanning()
        })
      }
    },
    close() {
      this.visible = false
      this.publication.debutRoulement = undefined
      this.publication.semaineMiseEnApplication = undefined
      this.formValid = false
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.modele-planning-publication {
  align-self: flex-end;
  justify-self: flex-end;
}

.modele-planning-publication-dialog {
  background-color: white;
}
</style>

import { render, staticRenderFns } from "./association-create-dialog.vue?vue&type=template&id=51c77b8c&scoped=true"
import script from "./association-create-dialog.vue?vue&type=script&lang=js"
export * from "./association-create-dialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51c77b8c",
  null
  
)

export default component.exports
<template>
  <div v-if="loading" class="d-flex align-center justify-center">
    <v-progress-circular indeterminate></v-progress-circular>
  </div>
  <v-card flat v-else>
    <v-card-text v-if="!loading">
      <template v-if="getDernierAccueilsUsager !== undefined">
        <p class="text-body-1">Service d'accueil actuel : {{ dernierAccueilUsager.serviceDAccueil.nom }}</p>
        <p class="text-body-2">
          {{ dates }}
        </p>
      </template>
      <p v-else>Aucun accueil trouvé pour cet usager.</p>
    </v-card-text>
    <v-card-actions>
      <v-btn block text color="primary" :to="{ name: 'historique-accueils-usager' }">Voir l'historique d'accueil</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'carte-accueil-usager',
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    ...mapGetters({
      formatDateToDisplay: 'calendrier/formatDateToDisplay',
      getMembreById: 'membre/getMembreById',
      getDernierAccueilsUsager: 'accueil/getDernierAccueilByUsagerId',
    }),
    usagerId() {
      return parseInt(this.$route.params.usagerId)
    },
    dernierAccueilUsager() {
      return this.getDernierAccueilsUsager(this.usagerId)
    },
    dates() {
      if (this.dernierAccueilUsager.dateDeFinDAccueil) {
        return (
          'Du ' +
          this.formatDateToDisplay(this.dernierAccueilUsager.dateDeDebutDAccueil) +
          ' au ' +
          this.formatDateToDisplay(this.dernierAccueilUsager.dateDeFinDAccueil)
        )
      } else {
        if (new Date(this.dernierAccueilUsager.dateDeDebutDAccueil) < new Date()) {
          return 'Depuis le ' + this.formatDateToDisplay(this.dernierAccueilUsager.dateDeDebutDAccueil)
        } else {
          return 'À compter du ' + this.formatDateToDisplay(this.dernierAccueilUsager.dateDeDebutDAccueil)
        }
      }
    },
  },
  methods: {
    ...mapActions({
      fetchAccueilsByUsagerId: 'accueil/fetchAccueilsByUsagerId',
    }),
  },
  beforeMount() {
    this.fetchAccueilsByUsagerId(this.usagerId).then(() => (this.loading = false))
  },
}
</script>

<style scoped></style>

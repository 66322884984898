<template>
  <v-list-item v-if="creneau.remplace || remplacePar" @click="annulerRemplacement()">
    <v-list-item-icon>
      <v-icon color="grey">fa-people-arrows</v-icon>
    </v-list-item-icon>
    <v-list-item-title>Annuler le remplacement</v-list-item-title>
  </v-list-item>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'creneau-annuler-remplacement',
  props: {
    creneau: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getRemplacants: 'servicePlanning/getRemplacants',
    }),
    remplacePar() {
      return this.getRemplacants(this.creneau).length > 0
    },
  },
  methods: {
    ...mapActions({
      dispatchAnnulerRemplacement: 'servicePlanning/annulerRemplacement',
    }),
    annulerRemplacement() {
      if (this.remplacePar) {
        this.dispatchAnnulerRemplacement(this.creneau.creneauId)
      } else if (this.creneau.remplace) {
        this.dispatchAnnulerRemplacement(this.creneau.remplace.creneauId)
      }
    },
  },
}
</script>

<style scoped></style>

import Vue from 'vue'
import Vuex from 'vuex'
import admin from './modules/admin.js'
import application from './modules/application.js'
import astreinte from './modules/astreinte.js'
import calendrier from './modules/calendrier'
import conges from './modules/conges'
import contrat from './modules/contrat'
import demande from './modules/demande'
import employe from './modules/employe'
import etablissement from './modules/etablissement'
import membre from './modules/membre'
import modelePlanning from './modules/modele-planning'
import planning from './modules/planning'
import security from './modules/security.js'
import service from './modules/service'
import servicePlanning from './modules/service-planning'
import utilisateur from './modules/utilisateur'
import posteAIM from '@/store/modules/aim/posteAIM'
import usager from '@/store/modules/usager'
import accueil from '@/store/modules/accueil'
import ficheAIM from '@/store/modules/aim/ficheAIM'
import renduAIM_IHM_helper from '@/store/modules/aim/renduAIM_IHM_helper'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    admin,
    application,
    astreinte,
    calendrier,
    conges,
    contrat,
    demande,
    employe,
    etablissement,
    membre,
    modelePlanning,
    planning,
    security,
    service,
    servicePlanning,
    utilisateur,
    posteAIM,
    usager,
    accueil,
    ficheAIM,
    renduAIM_IHM_helper,
  },
})

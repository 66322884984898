<template>
  <v-container v-if="loaded">
    <v-row>
      <v-col :cols="congesCredits.length > 0 ? 6 : 12" class="py-0">
        <v-toolbar flat dense :class="congesTypeId > 0 ? 'conges-toolbar' : 'conges-autres-toolbar'">
          <v-toolbar-title class="text-h6" v-text="'Congés'" />
          <v-spacer />
        </v-toolbar>
        <v-list two-line flat class="pa-0">
          <perfect-scrollbar>
            <v-list-item-group color="primary">
              <conges-list-item
                v-for="conge in conges"
                :key="conge.congesId"
                :conges="conge"
                :employeId="employe.employeId"
              />
            </v-list-item-group>
          </perfect-scrollbar>
        </v-list>
      </v-col>
      <v-col v-if="congesCredits.length > 0" cols="6" class="py-0">
        <v-toolbar flat dense class="conges-credits-toolbar">
          <v-toolbar-title class="text-h6" v-text="'Crédits de congés'" />
        </v-toolbar>
        <v-list two-line flat class="pa-0">
          <perfect-scrollbar>
            <v-list-item-group color="primary">
              <conges-credit-list-item
                v-for="congesCredit in congesCredits"
                :key="congesCredit.congesCreditId"
                :congesCredit="congesCredit"
                :employe="employe"
              />
            </v-list-item-group>
          </perfect-scrollbar>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CongesListItem from '../conges-list-item'
import CongesCreditListItem from '../credit/conges-credit-debit-list-item.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'conges-solde-detail',
  components: {
    CongesListItem,
    CongesCreditListItem,
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      getEmployeById: 'employe/getEmployeById',
    }),
    loaded() {
      return this.employe
    },
    congesTypeId() {
      return parseInt(this.$route.params.congesTypeId, 10)
    },
    employe() {
      return this.getEmployeById(parseInt(this.$route.params.employeId, 10))
    },
    conges() {
      if (this.congesTypeId > 0) {
        return this.employe.conges.filter((c) => c.type.congesTypeId === this.congesTypeId)
      } else {
        return this.employe.conges.filter((c) => !c.type.avecSolde)
      }
    },
    congesCredits() {
      if (this.congesTypeId > 0) {
        return this.employe.congesCredits.filter((cc) => cc.type.congesTypeId === this.congesTypeId)
      } else {
        return []
      }
    },
  },
}
</script>

<style scoped>
.conges-toolbar {
  border-bottom: 2px solid var(--v-error-lighten5);
}

.conges-autres-toolbar {
  border-bottom: 2px solid var(--v-primary-lighten5);
}

.conges-credits-toolbar {
  border-bottom: 2px solid var(--v-success-lighten5);
}
</style>

<template>
  <v-row no-gutters align="stretch">
    <v-col cols="2">
      <div class="pa-1 employe">
        <utilisateur-avatar :utilisateur="employe.utilisateur" :size="36" />
        <span class="body-2 ml-2 text-no-wrap">
          {{ employe.nomComplet }}
        </span>
        <v-spacer />
      </div>
      <v-divider v-if="divider" />
    </v-col>
    <v-col cols="10">
      <v-row no-gutters align="stretch" justify="center" style="height: 100%">
        <v-col :cols="modeImpression ? 12 : 10" style="height: 100%">
          <div class="jours" style="height: 100%">
            <conges-apercu-cellule-employe
              :mode-impression="modeImpression"
              v-for="index in nbJours"
              :key="index"
              :date="
                ''.concat(
                  mois.annee,
                  '-',
                  getNumeroDeMoisCorrespondant(mois.mois),
                  '-',
                  getNumeroDeJourCorrespondant(index)
                )
              "
              :donnees="getDonneesByJour(index)"
              :loading="loading"
            />
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import UtilisateurAvatar from '../../../components/utilisateur/utilisateur-avatar'
import CongesApercuCelluleEmploye from './conges-apercu-cellule-employe.vue'

export default {
  name: 'conges-apercu-ligne-employe',
  components: {
    UtilisateurAvatar,
    CongesApercuCelluleEmploye,
  },
  props: {
    employeId: {
      type: Number,
      required: true,
    },
    mois: {
      type: Object,
      required: true,
    },
    donnees: {
      type: Object,
      required: false,
      default: undefined,
    },
    divider: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    modeImpression: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      getEmployeById: 'employe/getEmployeById',
      getNbDaysInMonth: 'calendrier/getNbDaysInMonth',
      getDayOfTheWeekOfDate: 'calendrier/getDayOfTheWeekOfDate',
      isFerie: 'calendrier/isISODateFerie',
    }),
    nbJours() {
      // Les mois en JavaScript sont indexés à partir de 0 (janvier = 0, février = 1, etc)
      // mais en passant 0 comme numéro de jour en paramètre, ça va nous donner le dernier jour du mois précédent
      // Donc en passant 1, on obtient le dernier jour du mois de janvier plutôt que février
      return new Date(this.mois.annee, this.mois.mois, 0).getDate()
    },
    employe() {
      return this.getEmployeById(this.employeId)
    },
  },
  methods: {
    getDonneesByJour(_jour) {
      if (this.donnees && this.donnees.absences[_jour]) {
        return this.donnees.absences[_jour]
      } else {
        return undefined
      }
    },
    getCongesType(_jour) {
      let donnees = this.getDonneesByJour(_jour)
      if (donnees) {
        return donnees.first.shortLabel
      } else {
        return ''
      }
    },
    getNumeroDeMoisCorrespondant(_index) {
      if (_index < 10) {
        return '0'.concat(_index)
      } else {
        return ''.concat(_index)
      }
    },
    getNumeroDeJourCorrespondant(_index) {
      if (_index < 10) {
        return '0'.concat(_index)
      } else {
        return ''.concat(_index)
      }
    },
  },
}
</script>

<style scoped>
.employe {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 3px;
}

.jour {
  display: block;
}

.conges-apercu-cell-skeleton {
  display: flex;
  height: 100%;
  background: white;
  overflow: hidden;
}

.conges-apercu-cell-skeleton::after {
  background: linear-gradient(90deg, hsla(0, 0%, 100%, 0), rgba(0, 0, 0, 0.12), hsla(0, 0%, 100%, 0));
  content: '';
  animation: loading 1.5s infinite;
  height: 100%;
  width: 100%;
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  transform: translateX(-100%);
  z-index: 1;
}
</style>

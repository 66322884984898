<template>
  <v-dialog v-model="showDialog" persistent max-width="600px">
    <template v-slot:activator="{ on }">
      <v-btn color="info" tile small dark v-on="on" class="ma-7">Changer de mot de passe</v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Changer de mot de passe</span>
      </v-card-title>
      <v-form ref="form" v-model="formValid">
        <v-card-text>
          <v-container class="ma-0">
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  label="Nouveau mot de passe"
                  v-model="updatePasswordCommand.newPassword"
                  :rules="passwordRules"
                  :type="showPassword ? 'text' : 'password'"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword = !showPassword"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Confirmation"
                  v-model="passwordConfirmation"
                  :rules="passwordConfirmationRules"
                  :type="showPasswordConfirmation ? 'text' : 'password'"
                  :append-icon="showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPasswordConfirmation = !showPasswordConfirmation"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-form>
      <v-card-actions>
        <v-spacer />
        <v-btn color="grey" text @click="close()">Annuler</v-btn>
        <v-btn color="info" :disabled="!formValid" @click="sendUpdatePasswordCommand()"> Mettre à jour </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'update-password-dialog',
  data() {
    return {
      formValid: false,
      showDialog: false,
      showPassword: false,
      showPasswordConfirmation: false,
      updatePasswordCommand: undefined,
      passwordConfirmation: undefined,
      passwordRules: [
        (value) => !!value || 'Entrer le nouveau mot de passe',
        (value) => (value && /\d/.test(value)) || 'Au moins un chiffre',
        (value) => (value && /[A-Z]{1}/.test(value)) || 'Au moins une majuscule',
        (value) => (value && /[^A-Za-z0-9]/.test(value)) || 'Au moins un caractère spécial',
        (value) => (value && value.length >= 8) || 'Au moins 8 caractères',
      ],
      passwordConfirmationRules: [
        (value) => value === this.updatePasswordCommand.newPassword || 'Les mots de passe ne correspondent pas',
      ],
    }
  },
  computed: {
    ...mapGetters({
      selectedEtablissement: 'application/getSelectedEtablissement',
      utilisateur: 'security/getUtilisateur',
    }),
  },
  created() {
    this.passwordConfirmation = undefined
    this.updatePasswordCommand = {
      utilisateurId: this.utilisateur.utilisateurId,
      newPassword: undefined,
    }
  },
  methods: {
    ...mapActions({
      dispatchUpdatePassword: 'utilisateur/updatePassword',
    }),
    sendUpdatePasswordCommand() {
      this.dispatchUpdatePassword(this.updatePasswordCommand)
      this.close()
    },
    close() {
      this.showDialog = false
      this.reset()
    },
    reset() {
      this.$refs.form.reset()
      this.passwordConfirmation = undefined
      this.updatePasswordCommand = {
        utilisateurId: this.utilisateur.utilisateurId,
        newPassword: undefined,
      }
    },
  },
}
</script>

<style scoped></style>

<template>
  <v-form v-model="formValid">
    <v-container>
      <v-row>
        <v-col cols="6" class="py-0">
          <s-date-picker v-model="command.dateDebut" label="Date de début" required :maxISODate="command.dateFin" />
        </v-col>
        <v-col cols="6" class="py-0">
          <s-date-picker v-model="command.dateFin" label="Date de fin" required :minISODate="command.dateDebut" />
        </v-col>
        <v-col cols="12" class="py-0">
          <employe-autocomplete
            v-model="remplacant"
            :etablissement="etablissement"
            label="Remplaçant"
            required
            vacataires
          />
        </v-col>
        <v-col cols="12" class="py-0">
          <contrat-type-autocomplete v-model="command.type" remplacement required />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import employeAutocomplete from '../employe/employe-autocomplete'
import contratTypeAutocomplete from './contrat-type-autocomplete'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'contrat-de-remplacement-form',
  components: {
    employeAutocomplete,
    contratTypeAutocomplete,
  },
  data: () => ({
    formValid: false,
    numericFieldRule: [(v) => !isNaN(v) || 'Entrer une valeur numérique'],
    command: undefined,
  }),
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.command = this.value
    this.fetchContratTypes()
  },
  computed: {
    ...mapGetters({
      etablissement: 'application/getSelectedEtablissement',
      getEmployeById: 'employe/getEmployeById',
    }),
    remplacant: {
      get() {
        return this.getEmployeById(this.command.remplacantId)
      },
      set(_employe) {
        this.command.remplacantId = _employe.employeId
      },
    },
  },
  watch: {
    formValid() {
      this.$emit('formValidity', this.formValid)
    },
    command() {
      this.$emit('input', this.command)
    },
  },
  methods: {
    ...mapActions({
      fetchContratTypes: 'contrat/fetchContratTypes',
    }),
  },
}
</script>

<style scoped></style>

import { mapGetters } from 'vuex'

export const authenticated = {
  ...mapGetters('security', ['authenticated']),
}

export const user = {
  ...mapGetters('security', ['getAuthenticatedUser']),
}

export const etablissement = {
  ...mapGetters('application', ['getSelectedEtablissement']),
}

export const initialDataLoaded = {
  ...mapGetters('application', ['loaded']),
}

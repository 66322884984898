<template>
  <v-list-item class="py-0" @click="showCongesCreditUpdateDialog = true">
    <conges-credit-update-dialog
      v-if="canUpdate"
      v-model="showCongesCreditUpdateDialog"
      :congesCreditToUpdate="congesCredit"
      :employe="employe"
    />
    <v-list-item-content class="py-1">
      <v-list-item-title justify="center">
        <span>le {{ displayedDate }}</span>
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ commentaire }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <div>
        <v-icon v-if="congesCredit.exceptionnel" color="yellow darken-3" class="pr-1"> fa-star </v-icon>
        <v-badge :color="color" :content="congesCredit.nbJours" overlap>
          <v-chip outlined label>
            {{ congesCredit.type.label }}
          </v-chip>
        </v-badge>
      </div>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import CongesCreditUpdateDialog from './conges-credit-update-dialog'
import { mapGetters } from 'vuex'

export default {
  name: 'conges-credit-debit-list-item',
  components: {
    CongesCreditUpdateDialog,
  },
  props: {
    congesCredit: {
      type: Object,
      required: true,
    },
    employe: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showCongesCreditUpdateDialog: false,
    }
  },
  computed: {
    ...mapGetters({
      isInRole: 'security/isInRole',
      formatDateToDisplay: 'calendrier/formatDateToDisplay',
    }),
    canUpdate() {
      return this.isInRole('ADMINISTRATEUR_' + this.employe.etablissement.etablissementId)
    },
    commentaire() {
      if (this.congesCredit.commentaire && this.congesCredit.commentaire !== '') {
        return this.congesCredit.commentaire
      } else {
        if (this.congesCredit.exceptionnel) {
          return '-'
        } else {
          return ''
        }
      }
    },
    displayedDate() {
      return this.formatDateToDisplay(this.congesCredit.date)
    },
    color() {
      return this.congesCredit.nbJours > 0
        ? 'success lighten-4 success--text text--darken-4'
        : 'error lighten-4 error--text text--darken-4'
    },
  },
}
</script>
<style scoped></style>

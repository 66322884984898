<template>
  <div class="planning-body-jour" :style="style">
    <div class="planning-body-jour-container">
      <planning-body-slot
        v-for="borneHoraire in bornesHoraire"
        :key="borneHoraire.borneHoraireId"
        :borne-horaire="borneHoraire"
        :jour="jour"
        :jour_nuit="isJourNuit(borneHoraire)"
        :ferie="ferie"
        :slot-height="slotHeight"
      />
      <planning-creneau-container
        :creneaux="creneauxInBornes"
        :joursDeRepos="joursDeRepos"
        :semaine="semaine"
        :jour="jour"
        :membres="membres"
        :borneDebut="bornesHoraire[0]"
        :borneFin="bornesHoraire[bornesHoraire.length - 1]"
        :displayJoursDeRepos="displayJoursDeRepos"
        :disabled="apercu"
        :slot-height="slotHeight"
        apercu
      />
    </div>
  </div>
</template>

<script>
import planningBodySlot from './planning-body-slot'
import planningCreneauContainer from '@/components/planning/creneau/planning-creneau-container'
import { mapGetters } from 'vuex'

export default {
  name: 'planning-body-jour',
  components: {
    planningBodySlot,
    planningCreneauContainer,
  },
  props: {
    slotHeight: {
      type: Number,
      requred: true,
    },
    creneaux: {
      type: Array,
      required: true,
    },
    joursDeRepos: {
      type: Array,
      required: true,
    },
    semaine: {
      type: Object,
      required: true,
    },
    jour: {
      type: Object,
      required: true,
    },
    membres: {
      type: Array,
      required: true,
    },
    displayJoursDeRepos: {
      type: Boolean,
      required: false,
      default: false,
    },
    apercu: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      getBornesHoraireJournee: 'calendrier/getBornesHoraireJournee',
      getDateFromISOWeekDay: 'calendrier/getDateOfISOWeekDay',
      isDateFerie: 'calendrier/isISODateFerie',
      isJourNuit: 'calendrier/isJourNuit',
    }),
    creneauxInBornes() {
      return this.creneaux.filter(
        (c) =>
          (c.heureDebut.valeur >= this.bornesHoraire[0].valeur &&
            c.heureDebut.valeur < this.bornesHoraire[this.bornesHoraire.length - 1].valeur) ||
          (c.heureFin.valeur > this.bornesHoraire[0].valeur &&
            c.heureFin.valeur <= this.bornesHoraire[this.bornesHoraire.length - 1].valeur)
      )
    },
    bornesHoraire() {
      return this.getBornesHoraireJournee()
    },
    date() {
      return this.getDateFromISOWeekDay(this.semaine.annee, this.semaine.semaine, this.jour.jourId)
    },
    ferie() {
      return this.isDateFerie(this.date)
    },
    style() {
      return {
        'border-left': this.apercu ? 'none' : 'solid #c0c0c0 5px',
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.planning-body-jour {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex: 1;
  position: relative;
}

.planning-body-jour-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex: 1;
  position: relative;
}
</style>

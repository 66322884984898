import Vue from 'vue'
import axios from 'axios'
import vuetify from './plugins/vuetify'
import '@/components/global/globalComponents'
import App from './App.vue'
import store from './store'
import router from './router'
import moment from 'moment'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

moment.locale('fr')
axios.defaults.headers.common['Access-Control-Allow-Origin'] = 'localhost'
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      console.log('Code 401 reçu')
      store.dispatch('security/logout')
    }
    return Promise.reject(error)
  }
)

Vue.use(PerfectScrollbar)

new Vue({
  render: (h) => h(App),
  store,
  vuetify,
  router,
  created() {
    this.$store.dispatch('security/retrieveTokenFromStorage')
    this.$store.dispatch('application/retrieveSelectedEtablissementFromStorage')
  },
}).$mount('#app')

<template>
  <v-dialog v-model="visible" :disabled="disabled" persistent max-width="600px">
    <template v-slot:activator="{ on }">
      <span v-on="on">
        <slot />
      </span>
    </template>
    <v-card>
      <v-toolbar :color="infoLevel" dark class="mb-4">
        <v-toolbar-title class="headline">{{ dialogTitle }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <p style="text-align: justify">{{ message }}</p>
        <template v-if="texteDeConfirmation !== undefined">
          <p>
            Afin de confirmer cette action, veuillez saisir le texte suivant dans le champ de confirmation:
            <b>{{ texteDeConfirmation }}</b>
          </p>
          <v-text-field
            v-model="saisieDeConfirmation"
            label="Confirmation"
            outlined
            clearable
            hide-details
          ></v-text-field>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn :disabled="disabled" color="grey darken-1" text @click="visible = false">Annuler</v-btn>
        <v-btn
          :color="infoLevel"
          :dark="confirmationFormValid"
          @click="emitConfirmation()"
          fab
          x-small
          :disabled="!confirmationFormValid || disabled"
        >
          <v-icon>{{ icon }}</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 's-confirmation-dialog',
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    infoLevel: {
      type: String,
      required: true,
    },
    dialogTitle: {
      type: String,
      required: false,
      default: 'Êtes-vous sûr.e ?',
    },
    confirmBtnIcon: {
      type: String,
      required: false,
      default: 'mdi-check',
    },
    message: {
      type: String,
      required: false,
      default: 'Etes-vous sûr.e de vouloir effectuer cette action ?',
    },
    texteDeConfirmation: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      visible: false,
      saisieDeConfirmation: undefined,
    }
  },
  computed: {
    icon() {
      switch (this.infoLevel) {
        case 'error':
          return 'fa-trash'
        case 'warning':
          return 'mdi-check'
        case 'info':
        default:
          return 'mdi-check'
      }
    },
    confirmationFormValid() {
      return this.texteDeConfirmation === undefined || this.texteDeConfirmation === this.saisieDeConfirmation
    },
  },
  methods: {
    emitConfirmation() {
      this.visible = false
      this.$emit('confirmation')
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>

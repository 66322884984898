var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [(_vm.loaded)?_c('v-list-item',{key:_vm.membre.membreId},[_c('v-list-item-avatar',[_c('utilisateur-avatar',{attrs:{"utilisateur":_vm.employe.utilisateur,"size":36}})],1),_c('v-list-item-content',[_c('membre-update-dialog',{attrs:{"membre":_vm.membre},on:{"click":(e) => e.stopPropagation()},model:{value:(_vm.showMembreUpdateDialog),callback:function ($$v) {_vm.showMembreUpdateDialog=$$v},expression:"showMembreUpdateDialog"}}),_c('membre-remplacer-dialog',{attrs:{"membreARemplacer":_vm.membre},model:{value:(_vm.showMembreRemplacerDialog),callback:function ($$v) {_vm.showMembreRemplacerDialog=$$v},expression:"showMembreRemplacerDialog"}}),_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.membre.nomComplet)}}),(_vm.membre.serviceId !== 0)?_c('v-list-item-subtitle',{staticClass:"text--primary",domProps:{"textContent":_vm._s(_vm.membre.heuresHebdo + 'h par semaine')}}):_vm._e()],1),_c('v-fade-transition',[(
            hover &&
            (_vm.isInRole('CHEF_DE_SERVICE_' + _vm.service.serviceId) ||
              _vm.isInRole('ADMINISTRATEUR_' + _vm.service.etablissement.etablissementId))
          )?_c('div',{staticClass:"overlay"},[_c('v-btn',{attrs:{"x-large":"","icon":"","color":"info","small":""},on:{"click":_vm.selectEmploye}},[_c('v-icon',[_vm._v("fa-eye")])],1),_c('v-btn',{attrs:{"x-large":"","icon":"","color":"info"},on:{"click":function($event){_vm.showMembreUpdateDialog = true}}},[_c('v-icon',[_vm._v("fa-edit")])],1),_c('v-btn',{attrs:{"x-large":"","icon":"","color":"success"},on:{"click":function($event){_vm.showMembreRemplacerDialog = true}}},[_c('v-icon',[_vm._v("fa-people-arrows")])],1)],1):_vm._e()]),_c('v-list-item-action',[(_vm.membre.serviceId !== 0)?_c('div',{staticClass:"membre-couleur-indicateur",style:({
            'background-color': _vm.membre.couleur,
            opacity: _vm.membre.titulaire ? 1 : 0,
          })}):_vm._e()])],1):_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="jours">
    <div class="jour" v-for="index in getNbDaysInMonth(position)" :key="index">
      <div class="body-2 text-center">
        {{ getDayOfTheWeekOfDate(''.concat(position.annee, '-', position.mois, '-', index)).shortLabel }}
      </div>
      <div class="jour body-2 text-center">
        {{ index }}
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ligne-jours-conges-apercu',
  props: {
    position: {},
  },
  computed: {
    ...mapGetters({
      getNbDaysInMonth: 'calendrier/getNbDaysInMonth',
      getDayOfTheWeekOfDate: 'calendrier/getDayOfTheWeekOfDate',
    }),
  },
}
</script>
<style>
.jours {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
}

.jour {
  display: inline flex;
  flex-direction: column;
  width: 100%;
  padding: 2px;
  align-items: center;
  justify-content: center;
}
</style>
